import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { GroupType } from 'app/groups/models/group-type';
import { isNil } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ForumPost, IForumPost } from '../forum/models/forum-post';
import { ForumTopic } from '../forum/models/forum-topic';
import { Upload } from 'app/shared/models';
import { StorageService } from 'app/core/storage.service';
@Injectable({
  providedIn: 'root',
})
export class PostService {
  private userId: string;
  uploadsMarkedForDeletion: Upload[] = [];
  constructor(
    private dao: DAO,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
      } else {
        this.userId = undefined;
      }
    });
  }

  public setNewPostProperties(
    post: ForumPost,
    forumId: string,
    groupId: string,
    groupType: GroupType
  ): ForumPost {
    post.key = this.dao.createPushId();
    post.ownerId = this.userId;
    post.forumId = forumId;
    post.created = new Date();
    post.groupId = groupId;
    post.groupType = groupType;
    post.lastUpdate = new Date();
    post.latestComment = new Date();
    post.topic = new ForumTopic();
    return post;
  }

  private async removeFileFromStorage(index, arr: Upload[]) {
    await this.storageService
      .deleteFile(arr[index].path, true)
      .toPromise()
      .then(() => {
        arr.splice(index, 1);
      })
      .catch((e) => {
        if (this.uploadsMarkedForDeletion.length !== 0) {
          arr.splice(index, 1);
        }
      });
  }

  cleanDeletedAttachments() {
    this.uploadsMarkedForDeletion.forEach(async (val, index, arr) => {
      await this.removeFileFromStorage(index, arr);
    });

    this.uploadsMarkedForDeletion = [];
  }

  public async createPost(
    forumId: string,
    post: ForumPost,
    groupId: string,
    groupType: GroupType
  ): Promise<ForumPost> {
    if (post && forumId) {
      if (isNil(post.key)) {
        this.setNewPostProperties(post, forumId, groupId, groupType);
      }

      await this.cleanDeletedAttachments();

      await this.dao
        .ref(`${DataConstants.FORUM_POSTS}${forumId}/${post.key}`)
        .set({ releation: 'owned' });
      await this.dao
        .ref(`${DataConstants.POSTS}${post.key}`)
        .set(ForumPost.toJson(post));
      return post;
    }
  }

  public async updatePost(
    forumId: string,
    post: ForumPost
  ): Promise<ForumPost> {
    if (post && post.key) {
      post.lastUpdate = new Date();
      await this.cleanDeletedAttachments();

      await this.dao
        .ref(`${DataConstants.POSTS}${post.key}`)
        .set(ForumPost.toJson(post));
      return post;
    }
  }

  public getPost$(postId: string): Observable<ForumPost> {
    return this.dao
      .object$<IForumPost<number>>(`${DataConstants.POSTS}${postId}`)
      .pipe(map((post) => this.getPostOrNull(post, postId)));
  }

  private getPostOrNull(post, postId: string): ForumPost {
    if (post) {
      return ForumPost.fromJson(post);
    }
  }

  public getPosts$(
    forumId: string,
    batch: number,
    lastKey?: string
  ): Observable<Array<ForumPost>> {
    return this.getPostIds$(forumId, batch, lastKey).pipe(
      mergeMap((postIds) =>
        combineLatest(postIds.reverse().map((postId) => this.getPost$(postId)))
      )
    );
  }

  private getPostIds$(
    forumId: string,
    batch: number,
    lastKey?: string
  ): Observable<Array<string>> {
    return this.dao
      .listData$(`${DataConstants.FORUM_POSTS}${forumId}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(map((posts) => posts.map((post) => post.key)));
  }

  public getPublicPosts$(
    groupType: GroupType,
    batch: number,
    lastKey?: string
  ): Observable<Array<ForumPost>> {
    return this.dao
      .listData$(`${DataConstants.GROUP_PUBLIC_POSTS}${groupType}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        map((posts) =>
          posts
            .reverse()
            .map((post) =>
              this.getPostOrNull(post.data as IForumPost<number>, post.key)
            )
        )
      );
  }

  public getUserRelatedPosts$(
    batch: number,
    lastKey?: string
  ): Observable<ForumPost[]> {
    return this.dao
      .listData$(`${DataConstants.USER_POSTS}${this.userId}`, (ref) => {
        if (lastKey) {
          return ref.orderByKey().limitToLast(batch).endAt(lastKey);
        } else {
          return ref.orderByKey().limitToLast(batch);
        }
      })
      .pipe(
        mergeMap((postIds) =>
          combineLatest(
            postIds.reverse().map((postId) => this.getPost$(postId.key))
          )
        )
      );
  }
}
