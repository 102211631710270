import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { IRouteData } from '../app.routing-model';
import { AuthService } from '../core/auth.service';
import { AccountPermission } from '../shared/consts/accountPermission';
import { RoutingService } from './routing.service';
import { MondoUser } from '../shared/models/user/mondoUser';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private routingService: RoutingService,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.getUserStatus$().pipe(
      take(1),
      map((status) => {
        if (status.loggedIn && !status.waitingForStatus && status.user) {
          const userCleared = this.isUserCleared(
            status.user,
            route.data as IRouteData
          );
          if (!userCleared) {
            this.authService.setRedirectUrl(state.url);
            this.routingService.navigateToUserDefault(status);
          }
          return userCleared;
        }
        this.authService.setRedirectUrl(state.url);
        return false;
      })
    );
  }

  private isUserCleared(user: MondoUser, routeData: IRouteData): boolean {
    if (
      routeData &&
      routeData.validAccountTypes &&
      _.isNumber(routeData.minimumPermission)
    ) {
      const hasValidAccountType = routeData.validAccountTypes.includes(
        user.type
      );
      const hasValidPermission = user.permission >= routeData.minimumPermission;
      return (
        (hasValidAccountType && hasValidPermission) ||
        user.permission === AccountPermission.admin
      );
    }
    return false;
  }
}
