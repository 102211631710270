import { Component } from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {
  public cookieDeclaration: HTMLElement;
  public cookieTemplate: HTMLElement;
  public cookieConsent: HTMLElement;
  public isCookieDeclarationShown: boolean;
  constructor() { }

  showCookieDeclaration() {
    this.isCookieDeclarationShown = true;

    // this.cookieTemplate = document.createElement('div');
    // this.cookieTemplate.setAttribute('id', 'cicc-template');
    // document.getElementById('ACMO-Cookie').appendChild(this.cookieTemplate);

    // this.cookieConsent = document.createElement('script');
    // this.cookieConsent.setAttribute('id', 'CookieConsent');
    // this.cookieConsent.setAttribute('src', 'https://policy.app.cookieinformation.com/uc.js');
    // this.cookieConsent.setAttribute('data-culture', 'EN');
    // this.cookieConsent.setAttribute('type', 'text/javascript');
    // document.getElementById('ACMO-Cookie').appendChild(this.cookieConsent);

    this.cookieDeclaration = document.createElement('script');
    this.cookieDeclaration.setAttribute('id', 'CookiePolicy');
    this.cookieDeclaration.setAttribute('src', 'https://policy.app.cookieinformation.com/cid.js');
    this.cookieDeclaration.setAttribute('data-culture', 'EN');
    this.cookieDeclaration.setAttribute('type', 'text/javascript');
    this.cookieDeclaration.setAttribute('async', 'false');
    document.getElementById('ACMO-Cookie').appendChild(this.cookieDeclaration);
  }
}
