<p *ngIf="showText">
  {{ 'press' | preventHardcoding }} "{{
    'saveToAcademondo' | preventHardcoding
  }}" {{ 'makeCVVisibleEmployersLatestChanges' | preventHardcoding }}
</p>

<div class="centeredBtnContainer">
  <button mat-raised-button color="primary" (click)="publishCv()">
    {{ 'saveToAcademondo' | preventHardcoding }}
  </button>
</div>
