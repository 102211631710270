<mat-card class="notification" *ngIf="fromUser$ | async as fromUser">
  <img *ngIf="fromUser.photoURL" mat-card-avatar [src]="fromUser.photoURL">
  <img style="height: 50px;" *ngIf="!fromUser.photoURL" mat-card-avatar [src]="'/assets/logos/Mayn.svg'">
  <mat-card-header (click)="routeTo.emit(notification)">
    <mat-card-subtitle>
      {{notification.title}}
    </mat-card-subtitle>
    <mat-card-content>
      {{notification.message}}
    </mat-card-content>

  </mat-card-header>
  <mat-card-footer>
    <div class="notification__created">
      {{ notification.created | lastSeen }}
    </div>
  </mat-card-footer>
</mat-card>

