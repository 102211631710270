import {
  MondoUser,
  JsonMondoUser,
  StartUpInfo,
  MondoVersion,
} from './mondoUser';
import {
  PersonalDetails,
  JsonPersonalDetails,
} from '../../../shared/models/cv/personal-details';
import { AccountPermission } from '../../consts/accountPermission';
import { AccountType } from '../../consts/accountType';
import { IFilter } from '../iFilter';

export class ScientistUser extends MondoUser {
  static fromJson(
    obj: JsonScientistUser<number>,
    uid: string,
    permission: AccountPermission
  ): ScientistUser {
    const user = MondoUser.fromJson(obj, uid, permission) as ScientistUser;
    user.personalDetails = obj.personalDetails
      ? PersonalDetails.fromJson(obj.personalDetails)
      : new PersonalDetails();
    return user;
  }

  static toJson(user: ScientistUser): JsonScientistUser<number> {
    return {
      email: user.email,
      dateCreated: user.dateCreated ? user.dateCreated.getTime() : null,
      lastLogin: user.lastLogin ? user.lastLogin.getTime() : null,
      photoURL: user.photoURL,
      displayName: user.displayName,
      type: user.type,
      publishedCv: user.publishedCv,
      startUpInfo: user.startUpInfo,
      version: user.version,
      welcomeEmail: user.welcomeEmail,
      personalDetails: user.personalDetails
        ? PersonalDetails.toJson(user.personalDetails)
        : null,
      filters: user.filters,
      coverUrl: user.coverUrl,
    };
  }

  constructor(
    public readonly uid,
    public dateCreated: Date = null,
    public email,
    public photoURL = '',
    public displayName = '',
    public type: AccountType = AccountType.industry,
    public readonly permission: AccountPermission = AccountPermission.basic,
    public publishedCv = '',
    public startUpInfo: StartUpInfo = new StartUpInfo(),
    public lastLogin: Date = null,
    public version: MondoVersion = MondoVersion.version2,
    public welcomeEmail = true,
    public personalDetails: PersonalDetails = new PersonalDetails(),
    public filters: IFilter[] = [],
    public coverUrl = ''
  ) {
    super(
      uid,
      dateCreated,
      email,
      photoURL,
      displayName,
      type,
      permission,
      publishedCv,
      startUpInfo,
      lastLogin,
      version,
      welcomeEmail,
      filters,
      coverUrl
    );
  }
}

interface JsonScientistUser<T> extends JsonMondoUser<T> {
  dateCreated: T;
  lastLogin: T;
  email: string;
  photoURL: string;
  displayName: string;
  type: AccountType;
  publishedCv: string;
  startUpInfo: StartUpInfo;
  version: MondoVersion;
  welcomeEmail: boolean;
  personalDetails: JsonPersonalDetails<T>;
  filters: IFilter[];
  coverUrl: string;
}
