<div>
  <h1 mat-dialog-title>{{ getPropertiesTitle() | preventHardcoding }}</h1>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'Name' | preventHardcoding }}</mat-label>
    <input
      matInput
      (keyup.enter)="save()"
      onFocus="this.select()"
      type="text"
      [(ngModel)]="name"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">
    {{ 'cancel' | preventHardcoding }}
  </button>
  <button
    submit
    mat-raised-button
    color="primary"
    [disabled]="name === ''"
    (click)="save()"
  >
    {{ 'Save' | preventHardcoding }}
  </button>
</div>
