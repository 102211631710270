import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MondoRoutes } from 'app/app.routing-model';
import { AuthService } from '../core/auth.service';
import { MondoFormBuilder } from '../core/mondo-form-builder';
import { RoutingService } from '../core/routing.service';
import { PasswordValidator } from 'app/shared/models/formValidators';
import { Subscription } from 'rxjs';
import { StrictEmailValidator } from '@helpers';

enum ActionMode {
  passwordReset = 'resetPassword',
  emailVerify = 'verifyEmail'
}

@Component({
  selector: 'app-email-action-manager',
  templateUrl: './email-action-manager.component.html',
  styleUrls: ['./email-action-manager.component.scss']
})
export class EmailActionManagerComponent implements OnInit, OnDestroy {
  error = false;
  actionCode: string;
  mode: ActionMode;
  sub: Subscription;
  form: FormGroup;
  passwordResetForm: FormGroup;
  modes = ActionMode;
  constructor(
    private route: ActivatedRoute,
    private fb: MondoFormBuilder,
    private routingService: RoutingService,
    private auth: AngularFireAuth,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, StrictEmailValidator]],
      password: ['', [Validators.required]]
    });
    this.passwordResetForm = this.fb.group({
      password1: [
        '',
        [
          PasswordValidator.strong,
          Validators.minLength(6),
          Validators.maxLength(25),
          Validators.required
        ]
      ],
      password2: [
        '',
        [
          PasswordValidator.strong,
          Validators.minLength(6),
          Validators.maxLength(25),
          Validators.required
        ]
      ]
    });
    this.sub = this.route.queryParams.subscribe(params => {
      this.mode = params.mode;
      this.actionCode = params.oobCode;
    });
  }

  validateEmail(): void {
    this.auth.auth
      .applyActionCode(this.actionCode)
      .then(() => {
        this.routingService.navigateToUrlWithDataObject(
          MondoRoutes.login
        );
      })
      .catch(() => {
        this.error = true;
      });
  }
  resetPassword(): void {
    this.auth.auth
      .confirmPasswordReset(this.actionCode, this.password1.value)
      .then(() => {
        this.routingService.navigateToUrlWithDataObject(
          MondoRoutes.login
        );
      })
      .catch(() => {
        this.error = true;
      });
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get password1() {
    return this.passwordResetForm.get('password1');
  }

  get password2() {
    return this.passwordResetForm.get('password2');
  }

  isValidPassword() {
    return this.passwordResetForm.valid && this.password1.value === this.password2.value;
  }

  resendValidationEmail() {
    this.authService
      .resendVerificationMail(this.email.value, this.password.value)
      .then(() => {
        this.routingService.navigateToRoot();
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
