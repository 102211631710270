import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
} from '@angular/core';
// import { CvBuilderService, CvService } from 'app/home';
// import { map, take } from 'rxjs/operators';
// import { Observable } from 'rxjs';
import { CV } from 'app/shared/models/cv/cv';
import { CvService } from 'app/stepper/cv/services';

@Component({
  selector: 'app-profile-progress-tracker',
  templateUrl: './profile-progress-tracker.component.html',
  styleUrls: ['./profile-progress-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileProgressTrackerComponent implements OnInit {
  @Input() cv: CV;

  // isBuilderShown$: Observable<boolean>;
  // isBuilderShown: boolean;

  constructor(
    // private cvBuilderService: CvBuilderService,
    private cvService: CvService
  ) {}

  ngOnInit() {
    // this.isBuilderShown$ = this.cvBuilderService.isBuilderShow$
    // .pipe(
    //   map(val => this.isBuilderShown = val)
    // );
  }

  getStatusPercentage(cv: CV): number {
    let required = 0;
    const nonEmptyPDFields = Object.keys(cv.personalDetails).filter((key) => {
      // return cv.personalDetails[key] !== 0 && cv.personalDetails[key].toString().trim().length > 0;
    });

    return Math.floor((5 / 11) * 100);
  }

  getRequiredStatusPercentage(cv: CV): number {
    return this.cvService.getRequirementStatusAndText(cv).status;
  }

  getMinRequirementsText(cv: CV): string {
    return this.cvService.getRequirementStatusAndText(cv).text;
  }

  // getCurrentCV(): Observable<CV> {
  //   return this.cvBuilderService.getCurrentCv().pipe(take(1));
  // }
}
