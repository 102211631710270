<div class="settings-container">
  <mat-tab-group
    class="main-container"
    mat-stretch-tabs
    (selectedTabChange)="tabChanged($event)"
    [(selectedIndex)]="selectedTab"
  >
    <!-- <mat-tab label="{{'profileData'| preventHardcoding}}">
    <div class="tab-container">
      <div *ngIf="(currentUser$ | async) as user">
        <app-company-profile
          *ngIf="user && isIndustry(user.type)"
          (userUpdate)="updateUser($event)"
          [user]="user"></app-company-profile>
        <app-scientist-profile
          *ngIf="user && isScientist(user.type)"
          (userUpdate)="updateUser($event)"
          [user]="user"></app-scientist-profile>
        <button
          mat-raised-button
          (click)="save()">
          {{'saveChanges'| preventHardcoding}}
        </button>
      </div>
    </div>
  </mat-tab> -->
    <mat-tab label="{{ 'notificationSettings' | preventHardcoding }}">
      <div class="tab-container">
        <app-notification-settings></app-notification-settings>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'resetPassword' | preventHardcoding }}">
      <div class="tab-container">
        <div>
          <app-reset-password
            [showOnBack]="false"
            [readOnly]="true"
          ></app-reset-password>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'changeEmail' | preventHardcoding }}">
      <div class="tab-container">
        <div>
          <app-change-email></app-change-email>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
