import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { RoutingModel, MondoRoutes } from '../app.routing-model';
import { AuthGuard } from 'app/core/auth.guard';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { ChatRoomMessagesComponent } from './chat-room/chat-room-messages/chat-room-messages.component';
import { ChatRoomListComponent } from './chat-room-list/chat-room-list.component';
import { NewChatDialogComponent } from './chat-room-list/new-chat-dialog/new-chat-dialog.component';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
// export const chat = RoutingModel.chat;
// const routes = [
//   {
//     path: chat.path + '/:chatRoomKey',
//     component: ChatComponent,
//     canActivate: [AuthGuard],
//     data: chat.routeData
//   },
//   {
//     path: chat.path,
//     redirectTo: chat.path + '/' + MondoRoutes.none
//   }
// ];
@NgModule({
  imports: [
    CommonModule,
    // RouterModule.forChild(routes),
    SharedModule,
    SharedUiModule,
  ],
  declarations: [ChatComponent, ChatRoomComponent, ChatRoomMessagesComponent, ChatRoomListComponent, NewChatDialogComponent]
})
export class ChatModule { }
