<form *ngIf="textFormControl" [formGroup]="textFormControl.parent">
  <div class="editor-wrapper" [class.popped]="popOut">
    <!-- <button (click)="togglePopOut()">
      Popout
    </button> -->
    <div class="text-label">
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="isShowEditor">
      <ckeditor [config]="editorConfig" [formControlName]="textEditorFormControlName"></ckeditor>
    </ng-container>    
  </div>
</form>
