import { Observable, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { PubmedService } from 'app/core/pubmed.service';
import {
  MedlineDate,
  PubMedAuthor,
  PubMedAuthorList,
  PubmedArticle,
} from 'app/shared/models/pubmed';
import { CvFormService } from 'app/stepper/cv/services/cv-form.service';
import { Author } from 'app/shared/models/cv/author';
import { Experience } from 'app/shared/models/cv/experience';
import { Publications } from 'app/shared/models/cv/publications';
import { Publication } from 'app/shared/models/cv/publication';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap, take, mergeMap } from 'rxjs/operators';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationsComponent implements OnInit {
  coAuthors: Author[] = [];
  allAuthors: Author[];
  articleLoader: boolean;
  article$: Observable<any>;
  articleSub: Subscription;
  filterArticles: PubmedArticle[];
  selectedArticleIndex: number;
  selectedArticle: PubmedArticle;
  @Input() form: MondoFormGroup<Experience>;

  private months = {
    Jan: '0',
    Feb: '1',
    Mar: '2',
    Apr: '3',
    May: '4',
    Jun: '5',
    Jul: '6',
    Aug: '7',
    Sep: '8',
    Oct: '9',
    Nov: '10',
    Dec: '11',
  };

  constructor(
    public cvFormService: CvFormService,
    private pubmed: PubmedService,
    private snackBar: MatSnackBar,
    private formBuilder: MondoFormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  get publications(): MondoFormGroup<Publications> {
    return this.form.getSafeGroup((x) => x.publications);
  }

  get publicationsArray(): MondoFormArray<Publication[]> {
    return this.publications.getSafeArray<Publication[]>(
      (x) => x.publicationsList
    );
  }

  searchArticle(event, searchString) {
    if (this.articleLoader || searchString.value === '') {
      return;
    }
    event.stopPropagation();
    this.selectedArticle = null;
    this.selectedArticleIndex = -1;
    this.filterArticles = [];
    if (this.articleSub) {
      this.articleSub.unsubscribe();
    }
    this.articleLoader = true;
    this.articleSub = this.pubmed
      .getArticleIdsFromTerm(searchString.value)
      .pipe(
        switchMap((ids) =>
          this.pubmed.getArticlesFromIds(ids).pipe(
            mergeMap((articles) => {
              const articlesPreFilter = this.pubmed.arrayObjectHack(articles);
              const filterNoTitleArticles = articlesPreFilter
                .filter(
                  (article) => !!article.MedlineCitation.Article.ArticleTitle
                )
                .map((article) => this.checkArticle(article));
              this.filterArticles = filterNoTitleArticles.filter(
                (art) =>
                  !this.publicationsArray.value.find(
                    (addedArt) =>
                      addedArt.pmId === art.MedlineCitation.PMID['_']
                  )
              );
              if (this.filterArticles.length) {
                return this.filterArticles;
              } else {
                this.articleLoader = false;
                let snackText = '';
                if (articlesPreFilter > filterNoTitleArticles) {
                  snackText +=
                    hardcodedValues.someArticlesWithoutTitleRemoved + '\n';
                }
                if (filterNoTitleArticles > this.filterArticles) {
                  snackText +=
                    hardcodedValues.someArticlesWereAlreadyAddedPublications;
                }
                if (snackText) {
                  this.pubMedSnackError(snackText);
                }
                return [];
              }
            })
          )
        ),
        take(1)
      )
      .subscribe(
        () => {
          this.articleLoader = false;
          // markchangedectection
          this.cdr.markForCheck();
        },
        (e) => {
          this.articleLoader = false;

          this.pubMedSnackError(e);
          this.clearSearchResult();
          // markchangedectection
          this.cdr.markForCheck();
        }
      );
  }

  addPublication(article: PubmedArticle) {
    this.selectedArticle = article;
    const citation = this.selectedArticle.MedlineCitation;
    if (this.selectedArticle && citation) {
      const authorArray = this.formBuilder.array([]);
      if (citation.Article && citation.Article.AuthorList) {
        if (Array.isArray(citation.Article.AuthorList.Author)) {
          citation.Article.AuthorList.Author.forEach((author) => {
            if (author.ForeName || author.LastName) {
              authorArray.push(
                this.formBuilder.group(Author.fromPubMed(author))
              );
            } else if (author.CollectiveName) {
              author.LastName = author.CollectiveName;
              authorArray.push(
                this.formBuilder.group(Author.fromPubMed(author))
              );
            }
          });
        } else {
          const _author = citation.Article.AuthorList.Author as any;
          if (_author.ForeName || _author.LastName) {
            authorArray.push(
              this.formBuilder.group(Author.fromPubMed(_author))
            );
          } else if (_author.CollectiveName) {
            _author.LastName = _author.CollectiveName;
            authorArray.push(
              this.formBuilder.group(Author.fromPubMed(_author))
            );
          }
        }
      }

      let journal = '';
      if (
        citation.Article.Journal &&
        citation.Article.Journal.ISOAbbreviation
      ) {
        journal = citation.Article.Journal.ISOAbbreviation;
      }

      const publication = {
        pmId: citation.PMID['_'],
        date: this.fromPubMedIssueDate(
          citation.Article.Journal.JournalIssue.PubDate
        ),
        title: citation.Article.ArticleTitle,
        journal: journal,
        authors: authorArray,
      };

      this.publicationsArray.push(this.formBuilder.group(publication));

      const index = this.filterArticles.indexOf(this.selectedArticle, 0);
      if (index > -1) {
        this.filterArticles.splice(index, 1);
      }

      setTimeout(() => {
        this.emitCoAuthors();
      }, 300);
    }
  }

  checkArticle(article: PubmedArticle): PubmedArticle {
    article.MedlineCitation.Article.ArticleTitle =
      this.checkIfArticleTitleIsObject(
        article.MedlineCitation.Article.ArticleTitle
      );
    return article;
  }

  checkIfArticleTitleIsObject(val: string | Object) {
    if (val instanceof Object) {
      return val['_'];
    } else {
      return val;
    }
  }

  addAllPublications() {
    const articlesToAdd = this.filterArticles.slice(0);
    articlesToAdd.forEach((pub) => {
      this.addPublication(pub);
    });
  }

  getMonthObject(month) {
    return this.months[month];
  }

  getPubDate(pubDate: any): string {
    return (pubDate as MedlineDate).MedlineDate
      ? (pubDate.MedlineDate as string).substring(0, 4)
      : pubDate.Year;
  }

  fromPubMedIssueDate(pubmedDate: any): Date {
    if (pubmedDate.MedlineDate) {
      const date = (pubmedDate.MedlineDate as string).substring(0, 4);
      return new Date(date);
    } else {
      const year = pubmedDate.Year;
      let month;
      if (isNaN(pubmedDate.Month)) {
        month = pubmedDate.Month
          ? this.getMonthObject((pubmedDate.Month as string).substring(0, 3))
          : 1;
      } else {
        month = pubmedDate.Month;
      }
      const day = pubmedDate.Day ? pubmedDate.Day : 1;
      return new Date(year, month, day);
    }
  }

  removePublication(i) {
    this.publicationsArray.removeAt(i);
    setTimeout(() => {
      this.emitCoAuthors();
    }, 300);
  }

  getAuthorName(al: PubMedAuthorList) {
    if (Array.isArray(al.Author)) {
      const alArray = al.Author as PubMedAuthor[];
      return alArray[0].ForeName + ' ' + alArray[0].LastName;
    } else {
      const alObj = al.Author as PubMedAuthor;
      if (alObj.CollectiveName) {
        return alObj.CollectiveName;
      } else {
        return alObj.ForeName + ' ' + alObj.LastName;
      }
    }
  }

  emitCoAuthors() {
    this.coAuthors = [];
    if (this.publicationsArray && this.publicationsArray.length > 0) {
      this.publicationsArray.value.forEach((pub) => {
        const authors = pub.authors;
        if (authors && authors.length > 0) {
          authors.forEach((author) => {
            let alreadyPresent = false;
            if (this.coAuthors && this.coAuthors.length > 0) {
              alreadyPresent = !!this.coAuthors.find(
                (addedAuthor) => addedAuthor.key === author.key
              );
            }
            if (!alreadyPresent) {
              this.coAuthors.push(author);
            }
          });
        }
      });
    }
    this.cvFormService.updateNetworkAuthors(this.coAuthors);
  }

  onChangePublication(val, i) {
    this.selectedArticle = val;
    this.selectedArticleIndex = i;
  }

  clearSearchResult() {
    this.articleLoader = false;
    this.articleSub.unsubscribe();
    this.filterArticles = [];
  }

  clearAddedArticles() {
    for (let i = this.publicationsArray.length - 1; i >= 0; i--) {
      this.publicationsArray.removeAt(i);
    }
    setTimeout(() => {
      this.emitCoAuthors();
    }, 300);
  }

  private pubMedSnackError(errMsg: string): void {
    this.snackBar.open(errMsg, null, {
      duration: 10000,
      panelClass: ['snackbar-position-center'],
    });
  }

  trackByFn(index, item) {
    return item.pmId || item.key;
  }
}
