import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private fns: AngularFireFunctions) { }

  public createFunctionPromise = <T = any, R = any>(name: string): ((data: T) => Promise<R>) => {
    const callable = this.fns.httpsCallable(name);
    return (data: T) => {
      return callable(data).toPromise();
    };
  }

  public createFunction = <T = any, R = any>(name: string): ((data: T) => Observable<R>) => {
    const callable = this.fns.httpsCallable(name);
    return (data: T) => {
      return callable(data);
    };
  }

  public createFunctionData = <T = any, R = any>(name: string): ((data: T) => Promise<R>) => {
    const callable = this.fns.httpsCallable(name);
    return async (data: T) => {
      // const trace = firebase.performance().trace(`functions:${name}`);
      // trace.start();
      const result = await callable(data).toPromise();
      // trace.stop();
      return result.data;
    };
  }

}
