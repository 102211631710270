<mat-card class="note">
  <mat-card-content>
    <div class="content">
      <div class="note__content">
        <span class="note-user-name">{{ note.authorName }}</span>
        {{ note.text }}
      </div>
      <span class="edit-delete-container">
        <button
          class="edit-delete"
          mat-icon-button
          [matMenuTriggerFor]="noteMenu"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
      </span>
      <div class="last-seen">
        <span><app-date [date]="note.created" [showTime]="true"></app-date></span>
      </div>
    </div>
    <!-- menu -->
    <mat-menu #noteMenu="matMenu">
      <button mat-menu-item (click)="delete(note.key, note.entityUid)">
        <mat-icon>delete</mat-icon>
        {{ 'delete' | preventHardcoding }}
      </button>
    </mat-menu>
  </mat-card-content>
</mat-card>
