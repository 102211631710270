<div class="title-container">
  <h1 class="title">{{ 'PlanSelectorTitle' | preventHardcoding }}</h1>
  <p class="sub-title">{{ 'PlanSelectorSubTitle' | preventHardcoding }}</p>
  <p>{{ 'PlanSelectorParagraph1' | preventHardcoding }}</p>
  <div class="bullets">
    <ul>
      <li *ngIf="'PlanSelectorListItem1' | preventHardcoding">
        {{ 'PlanSelectorListItem1' | preventHardcoding }}
      </li>
      <li *ngIf="'PlanSelectorListItem2' | preventHardcoding">
        {{ 'PlanSelectorListItem2' | preventHardcoding }}
      </li>
      <li *ngIf="'PlanSelectorListItem3' | preventHardcoding">
        {{ 'PlanSelectorListItem3' | preventHardcoding }}
      </li>
      <li *ngIf="'PlanSelectorListItem4' | preventHardcoding">
        {{ 'PlanSelectorListItem4' | preventHardcoding }}
      </li>
    </ul>
  </div>
</div>
<ng-template #loading>
  <app-splash-screen></app-splash-screen>
</ng-template>
<div
  class="plan-container"
  *ngIf="planResponse$ | async as reponse; else loading"
>
  <ng-container *ngFor="let plan of reponse.results">
    <div class="plan" *ngIf="plan.planterms_set.length > 1">
      <!-- <div class="plan"> -->
      <ng-container *ngIf="isImgLink(plan?.description)">
        <div class="plan-image">
          <img
            *ngIf="isImgLink(plan?.description)"
            class="search-result-logo"
            [src]="plan?.description"
            [default]="'assets/logos/AM-inv.svg'"
            alt="logo"
          />
        </div>
        <div class="plan-sub-title">{{ plan?.name }}</div>
      </ng-container>
      <ng-container *ngIf="!isImgLink(plan?.description)">
        <div class="plan-title">{{ plan?.name }}</div>
        <div class="plan-sub-title">{{ plan?.description }}</div>
      </ng-container>
      <div class="plan-terms-container" *ngIf="plan.planterms_set">
        <ng-container *ngFor="let term of plan.planterms_set">
          <div *ngIf="term.active && !term.code">
            <ng-container *ngIf="term.interval_type === 'year'">
              <div class="discount">
                <div
                  *ngIf="
                    showDiscount(
                      term.price,
                      getMonthlyPricePerYear(plan?.planterms_set)
                    )
                  "
                >
                  {{ 'saveDiscount' | preventHardcoding }}
                  {{
                    getDiscountPercentage(
                      term.price,
                      getMonthlyPricePerYear(plan?.planterms_set)
                    )
                  }}%
                </div>
              </div>
              <<<<<<< HEAD
              <del
                *ngIf="
                  showDiscount(
                    term.price,
                    getMonthlyPricePerYear(plan?.planterms_set)
                  )
                "
                class="term-price"
                >{{
                  getMonthlyPricePerYear(plan?.planterms_set)
                    | currency: term.currency:'symbol':'1.0-0'
                }}</del
              >
              <!-- <ins class=term-price>{{term.price  | currency:term.currency:'symbol':'1.0-0'}}</ins> -->
              <ins class="term-price"
                >{{ (term.price | number: '1.0-0').replace(',', '.') }},-</ins
              >
              <div class="interval-type">
                {{ 'Per' | preventHardcoding }}
                {{ term.interval_type | preventHardcoding }}
              </div>
            </ng-container>
            <ng-container *ngIf="term.interval_type === 'month'">
              <div class="discount"></div>
              <!-- <div class=term-price>{{term.price | currency:term.currency:'symbol':'1.0-0'}}</div> -->
              <ins class="term-price"
                >{{ (term.price | number: '1.0-0').replace(',', '.') }},-</ins
              >
              <div class="interval-type">
                {{ 'Per' | preventHardcoding }}
                {{ term.interval_type | preventHardcoding }}
              </div>
              =======
              <del
                *ngIf="
                  showDiscount(
                    term.price,
                    getMonthlyPricePerYear(plan?.planterms_set)
                  )
                "
                class="term-price"
                >{{
                  getMonthlyPricePerYear(plan?.planterms_set)
                    | currency: term.currency:'symbol':'1.0-0'
                }}</del
              >
              <ins class="term-price">{{
                term.price | currency: term.currency:'symbol':'1.0-0'
              }}</ins>
              <!-- <ins class=term-price>{{(term.price | number:'1.0-0').replace(",", ".")}},-</ins> -->
              <div class="interval-type">
                {{ 'Per' | preventHardcoding }}
                {{ term.interval_type | preventHardcoding }}
              </div>
            </ng-container>
            <ng-container *ngIf="term.interval_type === 'month'">
              <div class="discount"></div>
              <div class="term-price">
                {{ term.price | currency: term.currency:'symbol':'1.0-0' }}
              </div>
              <!-- <ins class=term-price>{{(term.price | number:'1.0-0').replace(",", ".")}},-</ins> -->

              <div class="interval-type">
                {{ 'Per' | preventHardcoding }}
                {{ term.interval_type | preventHardcoding }}
              </div>
              >>>>>>> cb1b9b769cb141857b60296f67e9346721d49571
            </ng-container>
            <button
              mat-raised-button
              color="primary"
              (click)="selectPlan(term.uuid)"
            >
              {{ 'Select' | preventHardcoding }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
