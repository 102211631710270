import { MondoUser } from '../user/mondoUser';
import { ChatRoomState } from './chat-room-state';
import { ChatMessage } from './chat-message';

export class ChatRoom implements ChatRoomJson<Date, MondoUser> {
  public static fromJson(
    obj: ChatRoomJson<number, string>,
    creator: MondoUser,
    users: MondoUser[],
    lastMessage: ChatMessage
  ): ChatRoom {
    return new ChatRoom(
      obj.key,
      creator,
      users,
      new Date(obj.created),
      obj.chatRoomState as ChatRoomState,
      obj.name,
      lastMessage
    );
  }

  public static toJson(chatRoom: ChatRoom): ChatRoomJson<number, string> {
    return {
      key: chatRoom.key,
      creator: chatRoom.creator.uid,
      created: chatRoom.created.getTime(),
      chatRoomState: chatRoom.chatRoomState,
      name: chatRoom.name,
    };
  }

  constructor(
    public key = '',
    public creator: MondoUser = null,
    public users: MondoUser[] = [],
    public created: Date = new Date(),
    public chatRoomState: ChatRoomState = ChatRoomState.private,
    public name = '',
    public lastMessage: ChatMessage = null
  ) {}
}

export interface ChatRoomJson<T, E> {
  key: string;
  creator: E;
  created: T;
  chatRoomState: ChatRoomState;
  name: string;
}
