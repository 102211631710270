import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import {
  MondoAbstractControl,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import {
  DateOptions,
  PickerMode,
} from 'app/shared/components/date-picker/date-picker.component';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { AuthService } from 'app/core/auth.service';
import { CV } from 'app/shared/models/cv/cv';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { CvFormService } from '../../services/cv-form.service';
import { Experience } from 'app/shared/models/cv/experience';
import { StaticItemsService } from 'app/core/static-items.service';
import { takeUntil } from 'rxjs/operators';
import {
  Category0,
  FieldOfInterest,
  Category1,
  Technique,
} from 'app/shared/models';
import { SuggestFieldOfInterestDialogComponent } from 'app/shared/components/suggest-field-of-interest-dialog/suggest-field-of-interest-dialog.component';
import { SuggestTechniqueDialogComponent } from 'app/shared/components/suggest-technique-dialog/suggest-technique-dialog.component';
import { SuggestCategory0DialogComponent } from 'app/shared/components/suggest-category0-dialog/suggest-category0-dialog.component';
import { SuggestCategory1DialogComponent } from 'app/shared/components/suggest-category1-dialog/suggest-category1-dialog.component';
import { acaNames } from 'aca-names';

@Component({
  selector: 'app-basic-step',
  templateUrl: './basic-step.component.html',
  styleUrls: ['./basic-step.component.scss'],
})
export class BasicStepComponent implements OnInit {
  @Input() form!: MondoFormGroup<PersonalDetails>;
  @Input() cvForm!: MondoFormGroup<CV>;
  @Input() experience: MondoFormGroup<Experience>;
  @Input() categories: string[] = [];
  @Input() btnText: string;
  @Input() vertical = true;
  destroy$: Subject<boolean> = new Subject();

  private patchStreamInterest = new BehaviorSubject<FieldOfInterest[]>([]);
  private patchStreamCategory0 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory1 = new BehaviorSubject<Category1[]>([]);
  private patchStreamTech = new BehaviorSubject<Technique[]>([]);
  showUploader = false;
  yearDateOffset = 365 * 24 * 60 * 60 * 1000;
  now = new Date();
  max = new Date(this.now.getTime() - this.yearDateOffset * 15);
  startDate = new Date(this.now.getTime() - this.yearDateOffset * 30);

  readonly dobPickerOptions: DateOptions = {
    startDate: this.startDate,
    pickerMode: PickerMode.day,
    disabled: false,
    placeholder: 'dateOfBirth',
    timePickerLabel: '',
    optionalHint: 'dateOfBirthHint',
    startView: 'multi-year',
    showClearBtn: true,
    maxDate: this.max,
  };

  constructor(
    private authService: AuthService,
    private staticItemsService: StaticItemsService,
    private dialog: MatDialog,
    private cvFormService: CvFormService
  ) {}

  ngOnInit() {
    if (!this.authService.canBeAnonymous) {
      this.exclude.setValue(false);
    }

    this.cvFormService
      .getFormChangeStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((t: CV) => {
        if (t) {
          if (this.checkForCategory(acaNames.Foi)) {
            this.patchStreamInterest.next(
              t.experience.interests.map((entry) =>
                FieldOfInterest.fromJson(entry)
              )
            );
          }
          if (this.checkForCategory(acaNames.Tech)) {
            this.patchStreamTech.next(
              t.experience.techniques.map((entry) => Technique.fromJson(entry))
            );
          }
          if (
            t.experience.category0 &&
            this.checkForCategory(acaNames.Category0)
          ) {
            this.patchStreamCategory0.next(
              t.experience.category0.map((entry) => Category0.fromJson(entry))
            );
          }
          if (
            t.experience.category1 &&
            this.checkForCategory(acaNames.Category1)
          ) {
            this.patchStreamCategory1.next(
              t.experience.category1.map((entry) => Category1.fromJson(entry))
            );
          }
        }
      });
  }

  deletePictureUrl() {
    this.pictureUrl.setValue('');
  }

  onProfilePicUpload(url: string) {
    this.showUploader = false;
    this.pictureUrl.setValue(url);
  }

  toggleUploader() {
    this.showUploader = !this.showUploader;
  }

  canBeAnonymous(): boolean {
    return this.authService.canBeAnonymous;
  }

  get imagePath(): string {
    return `/cv/${this.cvKey}`;
  }

  get cvKey(): string {
    return this.cvForm.getSafeControl((x) => x.key).value;
  }

  get pictureUrl(): MondoAbstractControl<string> {
    return this.form.getSafeControl((x) => x.pictureUrl);
  }

  get location(): MondoFormGroup<MondoLocation> {
    return this.form.getSafeGroup((x) => x.address);
  }

  get dateOfBirth(): MondoFormGroup<Date> {
    return this.form.getSafeGroup((x) => x.dob);
  }

  get email(): MondoFormGroup<String> {
    return this.form.getSafeGroup((x) => x.contactInfo.email);
  }

  get exclude(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.exclude);
  }

  checkForCategory(category: string) {
    return this.categories.indexOf(category) > -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get patchInterest$() {
    return this.patchStreamInterest.asObservable();
  }

  get patchCategory0$() {
    return this.patchStreamCategory0.asObservable();
  }

  get patchCategory1$() {
    return this.patchStreamCategory1.asObservable();
  }

  get patchTech$() {
    return this.patchStreamTech.asObservable();
  }

  get fieldsOfInterests$() {
    return this.staticItemsService.getFieldOfInterest$();
  }

  get labTechniques$() {
    return this.staticItemsService.getTechniques$();
  }

  get getCategories0$() {
    return this.staticItemsService.getCategories0$();
  }

  get getCategories1$() {
    return this.staticItemsService.getCategories1$();
  }

  openSuggestionDialog(dialog: string): void {
    if (dialog === 'tech') {
      this.dialog.open(SuggestTechniqueDialogComponent, { width: '500px' });
    } else if (dialog === 'interest') {
      this.dialog.open(SuggestFieldOfInterestDialogComponent, {
        width: '500px',
      });
    } else if (dialog === 'category0') {
      this.dialog.open(SuggestCategory0DialogComponent, { width: '500px' });
    } else if (dialog === 'category1') {
      this.dialog.open(SuggestCategory1DialogComponent, { width: '500px' });
    }
  }
}
