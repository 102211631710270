import { MapsAPILoader } from '@agm/core';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { CountryService } from 'app/core/country.service';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSelectorComponent implements OnInit {
  @Input() locationForm: MondoFormGroup<MondoLocation>;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  @Input() placeholderTranslation = 'address';
  @Input() titleTranslation = 'address';
  @Input() inputReadonly = false;
  @Input() hint: string;

  searchControl: FormControl;
  autocomplete: google.maps.places.Autocomplete;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    // private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private authService: AuthService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.searchControl = new FormControl();
    this.mapInit();
  }

  get formattedAddress() {
    return this.locationForm.value.formattedAddress;
  }

  get placeId() {
    return this.locationForm.value.placeId;
  }

  mapInit() {
    this.mapsAPILoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ['address'],
          // componentRestrictions: {country: 'dk'}
        }
      );

      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = this.autocomplete.getPlace();
          if (!place.geometry) {
            return;
          }
          this.locationForm.setValue(
            this.getMondoLocationFromPlace(
              place,
              this.searchElementRef.nativeElement.value
            )
          );
          // this.cdr.markForCheck();
        });
      });
    });
  }

  getMondoLocationFromPlace(
    place: google.maps.places.PlaceResult,
    formattedText: string
  ): MondoLocation {
    const mondoLocation = new MondoLocation();
    for (let i = 0; i < place.address_components.length; i++) {
      const addressPart = place.address_components[i];
      const addressType = addressPart.types[0];
      switch (addressType) {
        case AddressType.country:
          mondoLocation.country = this.countryService.getCountryFromCode(
            addressPart.short_name
          );
          break;
        case AddressType.route:
          mondoLocation.street = addressPart.long_name;
          break;
        case AddressType.street_number:
          mondoLocation.number = addressPart.short_name;
          break;
        case AddressType.postal_code:
          mondoLocation.postalCode = addressPart.short_name;
          break;
        case AddressType.locality:
          mondoLocation.city = addressPart.long_name;
          break;
      }
    }
    mondoLocation.placeId = place.place_id;
    mondoLocation.formattedAddress = formattedText;
    mondoLocation.latLng.lat = place.geometry.location.lat();
    mondoLocation.latLng.lng = place.geometry.location.lng();
    return mondoLocation;
  }

  onFocusOut(event) {
    setTimeout(() => {
      if (_.isEmpty(this.placeId) && !_.isEmpty(event.target.value)) {
        event.target.value = '';
        this.authService.notEnoughPermission('addressSelectorInvalid', 5000);
      } else if (_.isEmpty(event.target.value)) {
        this.locationForm.setValue(new MondoLocation());
      }
    }, 1000);
  }
}

enum AddressType {
  street_number = 'street_number',
  route = 'route',
  locality = 'locality',
  administrative_area_level_1 = 'administrative_area_level_1',
  country = 'country',
  postal_code = 'postal_code',
}
