import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MondoFormBuilder, MondoFormGroup } from 'app/core/mondo-form-builder';
import {
  FieldOfInterest,
  Technique,
  Country,
  Category0,
  Category1,
} from 'app/shared/models';
import { ItemType } from '../../shared/model/ItemType';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { ContactInfo } from 'app/shared/models/cv/contact-info';
import { Intro } from 'app/shared/models/cv/intro';
import { Experience } from 'app/shared/models/cv/experience';
import { WorkExperience } from 'app/shared/models/cv/work-experience';
import { Education } from 'app/shared/models/cv/education';
import { ResearchStay } from 'app/shared/models/cv/research-stay';
import { WorkShop } from 'app/shared/models/cv/work-shop';
import { TeachingExperience } from 'app/shared/models/cv/teaching-experience';
import { Publications } from 'app/shared/models/cv/publications';
import { Publication } from 'app/shared/models/cv/publication';
import { AdditionalInfo } from 'app/shared/models/cv/additional-info';
import { Network } from 'app/shared/models/cv/network';
import { Author } from 'app/shared/models/cv/author';
import { CV } from 'app/shared/models/cv/cv';
import { MondoLocation } from '../../job/model/mondoLocation';
import { LanguageDetails } from 'app/shared/models/languages/language-details';
import { Languages } from 'app/shared/models/languages/languages';
import {
  CPRValidator,
  StrictEmailValidator,
  getFormValidationErrors,
} from '@helpers';

import { acaConfig } from 'aca-config';

@Injectable()
export class CvFormService {
  private cvForm: MondoFormGroup<CV> = this.formBuilder.group<CV>({
    type: ItemType.CV,
    status: new FormControl(null),
    lastUpdate: new FormControl(null),
    name: '',
    key: '',
    ownerId: '',
    created: new FormControl(null),
    madePublic: new FormControl(null),
    experience0: 0,
    experience1: 0,
    experience2: 0,
    experience3: 0,
    experience4: 0,
    experience5: 0,
    expireWarned: false,
    personalDetails: this.formBuilder.group<PersonalDetails>({
      firstName: '',
      middleName: '',
      lastName: '',
      sex: '',
      academicTitle: '',
      companyName: '',
      department: '',
      pictureUrl: '',
      coverUrl: '',
      dob: new FormControl(null),
      exclude: false,
      address: this.formBuilder.group<MondoLocation>({
        street: '',
        number: '',
        formattedAddress: '',
        placeId: '',
        city: '',
        postalCode: '',
        country: new FormControl(new Country()),
        latLng: new FormControl(null),
      }),
      contactInfo: this.formBuilder.group<ContactInfo>({
        phone: '',
        email: new FormControl(null), //, [StrictEmailValidator]),
        website: '',
      }),
      currentJobSituation: new FormControl(3),
      nationality: '',
      linkedin: '',
      twitter: '',
      github: '',
      cpr: acaConfig.showApplicationProcessFunctions
        ? new FormControl('', [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern('^[0-9]*$'),
            CPRValidator,
          ])
        : '',
      graduateProgram: new FormControl(null),
      dimittendsats: new FormControl(null),
      akademikerkampagnen: new FormControl(null),
    }),
    intro: this.formBuilder.group<Intro>({
      text: '',
      exclude: false,
    }),
    experience: this.formBuilder.group<Experience>({
      workExperiences: this.formBuilder.array<WorkExperience[]>([]),
      educations: this.formBuilder.array<Education[]>([]),
      researches: this.formBuilder.array<ResearchStay[]>([]),
      workshops: this.formBuilder.array<WorkShop[]>([]),
      teachingExperiences: this.formBuilder.array<TeachingExperience[]>([]),
      techniques: this.formBuilder.array<Technique[]>([]),
      interests: this.formBuilder.array<FieldOfInterest[]>([]),
      category0: this.formBuilder.array<Category0[]>([]),
      category1: this.formBuilder.array<Category1[]>([]),
      category2: this.formBuilder.array<Category0[]>([]),
      category3: this.formBuilder.array<Category0[]>([]),
      category4: this.formBuilder.array<Category0[]>([]),
      category5: this.formBuilder.array<Category0[]>([]),
      publications: this.formBuilder.group<Publications>({
        bibliographicStyle: '',
        publicationsList: this.formBuilder.array<Publication[]>([]),
      }),
    }),
    languages: this.formBuilder.group<Languages>({
      languages: this.formBuilder.array<LanguageDetails[]>([]),
    }),
    additionalInfo: this.formBuilder.group<AdditionalInfo>({
      title: '',
      description: '',
      exclude: new FormControl(false),
    }),
    network: this.formBuilder.group<Network>({
      authors: this.formBuilder.array<Author[]>([]),
      exclude: new FormControl(false),
    }),
  });

  constructor(private formBuilder: MondoFormBuilder) {}

  public getForm(): MondoFormGroup<CV> {
    return this.cvForm;
  }

  public getFormChangeStream(): Observable<CV> {
    return this.cvForm.valueChanges.pipe(
      debounceTime(300),
      map((value) => {
        if (this.cvForm.valid) {
          return value;
        }
      })
    );
  }

  patchCV(cvNewData: CV): void {
    if (!cvNewData) {
      return;
    }
    this.cvForm.patchValue({
      key: cvNewData.key,
      ownerId: cvNewData.ownerId,
      name: cvNewData.name,
      lastUpdate: cvNewData.lastUpdate,
      published: cvNewData.created,
      personalDetails: cvNewData.personalDetails || new PersonalDetails(),
      intro: cvNewData.intro || new Intro(),
      additionalInfo: cvNewData.additionalInfo || new AdditionalInfo(),
    });
    const experienceData = cvNewData.experience;
    if (experienceData && this.experience) {
      this.experience.setControlSafe(
        (x) => x.workExperiences,
        this.getFormArray<WorkExperience>(experienceData.workExperiences)
      );

      this.experience.setControlSafe(
        (x) => x.educations,
        this.getFormArray<Education>(experienceData.educations)
      );
      this.experience.setControlSafe(
        (x) => x.researches,
        this.getFormArray<ResearchStay>(experienceData.researches)
      );
      this.experience.setControlSafe(
        (x) => x.workshops,
        this.getFormArray<WorkShop>(experienceData.workshops)
      );
      this.experience.setControlSafe(
        (x) => x.teachingExperiences,
        this.getFormArray<TeachingExperience>(
          experienceData.teachingExperiences
        )
      );
      this.experience.setControlSafe(
        (x) => x.techniques,
        this.getFormArray<Technique>(experienceData.techniques)
      );
      this.experience.setControlSafe(
        (x) => x.interests,
        this.getFormArray<FieldOfInterest>(experienceData.interests)
      );
      this.experience.setControlSafe(
        (x) => x.category0,
        this.getFormArray<Category0>(experienceData.category0)
      );
      this.experience.setControlSafe(
        (x) => x.category2,
        this.getFormArray<Category0>(experienceData.category2)
      );
      this.experience.setControlSafe(
        (x) => x.category1,
        this.getFormArray<Category1>(experienceData.category1)
      );
      this.experience.setControlSafe(
        (x) => x.category3,
        this.getFormArray<Category0>(experienceData.category3)
      );
      this.experience.setControlSafe(
        (x) => x.category4,
        this.getFormArray<Category0>(experienceData.category4)
      );
      this.experience.setControlSafe(
        (x) => x.category5,
        this.getFormArray<Category0>(experienceData.category5)
      );
      if (cvNewData.experience.publications) {
        this.updatePublications(cvNewData.experience.publications);
      }
    }
    const languageData = cvNewData.languages;
    if (languageData && this.languages) {
      this.languages.setControlSafe(
        (x) => x.languages,
        this.getFormArray<LanguageDetails>(languageData.languages)
      );
    }
    if (cvNewData.network) {
      this.updateNetworkAuthors(cvNewData.network.authors);
      this.network.setControlSafe(
        (x) => x.exclude,
        new FormControl(cvNewData.network.exclude)
      );
    }
  }

  private updatePublications(publications: Publications) {
    const publicationList = publications.publicationsList.map((pub) => {
      const fg = this.formBuilder.group<Publication>(pub as any);
      fg.setControlSafe((x) => x.authors, this.getFormArray(pub.authors));
      return fg;
    });
    this.publications.setControlSafe(
      (x) => x.publicationsList,
      this.formBuilder.array(publicationList)
    );
    this.publications.setControlSafe(
      (x) => x.bibliographicStyle,
      new FormControl(publications.bibliographicStyle)
    );
  }

  public updateNetworkAuthors(authors: Author[]) {
    this.network.setControlSafe(
      (x) => x.authors,
      this.getFormArray<Author>(authors)
    );
  }

  public getFormArray<T>(list: T[]): any {
    const formGroups = list.map((item) => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    return formArray;
  }

  get personalDetails(): MondoFormGroup<PersonalDetails> {
    return this.cvForm.getSafeGroup<PersonalDetails>((x) => x.personalDetails);
  }

  get contactInfo(): MondoFormGroup<ContactInfo> {
    return this.cvForm.getSafeGroup<ContactInfo>(
      (x) => x.personalDetails.contactInfo
    );
  }

  get intro(): MondoFormGroup<Intro> {
    return this.cvForm.getSafeGroup<Intro>((x) => x.intro);
  }

  get additionalInfo(): MondoFormGroup<AdditionalInfo> {
    return this.cvForm.getSafeGroup<AdditionalInfo>((x) => x.additionalInfo);
  }

  get experience(): MondoFormGroup<Experience> {
    return this.cvForm.getSafeGroup<Experience>((x) => x.experience);
  }

  get languages(): MondoFormGroup<Languages> {
    return this.cvForm.getSafeGroup<Languages>((x) => x.languages);
  }

  get publications(): MondoFormGroup<Publications> {
    return this.cvForm.getSafeGroup<Publications>(
      (x) => x.experience.publications
    );
  }

  get network(): MondoFormGroup<Network> {
    return this.cvForm.getSafeGroup<Network>((x) => x.network);
  }
}
