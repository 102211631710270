import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { ActionItem } from 'app/shared/models/action-item';
import { IBaseStepItem } from 'app/stepper/shared/model/IBaseStepItem';
import { MatDialog } from '@angular/material/dialog';
import { IntroDialogComponent } from 'app/stepper/shared/components/intro-dialog/intro-dialog.component';
import { ItemType } from 'app/stepper/shared/model/ItemType';
import {
  ResultSortingItem,
  ResultSortingMode,
} from 'app/shared/common/search.model';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBarComponent implements OnInit, OnDestroy {
  @Output() showBuilder = new EventEmitter<boolean>(true);
  @Output() showViewer = new EventEmitter<boolean>(true);
  @Output() addItem = new EventEmitter();
  @Output() deleteItem = new EventEmitter<string>();
  @Output() dublicateItem = new EventEmitter<IBaseStepItem>();
  @Output() editItem = new EventEmitter<IBaseStepItem>();
  @Output() publishItem = new EventEmitter<IBaseStepItem>();
  @Output() showItem = new EventEmitter<IBaseStepItem>();
  @Output() sortingChanged = new EventEmitter<ResultSortingMode>();
  @Input() sortingModes: ResultSortingItem[] = [];
  @Input() actionItems: ActionItem[];
  @Input() item: IBaseStepItem;
  @Input() title: string;
  @Input() allItems: IBaseStepItem[];
  @Input() published: string[];
  @Input() rootUrl: string;

  destroy$: Subject<boolean> = new Subject();
  itemTypes = ItemType;
  selectedView = Views.sideBySide;
  sortingModeSelected: ResultSortingMode;
  constructor(private media: MediaObserver, private dialog: MatDialog) {}

  ngOnInit() {
    this.media
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0]),
        takeUntil(this.destroy$)
      )
      .subscribe((change: MediaChange) => {
        if (
          change.mqAlias === 'xs' ||
          change.mqAlias === 'sm'
          // || change.mqAlias === 'md'
        ) {
          this.setupSmallScreen();
        } else {
          this.setupBigScreen();
        }
      });
    if (this.sortingModes.length > 0) {
      this.selectSortingMode(this.sortingModes[0].mode);
    }
  }

  getYouListLabel() {
    switch (this.item.type) {
      case ItemType.CV:
        return 'yourCVs';
      case ItemType.Job:
        return 'yourJobs';
    }
  }

  setupSmallScreen() {
    if (this.selectedView === Views.sideBySide) {
      this.toggleView(true, false);
    }
  }

  setupBigScreen() {
    this.toggleView(true, true);
  }

  toggleView(showBuilder: boolean, showViewer: boolean) {
    this.showBuilder.emit(showBuilder);
    this.showViewer.emit(showViewer);
    if (showBuilder && !showViewer) {
      this.selectedView = Views.builder;
    }
    if (!showBuilder && showViewer) {
      this.selectedView = Views.viewer;
    }
    if (showBuilder && showViewer) {
      this.selectedView = Views.sideBySide;
    }
  }

  add() {
    this.addItem.emit();
  }

  delete(key: string) {
    this.deleteItem.emit(key);
  }

  dublicate(item: IBaseStepItem) {
    this.dublicateItem.emit(item);
  }

  edit(item: IBaseStepItem) {
    this.editItem.emit(item);
  }

  publish(item: IBaseStepItem) {
    this.publishItem.emit(item);
  }

  show(item: IBaseStepItem) {
    this.showItem.emit(item);
  }

  isSearchItem(): boolean {
    return this.item.type === ItemType.CvSearch;
  }

  selectSortingMode(mode: ResultSortingMode): void {
    this.sortingModeSelected = mode;
    this.sortingChanged.emit(mode);
  }

  showHelp() {
    const dialogRef = this.dialog.open(IntroDialogComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.itemType = this.item.type;
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

enum Views {
  sideBySide = 'sideBySide',
  viewer = 'viewer',
  builder = 'builder',
}
