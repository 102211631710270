<div fxLayout="row" fxLayoutAlign="space-between">
  <div class="header_wrap">
    <div class="header_title" *ngIf="companyName">
      {{ companyName }}
      <small class="header_subtitle">
        <span *ngIf="researchGroupName"
          >{{ researchGroupName }}
          <span *ngIf="streetAddress || zip || city || countryCode">–</span>
        </span>

        <span *ngIf="streetAddress">
          {{ streetAddress
          }}<span *ngIf="!number && (zip || city || countryCode)">,</span>
        </span>

        <span *ngIf="number">
          {{ number }}<span *ngIf="zip || city || countryCode">,</span>
        </span>

        <span *ngIf="zip">
          {{ zip }}<span *ngIf="!city && countryCode">,</span>
        </span>

        <span *ngIf="city"> {{ city }}<span *ngIf="countryCode">,</span> </span>

        <span *ngIf="countryCode">
          {{ countryCode }}
        </span>
      </small>
      <small class="header_subtitle">
        <span *ngIf="job?.jobInfo?.jobType !== null">
          {{ 'JobType' + job?.jobInfo?.jobType | preventHardcoding }}
        </span>
      </small>
    </div>
  </div>

  <div *ngIf="job.company.pictureUrl" class="viewer_image right-align">
    <img [src]="job.company.pictureUrl" [default]="'assets/logos/AM-inv.svg'" />
  </div>
</div>
<div class="divider clearfix"></div>
