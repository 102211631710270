import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieComponent } from './cookie.component';
import { RoutingModel } from '../app.routing-model';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
export const cookie = RoutingModel.cookies;
const routes = [
  {
    path: cookie.path,
    component: CookieComponent,
    data: cookie.routeData
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  declarations: [CookieComponent]
})
export class CookieModule { }
