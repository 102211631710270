<div class="chosen-component chosen-margin" (keyup)="handleKeyPress($event)">
  <div #selector class="selector">
    <form [formGroup]="searchForm">
      <mat-form-field appearance="standard">
        <input
          #searchInput
          matInput
          type="text"
          value
          [readonly]="readonly"
          autocomplete="off"
          formControlName="query"
          (mouseenter)="openSelector()"
          (focus)="showSearchResults()"
          (blur)="hideSearchResults($event)"
          [placeholder]="placeholderText"
        />
      </mat-form-field>
    </form>
    <div
      #searchResultList
      class="search-wrapper"
      [perfectScrollbar]
      tabindex="0"
      id="search-result-list"
      (blur)="hideSearchResults($event)"
    >
      <div class="search-no-results" *ngIf="getSearchResults().length === 0">
        {{ 'weFoundNothingThatMatchesYourSearch' | preventHardcoding }}
      </div>
      <div class="outer-list">
        <div
          class="search-result"
          *ngFor="
            let result of alphabeticalSort(getSearchResults());
            trackBy: trackByFn
          "
          (click)="selectTag(result)"
          [ngClass]="{ 'result-selected': isSelected(result) }"
        >
          <div class="checkbox-container">
            <mat-icon *ngIf="isSelected(result)">check_box</mat-icon>
            <mat-icon *ngIf="!isSelected(result)"
              >check_box_outline_blank</mat-icon
            >
          </div>
          <div class="information-container">
            <span
              class="search-result-title"
              [innerHTML]="getDisplayText(result) | safeHtml"
            >
            </span>
            <span
              class="search-result-description"
              [innerHTML]="getDescriptionText(result) | safeHtml"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="selection-list" *ngIf="showSelectedItems">
    <div class="no-selection" *ngIf="selection.length === 0">
      {{ 'useTheSearchFieldAboveToBeginSelection' | preventHardcoding }}
    </div>
    <div
      class="selection-brick"
      *ngFor="let selected of alphabeticalSort(selection); trackBy: trackByFn"
      [matTooltip]="selected.text"
    >
      <div class="selection-brick-text">{{ selected.text }}</div>
      <div class="selection-brick-action">
        <mat-icon (click)="remove(selected)">clear</mat-icon>
      </div>
    </div>
  </div>
</div>
