import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SiteInfo } from 'app/shared/models/site-info';

@Component({
  selector: 'app-site-viewer-footer',
  templateUrl: './site-viewer-footer.component.html',
  styleUrls: ['../site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteViewerFooterComponent {

  @Input() site: SiteInfo;

  constructor() { }

  isPublic(): boolean {
    return false;
  }

  get email(): string {
    return this.site.email;
  }

  get website(): string {
    return this.site.websiteUrl;
  }
}
