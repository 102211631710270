<ng-container>
  <div *ngIf="!formSubmitted" class="row">
    <div class="col-md-12">
      <h4>
        {{ 'pleaseProvideCPRToCompleteYourRegistration' | preventHardcoding }}
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-area">
      <div *ngIf="formSubmitted">
        <div class="row">
          <div class="col-md-12 text-center bug-reported-success">
            {{ 'ApplicationSuccess' | preventHardcoding }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-button color="primary" (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-button
              color="primary"
              (click)="applicationHelperDialog(data.job)"
            >
              {{ 'Continue' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="formError">
        <div class="row">
          <div class="col-md-12 text-center bug-reported-failed">
            {{ formErrorMessage | preventHardcoding }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-button color="primary" (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button mat-button (click)="retry()">
              {{ 'tryAgain' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <form
        [formGroup]="applicationForm"
        *ngIf="applicationForm && !formSubmitted"
      >
        <div class="row">
          <div class="col-md-12">
            <mat-form-field
              appearance="standard"
              *ngIf="applicationForm.invalid"
            >
              <mat-label>{{
                'EnterCprWithoutDash' | preventHardcoding
              }}</mat-label>
              <input
                matInput
                formControlName="cpr"
                input="number"
                maxlength="10"
                minlength="10"
                required
              />
              <mat-error
                *ngIf="
                  applicationForm.get('cpr').hasError('required') ||
                  applicationForm.get('cpr').hasError('maxLength') ||
                  applicationForm.get('cpr').hasError('minLength')
                "
              >
                {{ 'CprMustBeFilledOut' | preventHardcoding }}
              </mat-error>
              <mat-error *ngIf="applicationForm.get('cpr').hasError('pattern')">
                {{ 'OnlyNumbersAllowedDontUseDash' | preventHardcoding }}
              </mat-error>
              <mat-error *ngIf="applicationForm.get('cpr').hasError('CPR')">
                {{ 'cpr' | preventHardcoding }}
                {{ 'invalid' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- {{applicationForm.getRawValue() | json}} -->
        <!-- <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard" class="email-mat-field">
              <mat-label>accounting email</mat-label>
              <input matInput formControlName="email">
              <mat-error *ngIf="applicationForm.get('email').hasError('required')">
                Email must be filled out.
              </mat-error>
              <mat-error *ngIf="applicationForm.get('email').hasError('email')">
                Email must be an email ex. support@academondo.com.
              </mat-error>
            </mat-form-field>
          </div>
        </div>                -->
        <!-- <div class="row">
          <div class="col-md-12">
            <mat-checkbox color="primary" formControlName="acceptConditions">
              we will store your cpr for registrering?
            </mat-checkbox>
          </div>
        </div>                         -->
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-button (click)="cancel()" [disabled]="formLoading">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-button
              color="primary"
              type="button"
              (click)="createApplication()"
              [disabled]="formLoading || applicationForm.invalid"
            >
              {{ 'Apply' | preventHardcoding }}
            </button>
          </div>
        </div>
        <app-splash-screen *ngIf="formLoading"></app-splash-screen>
      </form>
    </div>
  </div>
</ng-container>
