<!-- <ng-container *ngIf="formChange$ | async"></ng-container> -->
<ng-container *ngIf="showCandidateInput">
  <div class="form-group" [formGroup]="personalDetails">
    <mat-form-field appearance="standard">
      <mat-label>{{ 'firstName' | preventHardcoding }}</mat-label>
      <input
        matInput
        name="fname"
        formControlName="firstName"
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'surname' | preventHardcoding }}</mat-label>
      <input
        matInput
        name="lname"
        formControlName="lastName"
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'academicTitle' | preventHardcoding }}</mat-label>
      <input
        matInput
        type="text"
        name="title"
        formControlName="academicTitle"
        autocomplete="off"
      />
    </mat-form-field>
    <!-- <mat-form-field appearance="standard">
      <mat-label>{{'companyName'| preventHardcoding}}</mat-label>
      <input matInput formControlName="companyName">
    </mat-form-field> -->
    <!-- <div style="position: relative; padding: 10px;">
      <app-date-picker [dateOptions]="dobPickerOptions" [fControl]="dateOfBirth"></app-date-picker>
    </div> -->
    <!-- <div class="sub-group" formGroupName="address">
      <app-address-selector [locationForm]="location"></app-address-selector>
    </div> -->
    <mat-form-field appearance="standard">
      <mat-label>{{ 'education' | preventHardcoding }}</mat-label>
      <input matInput formControlName="nationality" autocomplete="off" />
    </mat-form-field>

    <!-- <app-file-upload [ratio]="3/4" btnText="{{'uploadProfilePicture'| preventHardcoding}}" [defaultPicture]="'/assets/logos/Mayn.svg'"
        [currentPictureUrl]="pictureUrl.value" [filePath]="cvKey" (complete)="onProfilePicUpload($event)"></app-file-upload> -->

    <div class="sub-group" formGroupName="contactInfo">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
        <input
          matInput
          type="email"
          name="email"
          formControlName="email"
          autocomplete="off"
        />
        <mat-error *ngIf="email?.errors?.email">{{
          'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'phone' | preventHardcoding }}</mat-label>
        <input
          matInput
          type="tel"
          name="phone"
          formControlName="phone"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('fois' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchInterest$"
          [controlName]="'interests'"
          [dataStream]="fieldsOfInterests$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('interest')">
          {{'suggestNewFoi'| preventHardcoding}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category0' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories0' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory0$"
          [controlName]="'category0'"
          [dataStream]="getCategories0$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category0')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category0' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category1' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories1' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory1$"
          [controlName]="'category1'"
          [dataStream]="getCategories1$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category1')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category1' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category2' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories2' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory2$"
          [controlName]="'category2'"
          [dataStream]="getCategories0$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category1')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category1' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category3' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories3' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory3$"
          [controlName]="'category3'"
          [dataStream]="getCategories3$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category1')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category1' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category4' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories4' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory4$"
          [controlName]="'category4'"
          [dataStream]="getCategories4$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category1')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category1' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="'Category5' | preventHardcoding"
    class="row justify-content-md-center"
  >
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories5' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory5$"
          [controlName]="'category5'"
          [dataStream]="getCategories5$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('category1')">
          {{'suggestNewX' | preventHardcodingFormatText: ('category1' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div *ngIf="'Tech' | preventHardcoding" class="row justify-content-md-center">
    <div class="col-md-12">
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('techs' | preventHardcoding)
          }}"
          [form]="experience"
          [controlName]="'techniques'"
          [patchStream]="patchTech$"
          [dataStream]="labTechniques$"
        >
        </app-multi-selector>
      </div>
      <!-- <div>
        <button
          class="suggest-button"
          mat-button
          (click)="openSuggestionDialog('tech')">
          {{'suggestNewX' | preventHardcodingFormatText: ('tech' | preventHardcoding)}}
        </button>
      </div> -->
    </div>
  </div>
  <div class="spacer"></div>
  <app-text-editor [textFormControl]="text" textEditorFormControlName="text">
    {{ 'JobMatchDetails' | preventHardcoding }}
  </app-text-editor>
</ng-container>
