import { Injectable } from '@angular/core';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from '../shared/consts/dataConstants';
import { ErrorLog, Log } from '../shared/models/log';
import { AuthService } from './auth.service';

@Injectable()
export class LoggingService {
  private now = new Date();
  constructor(
    private auth: AuthService,
    private db: DAO
  ) { }

  logAction(log: Log) {
    if (this.auth.getCurrentUser()) {
      return this.db
        .object(
          DataConstants.LOGS +
            this.auth.getCurrentUser().uid +
            '/' +
            log.action +
            '/' +
            this.now.getTime().toString()
        )
        .set(log);
    }
  }

  logError(error: Error) {
    const log = new ErrorLog(error);
    return this.logAction(log);
  }
}
