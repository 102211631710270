<div *ngIf="getNotifications() | async as notifications">
  <button
    class="more-btn"
    mat-icon-button
    [matMenuTriggerFor]="notificationMenu"
    (click)="markAllOpenAsSeen()"
  >
    <span>
      <mat-icon
        [matBadgeHidden]="(userNotificationStatus$ | async)?.unseen === 0"
        [matBadge]="(userNotificationStatus$ | async)?.unseen"
        matBadgeColor="warn"
      >
        notifications
      </mat-icon>
    </span>
  </button>
  <mat-menu
    #notificationMenu="matMenu"
    xPosition="before"
    class="notification__more-menu"
  >
    <div class="notification__top-menu">
      <div
        [ngClass]="
          (userNotificationStatus$ | async)?.unread > 0 ? '' : 'disabled'
        "
        (click)="markAllOpenAsRead(); $event.stopPropagation()"
      >
        {{ 'markAsRead' | preventHardcoding }}
      </div>
      <div [routerLink]="[routes.profileEditor, 0]">
        {{ 'settings' | preventHardcoding }}
      </div>
    </div>
    <div class="notification__stream-area menu-item-grow">
      <perfect-scrollbar cdkScrollable>
        <ul *ngIf="notifications?.length; else noNotifications">
          <li
            *ngFor="
              let notification of notifications | sortBy: 'desc':'id';
              trackBy: trackByFn
            "
            [ngClass]="'notification__' + notification.status"
          >
            <app-notification-item
              [notification]="notification"
              (routeTo)="routeTo($event)"
            ></app-notification-item>
          </li>
          <li *ngIf="!finished">
            <button
              class="load-more-btn"
              mat-button
              (click)="onScroll(); $event.stopPropagation()"
            >
              {{ 'loadMore' | preventHardcoding }}
            </button>
          </li>
        </ul>
        <div *ngIf="finished" style="text-align: center">
          {{ 'endOfDatabase' | preventHardcoding }}
        </div>
      </perfect-scrollbar>
    </div>
  </mat-menu>
</div>
<ng-template #noNotifications>
  <p class="empty-notification">
    {{ 'noNotificationText' | preventHardcoding }}
  </p>
</ng-template>
