<div class="form-group" [formGroup]="form">

  <div>
    <label>{{'searchThroughPubmed'| preventHardcoding}}</label>
    <mat-form-field appearance="standard">
      <mat-label>{{'searchByNameTitleOfPublication'| preventHardcoding}}</mat-label>
      <input matInput #searchInput type="text" (keydown.enter)="false" (keyup.enter)="searchArticle($event, searchInput)" />
      <button mat-button matSuffix mat-icon-button [disabled]="articleLoader" (click)="searchArticle($event, searchInput)">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-progress-bar class="progress-bar-pub" *ngIf="articleLoader" mode="indeterminate"></mat-progress-bar>
  <div *ngIf="filterArticles?.length > 0" class="filtered-results">
    <p>{{'searchResults'| preventHardcoding}} <span>({{filterArticles?.length}})</span></p>
    <button mat-button (click)="clearSearchResult()">{{'clearSearchResults'| preventHardcoding}}</button>
    <button mat-button (click)="addAllPublications()">{{'addAll'| preventHardcoding}}</button>
    <mat-list *ngIf="filterArticles?.length > 0">
      <mat-list-item *ngFor="let article of filterArticles; let i=index; trackBy: trackByFn">
        <p>
          <span>{{getAuthorName(article.MedlineCitation.Article.AuthorList)}}</span><span>, </span>
          "<a [href]="article.MedlineCitation.PMID['_'] | pubmedUrl" target="_blank"><span matTooltip="{{article.MedlineCitation.Article.ArticleTitle}}">{{article.MedlineCitation.Article.ArticleTitle | urlSizeLimiter: 30}}</span></a>"
          - {{article.MedlineCitation.Article.Journal.ISOAbbreviation}}, {{getPubDate(article.MedlineCitation.Article.Journal.JournalIssue.PubDate)}}
        </p>
        <span>
          
        </span>
        <span class="mx-auto"></span>
        <button (click)="addPublication(article)" mat-icon-button>
          <mat-icon mat-icon>add</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </div>

  <div class="fetched-results" *ngIf="publicationsArray?.value?.length">
    <p>{{'addedPublications'| preventHardcoding}} <span>({{publicationsArray?.value?.length}})  </span><button mat-button (click)="clearAddedArticles()">{{'removeAllArticles'| preventHardcoding}}</button></p>
    <mat-list>
      <mat-list-item *ngFor="let publication of publicationsArray.value; let i=index; trackBy: trackByFn">
        <div>
          <span *ngIf="publication.authors && publication.authors.length > 0">{{publication.authors[0]['foreName'] | shortenFirstCharInEveryWord}} {{publication.authors[0]['lastName']}}, </span> 
          "<a [href]="publication.pmId | pubmedUrl" target="_blank"><span matTooltip="{{publication.title}}"></span>{{publication.title | urlSizeLimiter: 30}}</a>"
          - {{publication.journal}}, {{publication.date?.getFullYear()}}
        </div>
        <span class="mx-auto"></span>
        <div>
          <button mat-icon-button (click)="removePublication(i)" class="remove-btn">
            <mat-icon mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
