import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { SiteService } from 'app/core/site.service';
import { FenerumService } from 'app/core/fenerum.service';
import { FenerumAccount } from 'app/shared/models/fenerum/fenerumAccount';
import { Site } from 'app/shared/models/site';
import { FenerumAccountResponse } from 'app/shared/models/fenerum/fenerumAccountResponse';
import { AuthService } from 'app/core/auth.service';
import { CreateRecipientComponent } from '../create-recipient/create-recipient.component';
import { Subject } from 'rxjs';
import { hardcodedValues } from 'hardcodedValues';
import { CvService } from 'app/stepper/cv/services';
import { CV } from 'app/shared/models/cv/cv';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss'],
})
export class CreateCustomerComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  accountForm: FormGroup;
  formSubmitted = false;
  formError = false;
  formErrorMessage = '';
  formLoading = false;
  accountExists = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { siteKey: string; cvKey: string; terms: string },
    private dialogRef: MatDialogRef<CreateCustomerComponent>,
    private fenerumService: FenerumService,
    private authService: AuthService,
    private siteService: SiteService,
    private cvService: CvService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fenerumService
      .accountAlreadyCreated(this.authService.getCurrentUser().uid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.accountExists = res;
        if (!this.accountExists) {
          this.accountForm = this.createFormGroup();
          this.populateForm();
        }
      });
  }

  private populateForm() {
    if (this.data.siteKey) {
      this.siteService
        .getSiteData(this.data.siteKey)
        .pipe(takeUntil(this.destroy$))
        .subscribe((site: Site) => {
          this.accountForm.get('company_name').setValue(site.siteInfo.nameSite);
          this.accountForm.get('code').setValue(site.ownerId);
          this.accountForm
            .get('legal_address')
            .setValue(
              site.siteInfo.addressSite.street +
                ' ' +
                site.siteInfo.addressSite.number
            );
          this.accountForm
            .get('legal_zipcode')
            .setValue(site.siteInfo.addressSite.postalCode);
          this.accountForm
            .get('legal_city')
            .setValue(site.siteInfo.addressSite.city);
          this.accountForm
            .get('legal_country')
            .setValue(site.siteInfo.addressSite.country.code);
        });
    } else if (this.data.cvKey) {
      this.cvService
        .getDraftCv(this.data.cvKey)
        .pipe(takeUntil(this.destroy$))
        .subscribe((cv: CV) => {
          this.accountForm
            .get('company_name')
            .setValue(
              `${cv.personalDetails.firstName} ${cv.personalDetails.lastName}`
            );
          this.accountForm.get('code').setValue(cv.ownerId);
          this.accountForm
            .get('legal_address')
            .setValue(
              cv.personalDetails.address.street +
                ' ' +
                cv.personalDetails.address.number
            );
          this.accountForm
            .get('legal_zipcode')
            .setValue(cv.personalDetails.address.postalCode);
          this.accountForm
            .get('legal_city')
            .setValue(cv.personalDetails.address.city);
          this.accountForm
            .get('legal_country')
            .setValue(cv.personalDetails.address.country.code);
        });
    }
  }

  private createFormGroup() {
    return new FormGroup({
      company_name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      legal_address: new FormControl('', [Validators.required]),
      legal_zipcode: new FormControl('', [Validators.required]),
      legal_city: new FormControl('', [Validators.required]),
      legal_country: new FormControl('', [
        Validators.required,
        Validators.maxLength(2),
        Validators.minLength(2),
      ]),
    });
  }

  createAccount(): void {
    this.accountForm.disable({
      onlySelf: false,
      emitEvent: true,
    });
    this.formLoading = true;
    const company_name = this.accountForm.get('company_name').value;
    const code = this.accountForm.get('code').value;
    const legal_address = this.accountForm.get('legal_address').value;
    const legal_zipcode = this.accountForm.get('legal_zipcode').value;
    const legal_city = this.accountForm.get('legal_city').value;
    const legal_country = this.accountForm.get('legal_country').value;
    const account = new FenerumAccount(
      company_name,
      code,
      legal_address,
      legal_zipcode,
      legal_city,
      legal_country
    );
    this.fenerumService
      .createAccount(account)
      // .pipe(takeUntil(this.destroy$))
      .then(
        (response: FenerumAccountResponse) => {
          this.formLoading = false;
          this.formSubmitted = true;
          account.uuid = response.uuid;
          this.fenerumService.createFenerumAccountInFirebase(
            account,
            FenerumAccount.toJson
          );
        },
        (err) => {
          this.formLoading = false;
          this.formErrorMessage =
            hardcodedValues.SomethingWentWrongPaymentAccount;
          this.formError = true;
        }
      );
  }

  retry(): void {
    this.formError = false;
    this.accountForm.enable({
      onlySelf: false,
      emitEvent: true,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openPaymentSite(): void {
    this.cancel();
    this.dialog.open(CreateRecipientComponent, {
      width: '500px',
      data: {
        userId: this.authService.getCurrentUser().uid,
        terms: this.data.terms,
        siteKey: this.data.siteKey,
        cvKey: this.data.cvKey,
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
