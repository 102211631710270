import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CV } from 'app/shared/models/cv/cv';

@Injectable()
export class CvBuilderService {
  isBuilderShow$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isViewerShow$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  currentCv$: BehaviorSubject<CV> = new BehaviorSubject(null);

  toggleBuilder(show?: boolean) {
    show ? this.isBuilderShow$.next(true) : this.isBuilderShow$.next(false);
  }

  toggleViewer(show?: boolean) {
    show ? this.isViewerShow$.next(true) : this.isViewerShow$.next(false);
  }

  getBuilderStatus(): Observable<boolean> {
    return this.isBuilderShow$.asObservable();
  }

  getViewerStatus(): Observable<boolean> {
    return this.isViewerShow$.asObservable();
  }

  setCurrentCv(cv: CV) {
    this.currentCv$.next(cv);
  }

  getCurrentCv(): Observable<CV> {
    return this.currentCv$.asObservable();
  }

}
