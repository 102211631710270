<div *ngIf="languages">
  <div class="skills-wrap" *ngIf="languageDetails.length && languageDetails[0]?.language?.name">
    <app-icon-title title="{{languageTitle}}" iconPath="/assets/icons/Fields1.svg"></app-icon-title>
    <ul class="double-column smaller-light">
      <ng-container *ngFor="let lang of languageDetails; trackBy: trackByFn">
        <li *ngIf="lang?.language?.name">
          <span>
            {{lang?.language?.name}} - {{lang?.level | preventHardcoding}}
          </span>
        </li>
      </ng-container>      
    </ul>
  </div>
</div>
