import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { FenerumService } from 'app/core/fenerum.service';
import { FenerumRecipient } from 'app/shared/models/fenerum/fenerumRecipient';
import { AuthService } from 'app/core/auth.service';
import { take } from 'rxjs/operators';
import { CreateSubscriptionComponent } from '../create-subscription/create-subscription.component';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-create-recipient',
  templateUrl: './create-recipient.component.html',
  styleUrls: ['./create-recipient.component.scss'],
})
export class CreateRecipientComponent implements OnInit {
  recipientForm: FormGroup;
  formSubmitted = false;
  formError = false;
  formErrorMessage = '';
  formLoading = false;
  recipientExists: boolean;
  accountUuid: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      terms: string;
      siteKey: string;
      cvKey: string;
    },
    private dialogRef: MatDialogRef<CreateRecipientComponent>,
    private fenerumService: FenerumService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.fenerumService
      .recipientAlreadyCreated(this.authService.getCurrentUser().uid)
      .pipe(take(1))
      .subscribe((res) => {
        this.recipientExists = res;
        if (!this.recipientExists) {
          this.recipientForm = this.createFormGroup();
          this.populateForm();
        }
      });
  }

  private async populateForm() {
    const ownAccount = await this.fenerumService.getAccount(this.data.userId);
    this.recipientForm.get('account').setValue(ownAccount.uuid);
    this.recipientForm.get('name').setValue(ownAccount.company_name);
    this.recipientForm
      .get('email')
      .setValue(this.authService.getCurrentUser().email);
  }

  private createFormGroup() {
    return new FormGroup({
      account: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      receive_invoice: new FormControl(true, [Validators.required]),
      receive_payment_confirmation: new FormControl(true, [
        Validators.required,
      ]),
      receive_subscription_notifications: new FormControl(true, [
        Validators.required,
      ]),
    });
  }

  retry(): void {
    this.formError = false;
    this.recipientForm.enable({
      onlySelf: false,
      emitEvent: true,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  openPaymentSite(): void {
    this.cancel();
    this.dialog.open(CreateSubscriptionComponent, {
      width: '500px',
      data: {
        userId: this.authService.getCurrentUser().uid,
        terms: this.data.terms,
        siteKey: this.data.siteKey,
        cvKey: this.data.cvKey,
      },
    });
  }

  async createRecipient() {
    this.recipientForm.disable({
      onlySelf: false,
      emitEvent: true,
    });
    this.formLoading = true;
    const account = this.recipientForm.get('account').value;
    const name = this.recipientForm.get('name').value;
    const email = this.recipientForm.get('email').value;
    const receive_invoice = this.recipientForm.get('receive_invoice').value;
    const receive_payment_confirmation = this.recipientForm.get(
      'receive_payment_confirmation'
    ).value;
    const receive_subscription_notifications = this.recipientForm.get(
      'receive_subscription_notifications'
    ).value;
    const fenerumRecipient: FenerumRecipient = {
      account,
      name,
      email,
      receive_invoice,
      receive_payment_confirmation,
      receive_subscription_notifications,
    };
    this.fenerumService
      .addRecipientAtFenerum(fenerumRecipient)
      // .pipe(take(1))
      .then(
        (response) => {
          // console.log('success: ', response)
          this.formLoading = false;
          this.formSubmitted = true;
          this.fenerumService.addFenerumRecipientInFirebase(
            fenerumRecipient,
            this.data.userId
          );
        },
        (err) => {
          // console.log('failed: ', err)
          this.formLoading = false;
          this.formErrorMessage =
            hardcodedValues.SomethingWentWrongRecipientEmail;
          this.formError = true;
        }
      );
  }
}
