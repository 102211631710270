import { hardcodedValuesDK } from '../src/hardcodedValuesDK';
import { hardcodedValuesENG } from '../src/hardcodedValuesENG';

export const hardcodedValues = hardcodedValuesDK;
// export const hardcodedValues = hardcodedValuesENG;

export const formatText = function (text: string, ...replacement: string[]) {
  return text.replace(/{(\d+)}/g, (match, number) => {
    return typeof replacement[number] != 'undefined'
      ? replacement[number]
      : match;
  });
};
