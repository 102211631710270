import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SiteInfo } from 'app/shared/models/site-info';

@Component({
  selector: 'app-site-viewer-content',
  templateUrl: './site-viewer-content.component.html',
  styleUrls: ['../site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteViewerContentComponent implements OnInit {
  @Input() siteInfo: SiteInfo | undefined;
  constructor() {}

  ngOnInit() {}

  // get title(): string {
  //   if (this.siteInfo.nameSite) {
  //     return `${hardcodedValues.about}  ${this.siteInfo.nameSite}`;
  //   }
  //   return hardcodedValues.aboutTheCompany;
  // }
}
