<ng-container *ngIf="!showAsFilters">
  <div
    class="site-viewer-element"
    *ngIf="
      site?.experience?.techniques?.length > 0 ||
      site?.experience?.interests?.length > 0 ||
      site?.siteInfo?.subtypes?.length > 0 ||
      site?.experience?.category0?.length > 0 ||
      site?.experience?.category1?.length > 0 ||
      site?.experience?.category2?.length > 0 ||
      site?.experience?.category3?.length > 0
    "
  >
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Foi' | preventHardcoding) && site?.experience?.interests?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.interests'" class="title_icon normal-regular">
            {{ 'Fois' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light side-by-side">
            <li
              *ngFor="
                let interest of site?.experience?.interests;
                trackBy: trackByFn
              "
            >
              {{ interest.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Category0' | preventHardcoding) &&
          site?.experience?.category0?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.category0'" class="title_icon normal-regular">
            {{ 'Category0' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light side-by-side">
            <li
              *ngFor="
                let cat0 of site?.experience?.category0;
                trackBy: trackByFn
              "
            >
              {{ cat0.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Category2' | preventHardcoding) &&
          site?.experience?.category2?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.category2'" class="title_icon normal-regular">
            {{ 'Categories2' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light side-by-side">
            <li
              *ngFor="
                let cat2 of site?.experience?.category2;
                trackBy: trackByFn
              "
            >
              {{ cat2.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Category1' | preventHardcoding) &&
          site?.experience?.category1?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.category1'" class="title_icon normal-regular">
            {{ 'Categories1' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light side-by-side">
            <li
              *ngFor="
                let cat1 of site?.experience?.category1;
                trackBy: trackByFn
              "
            >
              {{ cat1.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Category3' | preventHardcoding) &&
          site?.experience?.category3?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.category3'" class="title_icon normal-regular">
            {{ 'Categories3' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light side-by-side">
            <li
              *ngFor="
                let cat3 of site?.experience?.category3;
                trackBy: trackByFn
              "
            >
              {{ cat3.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('Tech' | preventHardcoding) &&
          site?.experience?.techniques?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.techniques'" class="title_icon normal-regular">
            {{ 'Techs' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light">
            <li
              *ngFor="
                let technique of site?.experience?.techniques;
                trackBy: trackByFn
              "
            >
              {{ technique.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-12"
        *ngIf="
          ('CompanySubtype' | preventHardcoding) &&
          site?.siteInfo?.subtypes?.length > 0
        "
      >
        <div class="site_technical">
          <h5 [id]="'siteSteps.subtypes'" class="title_icon normal-regular">
            {{ 'CompanySubtypes' | preventHardcoding }}
          </h5>
          <ul class="double-column smaller-light">
            <li
              *ngFor="
                let subtype of site?.siteInfo?.subtypes;
                trackBy: trackByFn
              "
            >
              {{ subtype.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="divider clearfix"></div>
  </div>
</ng-container>
<ng-container *ngIf="showAsFilters">
  <div class="chips" *ngIf="filters.length > 0">
    <app-i-filter-chip-list [filters]="filters"></app-i-filter-chip-list>
  </div>
</ng-container>
