import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FenerumAccount } from 'app/shared/models/fenerum/fenerumAccount';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { map, first } from 'rxjs/operators';
import { PlanResponse } from 'app/shared/models/fenerum/fenerumPlans';
import { FenerumSubscription } from 'app/shared/models/fenerum/fenerumSubscription';
import { FenerumRecipient } from 'app/shared/models/fenerum/fenerumRecipient';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from './helper.service';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root'
})
export class FenerumService {

  constructor(
    public snackBar: MatSnackBar,
    private db: DAO,
    private helperService: HelperService,
    private httpClient: HttpClient) { }

  public createAccount(account: FenerumAccount) {
    return this.createAccountAtFenerum(FenerumAccount.toJson(account));
  }

  public createFenerumAccountInFirebase(account: FenerumAccount, toJson: (item: FenerumAccount) => any) {
    this.setAccount(account, toJson);
}

  public addFenerumRecipientInFirebase(recipient: FenerumRecipient, userId: string) {
    this.setRecipient(recipient, userId);
}

  private createAccountAtFenerum(account: FenerumAccount) {
    return this.paymentHandler({handle: fenerumHandles.postAccount, json: account});
  }

  async setAccount(account: FenerumAccount, toJson: (account: FenerumAccount) => any) {
    return this.db.object(`${DataConstants.FENERUM_ACCOUNTS}${account.code}`).update(toJson(account));
  }

  async setRecipient(recipient: FenerumRecipient, userId: string) {
    return this.db.object(`${DataConstants.FENERUM_ACCOUNTS}${userId}/${DataConstants.FENERUM_RECIPIENT}`).update(recipient);
  }

  async getAccountSpecificStripeUrl(userId: string): Promise<string> {
    return this.db.object$(`${DataConstants.FENERUM_ACCOUNTS}${userId}`)
      .pipe(
        first(),
        map((result: FenerumAccount) => {
          const user_account_uuid =  result.uuid;
          const stripeUrl = `${environment.fenerumStripeCardUrl}${environment.academondoIdAtfenerum}/${user_account_uuid}/`;
          return stripeUrl;
        })
      ).toPromise();
  }

  public accountAlreadyCreated(userId: string) {
    return this.db
    .object(`${DataConstants.FENERUM_ACCOUNTS}${userId}`)
    .snapshotChanges()
    .pipe(
      map(snap => {
        return snap.payload.exists();
      })
    );

  }

  public subscriptionAlreadyCreated(userId: string) {
    return this.db
    .object(`${DataConstants.FENERUM_SUBSCRIPTION}${userId}`)
    .snapshotChanges()
    .pipe(
      map(snap => {
        return snap.payload.exists();
      })
    );
  }

  recipientAlreadyCreated(userId: string) {
    return this.db
    .object(`${DataConstants.FENERUM_ACCOUNTS}${userId}/${DataConstants.FENERUM_RECIPIENT}`)
    .snapshotChanges()
    .pipe(
      map(snap => {
        return snap.payload.exists();
      })
    );
  }

  async getAccount(userId: string): Promise<FenerumAccount> {
    return this.db.object$<FenerumAccount>(`${DataConstants.FENERUM_ACCOUNTS}${userId}`)
    .pipe(
      first()
    )
    .toPromise();
  }

  public getPlansAtFenerum(): Promise<PlanResponse> {
    return this.paymentHandler({handle: fenerumHandles.getPlans});
  }

  public addRecipientAtFenerum(fenerumRecipient: FenerumRecipient) {
    return this.paymentHandler({handle: fenerumHandles.postRecipients, json: fenerumRecipient});
  }
  public createSubscriptionAtFenerum(fenerumSubscription: FenerumSubscription) {
    return this.paymentHandler({handle: fenerumHandles.postSubscriptions, json: fenerumSubscription});
  }

  private paymentHandler = this.helperService.createFunctionPromise<{handle: string, json?: any}, any>(environment.paymentHandler);

  public async openStripe(stripeUrl: string) {
    return this.httpClient.get(stripeUrl);
  }
}

export enum fenerumHandles {
  postAccount = 'postAccount',
  getPlans = 'getPlans',
  postSubscriptions = 'postSubscriptions',
  postRecipients = 'postRecipients',
}
