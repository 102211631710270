<div class="image-intro-text">
  <span>{{ 'MoveTheGridToCropImage' | preventHardcoding }}</span>
  <span>{{ 'AspectRatioLocked' | preventHardcoding }}</span>
</div>
<image-cropper
  [backgroundColor]="'white'"
  [imageChangedEvent]="image"
  [maintainAspectRatio]="maintainAspectRatio"
  [containWithinAspectRatio]="containWithinAspectRatio"
  [roundCropper]="roundCropper"
  [aspectRatio]="aspectRatio"
  [resizeToWidth]="resizeToWidth"
  [resizeToHeight]="resizeToHeight"
  [canvasRotation]="canvasRotation"
  [transform]="transform"
  format="jpeg"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (loadImageFailed)="loadImageFailed()"
>
</image-cropper>
<div *ngIf="showPreview" class="cropped-image-result">
  <img
    [src]="croppedImage"
    [style.border]="croppedImage ? '1px solid black' : 'none'"
  />
</div>
<div class="image-buttons" mat-dialog-actions>
  <div>
    <button
      matTooltip="{{ 'rotateLeft' | preventHardcoding }}"
      mat-icon-button
      (click)="rotateLeft()"
    >
      <mat-icon>undo</mat-icon>
    </button>
    <button
      matTooltip="{{ 'rotateRight' | preventHardcoding }}"
      mat-icon-button
      (click)="rotateRight()"
    >
      <mat-icon>redo</mat-icon>
    </button>
    <button
      matTooltip="{{ 'zoomOut' | preventHardcoding }}"
      mat-icon-button
      (click)="zoomOut()"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <button
      matTooltip="{{ 'zoomIn' | preventHardcoding }}"
      mat-icon-button
      (click)="zoomIn()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      matTooltip="{{ 'resetImage' | preventHardcoding }}"
      mat-icon-button
      (click)="resetImage()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div>
    <input #imgFileInput hidden type="file" (change)="onNewFile($event)" />
    <button mat-raised-button (click)="imgFileInput.click()">
      {{ 'selectAnotherImage' | preventHardcoding }}
    </button>
    <button mat-raised-button color="primary" (click)="onSave()">
      {{ 'saveImage' | preventHardcoding }}
    </button>
    <button mat-button mat-dialog-close>
      {{ 'cancel' | preventHardcoding }}
    </button>
  </div>
</div>
