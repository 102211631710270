import { Compiler, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AccountType } from '../shared/consts/accountType';
import * as firebase from 'firebase/app';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { MondoUser, MondoVersion } from 'app/shared/models/user/mondoUser';
import { ErrorService } from './error.service';
import { RoutingService } from './routing.service';
import { AccountPermission } from 'app/shared/consts/accountPermission';
import { MatSnackBar } from '@angular/material/snack-bar';
import { hardcodedValues } from 'hardcodedValues';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { getFromJson, getJsonUser } from '@helpers';
import { PermissionService } from './permission.service';
import { DAO } from 'app/shared-services/db-access/dao';
import { IndustryUser } from 'app/shared/models/user/industryUser';
import { GroupType } from 'app/groups/models/group-type';
import { MatDialog } from '@angular/material';
import { PreviewCvComponent } from 'app/search/common/templates/preview-cv/preview-cv.component';
import { PreviewSiteComponent } from 'app/search/common/templates/preview-site/preview-site.component';
import { ScientistUser } from 'app/shared/models/user/scientistUser';

export class UserStatus {
  constructor(
    public loggedIn = false,
    public waitingForStatus = true,
    public user: MondoUser = null,
    public dbAccess = true
  ) {}
}

@Injectable()
export class AuthService {
  private redirectUrl: string;
  private userStatus: BehaviorSubject<UserStatus> =
    new BehaviorSubject<UserStatus>(new UserStatus());
  public logged = false;
  public userLogged: Subject<void> = new Subject<void>();

  private prevPermission: AccountPermission = null;
  private prevType: AccountType = null;
  private isAdministrator = false;
  canCreateMultipleSites = false;
  canCreateSite = false;
  canSearch = true; // set to true if search should be enabled without login
  canPublishJob = false;
  canUseNotes = false;
  canCreateJobFromSite = false;
  canBeAnonymous = false;
  canSeeAnonymous = false;
  canPublishCV = false;
  canPublishSite = false;
  canCreateJob = false;
  canSeeApplyJobButton = false;
  canEditCompanyDetails = false;
  canSeeApplyForEmployeeButton = false;
  canCreateDraftCommunities = false;
  canPublishCommunity = false;
  canCreatePublicCommunities = false;
  canCreateDraftEvents = false;
  canPublishEvent = false;
  canCreateDraftIdeas = false;
  canPublishIdea = false;
  canCreatePublicIdeas = false;

  public canDraftGroup(groupType: GroupType): boolean {
    switch (groupType) {
      case GroupType.Communities:
        return this.canCreateDraftCommunities;
      case GroupType.Events:
        return this.canCreateDraftEvents;
      case GroupType.Ideas:
        return this.canCreateDraftIdeas;
      default:
        return false;
    }
  }
  public canPublishGroup(groupType: GroupType): boolean {
    switch (groupType) {
      case GroupType.Communities:
        return this.canPublishCommunity;
      case GroupType.Events:
        return this.canPublishEvent;
      case GroupType.Ideas:
        return this.canPublishIdea;
      default:
        return false;
    }
  }

  get getPrevPermission(): AccountPermission {
    return this.prevPermission;
  }

  get getPrevType(): AccountType {
    return this.prevType;
  }

  get isScientist(): boolean {
    return AuthService.isUserOfScientistType(this.getPrevType);
  }

  get isCompany(): boolean {
    return AuthService.isUserOfCompanyType(this.getPrevType);
  }

  public static isUserOfScientistType(userType: AccountType): boolean {
    return (
      userType === AccountType.scientist ||
      userType === AccountType.labTech ||
      userType === AccountType.medLabTech ||
      userType === AccountType.ssoAuthedUser
    );
  }

  get isAdmin(): boolean {
    return this.isAdministrator;
  }

  get isEcorp(): boolean {
    return true;
    // return this.getCurrentUser().type === AccountType.labTech;
  }

  public static isUserOfCompanyType(userType: AccountType): boolean {
    return (
      userType === AccountType.industry || userType === AccountType.university
    );
  }

  constructor(
    private afAuth: AngularFireAuth,
    private db: DAO,
    private errorService: ErrorService,
    private permissionService: PermissionService,
    private routingService: RoutingService,
    private compiler: Compiler,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  afstateHandler() {
    return this.afAuth.authState.pipe(
      switchMap((user) => this.handleAuthState(user))
    );
  }

  handleAuthState(fbUser: firebase.User) {
    const validatedUser = this.getValidatedUser(fbUser);
    if (validatedUser) {
      return this.readUserByUid(fbUser.uid).pipe(
        tap((mondoUser: MondoUser) => {
          if (mondoUser) {
            this.logged = true;
            this.prevPermission = mondoUser.permission;
            this.prevType = mondoUser.type
              ? mondoUser.type
              : AccountType.scientist;
            if (mondoUser && !mondoUser.welcomeEmail) {
              mondoUser.welcomeEmail = true;
              this.updateUser(mondoUser);
            }
            if (
              mondoUser &&
              (!this.getCurrentUser() ||
                this.getCurrentUser().uid !== mondoUser.uid)
            ) {
              const now = new Date();
              mondoUser.lastLogin = now;
              this.updateUser(mondoUser);
              this.checkAndRedirectToUrl(
                new UserStatus(true, false, mondoUser)
              );
            }
            this.handleUserPermissions(mondoUser);
            this.publishUser(mondoUser);
          } else {
            console.debug(
              'user is authed, but not created or found in db.. FIXING!'
            );
            console.debug('create this user as a basic user!:', fbUser.uid);
            const dateCreated = new Date();
            const mondoUser = new MondoUser(
              fbUser.uid,
              dateCreated,
              fbUser.email,
              '',
              fbUser.displayName
            );
            this.createUser(mondoUser);
          }
        })
      );
    } else {
      if (this.logged) {
        this.userLogged.next();
        this.userLogged.complete();
      }
      this.logged = false;
      this.afAuth.auth.signOut();
      this.publishUser(null);
      return of(null);
    }
  }

  private checkAndRedirectToUrl(status: UserStatus) {
    if (this.redirectUrl) {
      if (this.redirectUrl.length) {
        this.routingService.navigateToUrlWithDataArray(this.redirectUrl);
      } else {
        this.routingService.navigateToUrlWithDataObject(this.redirectUrl);
      }
      this.redirectUrl = null;
    } else if (!this.routingService.isAllowedUrlForAll()) {
      this.routingService.navigateToUserDefault(status);
    }
  }

  readUserByUid(uid: string): Observable<MondoUser> {
    return this.db.object$(DataConstants.USERS + uid).pipe(
      mergeMap((dbUser: any) => {
        if (dbUser) {
          return this.permissionService.getUserPermission(uid).pipe(
            map((permission) => {
              return getFromJson(dbUser, uid, permission);
            })
          );
        }
        return of(null);
      }),
      catchError((err) => {
        this.publishUser(null, false);
        return of(null);
      })
    );
  }

  createUser(user: MondoUser): Promise<void> {
    const now = new Date();
    user.dateCreated = now;
    user.version = MondoVersion.version2;
    return this.db
      .object(DataConstants.USERS + user.uid)
      .set(getJsonUser(user));
  }

  updateUser(user: MondoUser): Promise<void> {
    return this.db
      .object(DataConstants.USERS + user.uid)
      .update(getJsonUser(user));
  }

  updateUserField(field: object, userId: string) {
    if (field && userId) {
      return this.db.object(DataConstants.USERS + userId).update(field);
    }
  }

  public getUserStatus$(): Observable<UserStatus> {
    return this.userStatus.asObservable();
  }

  public getUserStatus(): UserStatus {
    return this.userStatus.value;
  }

  public getCurrentUser$(): Observable<MondoUser> {
    return this.getUserStatus$().pipe(map((status) => status.user));
  }

  public getCurrentUser(): MondoUser {
    return this.getUserStatus().user;
  }

  public emailLogin(email: string, password: string): Promise<void> {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        const validatedUser = this.getValidatedUser(userCredentials.user);
        if (validatedUser) {
          return;
        } else {
          const error = new Error(
            'email-not-verified'
          ) as firebase.FirebaseError;
          error.code = 'auth/email-not-verified';
          throw error;
        }
      })
      .catch((error) => {
        throw this.handleError(error);
      });
  }

  public emailSignUp(
    email: string,
    password: string,
    accountType: AccountType = AccountType.scientist
  ): Promise<MondoUser> {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((userData) =>
        userData.user.sendEmailVerification().then(() => {
          const mondoUser = MondoUser.fromFirebaseUser(
            userData.user,
            accountType,
            new Date()
          );
          this.createUser(mondoUser);
          return mondoUser;
        })
      )
      .catch((error) => {
        throw this.handleError(error);
      });
  }

  public resendVerificationMail(
    email: string,
    password: string
  ): Promise<void> {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((userData) => {
        userData.user.sendEmailVerification();
      });
  }

  private getValidatedUser(user: firebase.User): firebase.User {
    if (user && user.emailVerified) {
      return user;
    } else {
      return null;
    }
  }

  public resetPassword(email: string): Promise<void | Error> {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch((error) => this.handleError(error));
  }

  public changeEmail(newEmail: string): Promise<void | Error> {
    return firebase.auth().currentUser.updateEmail(newEmail);
  }

  public signOut(): void {
    this.afAuth.auth.signOut().then(() => {
      this.compiler.clearCache();
      this.publishUser(null);
      window.location.replace('/');
      this.routingService.navigateToUserDefault(this.getUserStatus());
    });
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  private handleError(error: firebase.FirebaseError): Error {
    return this.errorService.parseToMondoError(error);
  }

  private publishUser(user: MondoUser, access = true): void {
    if (user) {
      this.userStatus.next(new UserStatus(true, false, user, access));
    } else {
      this.userStatus.next(new UserStatus(false, false, null, access));
    }
  }

  public showCVPreview(cvKey: string) {
    if (cvKey) {
      this.dialog.open(PreviewCvComponent, {
        panelClass: 'preview-dialog',
        data: { key: cvKey },
      });
    }
  }

  public showCompanyPreview(siteKey: string) {
    if (siteKey) {
      this.dialog.open(PreviewSiteComponent, {
        panelClass: 'preview-dialog',
        data: { key: siteKey },
      });
    }
  }

  public showPreview(user: MondoUser): void {
    if (AuthService.isUserOfScientistType(user.type)) {
      // this.cvService.logView(cv); // todo
      const scientistUser = user as ScientistUser;
      const hasPublicProfileKey = scientistUser.publishedCv
        ? scientistUser.publishedCv
        : null;
      if (hasPublicProfileKey) {
        this.showCVPreview(hasPublicProfileKey);
      } else {
        this.notEnoughPermission('UserDoesntExistAnymore', 5000);
      }
    }

    if (AuthService.isUserOfCompanyType(user.type)) {
      // this.siteService.logView(site); // todo
      const companyUser = user as IndustryUser;
      const hasPublicProfileKey = companyUser.company
        ? companyUser.company.uid
        : null;
      this.showCompanyPreview(hasPublicProfileKey);
    }
  }

  public notEnoughPermission(text = 'notEnoughPermission', duration = 10000) {
    this.snackBar.open(
      hardcodedValues[text] ? hardcodedValues[text] : text,
      null,
      {
        duration: duration,
        panelClass: ['snackbar-position-center'],
      }
    );
  }

  private handleUserPermissions(mondoUser: MondoUser) {
    if (mondoUser) {
      this.isAdministrator = this.setIsAdministrator(mondoUser);
      this.canCreateMultipleSites = this.setCanCreateMultipleSites(mondoUser);
      this.canUseNotes = this.setNotesPermission(mondoUser);
      this.canSearch = this.setSearchPermission(mondoUser);
      this.canPublishJob = this.setCanPublishJob(mondoUser);
      this.canCreateJobFromSite =
        this.setCreateJobFromSitePermission(mondoUser);
      this.canSeeAnonymous = this.setCanSeeAnonymous(mondoUser);
      this.canBeAnonymous = this.setCanBeAnonymous(mondoUser);
      this.canPublishCV = this.setCanPublishCV(mondoUser);
      this.canPublishSite = this.setCanPublishSite(mondoUser);
      this.canCreateJob = this.setCanCreateJob(mondoUser);
      this.canSeeApplyJobButton = this.setCanSeeApplyJobButton(mondoUser);
      this.canSeeApplyForEmployeeButton =
        this.setCanSeeApplyForEmployeeButton(mondoUser);
      this.canEditCompanyDetails = this.setCanEditCompanyDetails(mondoUser);
      this.canCreateDraftCommunities =
        this.setCanCreateDraftCommunities(mondoUser);
      this.canPublishCommunity = this.setCanPublishCommunity(mondoUser);
      this.canCreatePublicCommunities =
        this.setCanCreatePublicCommunities(mondoUser);
      this.canCreateDraftIdeas = this.setCanCreateDraftIdeas(mondoUser);
      this.canPublishIdea = this.setCanPublishIdea(mondoUser);
      this.canCreatePublicIdeas = this.setCanCreatePublicIdeas(mondoUser);
      this.canCreateDraftEvents = this.setCanCreateDraftEvents(mondoUser);
      this.canPublishEvent = this.setCanPublishEvent(mondoUser);
      this.canCreateDraftIdeas = this.setCanCreateDraftIdeas(mondoUser);
      this.canPublishIdea = this.setCanPublishIdea(mondoUser);
    }
  }

  private setIsAdministrator(mondoUser: MondoUser) {
    return mondoUser.permission === AccountPermission.admin;
  }

  private setCanCreateMultipleSites(mondoUser: MondoUser) {
    return false;
    // return mondoUser.permission === AccountPermission.admin;
  }

  private setNotesPermission(mondoUser: MondoUser) {
    return false;
    // return mondoUser.permission >= AccountPermission.admin; // remember database rules
  }

  private setSearchPermission(mondoUser: MondoUser) {
    if (this.isCompany) {
      // return mondoUser.permission > AccountPermission.basic;
      return mondoUser.permission >= AccountPermission.basic;
    } else if (this.isScientist) {
      return !!mondoUser.publishedCv;
      // return mondoUser.permission > AccountPermission.basic;
    }
  }

  private setCanPublishJob(mondoUser: MondoUser) {
    if (this.isCompany) {
      return mondoUser.permission > AccountPermission.basic;
    } else if (this.isScientist) {
      // return false;
      // return !!mondoUser.publishedCv;
      return (
        mondoUser.permission > AccountPermission.basic &&
        !!mondoUser.publishedCv
      );
    }
  }
  private setCanSeeAnonymous(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.admin;
  }

  private setCanBeAnonymous(mondoUser: MondoUser) {
    return false;
    // return mondoUser.permission === AccountType.labTech;
  }

  private setCanPublishCV(mondoUser: MondoUser) {
    // return mondoUser.permission > AccountPermission.basic;
    return true;
  }

  private setCanPublishSite(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.basic;
    // return mondoUser.permission > AccountPermission.basic;
  }

  private setCanCreateJob(mondoUser: MondoUser) {
    if (!this.canPublishJob) {
      return false;
    }
    if (this.isCompany) {
      return mondoUser['company'] && mondoUser['company'].uid ? true : false;
    } else if (this.isScientist) {
      return !!mondoUser.publishedCv;
    }
  }

  private setCanSeeApplyJobButton(mondoUser: MondoUser) {
    if (this.isCompany) {
      return false;
    } else if (this.isScientist) {
      return !!mondoUser.publishedCv;
    }
  }

  private setCanSeeApplyForEmployeeButton(mondoUser: MondoUser) {
    if (this.isCompany) {
      return false;
    } else if (this.isScientist) {
      // return false;
      return !!mondoUser.publishedCv;
    }
  }

  private setCanEditCompanyDetails(mondoUser: MondoUser) {
    return true;
    // const validAccountTypes = [
    //   // AccountType.scientist,
    //   // AccountType.labTech,
    //   AccountType.medLabTech,
    //   // AccountType.industry,
    //   AccountType.university,
    // ];
    // const hasValidAccountType = validAccountTypes.includes(mondoUser.type);
    // return mondoUser.permission >= AccountPermission.extended && hasValidAccountType;
  }

  private setCreateJobFromSitePermission(mondoUser: MondoUser) {
    const validAccountTypes = [
      // AccountType.scientist,
      // AccountType.labTech,
      AccountType.medLabTech,
      AccountType.industry,
      // AccountType.university
    ];
    const hasValidAccountType = validAccountTypes.includes(mondoUser.type);
    return (
      mondoUser.permission >= AccountPermission.admin && hasValidAccountType
    );
  }

  private setCanCreateDraftCommunities(mondoUser: MondoUser) {
    return mondoUser.permission > AccountPermission.extended;
  }

  private setCanPublishCommunity(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.basic;
  }
  private setCanCreatePublicCommunities(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.admin;
  }

  private setCanCreateDraftEvents(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.extended;
  }

  private setCanPublishEvent(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.basic;
  }

  private setCanCreateDraftIdeas(mondoUser: MondoUser) {
    return mondoUser.permission > AccountPermission.extended;
  }

  private setCanPublishIdea(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.basic;
  }

  private setCanCreatePublicIdeas(mondoUser: MondoUser) {
    return mondoUser.permission >= AccountPermission.admin;
  }

  public isUserPartOfGivenAccountType(accountTypes: AccountType[]): boolean {
    return accountTypes.includes(this.prevType);
  }
}
