import { MultiSelectorItem } from '../components/multi-selector/multi-selector-item';

export class Species implements MultiSelectorItem {
  public static fromJson({ name, id }): Species {
    return new Species(name, id);
  }
  constructor(public name: string, public id: string) { }

  public getId(): string {
    return this.id;
  }

  public getDisplayText(): string {
    return this.name;
  }

  public getDescription(): string {
    return undefined;
  }
}
