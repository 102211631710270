import { SiteInfo } from './site-info';
import { IBaseStepItem } from '../../stepper/shared/model/IBaseStepItem';
import { StatusContext } from '../../stepper/shared/model/mondo-status/status-context';
import { ItemType } from '../../stepper/shared/model/ItemType';
import { Experience } from './cv/experience';

export class Site implements IBaseStepItem, IJsonSite<Date> {
  public readonly type = ItemType.Site;

  public static fromJson(
    {
      name,
      ownerId,
      created,
      lastUpdate,
      madePublic,
      siteInfo,
      experience,
      emplNetworkId,
      expireWarned,
    }: IJsonSite<number>,
    key: string,
    status: StatusContext = new StatusContext()
  ): Site {
    return new Site(
      status,
      name,
      ownerId,
      key,
      created ? new Date(created) : null,
      lastUpdate ? new Date(lastUpdate) : null,
      madePublic ? new Date(madePublic) : null,
      SiteInfo.fromJson(siteInfo),
      Experience.fromJson(experience || {}, key),
      emplNetworkId,
      expireWarned
    );
  }

  public static toJson(site: Site): IJsonSite<number> {
    return {
      name: site.name,
      ownerId: site.ownerId,
      key: site.key,
      created: site.created ? site.created.getTime() : null,
      lastUpdate: site.lastUpdate ? site.lastUpdate.getTime() : null,
      madePublic: site.madePublic ? site.madePublic.getTime() : null,
      siteInfo: site.siteInfo,
      experience: Experience.toJson(site.experience),
      emplNetworkId: site.emplNetworkId,
      expireWarned: site.expireWarned,
    };
  }

  constructor(
    public status: StatusContext = new StatusContext(),
    public name = '',
    public ownerId = '',
    public key = '',
    public created: Date = null,
    public lastUpdate: Date = null,
    public madePublic: Date = null,
    public siteInfo: SiteInfo = new SiteInfo(),
    public experience: Experience = new Experience(),
    public emplNetworkId = '',
    public expireWarned = false
  ) {}
}

interface IJsonSite<T> {
  name: string;
  ownerId: string;
  key: string;
  created: T;
  lastUpdate: T;
  madePublic: T;
  siteInfo: SiteInfo;
  experience;
  emplNetworkId: string;
  expireWarned: boolean;
}
