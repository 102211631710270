import { Component, OnInit, Inject } from '@angular/core';
import { CreateCustomerComponent } from '../create-customer/create-customer.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { FenerumService } from 'app/core/fenerum.service';
import { BehaviorSubject } from 'rxjs';
import { PlanResponse, PlanTerm } from 'app/shared/models/fenerum/fenerumPlans';

@Component({
  selector: 'app-plan-selector',
  templateUrl: './plan-selector.component.html',
  styleUrls: ['./plan-selector.component.scss'],
})
export class PlanSelectorComponent implements OnInit {
  public planResponse$: BehaviorSubject<PlanResponse> = new BehaviorSubject(
    null
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { siteKey: string; cvKey: string },
    private dialogRef: MatDialogRef<PlanSelectorComponent>,
    private fenerumService: FenerumService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getPlans().then((plans) => {
      this.planResponse$.next(plans);
    });
  }

  private getPlans(): Promise<PlanResponse> {
    return this.fenerumService.getPlansAtFenerum();
  }

  selectPlan(term_uuid: string) {
    this.openCustomerRegistration(term_uuid);
  }

  isImgLink(text: string): boolean {
    return text.indexOf('http') !== -1;
  }

  getMonthlyPricePerYear(planterms_set: PlanTerm[]) {
    let price = 0;
    if (planterms_set.length && planterms_set.length > 1) {
      if (planterms_set[0].interval_type === 'month') {
        price = parseInt(planterms_set[0].price, 10);
      } else if (planterms_set[1].interval_type === 'month') {
        price = parseInt(planterms_set[1].price, 10);
      }
      return price > 0 ? price * 12 : price;
    }
  }

  showDiscount(nominator: number, denominator: string) {
    return this.getDiscountPercentage(nominator, denominator) > 0;
  }

  getDiscountPercentage(nominator: number, denominator: string): Number {
    return 100 - (nominator / +denominator) * 100;
  }

  openCustomerRegistration(term_uuid): void {
    this.dialogRef.close();
    this.dialog.open(CreateCustomerComponent, {
      width: '500px',
      data: {
        siteKey: this.data.siteKey,
        cvKey: this.data.cvKey,
        terms: term_uuid,
      },
    });
  }
}
