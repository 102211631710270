import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Observable, Subject } from 'rxjs';
import { MondoUser } from '../../shared/models/user/mondoUser';
import { AccountType } from '../../shared/consts/accountType';
import { UserService } from '../../core/user.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();
  // currentUser$: Observable<MondoUser>;
  tempUser: MondoUser;
  selectedStep: SelectedUserProfileStep = SelectedUserProfileStep.userData;
  selectedTab = 0;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.selectedTab = (parseInt(params['tab'], 10) || 0);
      });
  }

  ngOnInit() {
  }

  updateUser(user: MondoUser) {
    this.tempUser = user;
  }

  save() {
    if (this.tempUser) {
      this.userService.updateUser(this.tempUser);
    }
  }

  tabChanged(indexEvent) {
    this.selectedStep = indexEvent.index;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

enum SelectedUserProfileStep {
  userData,
  notifications
}
