import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckImageUrlDirective } from '../shared-directives/directives/check-image-url.directive';

@NgModule({
  declarations: [CheckImageUrlDirective],
  imports: [CommonModule],
  exports: [CheckImageUrlDirective],
})
export class SharedDirectivesModule {}
