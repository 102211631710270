<div class="form-group" [formGroup]="form">
  <div formArrayName="languages">
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="activeIndex === i"
        (opened)="activeIndex = i"
        *ngFor="
          let lang of languagesArray.controls;
          let i = index;
          trackBy: trackByFn
        "
        [formGroupName]="i"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{
              getHeaderText(
                lang,
                getLevel(lang) | preventHardcoding,
                'Language' | preventHardcoding
              )
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="tab-view-content">
            <div class="row">
              <div class="col">
                <app-language-picker
                  [form]="getLanguageFormGroup(lang)"
                ></app-language-picker>
                <mat-select
                  formControlName="level"
                  placeholder="{{ 'educationalLevel' | preventHardcoding }}"
                >
                  <mat-option [value]="languageProficiencies.beginner">{{
                    languageProficiencies.beginner | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="languageProficiencies.conversational">{{
                    languageProficiencies.conversational | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="languageProficiencies.proficient">{{
                    languageProficiencies.proficient | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="languageProficiencies.fluent">{{
                    languageProficiencies.fluent | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="languageProficiencies.bilingual">{{
                    languageProficiencies.bilingual | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="languageProficiencies.native">{{
                    languageProficiencies.native | preventHardcoding
                  }}</mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <mat-action-row class="button-row">
            <button
              *ngIf="
                'areYouSureWantToDeleteX'
                  | preventHardcodingFormatText
                    : ('language' | preventHardcoding) as confirmText
              "
              mat-raised-button
              class="warn"
              (click)="delete(i, confirmText)"
            >
              <mat-icon>delete</mat-icon>{{ 'delete' | preventHardcoding }}
            </button>
            <button mat-raised-button (click)="activeIndex = -1">
              <mat-icon>check</mat-icon>{{ 'done' | preventHardcoding }}
            </button>
          </mat-action-row>
        </ng-template>
      </mat-expansion-panel>
      <mat-action-row>
        <button (click)="add()" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>{{ 'add' | preventHardcoding }}
        </button>
      </mat-action-row>
    </mat-accordion>
  </div>
</div>
