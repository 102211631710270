import { acaConfig } from '../src/aca-config';
import { acaNames } from '../src/aca-names';

const common = {
  yourPost: 'dit indlæg',
  yourPosts: 'dine indlæg',
  aPost: 'et indlæg',
  thePost: 'indlægget',
  ThePost: 'Indlægget',
  newPost: 'nyt indlæg',
  NewPost: 'Nyt indlæg',
  NewPosts: 'Nye indlæg',
  post: 'indlæg',
  Post: 'Indlæg',
  posts: 'indlæg',
  Posts: 'Indlæg',
  PostBuilder: 'Indlægs bygger',
};

export const hardcodedValuesDK = {
  academondo: acaConfig.academondo,
  academondoDash: acaConfig.academondoDash,
  academondoCom: acaConfig.academondoCom,
  academondoUrl: acaConfig.academondoUrl,
  supportMail: acaConfig.supportMail,
  reviewMail: acaConfig.reviewMail,
  senderMail: acaConfig.senderMail,
  defaultUserCoverUrl: acaConfig.defaultUserCoverUrl,
  defaultEventCoverUrl: acaConfig.defaultEventCoverUrl,
  defaultCommunityCoverUrl: acaConfig.defaultCommunityCoverUrl,
  defaultIdeaCoverUrl: acaConfig.defaultIdeaCoverUrl,
  welcomeWidgetCoverUrl: acaConfig.welcomeWidgetCoverUrl,

  // sign up
  byContinuingYouConsent:
    'Ved at forsætte giver du accept og er enig i brugen af dine data på følgende',
  termsLink: acaConfig.termsLink,
  terms: 'Betingelser',
  howWeTreatYourData:
    'Hvis du vil se mere om hvordan vi behandler dine data - se vores',
  privacyPolicyLink: acaConfig.privacyPolicyLink,
  privacyPolicy: 'Privatlivs politik',
  copyRightDate: acaConfig.copyRightDate,
  showTermsInNavbarMenu: acaConfig.showTermsInNavbarMenu,

  mailSubject: acaNames.mailSubject,
  notEnoughPermission: acaNames.notEnoughPermission,
  YouDontHaveAccess: `Du har ikke adgang til den side`,
  welcomeToAcademondo: `Velkommen til ${acaConfig.academondo}`,
  welcomeToAcademondoDash: `Velkommen til ${acaConfig.academondo}`,
  welcomeToPublicUserProfile: 'Velkommen til din offentlige profil',
  suggestionSalute: `Tak for at gøre ${acaConfig.academondo} endnu bedre, dit forslag vil blive gennemgået`,
  sendSuggestion: 'Send forslag',

  academondoStreet: acaConfig.academondoStreet,
  academondoPostboxCity: acaConfig.academondoPostboxCity,
  academondoCVR: acaConfig.academondoCVR,
  academondoPhone: acaConfig.academondoPhone,

  twitterLink: acaConfig.twitterLink,
  facebookLink: acaConfig.facebookLink,
  linkedinLink: acaConfig.linkedinLink,
  instagramLink: acaConfig.instagramLink,
  fbPagePluginLink: acaConfig.fbPagePluginLink,

  // intro dialog/help
  welcomeToHeader: `Velkommen til ${acaConfig.academondo}`,
  // intro dialog - cv
  helpheaderCV: acaNames.helpheaderCV,
  helpLineOneCV: acaNames.helpLineOneCV,
  helpLineTwop1CV: acaNames.helpLineTwop1CV,
  helpLineTwop2CV: acaNames.helpLineTwop2CV,
  helpLineTwop3CV: acaNames.helpLineTwop3CV,
  helpLineThreep1CV: acaNames.helpLineThreep1CV,
  helpLineThreep2CV: acaNames.helpLineThreep2CV,

  // intro dialog - job
  helpheaderJob: acaNames.helpheaderJob,
  helpLineOneJob: acaNames.helpLineOneJob,
  helpLineTwoJob: acaNames.helpLineTwoJob,

  scienceMatchRoute: acaNames.scienceMatchRoute,
  scienceMatch: acaNames.scienceMatch,
  scienceMatchSubtitle: acaNames.scienceMatchSubtitle,
  scienceMatchSearchResults: 'Søgeresultater',
  term: 'udtryk',
  SearchByX: `Søg efter {0}`,
  chooseX: `Søg og vælg {0}...`,
  wantedX: `Ønskede {0}`,
  suggestNewX: `Foreslå {0}`,
  areYouSureWantToDeleteX: 'Er du sikker på du vil slette {0}?',
  facility: 'arbejdsplads',
  name: 'navn',
  showMaxSearchResultSelector: acaConfig.showMaxSearchResultSelector,
  maxSearchResults: acaConfig.maxSearchResults,

  ifYouHaveSuggestion: `Hvis du har et forslag som du ønsker at tilføje til vores database så hører vi gerne fra dig. Udfyld venligst formen herunder.`,
  description: 'beskrivelse',
  Description: 'Beskrivelse',

  category: 'Kategori',
  Category: 'Kategori',
  categories: 'Kategorier',
  foi: `${acaNames.foi}`,
  Foi: `${acaNames.Foi}`,
  interests: `${acaNames.shortFoi}`,
  Interests: `${acaNames.ShortFoi}`,
  fois: `${acaNames.fois}`,
  Fois: `${acaNames.Fois}`,

  tech: acaNames.tech,
  Tech: acaNames.Tech,
  techs: acaNames.techs,
  Techs: acaNames.Techs,

  companySubtype: acaNames.companySubtype,
  CompanySubtype: acaNames.CompanySubtype,
  companySubtypes: acaNames.companySubtypes,
  CompanySubtypes: acaNames.CompanySubtypes,

  network: acaNames.network,
  Network: acaNames.Network,
  networks: acaNames.networks,
  Networks: acaNames.Networks,
  networkSubtype: `${acaNames.network} subtype`,
  NetworkSubtype: ``, // leave blank if it should not be shown
  networkSubtypes: `${acaNames.network} subtyper`,
  NetworkSubtypes: `${acaNames.Network} subtyper`,

  language: 'sprog',
  Language: 'Sprog',
  languages: 'sprogkundskaber',
  Languages: 'Sprogkundskaber',

  category0: `${acaNames.category0}`,
  Category0: `${acaNames.Category0}`,
  categories0: `${acaNames.categories0}`,
  Categories0: `${acaNames.Categories0}`,
  category1: `${acaNames.category1}`,
  Category1: `${acaNames.Category1}`,
  categories1: `${acaNames.categories1}`,
  Categories1: `${acaNames.Categories1}`,
  category2: `${acaNames.category2}`,
  Category2: `${acaNames.Category2}`,
  categories2: `${acaNames.categories2}`,
  Categories2: `${acaNames.Categories2}`,
  category3: `${acaNames.category3}`,
  Category3: `${acaNames.Category3}`,
  categories3: `${acaNames.categories3}`,
  Categories3: `${acaNames.Categories3}`,
  category4: `${acaNames.category4}`,
  Category4: `${acaNames.Category4}`,
  categories4: `${acaNames.categories4}`,
  Categories4: `${acaNames.Categories4}`,
  category5: `${acaNames.category5}`,
  Category5: `${acaNames.Category5}`,
  categories5: `${acaNames.categories5}`,
  Categories5: `${acaNames.Categories5}`,
  Category0Hint: `${acaNames.Category0Hint}`,
  Category0Link: `${acaNames.Category0Link}`,
  Category0Dialog: acaNames.Category0Dialog,
  Category0Tooltip: acaNames.Category0Tooltip,
  Category1Hint: `${acaNames.Category1Hint}`,
  Category1Link: `${acaNames.Category1Link}`,
  Category1Dialog: acaNames.Category1Dialog,
  Category1Tooltip: acaNames.Category1Tooltip,
  Category2Hint: `${acaNames.Category2Hint}`,
  Category2Link: `${acaNames.Category2Link}`,
  Category2Dialog: acaNames.Category2Dialog,
  Category2Tooltip: acaNames.Category2Tooltip,
  Category3Hint: `${acaNames.Category3Hint}`,
  Category3Link: `${acaNames.Category3Link}`,
  Category3Dialog: acaNames.Category3Dialog,
  Category3Tooltip: acaNames.Category3Tooltip,
  Category4Hint: `${acaNames.Category4Hint}`,
  Category4Link: `${acaNames.Category4Link}`,
  Category4Dialog: acaNames.Category4Dialog,
  Category4Tooltip: acaNames.Category4Tooltip,
  Category5Hint: `${acaNames.Category5Hint}`,
  Category5Link: `${acaNames.Category5Link}`,
  Category5Dialog: acaNames.Category5Dialog,
  Category5Tooltip: acaNames.Category5Tooltip,
  FoiHint: `${acaNames.FoiHint}`,
  FoiLink: `${acaNames.FoiLink}`,
  FoiDialog: acaNames.FoiDialog,
  FoiTooltip: acaNames.FoiTooltip,
  TechHint: `${acaNames.TechHint}`,
  TechLink: `${acaNames.TechLink}`,
  TechDialog: acaNames.TechDialog,
  TechTooltip: acaNames.TechTooltip,

  // email validation
  pressBelowToValidateEmail:
    'Tryk på knappen herunder for at bekræfte din email.',
  validateEmail: 'Bekræft email',
  validationFailedResendEmail:
    '❗ Bekræftigelse fejlede, gensend bekræftigelses email og tjek din indbakke/spam mappe.',
  resendValidationEmail: 'Gensend bekræftigelses email',
  enterNewPassword: 'Indtast nyt kodeord',
  confirmPassword: 'Bekræft kodeord',
  theGivenEmailIsNotVerified: 'Den givne email er ikke bekræftet.',
  thatEmailAlreadyExists:
    'Hvis denne email eksisterer, så er der sendt en mail.',
  wrongEmailOrPassword: 'Forkert email eller kodeord.',
  unknownError: 'Ukendt fejl!',
  'Unknown error!': 'Ukendt fejl!',
  forErrorsTryAgain: ' for fejl og prøv igen.',
  duplicateNewName: ' kopi',

  thankYouForUsing: `Tak for at du bruger ${acaConfig.academondo}! :)`,
  internetExplorerEdgeNotSupported: `Internet Explorer er usikker og ikke supporteret af ${acaConfig.academondo}.`,
  pleaseUseModernBrowser:
    'Brug venligtst en anden browser. Vi foreslår Chrome, Firefox, Safari eller Edge',
  getChrome: 'Hent Chrome',
  accountSelector1: acaNames.accountSelector1,
  accountSelector2: acaNames.accountSelector2,
  accountSelector3: acaNames.accountSelector3,
  accountSelector4: acaNames.accountSelector4,

  inviteToAcademondo: `Invitér til ${acaConfig.academondo}`,
  inviteToAcademondoText: acaNames.inviteToAcademondoText,
  Invite: 'Invitér',
  ok: 'ok',
  Ok: 'Ok',
  invite: 'Invitér',
  Uninvite: 'Tilbagetræk invitation',
  begin: 'Begynd',
  enter: 'Hop ind',
  cancel: 'Annullér',

  acceptAccess: 'Acceptér',
  acceptRequestForMembership: 'Acceptér anmodning',
  rejectRequestForMembership: 'Afvis anmodning',
  request: 'anmod',
  requested: 'anmodet',
  Request: 'Anmod',
  Requested: 'Anmodet',
  invited: 'inviteret',
  Invited: 'Inviteret',
  selectedUsers: 'udvalgte brugere?',
  search: 'Søg',
  searchFor: 'Søg efter',

  clearSearch: 'Ryd søgning',
  filteredSearch: 'Filtreret søgning',
  withinARadiusOf: 'Indenfor en afstand af',
  radius: 'Km',
  distanceFromYou: 'Afstand',

  showAvailableJobs: `Aktuelle ${acaNames.jobs}`,
  showExpiredJobs: `Gamle ${acaNames.jobs}`,

  mostRecentExperience: 'Seneste erfaring',
  academicTitle: acaNames.academicTitle,
  achievedAcademicDegree: 'Opnåede akademiske grad',
  any: 'Alle',
  AnyRange: 'Alt',

  userSearchTerm: `${acaNames.academicTitle}, navn, ${acaNames.companyName}..`,

  Experience0: acaNames.Experience0,
  experience0: `år med ${acaNames.experience0} erfaring`,
  experience0Years: `${acaNames.Experience0} år`,
  ofExperience0Experience: `med ${acaNames.experience0} erfaring`,
  yearsOfExperience0: `år med ${acaNames.experience0} erfaring`,

  Experience1: acaNames.Experience1,
  experience1: `år med ${acaNames.experience1} erfaring`,
  experience1Years: `${acaNames.Experience1} years`,
  ofExperience1Experience: `med ${acaNames.experience1} erfaring`,
  yearsOfExperience1: `år med ${acaNames.experience1} erfaring`,

  Experience2: acaNames.Experience2,
  experience2: `år med ${acaNames.experience2} erfaring`,
  experience2Years: `${acaNames.Experience2} år`,
  ofExperience2Experience: `med ${acaNames.experience2} erfaring`,
  yearsOfExperience2: `år med ${acaNames.experience2} erfaring`,

  Experience3: acaNames.Experience3,
  experience3: `år med ${acaNames.experience3} erfaring`,
  experience3Years: `${acaNames.Experience3} år`,
  ofExperience3Experience: `med ${acaNames.experience3} erfaring`,
  yearsOfExperience3: `år med ${acaNames.experience3} erfaring`,

  Experience4: acaNames.Experience4,
  experience4: `y of ${acaNames.experience4} exp`,
  experience4Years: `${acaNames.Experience4} years`,
  ofExperience4Experience: `of ${acaNames.experience4} experience`,
  yearsOfExperience4: `y of ${acaNames.experience4} exp`,

  Experience5: acaNames.Experience5,
  experience5: `y of ${acaNames.experience5} exp`,
  experience5Years: `${acaNames.Experience5} years`,
  ofExperience5Experience: `of ${acaNames.experience5} experience`,
  yearsOfExperience5: `y of ${acaNames.experience5} exp`,

  country: 'Land',
  degree: 'Grad',
  viewMore: 'Vis mere',
  viewLess: 'Vis mindre',
  viewProfile: 'Vis profil',
  searchForAcademondoCompanyProfiles: `Søg efter ${acaNames.sites}`,
  searchForAcademondoProfiles: `Søg efter ${acaNames.users}`,
  PublishedCVProfileTag: `Offentlig ${acaConfig.academondo} profil`,
  create: 'opret',
  Create: 'Opret',
  account: 'konto',
  Account: 'Konto',
  accountType: 'Konto type',
  changeAccountType: 'Rediger konto type',
  createAccount: 'Opret konto',
  createNewCompany: `Opret ${acaNames.newsite}`,
  createNew: 'Opret ny',
  companyName: acaNames.companyName,
  CompanyName: acaNames.CompanyName,
  login: 'Login',
  myProfile: 'Min Profil',

  iconIsUsedProfilePicture: 'Ikonet bruges som profilbillede i forumet',
  iconIsUsedOnMap: 'Ikonet bruges på kortet',
  pleaseEnterValidUrl: 'Venligst indtast en gyldig url',
  websiteUrl: 'Hjemmside url',
  networkWebsiteUrl: 'Hjemmside url',

  writeInfoAboutCompany: acaNames.writeInfoAboutCompany,
  writeQualificationsPosition: acaNames.writeQualificationsPosition,
  iAmACompany: `Jeg er ${acaNames.asite}`,
  iAmAUser: `Jeg er ${acaNames.auser}`,
  UserDoesntExistAnymore: `${acaNames.User} er ikke længere aktiv`,

  selected: 'Valgte',
  JobType: `${acaNames.JobType}`,
  viewJob: `Vis ${acaNames.job}`,
  job: acaNames.job,

  JobType0: `${acaNames.JobType0}`,
  JobType1: `${acaNames.JobType1}`,
  JobType2: `${acaNames.JobType2}`,
  JobType3: `${acaNames.JobType3}`,
  JobType4: `${acaNames.JobType4}`,
  JobType5: `${acaNames.JobType5}`,

  jobType0Desc: `${acaNames.jobType0Desc}`,
  jobType1Desc: `${acaNames.jobType1Desc}`,
  jobType2Desc: `${acaNames.jobType2Desc}`,
  jobType3Desc: `${acaNames.jobType3Desc}`,
  jobType4Desc: `${acaNames.jobType4Desc}`,
  jobType5Desc: `${acaNames.jobType5Desc}`,

  newCompany: `${acaNames.Newsite}`,
  company: acaNames.site,
  Company: acaNames.Site,
  companies: acaNames.sites,
  Companies: acaNames.Sites,
  user: acaNames.user,
  User: acaNames.User,
  users: acaNames.users,
  Users: acaNames.Users,

  admin: 'Admin',
  UserType0: acaNames.UserType0,
  UserTypes0: acaNames.UserTypes0,
  UserType1: acaNames.UserType1,
  UserTypes1: acaNames.UserTypes1,
  UserType2: acaNames.UserType2,
  UserTypes2: acaNames.UserTypes2,
  UserType3: acaNames.UserType3,
  UserTypes3: acaNames.UserTypes3,
  UserType4: acaNames.UserType4,
  UserTypes4: acaNames.UserTypes4,
  UserType5: acaNames.UserType5,
  UserTypes5: acaNames.UserTypes5,

  showUsersWithoutCV: acaConfig.showUsersWithoutCV,
  AllUserTypesWithoutCV: `${acaNames.Users} uden ${acaNames.cv}`,
  AllUserTypesWithCV: `${acaNames.Users} med ${acaNames.cv}`,
  PermissionGrantedTo: 'Rettighed givet til',
  AccountWasNotFoundWithThisEmail:
    'Der blev ikke fundet en konto med denne email',
  UsertypeChanged: 'Konto type ændret',
  Grant: 'Giv',
  GrantOwnership: 'Overdrag ejerskab',
  ThisCannotBeUndone: 'Dette kan ikke gøres om',
  privileges: 'rettigheder',
  Permission: 'Rettighed',
  AccountPermission: 'AccountPermission',
  sidePanel: 'Side panel',
  visitOfficialWebsite: 'Hjemmeside',
  clickToCopy: 'klik for at kopiere email',
  profileBuilder: 'Profil bygger',

  both: 'Begge',

  unableToFindAnythingMatchingYourSearchCriteria:
    'Vi kan ikke finde noget der passer på dine søgekriterier..',

  pleaseChooseSomeSearchCriteria: 'Vælg venligst nogle søgekriterier.',

  modeSwitcher: 'Skift visning',

  awaitingApproval: 'Anmodet',
  youHaveBeenInvited: 'Du er allerede inviteret',

  contractLength: 'Kontrakt længde',
  permanentContract: acaNames.permanentContract,
  pasteAccessNumber: 'Indsæt adgangsnummer og tryk søg',
  year: 'år',
  Year: 'År',
  s: '', // set to '' if no ending on plural of year
  yearss: 'år',

  years: 'år',
  months: 'måned(er)',
  monthss: 'måneder',
  month: 'måned',
  MonthsMayOnlyBeBetween0And11: 'Måneder må kun være mellem 0 og 11.',
  minimumWorkExperience: 'Minimums arbejdserfaring',
  minimumEducationalLevel: 'Minimums uddannelsesniveau',
  educationalLevel: 'Uddannelsesniveau',
  selectMinimumEducationalLevel: 'Vælg minimums uddannelsesniveau',

  // date picker
  startDate: 'Start dato',
  endDate: 'Slut dato',
  startTime: 'Starttidspunkt',
  endTime: 'Sluttidspunkt',

  // personal details
  firstName: 'Fornavn',
  middleName: 'Mellemnavn',
  showMiddleName: acaConfig.showMiddleName,
  surname: 'Efternavn',
  Sex: 'Køn',
  showSexInPersonalDetails: acaConfig.showSexInPersonalDetails,
  showDepartmentInPersonalDetails: acaConfig.showDepartmentInPersonalDetails,
  dateOfBirth: 'Fødselsdato',
  dateOfBirthHint: acaNames.dateOfBirthHint,

  address: 'Adresse',
  siteAddressDisclaimer: `Ved at tilføje en adresse vises ${acaNames.thesite} på kortet`,
  placeAddressDisclaimer: `Ved at tilføje en adresse vises ${acaNames.theplace} på kortet`,
  cvAddressDisclaimer: `Ved at tilføje en adresse kommer du på kortet`,
  cvAddress: 'Adresse',

  addressSelectorInvalid: 'Indtast en gyldig adresse...',

  uploadProfilePicture: 'Upload profilbillede',
  writeAboutYourself: acaNames.writeAboutYourself,
  showGithub: acaNames.showGithub,
  showTwitter: acaNames.showTwitter,
  personalGithub: 'Github',
  personalTwitter: 'Twitter',
  github: 'Github',
  twitter: 'Twitter',
  enterAValidGithub:
    'Indtast en gyldig github url eks. https://www.github.com/user',
  githubUrl: 'https://www.github.com/user',
  enterAValidTwitter:
    'Indtast en gyldig twitter url eks. https://www.twitter.com/user',
  twitterUrl: 'https://www.twitter.com/user',
  personalLinkedin: 'Personlig LinkedIn',
  companyLinkedin: 'LinkedIn',
  linkedin: 'LinkedIn',
  enterAValidLinkedin:
    'Indtast en gyldig linkedin url eks. https://www.linkedin.com/in/navn',
  enterAValidUrl: 'Indtast en gyldig url eks. https://academondo.com',
  linkedUrl: 'https://www.linkedin.com/in/navn',

  // education
  titleOfEducation: 'Titel',
  titleOfEducationHint: acaNames.titleOfEducationHint,
  lineOfEducation: 'Uddannelses linje',
  educationalInstitution: 'Institution',
  department: 'afdeling',
  Department: 'Afdeling',
  writeProjectsQualificationsDuringStudies:
    'Skriv om projekter og kvalifikationer du har opnået gennem studiet',
  currentEducation: 'Nuværende uddannelse',
  newEducation: 'Ny uddannelse',

  // conference
  title: 'Titel',
  city: 'By',
  contribution: 'Bidrag',
  insertAbstract: 'Indsæt abstract',
  writeConference: 'Skriv om konferencen og dit bidrag',
  newWorkshopConference: 'Ny Workshop/konference',

  // researchStay
  newResearchStayStudiesAbroad: 'Nyt studie i udlandet',
  universityInstitution: 'Universitet/institution',
  lab: 'Lab', // laboratory
  writeAboutResearchStayQualifications:
    'Skriv om de kvalifikationer du har fået under dit studie udlandsophold',
  currentResearchStay: 'Nuværende studie i udlandet',

  // teaching exp
  titleOfTeachingExperience: 'Undervisningserfarings titel',
  writeAboutSpecificTopics: 'Skriv om de emner du har givet lektioner i',
  currentlyTeaching: 'Nuværende undervisning',
  newTeachingExperience: 'Ny undervisningserfaring',

  // work exp
  writeAboutPositionQualificationsRequiredPosition:
    'Skriv om stillingen og kvalifikationerne der kræves i denne stilling',
  employmentType: 'Ansættelsesforhold',
  companyInstitution: 'Virksomhed',
  jobTitle: 'titel',
  JobTitle: 'Titel',
  currentEmployment: 'Nuværende ansættelse',
  newWorkExperience: 'Ny Arbejdserfaring',

  // additional information
  additionalInformationEtc:
    'Her kan du indsætte andre ting som du mener hører til i din profil',
  writeTheRelevantInformation: 'Skriv relevant information',
  excludeFromCV: 'Vis ikke i profil',
  updateCV: 'Opdatér profil',

  // publications
  searchThroughPubmed: 'Søg på pubmed',
  searchByNameTitleOfPublication: 'Search by name or title of publication',
  searchResults: 'Søgeresultater',
  clearSearchResults: 'Ryd søgeresultater',
  addAll: 'Tilføj',
  addedPublications: 'Added publications',
  removeAllArticles: 'Remove all articles',
  someArticlesWithoutTitleRemoved: 'Some articles without a title were removed',
  someArticlesWereAlreadyAddedPublications: `Some articles were already added to your publications on ${acaConfig.academondo}`,

  // education types
  None: 'Ingen',
  'Academy Profession': 'Akademi uddannelse',
  Bachelor: 'Bachelor',
  Master: 'Kandidat',
  'Ph.D': 'Ph.D',
  Other: 'Anden',

  // cv-builder
  basisStepTitle: 'Basis profil',
  basisStepNextBtnText: 'Fortsæt til udvidet',
  additionalInformation: acaNames.additionalInformation,
  viewTemplate: 'Vis skabelon',
  beginBuildingYourCV: `Byg ${acaNames.yourcv}`,
  personalDetails: 'Personlige detajler',
  introductoryText: 'Introduktionstekst',
  workExperience: 'Arbejdserfaring',
  education: 'Uddannelse',
  researchStayStudiesAbroad: 'Studie i udlandet',
  conferenceAndWorkshops: 'Konferencer og workshops',
  teachingExperience: 'Undervisningserfaring',
  publications: 'Publikationer',
  saveToAcademondo: `Gem på ${acaConfig.academondo}`,
  print: 'Print',
  profileCVBuilder: 'Profil bygger',
  press: 'Tryk',
  makeCVVisibleEmployersLatestChanges:
    'for at gøre din profil synlig eller opdater det med dine seneste ændringer',
  youHaveToAddFirstName: 'Fornavn krævet',
  youHaveToAddSurname: 'Efternavn krævet',
  youHaveToAddLeast1Education: 'Uddannelse krævet',
  youHaveToAddLeast1Foi: `${acaNames.Foi} krævet`,
  seeCoauthors: 'Check to see list of coauthors',
  youHaveToAddDob: 'Du skal tilføje fødselsdato',

  // cv-viewer
  current: 'Nuværende',
  educationAndWorkExperience: 'Uddannelse og arbejdserfaring',
  conferencesAndWorkshops: 'Konferencer og workshops',

  // job-builder
  jobStartTitle: 'Start',

  jobStartStep1: `Velkommen til ${acaNames.job} byggeren, her kan du oprette ${acaNames.jobs}.`,
  jobStartStep2: `Husk at offentliggøre ${acaNames.yourjob} ved at trykke på "Indryk" til sidst`,
  beginBuildingYourJobPost: `Byg ${acaNames.yourjob}`,
  jobInformation: 'Information',
  researchQualifications: 'Kategorisering',
  employmentPeriod: 'Ansættelsesperiode',
  contactInformation: 'Kontakt information',
  visibilityPeriod: 'Synlighedsperiode',
  PublishJob: `Indryk ${acaNames.job}`,
  PublishJobs: `Indryk ${acaNames.jobs}`,
  uploadLogo: 'Upload logo til profil',
  URLToTheOfficialJobSite: 'URL til den officielle side',
  URLToTheOfficialJobApplicationForm: 'URL til den officielle formular',
  pasteURLHere: 'Indsæt URL her',
  reseachGroup: 'Afdeling',
  researchLeader: 'Kontaktperson',
  writeNamesAndInformationOnThePeopleToContact:
    'Skriv navne og information om kontaktpersoner.',
  permanentPosition: 'Permanent',
  temporaryPosition: 'Midlertidig',
  startAsSoonAsPossible: 'Hurtigst muligt',
  titleOfThePosition: 'Overskrift',
  writeInformationAboutThePositionTasksResponsibilities: `Beskriv ${acaNames.thejob}`,
  PressTheButtonBelowOpenPublishMenu: `Tryk på knappen herunder for at åbne Indrykkelses menuen. Her kan du se status, indrykke, opdatere eller eller fjerne ${acaNames.yourjob}.`,
  YourApplicationDeadline6WeeksFromPublicationDate: `Udløbsdato kan være op til 3 måneder fra udgivelsesdatoen. Når
  ${acaNames.thejob} udløber bliver det taget ned 1 uge senere`,
  makeVisibleToday: 'Indryk idag',
  applicationDeadline: 'Udløbsdato',
  publicationDate: 'Indrykket',
  cvr: 'CVR',
  invalid: 'ugyldigt',
  valid: 'gyldigt',

  cvrLengthError: 'CVR skal have en længde på 8',
  cvrRequiredError: 'CVR skal udfyldes',
  numberOfMaxApplicants: 'Maksimalt antal ansøgere',
  Apply: 'Anmod',
  userAppliedToJob: 'Åbn Bryder model',
  noMoreApplicantsTooltip: ' tager ikke imod flere ansøgninger',
  numberOfApplicants: 'Antal ansøgere',
  applicationEmail: 'Email',
  applicantionEmailRequiredError: 'Email skal udfyldes',

  // job publish menu
  publishMenu: 'Tryk her',
  status: 'Status',
  numberOfPublishedJobs: `Antal indrykkede ${acaNames.jobs}`,
  youCanPublishToMakeThisJobPublic:
    'Du kan indrykke for at gøre dette offentligt og søgbart.',
  draftHasBeenEdited:
    'Kladden er blevet ændret. du kan genindrykke dine ændringer.',
  unpublishToRemove: 'Tilbagetræk for at fjerne den offentlige version.',
  publicFrom: 'Indrykket',
  yourCurrentPublicPeriod: 'Din nuværende indrykkelseperiode er sat til',

  NoChangesHasBeenMade: 'Du har ikke lavet nogen ændringer',

  yourOldPublicPeriod: 'Din tidligere indrykkelseperiode var sat til',

  youNeedToProvideVisibilityPeriod:
    'Du skal udfylde en synligsperiode for indrykningen.',
  youHaveReachedMaximumPublishedJobs: `Du har nået dit maksimale antal af indrykkede ${acaNames.jobs}, upgrade your plan eller tilbagetræk nogle ${acaNames.jobs}.`,
  unpublish: 'Tilbagetræk',
  unpublished: 'ikke indrykket endnu',
  queuedForPublishing: 'Sat i kø til indrykning',
  publishing: 'Gemmer...',
  published: 'Indrykket',
  republish: 'Genindryk',

  oldPost: `${acaNames.Oldjob}`,
  Rejected: 'Afvist',

  new: 'nyt',
  New: 'Nyt',
  updateCompanyDetails: 'Opdatér oplysninger',
  YouHavePublishedJobWithNewDetails: `Du har indrykket ${acaNames.ajob} med nye ${acaNames.site}soplysninger. vil du opdatere din profil med ændringerne?`,
  yourCVIsPublished: `${acaNames.Yourcv} er nu offentliggjort!`,
  yourJobIsQueued: `${acaNames.Yourjob} er sat i kø!`,
  yourCompanyIsPublished: `Din ${acaNames.site} er nu offentliggjort!`,
  YourPostIsPublished: `${acaNames.Yourjob} er nu offentliggjort!`,
  YourPlaceIsPublished: `Dit ${acaNames.place} er nu offentliggjort!`,
  // job-viewer
  about: 'Om',
  aboutTheCompany: 'Om virksomheden',
  aboutThePosition: 'Beskrivelse',
  officialJobApplication: 'Officielle formular',
  officialJobSite: 'Officielle side',
  jobApplication: 'Formular',
  relevantLinks: 'Relevante links',
  jobSite: 'Side',
  starting: 'Start dato',
  asSoonAsPossible: 'Hurtigst muligt',
  ending: 'Slut dato',
  experience: 'erfaring',
  qualifications: 'Kvalifikationer',
  deadlineToday: `Udløbsdato i dag`,
  deadlineExceeded: `Udløbsdato overskredet`,

  // site-builder
  slimSiteIndex: acaConfig.slimSiteIndex,
  siteStartStep1: `Velkommen til ${acaNames.site} byggeren, her opretter du din ${acaNames.site}.`,
  siteStartStep2: `Husk at offentliggøre ${acaNames.site}en ved at trykke på gem til sidst`,
  beginBuildingYourSite: `Byg ${acaNames.site}`,
  Email: 'Email',
  SiteEmail: `${acaNames.Site} email`,
  saveYourProfile: 'Gem din profil',
  saveProfile: 'Gem profil',
  uploadCover: 'Upload cover',
  uploadIcon: 'Upload logo til oversigt',
  uploadImageLink: 'Upload billede',
  YouHaveToFillOut: 'Udfyld',
  YouHaveToSelect: 'Du skal vælge',
  CatchPhrase: 'Slogan',

  // multi-selector
  weFoundNothingThatMatchesYourSearch:
    'Vi kan ikke finde noget der passer på dine søgekriterier.',
  useTheSearchFieldAboveToBeginSelection:
    'brug søgefeltet ovenfor for at starte.',

  // login
  resendVerificationMail: 'Send bekræftelsesemail',
  verificationMailSent:
    '❗ Bekræftelsesemail afsendt, tjek din inbox/spam mappe.',
  verificationMailSentToUser: 'Bekræftelsesemail afsendt.',
  pleaseVerifyYourEmail: 'Bekræft venligst din email',
  dontHaveAnAccount: 'Har du ikke en konto?',
  signUp: acaNames.signUp,
  SignupAs: 'Skriv dig op som',
  signIn: 'Log på',
  containLeastOneNumberAndOneCapitalLetter:
    'Skal indeholde mindst 1 tal og 1 stort bogstav',
  mustBetween6And24Characters: 'Skal være mellem 6 og 24 karakterer',

  EnterYourEmailAddressAndPasswordToAccessAccount:
    'Enter your email address and password to access account',

  // forum
  postStartStep1: `Velkommen til profil byggeren, her opretter du et ${common.post}.`,

  postStartStep2: `Husk at offentliggøre profilen ved at trykke på gem til sidst`,

  beginBuildingYourPost: `Byg ${common.yourPost}`,
  generalInformation: 'Generel information',
  topics: 'Kategorier',

  postBuilder: `${common.PostBuilder}`,
  titleOfAnnouncement: `titel på ${common.post}`,
  TitleOfAnnouncement: `Titel på ${common.post}`,
  writeInformationAboutYourRequest: 'Skriv information om din forespørgelse.',
  youHaveToAddATitle: 'Du skal tilføje en titel',
  youHaveToAddATitleOfAtLeast4Characters:
    'Du skal tilføje en titel med mindst 4 karakterer',
  youHaveToSelectAtLeast1Forum: 'Du skal vælge mindst 1 forum',
  emptyForumText: `Dette forum er tomt, opret ${common.aPost} for at starte`,

  // comments
  yourComment: 'Din kommentar...',
  youNeedToTypeText: 'du skal skrive en tekst',
  commentHint: 'du skal skrive en tekst eller tilføje et billede',
  Save: 'Gem',
  Close: 'Luk',
  reply: 'Svar',
  showReplies: 'Vis svar',
  hideReplies: 'Skjul svar',
  showMore: 'Vis mere',
  showLess: 'Vis mindre',
  showTags: 'Vis tags',
  hideTags: 'Skjul tags',
  hideAll: 'Skjul alle',
  comments: 'kommentarer',
  loadMore: 'Indlæs flere...',
  loadMoreComments: 'Indlæs flere kommentarer',
  lastSeen: 'sidst set',
  edited: 'ændret',
  Edited: 'Ændret',
  charsLeft: 'karakterer tilbage',
  unfoldComments: 'Fold ud',
  foldComments: 'Fold ind',

  defaultStartStep1:
    'Velkommen til profil byggeren, her opretter du din profil.',
  defaultStartStep2:
    'Husk at offentliggøre profilen ved at trykke på gem til sidst',

  // partners
  weProudlyPresentPartners: 'We proudly present our partners',

  // profileviewer
  Printable: 'Printbar',
  noPublicProfile: `Du har ikke en offentlig ${acaNames.cv} endnu, opret en og gem den for at begynde!`,
  makeYourProfile: 'Lav din profil',
  noProfileWithThatID: `Der er ingen ${acaNames.cv} med det Id`,
  pleaseSignInToSeeProfiles: `Venligst log på for at se See your own ${acaNames.cvs}`,
  seeYourOwnProfile: `Se din egen ${acaNames.cv}`,
  saveChanges: 'Gem ændringer',

  followUs: 'Følg os',
  phone: 'Tlf',
  contact: 'Kontakt',

  contactEmail: 'Email',
  contactPhone: 'Tlf',
  resetYourPassword: 'nulstille dit kodeord',
  ResetYourPassword: 'Nulstil dit kodeord',
  resetPassword: 'Nulstil kodeord',
  forgotPassword: 'Glemt kodeord',

  EnterYourEmailToGetStarted: 'Indtast din email for at starte',

  resetPWEmailSent:
    '❗ Nulstil kodeord link er sendt til din email, tjek din inbox/spam mappe',

  // new Email
  changeEmail: 'Rediger din email',
  newEmailConfirm: 'Er du sikker på at du vil ændre din konto email?',
  NewEmail: 'Ny email',
  newEmailText: `Email ændret, husk at opdatere profil
            Næste gang du logger på, brug samme kodeord og verificér din email`,

  password: 'Kodeord',
  optional: 'Valgfri',
  accept: 'acceptére',
  Accept: 'Acceptér',
  decline: 'afvis',
  Decline: 'Afvis',
  accepting: 'Accepterer',
  declining: 'Afviser',
  anInvite: 'denne invitation',

  // emptyProfile
  createYourProfileAndLetTheWorldDiscoverYou:
    'Opret din profil og lad verden finde dig',
  Profile: 'Profil',
  profile: 'profil',
  yourFree: 'din', // gratis
  You: 'Du',
  Yourself: 'Dig',
  Your: 'Dit',
  Name: 'Navn',
  yourTitle: 'Din titel',
  yourAddress: 'Din adresse',
  aboutYourCompanyInstittution: 'Om din virksomhed',
  yourMostRecentOccupation: 'Din seneste beskæftigelse',

  // filter-tags
  removeAllTags: 'Fjern alle tags',

  // profile-editor
  profileData: 'Profil information',
  notificationSettings: 'Notifikationer',
  SaveNotificationSettings: 'Gem Notifikation indstillinger',

  // notification settings
  Notifications: 'Notifikationer',
  Never: 'Aldrig',
  Asap: 'Asap',
  Reminder: 'Påmindelse',
  Daily: 'Daglig',
  Weekly: 'Ugentlig',
  Monthly: 'Månedlig',
  commentsOnYourPosts: `Kommentarer på dit ${common.post}`,

  NewReplyToYourComment: `Ny kommentar til en kommentar du har lavet`,

  // Notifications
  userAcceptedGroupInviteFromYou_title: `{0} accepterede din invitation`,
  userAcceptedGroupInviteFromYou_message: `{0} deltager i {1}`,
  userRejectedCommunityInviteFromYou_title: `{0} afviste din invitation`,
  userRejectedCommunityInviteFromYou_message: `{0} afviste din invitation til {1}`,
  ownerInvitedUserToCommunity_title: `Ny ${acaNames.community} invitation`,
  ownerInvitedUserToCommunity_message: `{0} inviterede dig til at deltage i {1}`,
  userRequestsCommunityMembership_title: `Medlemsanmodning til {0}`,
  userRequestsCommunityMembership_message: `{0} har anmodet medlemsskab til {1}`,
  ownerAcceptsCommunityMembershipRequest_title: `Din anmodning til {0} blev accepteret`,
  ownerAcceptsCommunityMembershipRequest_message: `{0} har accepteret din anmodning til {1}`,
  ownerRejectsCommunityMembershipRequest_title: `Din anmodning til {0} blev afvist`,
  ownerRejectsCommunityMembershipRequest_message: `{0} har afvist din anmodning til {1}`,
  youAreMadeOwnerOfGroup_title: `Du er nu ejer af {0}`,
  youAreMadeOwnerOfGroup_message: `{0} har givet dig ejerskabet af {1}`,
  youAreMadeAdministatorOfGroup_title: `Du er nu administrator af {0}`,
  youAreMadeAdministatorOfGroup_message: `{0} har forfremmet dig i {1}`,
  memberJoinedYourCommunity_title: `Nyt ${acaNames.member} i {0}`,
  memberJoinedYourCommunity_message: `{0} deltager i {1}`,
  youWereKickedFromCommunity_title: `Du blev fjernet fra {0}`,
  youWereKickedFromCommunity_message: `{0} fjernede dig fra {1}`,
  ownerInvitedUserToEvent_title: `Ny ${acaNames.event} invitation`,
  ownerInvitedUserToEvent_message: `{0} inviterede dig til {1}`,
  memberAttendingYourEvent_title: `{0} deltager`,
  memberAttendingYourEvent_message: `{0} deltager i {1}`,
  memberMaybeAttendingYourEvent_title: `{0} deltager måske`,
  memberMaybeAttendingYourEvent_message: `{0} har markeret sig som måske deltagende {1}`,
  memberDeclinedYourEvent_title: `{0} deltager ikke`,
  memberDeclinedYourEvent_message: `{0} deltager ikke {1}`,
  memberIsInterestedYourEvent_title: `{0} ikke interesseret`,
  memberIsInterestedYourEvent_message: `{0} er interesseret i {1}`,
  newCommentInPostYouCreated_title: `{0} tilføjet en ny kommentar til ${common.yourPost}`,
  newReplyToYourComment_title: `{0} svarede på din kommentar`,
  newCommentToPostYouCommented_title: `{0} tilføjede en ny kommentar`,
  newPostInGroupWereYouAreMember_title: `{0} tilføjede et ${common.newPost} til {1}`,
  newPostInGroupWereYouOwn_title: `{0} tilføjede et ${common.newPost} til {1}`,

  // Notification Settings
  comments_notificationSetting: `Kommentarer`,
  community_notificationSetting: `${acaNames.Communities}`,
  communityOwners_notificationSetting: `Egne ${acaNames.communities}`,
  posts_notificationSetting: `${common.Posts}`,
  newPostInGroupYouOwn_notificationSetting: `${common.NewPosts} i ${acaNames.communities} eller ${acaNames.events} du ejer`,
  newPostInGroupYouAreMember_notificationSetting: `${common.NewPosts} i ${acaNames.communities} eller ${acaNames.events}, hvor du er medlem`,
  requestedMembership_notificationSetting: `Anmodninger`,
  rejectedInvite_notificationSetting: `Afviste invitationer`,
  memberLeft_notificationSetting: `Bruger forlader`,
  memberJoined_notificationSetting: `Bruger deltagelse`,
  acceptedInvite_notificationSetting: `Accepterede invitationer`,
  newCommentinPostWithTagMatch_notificationSetting: `Ny kommentar i  ${common.post} med matchende tags`,
  kicked_notificationSetting: `Smidt ud`,
  invited_notificationSetting: `Invitationer`,
  acceptedRequest_notificationSetting: `Anmodning accepteret`,
  rejectedRequest_notificationSetting: `Anmodning afvist`,
  newCommentInPostYouFollow_notificationSetting: `Nye kommentarer i  ${common.post} du følger`,
  newCommentInPostYouCreated_notificationSetting: `Nye kommentarer i  ${common.post} du har oprettet`,
  newCommentInPostYouCommented_notificationSetting: `Nye kommentarer i  ${common.post} du har kommenteret`,
  newReplyToYourComment_notificationSetting: `Svar til dine kommentarer`,
  promoted_notificationSetting: `Forfremmelser`,
  eventOwners_notificationSetting: `Egne ${acaNames.events}`,
  event_notificationSetting: `${acaNames.Events}`,
  interested_notificationSetting: `Interesserede brugere`,
  declined_notificationSetting: `Afvisninger`,
  maybe_notificationSetting: `Måske brugere`,
  attending_notificationSetting: `Deltagende brugere`,
  requestedAttendance_notificationSetting: `Anmodninger`,

  expiration_notificationSetting: 'Udløb',
  cvExpiration_notificationSetting: `${acaNames.CV}`,
  siteExpiration_notificationSetting: `${acaNames.Site}`,
  jobExpiration_notificationSetting: `${acaNames.Job}`,

  noNotificationText: 'Du har ikke nogen notifikationer',
  markAsRead: 'Markér alle som læst',
  settings: 'Indstillinger',

  invitation: 'invitation',
  inApp: 'in app',
  optOuts: 'opt outs',
  addedA: 'tilføjet et',
  in: 'i',

  // uploading
  anyoneWithLinkCanDownloadFile: 'NB: alle med dette link kan hente filen',
  uploads: 'Uploads',
  uploadsDescription: `Dette viser filer fra alle ${common.posts}`,
  dragAndDropArea: 'Drag and Drop område',
  orChooseFile: 'eller vælg en fil…',
  downloadMe: 'Hent!',
  of: ' af ',
  pause: 'Pause',
  resume: 'Genoptag',

  yourCVs: `Dine ${acaNames.cvs}`,
  yourJobs: `Dine ${acaNames.jobs}`,
  sideBySide: 'Side om side',
  builderOnly: 'Kun bygger',
  viewerOnly: 'Kun visning',
  sorting: 'Sortering',
  help: 'Hjælp',
  selectAnotherImage: 'Vælg et andet billede',
  yes: 'Ja',
  no: 'Nej',
  becomeMember: 'Bliv medlem',
  enterValidMail: 'Indtast en gyldig e-mail adresse.',
  mustBe2Characters: 'skal være mindst 2 karakterer.',
  mustBeFilledOut: 'skal udfyldes.',
  required: 'påkrævet',
  Required: 'Påkrævet',
  delete: 'Slet',
  deletePost: `Slet ${common.post}`,
  deleteCV: `Slet ${acaNames.cv}`,
  deleteJob: `Slet ${acaNames.job}`,
  deleteCompany: `Slet ${acaNames.site}`,
  newCV: acaNames.CV,
  post: common.post,
  Post: common.Post,
  posts: common.posts,
  Posts: common.Posts,
  thePost: common.thePost,
  newPost: common.newPost,
  newJobPosition: `Nyt ${acaNames.job}`,
  uploadPdfLabel: `Upload filer til ${common.yourPost}`,
  uploadPdf: 'Upload pdf',
  uploadPdfTooltip: 'Upload pdf',
  openProfileTooltip: `Åbn ${acaNames.cv} på en ny tab`,

  // network
  filter: `Filtrer`,
  createNewPost: `Opret ${common.newPost}`,
  createNewEvent: `Opret ${acaNames.newEvent}`,
  createNewSubgroup: `Opret ${acaNames.newSubgroup}`,
  remove: 'Fjern',
  areYouSureYouWantToX: 'Er du sikker på du vil {0}?',
  areYouWantToLeaveX: 'Er du sikker på du vil forlade {0}?',
  applyForMembership: 'Anmod nu',

  edit: 'Rediger',
  Edit: 'Rediger',
  rename: 'Omdøb',
  renameCV: `Rename ${acaNames.thecv}`,
  renameJob: `Rename ${acaNames.thejob}`,
  duplicate: 'Duplikér',
  done: 'Færdig',
  doneEmoji: '✅',
  add: 'Tilføj',
  back: 'tilbage',
  Back: 'Tilbage',
  next: 'næste',
  Next: 'Næste',
  logOut: 'Log ud',
  Collapse: 'Skjul',
  maximize: 'Maksimer',
  minimize: 'Minimer',
  hide: 'Skjul',
  deviceLocationTooltip:
    'Tillad din position på dit apparatfor at bruge denne parameter',
  noJobExists: `Der findes ikke et ${acaNames.job} med dette ID`,
  jobsMatchingYourQualifications: `Log på for at søge efter ${acaNames.jobs} der passer til dine kvalifikationer`,
  landingPageRoute: 'velkommen',

  Beginner: 'Begynder',
  Conversational: 'Konversation',
  Proficient: 'Dygtig',
  Fluent: 'Flydende',
  Bilingual: 'Tosproget',
  Native: 'Modersmål',

  // Account Permission
  Basic: acaNames.Basic,
  Extended: acaNames.Extended,
  Full: acaNames.Full,
  Admin: acaNames.Admin,

  Forum: 'Forum',
  calendar: 'Kalender',
  member: `${acaNames.member}`,
  Member: `${acaNames.Member}`,
  members: `${acaNames.members}`,
  Members: `${acaNames.Members}`,
  participant: `${acaNames.participant}`,
  Participant: `${acaNames.Participant}`,
  participants: `${acaNames.participants}`,
  Participants: `${acaNames.Participants}`,
  participantInvite: `${acaNames.participantInvite}`,
  ParticipantInvite: `${acaNames.ParticipantInvite}`,
  participantsInvite: `${acaNames.participantsInvite}`,
  ParticipantsInvite: `${acaNames.ParticipantsInvite}`,
  administrative: 'Administrativ',
  employee: 'Medarbejder ',
  employees: 'medarbejdere ',
  employeesDash: 'Medarbejdere - ',

  // network types
  Public: acaNames.Public,
  OpenPrivate: acaNames.OpenPrivate,
  Private: acaNames.Private,
  Hidden: acaNames.Hidden,
  InviteOnly: acaNames.InviteOnly,
  public: acaNames.public,
  openPrivate: acaNames.openPrivate,
  private: acaNames.private,
  inviteOnly: acaNames.inviteOnly,
  hidden: acaNames.hidden,

  University: 'Universitet',
  Organisation: 'Organisation',

  // invite chips
  all: 'alt',
  All: 'Alt',
  Alls: 'Alt',
  universities: 'universiteter',
  organisations: 'organisationer',
  hospitals: 'hospitaler',

  // routes
  CVBuilderRoute: acaNames.CVRoute,
  CVBuilderTitle: acaNames.CV,
  CVSearchRoute: 'Profil Søgning',
  jobBuilderRoute: acaNames.jobBuilderRoute,
  jobBuilderTitle: acaNames.jobBuilderTitle,
  adminRoute: 'Admin',
  profileEditorRoute: 'Profil Editor',
  profileRoute: 'Profil',
  jobRoute: `${acaNames.Job}`,
  mapRoute: 'kort',
  mapTitle: 'Kort',
  filesRoute: 'Filer',
  aboutUsRoute: 'Sådan gør du',
  aboutJobSearchRoute: `Find ${acaNames.ajob}`,
  aboutJobBuilderRoute: `Post ${acaNames.ajob}`,
  newUserRoute: 'Ny bruger',
  chatRoute: 'Chat',
  cookiesRoute: 'Cookies',
  partnersRoute: 'Partnere',
  dashboardRoute: acaNames.dashboardRoute,
  dashboardTitle: acaNames.dashboardTitle,
  homeRoute: 'firma',
  searchRoute: 'Søgning',
  messagesRoute: 'Beskeder',
  siteBuilderRoute: acaNames.siteBuilderRoute,
  jobListRoute: `${acaNames.Job}skatalog`,
  siteListRoute: `${acaNames.Site}skatalog`,
  cvListRoute: `${acaNames.User} katalog`,
  postSearchRoute: `Posts`,
  companySearchRoute: `${acaNames.Sites}`,
  candidateSearchRoute: `${acaNames.Users}`,
  homeProfileRoute: 'Profil',

  // router hints
  site: acaNames.site,
  Site: acaNames.Site,
  sites: acaNames.sites,
  Sites: acaNames.Sites,
  CV: acaNames.CV,
  cv: acaNames.cv,
  CVs: acaNames.CVs,
  cvs: acaNames.cvs,

  // publishDialog
  thisWillMakeCVSearchable: acaNames.thisWillMakeCVSearchable,
  thisWillUpdateYourCurrentOnline: 'Dette vil opdatere din nuværende',
  Continue: 'Fortsæt',
  giveYourCVAName: `Giv ${acaNames.thecv} et navn`,
  giveYourJobAName: `Giv ${acaNames.thejob} et navn`,

  // about-us
  Testimonials: 'Statements',
  Partners: 'Partnere',
  Visit: 'Besøg',
  Founders: 'Stiftere',

  // application process
  notRegistered: 'ikke registeret',
  checkPdfAtPage: 'se bryder modellen på side 56',
  applicationMaterialLinkUrl:
    'https://www.akademikerkampagnen.dk/wp-content/uploads/2019/01/SKABv%C3%A6rdi_Ebog_komprimeret.pdf',
  applicationMaterialLinkText: 'Bryder model',
  applicationProcessLine1: 'Din opgave er nu at følge denne model',
  applicationProcessLine2: 'og sende din ansøgning',
  applicationProcessLine3: 'Held og lykke, benyt nedenstående email',
  applicationProcessLine4:
    'Ps. du kan altid vende tilbage til denne dialog ved at søge jobbet frem igen',
  maxApplicationsReceived: 'Fyldt',
  OnlyNumbersAllowedDontUseDash: 'kun tal er tilladt, bring ikke bindestreg',
  CprMustBeFilledOut: 'CPR nummer skal udfyldes.',
  EnterCprWithoutDash: 'Indtast CPR uden bindestreg',
  tryAgain: 'Prøv igen',
  pleaseProvideCPRToCompleteYourRegistration:
    'Ved at fortsætte accepterer du at benytter vi dit CPR nummer til din ansøgning',
  sendYourApplicationToThisEmail: 'Send din ansøgning til denne mail',
  ApplicationSuccess: 'Tak for din ansøgning, fortsæt til bryder modellen',
  Applications: 'Ansøgninger',
  exportToExcel: 'Eksport til Excel',
  cpr: 'CPR',
  somethingWentWrongAttemptingToApplyForJob:
    'Noget gik galt, prøv venligst igen',
  cvTechs: `${acaNames.CV} ${acaNames.techs}`,
  jobTechs: `${acaNames.Job} ${acaNames.techs}`,
  jobCat0: `${acaNames.Job} ${acaNames.category0}`,
  GraduateProgram: acaNames.GraduateProgram,
  GraduateProgramHint: acaNames.GraduateProgramHint,
  GraduateProgramLink: acaNames.GraduateProgramLink,
  GraduateProgramDialog: acaNames.GraduateProgramDialog,
  GraduateProgramTooltip: acaNames.GraduateProgramTooltip,
  GraduateProgramRequired: `${acaNames.GraduateProgram}?`,
  Dimittendsats: acaNames.Dimittendsats,
  DimittendsatsHint: acaNames.DimittendsatsHint,
  DimittendsatsLink: acaNames.DimittendsatsLink,
  DimittendsatsDialog: acaNames.DimittendsatsDialog,
  DimittendsatsTooltip: acaNames.DimittendsatsTooltip,
  DimittendsatsRequired: `${acaNames.Dimittendsats}?`,
  Akademikerkampagnen: acaNames.Akademikerkampagnen,
  AkademikerkampagnenHint: acaNames.AkademikerkampagnenHint,
  AkademikerkampagnenLink: acaNames.AkademikerkampagnenLink,
  AkademikerkampagnenDialog: acaNames.AkademikerkampagnenDialog,
  AkademikerkampagnenTooltip: acaNames.AkademikerkampagnenTooltip,
  AkademikerkampagnenRequired: `${acaNames.Akademikerkampagnen}?`,

  SSOEnabled: acaConfig.SSOEnabled,

  groupFilesEnabled: acaConfig.groupFilesEnabled,
  enabledSubgroups: acaConfig.enabledSubgroups,
  expireProfiles: acaConfig.expireProfiles,
  expireProfilesHint: `${acaNames.CVs} udløber 60 dage efter sidste opdatering`,
  expireProfilesNotificationTitle: `${acaNames.CV} udløbsnotifikation`,
  expireProfilesNotificationMessage: `${acaNames.Yourcv} er ved at udløbe.
  30 dage uden en opdatering og ${acaNames.thecv} bliver fjernet fra søgningen, men ${acaNames.thecv} slettes ikke`,
  expireSites: acaConfig.expireSites,
  expireSitesHint: `${acaNames.Sites} udløber 365 dage efter sidste opdatering`,
  expireSitesNotificationTitle: `${acaNames.Site} udløbsnotifikation`,
  expireSitesNotificationMessage: `${acaNames.Site} er ved at udløbe.
  7 dage uden en opdatering og ${acaNames.thesite} bliver fjernet fra søgningen, men ${acaNames.thesite} slettes ikke`,
  enableJobs: acaConfig.enableJobs,
  expireJobsNotificationTitle: `${acaNames.Job} udløbsnotifikation`,
  expireJobsNotificationMessage: `${acaNames.yourjob} udløber i morgen.
  lav en ny udløbsdato eller ${acaNames.thejob} bliver fjernet fra søgningen, men ${acaNames.thejob} slettes ikke`,

  showApplicationProcessFunctions: acaConfig.showApplicationProcessFunctions,
  registerVisitFunctions: acaConfig.registerVisitFunctions,
  showRelatedJobsFunctions: acaConfig.showRelatedJobsFunctions,
  showCalendarInNetworks: acaConfig.showCalendarInNetworks,
  showMapWithSpecificColors: acaConfig.showMapWithSpecificColors,
  showMapInNetworks: acaConfig.showMapInNetworks,
  showBorderedButtonsInNavBar: acaConfig.showBorderedButtonsInNavBar,
  showCapitalizedButtonsInNavBar: acaConfig.showCapitalizedButtonsInNavBar,
  showNavSpacersInNavBar: acaConfig.showNavSpacersInNavBar,
  showAccountChangerInNavBar: acaConfig.showAccountChangerInNavBar,
  showAccountSelectorIcons: acaConfig.showAccountSelectorIcons,
  showChangeAccountTypeInSignup: acaConfig.showChangeAccountTypeInSignup,
  showSiteImageOnMap: acaConfig.showSiteImageOnMap,
  showPlaceImageOnMap: acaConfig.showPlaceImageOnMap,
  showNetworkImageOnMap: acaConfig.showNetworkImageOnMap,
  showCommunityImageOnMap: acaConfig.showCommunityImageOnMap,
  showIdeaImageOnMap: acaConfig.showIdeaImageOnMap,
  showEventImageOnMap: acaConfig.showEventImageOnMap,
  showCreatorOfCommunities: acaConfig.showCreatorOfCommunities,
  showCreatorOfEvents: acaConfig.showCreatorOfEvents,
  showCreatorOfIdeas: acaConfig.showCreatorOfIdeas,
  showOptionToRemoveUsersPostsAndEvEventsOnLeaving:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnLeaving,
  showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser,
  showInviteWidget: acaConfig.showInviteWidget,
  showBasicStep: acaConfig.showBasicStep,
  RegisterDirectMatch: acaConfig.RegisterDirectMatch,
  contactConsentGiven: acaConfig.contactConsentGiven,
  showCreateCommunityButtonForUserWithoutPermission:
    acaConfig.showCreateCommunityButtonForUserWithoutPermission,
  showCreateEventButtonForUserWithoutPermission:
    acaConfig.showCreateEventButtonForUserWithoutPermission,
  showCreateIdeaButtonForUserWithoutPermission:
    acaConfig.showCreateIdeaButtonForUserWithoutPermission,

  // suggestion

  yourSuggestion: 'Dit forslag',
  hasBeenAddedThanks: 'er tilføjet, tak!',
  areYouSureYouWantToDeleteTheSuggested: 'Er du sikker på du vil slette',
  areYouSureYouWantToAddX: 'Er du sikker på du vil tilføje {0}?',
  areYouSureYouWantToAddThis: 'Er du sikker på du vil tilføje dette',

  // fenerum

  PleaseCheckThatThisIsYourCorrectBillingInformation:
    'Tjek venligst at betalingsinformation er korrekte.',
  ThankYouYourAccountHasBeenCreated: 'Tak! Din betalingskonto er nu oprettet.',
  SetupAccountingEmail: 'Betalingskonto registrering',
  TryAgain: 'Prøv igen',
  line: 'linje',
  Zipcode: 'Postnummer',
  Submit: 'Opret',
  SomethingWentWrongPaymentAccount:
    'Noget gik galt, mens vi prøvede at oprette betalingskonto. Prøv venligst igen.',
  saveDiscount: 'Spar',

  PleaseProvideNameEmailForAccountThisEmailInvoices:
    'Angiv navn og email til din betalingskonto, denne email bliver kun brugt til fakturaer og lignende.',
  ThankYouYourAccountingEmailHasBeenSet:
    'Tak skal du have! Din konto email er indstillet.',
  ContinueToCheckout: 'Fortsæt til checkout',
  Checkout: 'Checkout',
  AccountingEmail: 'Betalingskonto email',
  EmailMustBeAnEmailExSupportAtAcademondoCom:
    'Email skal være en email, f.eks. support@academondo.com.',
  ReceiveInvoice: 'Vil du modtage faktura?',
  ReceivePaymentConfirmation: 'Vil du modtage betalingsbekræftigelse?',
  ReceiveSubscriptionNotifications:
    'Vil du modtage notifikationer om dit abonnement?',
  SomethingWentWrongRecipientEmail:
    'Noget gik galt, mens vi prøvede at tilføje emailen til din betalingskonto. Prøv venligst igen.',

  PleaseSelectYourFormOfPayment: 'Vælg betalingsmetode.',
  ThankYouYourSubscriptionHasBeenSubmitted: 'Tak! Dit abonnement er oprettet.',
  YouAreNowReadyToExploreOurUniverse: `Du er nu klar til at bruge ${acaNames.dashboardWelcomeTitle}`,
  YouAlreadyHaveASubscriptionReachOut: `Du har allerede et abonnement, ræk ud support@academondo.com, hvis du stadig ikke har adgang.`,
  PaymentMethod: 'Betalingsmetode',
  PaymentCard: 'Betalingskort - ikke understøttet endnu',
  Invoice: 'Faktura',
  PaymentMethodMustBeSelected: 'Betalingsmetode skal vælges.',
  SomethingWentWrongPaymentMethod:
    'Noget gik galt, mens vi prøvede at oprette betalingsmetode. Prøv venligst igen.',

  // plan selector
  PlanSelectorTitle: 'Vælg pakke',
  PlanSelectorSubTitle: 'tilbyder pakker til alle - hvilken passer dig?',
  PlanSelectorParagraph1: 'Her er hvad vi tilbyder:',
  PlanSelectorListItem1:
    'Unik eksponering: Find potentielle kunder, investorer eller samarbejdspartnere',
  PlanSelectorListItem2:
    'Få ubegrænset adgang til at oprette tilbud, stillingsopslag eller projekter',
  PlanSelectorListItem3:
    'Deltag i netværk for at samarbejde, innovere og dele viden',
  PlanSelectorListItem4: '',

  // youtube video
  youtubeVideoInputTooltip: 'Indsæt youtube link eller YouTube video id',
  youtubeVideoInputLabel: 'Video',
  youtubeVideoInput: 'https://www.youtube.com/watch?v=videoId',

  // notes
  Notes: 'Noter',
  Comment: 'Kommentér',
  noteLabel: 'Skriv en note...',
  notePlaceholder: 'Eks. Simon siger...',
  noteText: 'Note',
  defaultError: 'Noget gik galt, prøv igen.',
  notesHint: 'Skrive en note',
  noteDeleteMsg: 'Er du sikker på at du vil slette denne note?',
  filterNotes: 'Filtrer noter',

  saveNoteHint: 'Brug Ctrl + Enter for at gemme',

  // places
  place: `${acaNames.place}`,
  Place: `${acaNames.Place}`,
  newPlace: `Nyt ${acaNames.place}`,
  placeName: `${acaNames.Place} navn`,
  placesBuilder: `${acaNames.Place} bygger`,
  deletePlace: `Slet ${acaNames.Place}`,
  YouCanOnlySelect: 'Du kan kun vælge',
  placeType: `${acaNames.Place} type`,

  PlacesTypes0: acaNames.PlacesTypes0,
  PlacesTypes1: acaNames.PlacesTypes1,
  PlacesTypes2: acaNames.PlacesTypes2,
  PlacesTypes3: acaNames.PlacesTypes3,

  Anonymous: 'Anonymous',
  Anonymise: 'Anonymise', // leave blank if no anonymity
  descOfAnonymous: `
    When you check this, your profile won't show your personal details. \n
    Only administrators and you will see the infomation.
  `,

  // new-user
  youHaveBeenInvitedToJoin: `Du er blevet inviteret til ${acaConfig.academondo}!`,

  copiedToClipboard: 'kopieret!!',
  Per: 'Per',
  Select: 'Vælg',

  '': '',

  // reusable words
  with: 'med',
  tags: 'tags',
  postedIn: 'opslået i',
  hasCommentedOn: 'har kommenteret på',
  replyOnComment: 'svaret på kommentar',
  selectInApp: 'Vælg alle InApp',
  selectEmail: 'Vælg alle email',
  email: 'email',
  is: 'er',
  set: 'sat',
  to: 'til',
  has: 'er',
  been: 'blevet',
  created: 'oprettet',
  Created: 'Oprettet',
  not: 'not',
  Number: 'Nummer',
  Info: 'Info',
  Toggle: 'Fold ud',
  Or: 'Eller',
  My: 'Mine',

  // SSO
  SSOLoginIntro: `For at fortsætte, log på ${acaNames.dashboardWelcomeTitle}`,
  SSOLogin: `Fortsæt med ${acaConfig.academondo} konto`,
  SSOLoginOutro: `Fortsæt med email og kodeord`,
  SSOSignupIntro: `Skriv dig op gratis til ${acaNames.dashboardWelcomeTitle}`,
  SSOSignup: `Skriv dig op med ${acaConfig.academondo} konto`,
  SSOSignupOutro: `Skriv dig op med email og kodeord`,
  ssoVerified: `Verificeret ${acaConfig.academondo} ${acaNames.user}`,

  // invite Email

  Hi: 'Hej',
  YouHaveBeenInvitedBy: 'Du er blevet inviteret af',
  toJoin: 'til at blive medlem af',

  YourInvitationTo: 'Din invitation til',
  hasBeenSent: 'er blevet sendt',

  change: 'skift',
  Change: 'Skift',
  suggested: 'foreslåede',
  Suggested: 'Foreslåede',
  Suggestions: 'Forslag',

  listOfSuggestionsEmpty: 'Listen af forslag er tom :-)',

  IncorrectFileFormat: 'ukorrekt filtype, venligst upload',
  FileSizeExceeds: 'Filstørrelse overgår',

  LoadEntities: 'Indlæs Enheder',
  ExportToExcel: 'Eksport til Excel',

  // Admin Page

  UserAdministrationTitle: 'Bruger Adm.',
  CVsTitle: acaNames.CVs,
  CompaniesTitle: acaNames.Sites,
  JobsTitle: acaNames.Jobs,
  FenerumTitle: 'Subscriptions',
  StatisticsTitle: 'Stats',
  ExcelStatisticsTitle: 'Excel Statistik',
  PlacesTitle: `${acaNames.Places}`,
  AdminFunctionalityTitle: '⛔',

  Show: 'Vis',

  RegisterNewCandidate: 'Kandidat',

  directMatch: 'Direkte Match',

  directMatchAdded: 'Direkte Match tilføjet',

  JobMatchDetails: 'Job match detaljer',

  EndDirectMatch: 'Afslut registrering',

  onlyOwnerCanCreatePosts: `${acaNames.Members} kan ikke oprette ${common.posts}`,

  onlyOwnerCanComment: `${acaNames.Member} kan ikke tilføje kommentarer`,

  // intro dialog - contact Consent
  helpheaderContactConsent:
    'Find den rigtige leverandør i dit område. I indkøbsservice sparer vi dig for en masse ressourcer ved at screene leverandøren for dig.',
  helpLineOneContactConsent:
    'Det er gratis at få et tilbud fra os og du vælger selv om du vil gøre brug af det.',
  helpLineTwoContactConsent:
    'Du vil blive kontaktet indenfor 48 timer med en potentiel løsning af din opgave.',

  contactConsentExplanation: 'Beskrivelse',

  imageLinkInputLabel: 'Indsæt et link eller upload dit eget billede',

  imageLinkInput: 'https://imagelink.com/imageID',

  'Error: The email address is badly formatted.':
    'Fejl: email skal være en email.', // signup error msg bad email

  'Wrong email or password.': 'Forkert email eller kodeord.', // login error msg

  showRelatedJobs: `Vis ${acaNames.jobs}`,
  hideRelatedJobs: `Skjul`,

  result: 'resultat',
  results: 'resultater',

  endOfDatabase: 'Enden er nået',

  ThePasswordsMustMatch: 'Kodeordene skal være ens',

  dateFormat: acaConfig.dateFormat,
  date: 'Dato',
  datePrefix: 'kl.',
  aYear: 'et år',
  aMonth: 'en måned',
  day: 'dag',
  days: 'dage',
  aDay: 'en dag',
  hour: 'time',
  hours: 'timer',
  anHour: 'en time',
  minute: 'minut',
  minutes: 'minutter',
  aMinute: 'et minut',
  aFewSeconds: 'et par sekunder',
  ago: ' siden',

  Monday: 'Mandag',
  Tuesday: 'Tirsdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lørdag',
  Sunday: 'Søndag',

  January: 'Januar',
  February: 'Februar',
  March: 'Marts',
  April: 'April',
  May: 'Maj',
  June: 'Juni',
  July: 'Juli',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'December',

  youHaveNoJobsCreateOneFromTheListOfCompanies: `Du har ikke oprettet ${acaNames.ajob} endnu - Opret her`,
  AllJobsAreNowPublished: `Alle ${acaNames.jobs} er nu offentlige`,

  Load: 'Indlæs',
  Remove: 'Fjern',
  Job: `${acaNames.Job}`,
  Jobs: `${acaNames.Jobs}`,
  jobs: `${acaNames.jobs}`,

  showMembersPlugin: `Vis ${acaNames.members}`,
  showCalendarPlugin: 'Show calendar plugin',
  showFilesPlugin: 'Vis filer',
  showMapsPlugin: 'Vis kort',
  showForumPlugin: 'Tillad forum',
  showEventsPlugin: `Tillad ${acaNames.events}`,
  showSubgroupsPlugin: `Tillad ${acaNames.subgroups}`,
  membersCanCreateEventsPlugin: `${acaNames.Members} kan oprette ${acaNames.events}`,
  membersCanCreateSubgroupsPlugin: `${acaNames.Members} kan oprette ${acaNames.subgroups}`,
  membersCanInvitePlugin: `${acaNames.Members} kan invitére`,

  registration: 'registrering',
  VisitRegistrations: 'Besøgsregistreringer',
  VisitRegistrationsStoredAutomatically:
    'Besøgsregistreringer gemmes automatisk',
  JobRegistrations: `Tidligere ${acaNames.jobs}`,
  RegisterVisitDialogText: `Registrering sendes til Admin,
    det vil ikke være muligt at ændre i din registreringen efterfølgende`,
  Send: 'Send',
  ThankYouForYourRegistrationOfVisitAt:
    'Tak for din registrering af besøget hos',

  // Dashboard
  dashboardWelcomeTitle: acaNames.dashboardWelcomeTitle,
  dashboardWelcomeLinks: [
    // {
    //   text: 'Explore our services',
    //   link: 'https://ebrains.eu/#explore_anchor'
    // },
    // {
    //   text: `What is ${acaConfig.academondo}?`,
    //   link: `https://ebrains.eu/#What%20is%20EBRAINS?`
    // }
  ],

  // SubGroups

  subgroup: `${acaNames.subgroup}`,
  Subgroup: `${acaNames.Subgroup}`,
  subgroups: `${acaNames.subgroups}`,
  Subgroups: `${acaNames.Subgroups}`,

  inviteAllMembersToNewSubgroup: `invitér alle ${acaNames.members} til det nye ${acaNames.subgroup}`,

  // Events
  SignupLink: 'Billet udbyder link',
  signupLinkExample: 'Ticketmaster.dk, EventBrite etc.',
  EventSignupLink: `Skriv dig op til ${acaNames.theevent}`,
  OnlineMeeting: 'Online møde',
  isOnlineEvent: `Online ${acaNames.event}`,
  Online: `Online`,
  MeetingLink: 'Møde link',
  meetingLinkExample: 'Zoom, Teams, Meet, Jitsi etc.',
  PleaseAddToYourCalendarButtonText: `Tilføj til din kalender`,
  gotoEvents: `Tilbage til ${acaNames.events}`,
  events: acaNames.events,
  event: acaNames.event,
  _events: acaNames.event,
  Events: acaNames.Events,
  Event: acaNames.Event,
  EventTitle: acaNames.EventsTitle,

  PastEvents: `Tidligere ${acaNames.events}`,
  UpComingEvents: `Upcoming ${acaNames.events}`,
  Discover: 'Udforsk',
  initialEventName: `Min nye ${acaNames.event}`,
  newEvent: `Nye ${acaNames.event}`,
  eventsSubTitle: '',
  deleteEvent: `Slet ${acaNames.event}`,
  confirmDeleteEvent: `Er du sikker på at du vil slette dette ${acaNames.event}?`,
  aboutTheEvent: `Om dette ${acaNames.event}`,
  DeleteEvent: `Slet ${acaNames.event}`,
  PublishDraftEvent: `Gem kladden`,
  eventStartStep1: `Velkommen til ${acaNames.event} byggeren, følg disse skridt for at oprette dit ${acaNames.event}.`,
  eventStartStep2: `${acaNames.Theevent} vil være synligt baseret på indstillingerne.`,
  beginBuildingYourEvent: `Byg ${acaNames.yourEvent}`,
  typeOfEvent: `Tags og kategorier`,
  PublishEvent: `Offentliggør ${acaNames.event}`,
  locationOfEvent: `Den fysiske addresse af dette ${acaNames.event}`,
  eventName: `${acaNames.event} navn`,
  EventName: `${acaNames.Event} navn`,
  writeInformationAboutThePurposeOfThisEvent: `Skriv om ${acaNames.theevent}`,
  inviteOnlyEventDesc: `${acaNames.Members} skal inviteres for at få adgang til ${acaNames.theevent}`,
  PrivateEventDesc: `${acaNames.Private} ${acaNames.event} - synligt for alle, men man skal godkendes`,
  OpenPrivateEventDesc: `${acaNames.OpenPrivate} ${acaNames.event} - synligt for alle, alle kan deltage`,
  PublicEventDesc: `${acaNames.Public} ${acaNames.event} - synligt for alle, alle kan deltage`,
  YourEvents: `${acaNames.YourEvents}`,
  permissionsForEvent: `Indstil de features der skal være tilrådighed for ${acaNames.members} af ${acaNames.theevent}`,
  publishEventDesc: `Offentliggør ${acaNames.theevent} og start med at invitere ${acaNames.members}.`,
  inviteTo_events: `Invitér til ${acaNames.event}`,
  MissingCanCreateDraftEventMsg: `Du kan ikke oprette ${acaNames.events}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishEventsMsg: `Du kan ikke offentliggøre ${acaNames.events}, ræk ud til ${acaConfig.supportMail} for mere info`,
  attending: 'Deltager',
  declined: 'Afvist',
  maybe: 'Måske',
  interested: 'Interesseret',
  YouHaveReceivedAn: 'Du har modtaget et',
  hasInvitedYouTo: 'har inviteret dig til',
  join: 'blive en del af',
  from: 'fra',
  Dear: 'Kære',
  inviteName: 'Navn på kontakt',
  personalText: 'Personlig note',
  noReply: 'Intet svar',
  attending_action: 'Deltag',
  declined_action: 'Afvis',
  maybe_action: 'Måske',
  interested_action: 'Interesseret',
  noReply_action: 'Intet svar',
  attendance: 'Deltagelse',
  selectAttendance: 'Vælg deltagelse',
  LeaveEvent: `Forlad ${acaNames.event}`,
  AllYourPostsFromXWillBeDeleted: `Alle ${common.yourPosts} fra {0} vil blive slettet`,
  StartDateReq: `Start dato krævet`,
  EndDateReq: `Slut dato krævet`,
  youHaveBeenInvitedToEvent: `Du er inviteret`,
  youHaveDeclinedEvent: `Du har afvist`,
  whoCanCreateEventsMessage: `${acaNames.Manager} og ejer kan oprette ${acaNames.community} ${acaNames.events}`,

  NetworkCVStep: 'Invitér dit netværk',
  Home: acaNames.dashboardTitle,

  LeaveRemovePostsSpecialButton: `Forlad og slet ${common.posts}`,

  // event search Settings
  hidePast: `Skjul gamle`,
  hideStarted: `Skjul startede`,
  showOngoing: `Vis nuværende`,
  showExpired: `Vis udløbne`,
  show24Hours: 'Sidste 24 timer',
  show1Week: 'Sidste uge',
  show4Weeks: 'Sidste 4 uger',
  showAll: `Vis alle`,

  // image-cropper
  rotateLeft: 'Rotatér venstre',
  rotateRight: 'Rotatér højre',
  zoomIn: 'Zoom ind',
  zoomOut: 'Zoom ud',
  resetImage: 'Nulstil',
  saveImage: 'Gem billede',
  MoveTheGridToCropImage: 'Bevæg gitteret for at beskære',
  AspectRatioLocked: 'Aspect ratio: låst',

  // Ideas
  gotoIdea: `Tilbage til ${acaNames.ideas}`,
  initialIdeaName: `Mit nye ${acaNames.idea}`,
  idea: acaNames.idea,
  _ideas: acaNames.idea,
  Idea: acaNames.Idea,
  ideas: acaNames.ideas,
  Ideas: acaNames.Ideas,
  IdeasTitle: acaNames.IdeasTitle,
  ideasSubTitle: '',
  typeOfIdea: acaNames.Category5
    ? `Tags og ${acaNames.categories5}`
    : `Tags og kategorier`,
  writeInformationAboutThePurposeOfThisIdea: `Skriv information om formålet med dette ${acaNames.idea}`,
  locationOfIdea: `Den fysiske adresse af ${acaNames.idea}`,
  ideaStartStep1: `Velkommen til ${acaNames.idea} byggeren, følge disse steps for at oprette det eget ${acaNames.idea}.`,
  ideaStartStep2: `${acaNames.Idea}et vil være synligt baseret på baggrund af privatlivs indstillingen.`,
  beginBuildingYourIdea: `Byg ${acaNames.yourIdea}`,
  newIdea: `Nyt ${acaNames.idea}`,
  permissionsForIdea: `Vælg hvad der skal være muligt for ${acaNames.members} af dette ${acaNames.idea}`,
  inviteOnlyIdeaDesc: `${acaNames.Members} skal inviteres for at få adgang til dette ${acaNames.idea}.`,
  PublicIdeaDesc: `${acaNames.Public} ${acaNames.idea} - synligt for alle, alle kan deltage.`,
  OpenPrivateIdeaDesc: `${acaNames.OpenPrivate} ${acaNames.idea} - synligt for alle, alle kan deltage og ${common.posts} er søgbare`,
  PrivateIdeaDesc: `${acaNames.Private} ${acaNames.idea} - synligt for alle, men man skal godkendes`,
  publishIdea: `Gem ${acaNames.idea}`,
  publishIdeaDesc: `Gem dette ${acaNames.idea} og begynd at inviterer ${acaNames.members}.`,
  deleteIdea: `Slet ${acaNames.idea}`,
  confirmDeleteIdea: `Er du sikker på at du vil slette dette ${acaNames.idea}?`,
  aboutTheIdea: `Om ${acaNames.idea}`,
  inviteTo_ideas: `Invitér til ${acaNames.idea}`,
  PublicIdea: `${acaNames.Public} ${acaNames.Idea}`,
  PrivateIdea: `${acaNames.Private} ${acaNames.Idea}`,
  InviteOnlyIdea: `${acaNames.InviteOnly} ${acaNames.Idea}`,
  FilterIdeas: `Filtrer dine ${acaNames.ideas}`,
  NoMatchingIdeas: `Ingen ${acaNames.Ideas} matcher din søgning...`,
  ideaName: `${acaNames.idea} navn`,
  IdeaName: `${acaNames.Idea} navn`,
  IdeasYouManage: `${acaNames.Ideas} du bestyrer`,
  YourIdeas: `${acaNames.YourIdeas}`,
  LeaveIdea: `Forlad ${acaNames.idea}`,
  DeleteIdea: `Slet ${acaNames.idea}`,
  PublishDraftIdea: `Gem kladden`,
  MissingCanCreateDraftIdeaMsg: `Du kan ikke oprette ${acaNames.ideas}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishIdeaMsg: `Du kan ikke gemme ${acaNames.ideas}, ræk ud til ${acaConfig.supportMail} for mere info`,
  youHaveToAddAXForY: `Du skal give dit {1} et {0}`,
  websiteCollaboratoryUrl: acaNames.websiteCollaboratoryUrl,

  // Communities
  gotoCommunity: `Tilbage til ${acaNames.communities}`,
  initialCommunityName: `Mit nye ${acaNames.community}`,
  community: acaNames.community,
  _communities: acaNames.community,
  Community: acaNames.Community,
  Communitys: acaNames.Communities,
  communities: acaNames.communities,
  Communities: acaNames.Communities,
  CommunitiesTitle: acaNames.communitiesTitle,
  communitiesSubTitle: '',
  media: 'Media',
  typeOfCommunity: `Tags og kategorier`,
  uploadCoverImage: 'Upload cover billede',
  coverImage: 'Cover billede',
  writeInformationAboutThePurposeOfThisCommunity: `Skriv information om formålet med dette ${acaNames.community}`,
  locationOfCommunity: `Den fysiske adresse af ${acaNames.community}`,
  communityStartStep1: `Velkommen til ${acaNames.community} byggeren, følge disse steps for at oprette det eget ${acaNames.community}.`,
  communityStartStep2: `${acaNames.Community}et vil være synligt baseret på baggrund af privatlivs indstillingen.`,
  beginBuildingYourCommunity: `Byg ${acaNames.community}`,
  newCommunity: `Nyt ${acaNames.community}`,
  privacySettings: 'Privatliv and Indstillinger',
  permissionsForCommunity: `Vælg hvad der skal være muligt for ${acaNames.members} af dette ${acaNames.community}`,
  inviteOnlyCommunityDesc: `${acaNames.Members} skal inviteres for at få adgang til dette ${acaNames.community}.`,
  PublicCommunityDesc: `${acaNames.Public} ${acaNames.community} er synligt for alle, alle kan deltage, ${common.posts} er søgbare og vises på alles væg`,
  OpenPrivateCommunityDesc: `${acaNames.OpenPrivate} ${acaNames.community} - synligt for alle, alle kan deltage og ${common.posts} er søgbare`,
  PrivateCommunityDesc: `${acaNames.Private} ${acaNames.community} - synligt for alle, men man skal godkendes`,
  publishCommunity: `Gem ${acaNames.community}`,
  publishCommunityDesc: `Gem dette ${acaNames.community} og begynd at inviterer ${acaNames.members}.`,
  deleteCommunity: `Slet ${acaNames.community}`,
  confirmDeleteCommunity: `Er du sikker på at du vil slette dette ${acaNames.community}?`,
  aboutTheCommunity: `Om ${acaNames.community}`,
  Owner: 'Ejer',
  Drafting: 'Kladde',
  Moderator: `${acaNames.Moderator}`,
  Administrator: `${acaNames.Manager}`,
  PromoteToModerator: `Forfrem til ${acaNames.Moderator}`,
  PromoteToAdministrator: `Forfrem til ${acaNames.Manager}`,
  DowngradeToMember: `Gør til ${acaNames.Member}`,
  RemoveMember: `Slet ${acaNames.Member}`,
  RemoveMemberAndRemovePosts: `Slet ${acaNames.Member} og ${common.posts}`,
  inviteTo_communities: `Invitér til ${acaNames.community}`,
  PublicCommunity: `${acaNames.Public} ${acaNames.Community}`,
  PrivateCommunity: `${acaNames.Private} ${acaNames.Community}`,
  InviteOnlyCommunity: `${acaNames.InviteOnly} ${acaNames.Community}`,
  FilterCommunities: `Filtrer dine ${acaNames.communities}`,
  NoMatchingCommunities: `Ingen ${acaNames.Communities} matcher din søgning...`,
  PendingInvites: 'Anmodninger',
  GoToSearch: 'Gå til søg',
  Drafts: 'Kladder',
  Update: 'Opdatér',
  publish: 'Indryk',
  Publish: 'Indryk',
  communityName: `${acaNames.community} navn`,
  CommunityName: `${acaNames.Community} navn`,
  CommunitiesYouManage: `${acaNames.Communities} du bestyrer`,
  YourCommunities: `${acaNames.YourCommunities}`,
  Find: 'Find',
  By: 'Af',
  ThisIsHowYourPostWillLook: `Således vil ${common.yourPost} se ud`,
  BackTo: 'Tilbage',
  GoTo: 'Gå til',
  ViewAll: 'Vis alle',
  Comments: 'Kommentarer',
  LatestComment: 'Seneste kommentar',
  MakeAComment: 'Lav en kommentar',
  LeaveCommunity: `Forlad ${acaNames.community}`,
  DeleteCommunity: `Slet ${acaNames.community}`,
  PublishDraftCommunity: `Gem kladden`,
  MissingCanCreateDraftCommunityMsg: `Du kan ikke oprette ${acaNames.communities}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishCommunityMsg: `Du kan ikke gemme ${acaNames.communities}, ræk ud til ${acaConfig.supportMail} for mere info`,
  LoadAllComments: 'Indlæs alle kommentarer',
  XPrivacyCanOnlyBeChangedOnCreation: `❗ Privatliv kan ikke ændres, efter et {0} er gemt`,

  MissingCanCreateDraftGroupMsg: `Du kan ikke oprette gruppe, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishGroupMsg: `Du kan ikke offentliggøre gruppe, ræk ud til ${acaConfig.supportMail} for mere info`,

  // Forum
  Preview: 'Forhåndsvining',
  GroupPublicPost: `${common.Post}`,
  GroupPublicPosts: `${common.Posts}`,
  discardChangesPostCreateTitle: `Glem ${common.post}?`,
  discardChangesPostUpdateTitle: 'Glem ændringerne?',
  discardChangesPostCreateBody:
    'Er du sikker på at du vil afslutte, det indtastede forsvinder?',
  discardChangesPostUpdateBody:
    'Er du sikker på at du vil afslutte denne opdatering, det indtastede forsvinder?',
  deletePostTitle: `Slet ${common.post}?`,
  deletePostBody: `${common.ThePost} og alle kommentarer bliver slettet, er du sikker?`,
  attachments: 'Vedhæftelser',
  newComment: 'Ny Kommentar',

  CanLeaveCVTitle: 'You need to save to make the profile public',
  CanLeaveCVText: 'Do you want to leave or publish your changes',
  CanLeaveCVHint: '',
  PublishCV: `Gem ${acaNames.cv}`,
  LeaveCvBuilder: `Forlad uden at gemme`,

  // map
  mapSiteTooltip: acaNames.mapSiteTooltip,
  mapSiteHint: acaNames.mapSiteHint,
  u0Marker: acaConfig.u0Marker,
  siteMarker: acaConfig.siteMarker,
  u2Marker: acaConfig.u2Marker,
  u3Marker: acaConfig.u3Marker,
  u4Marker: acaConfig.u4Marker,
  u5Marker: acaConfig.u5Marker,
  gMarker: acaConfig.gMarker,
  cMarker: acaConfig.cMarker,
  eMarker: acaConfig.eMarker,
  iMarker: acaConfig.iMarker,
  nMarker: acaConfig.nMarker,
  p0Marker: acaConfig.p0Marker,
  p1Marker: acaConfig.p1Marker,
  p2Marker: acaConfig.p2Marker,
  p3Marker: acaConfig.p3Marker,
};
