<div class="form-group" [formGroup]="form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'CompanyName' | preventHardcoding }}</mat-label>
    <input matInput [formControl]="name" [readonly]="!canEditCompanyDetails" />
  </mat-form-field>
  <ng-container *ngIf="'showApplicationProcessFunctions' | preventHardcoding">
    <mat-form-field appearance="standard">
      <mat-label>{{ 'cvr' | preventHardcoding }}</mat-label>
      <input
        matInput
        [formControl]="cvr"
        input="number"
        maxlength="8"
        minlength="8"
        [readonly]="!canEditCompanyDetails"
      />
      <mat-error *ngIf="cvr.hasError('minlength') || cvr.hasError('maxlength')">
        {{ 'cvrLengthError' | preventHardcoding }}
      </mat-error>
      <mat-error *ngIf="cvr.hasError('CVR')">
        {{ 'cvr' | preventHardcoding }} {{ 'invalid' | preventHardcoding }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'applicationEmail' | preventHardcoding }}</mat-label>
      <input matInput type="email" [formControl]="email" />
      <mat-error *ngIf="email?.errors?.email">{{
        'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'numberOfMaxApplicants' | preventHardcoding }}</mat-label>
      <mat-select [formControl]="maxApplicants">
        <mat-option value="1">1</mat-option>
        <mat-option value="2">2</mat-option>
        <mat-option value="3">3</mat-option>
        <mat-option value="4">4</mat-option>
        <mat-option value="5">5</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <div class="sub-group">
    <app-address-selector
      [locationForm]="location"
      [inputReadonly]="!canEditCompanyDetails"
    ></app-address-selector>
  </div>
  <app-file-upload
    btnText="{{ 'uploadIcon' | preventHardcoding }}"
    [ratio]="1 / 1"
    [currentPictureUrl]="pictureUrl.value"
    [defaultPicture]="'/assets/logos/Corporate.svg'"
    [filePath]="imagePath"
    (complete)="onPicUpload($event)"
  >
  </app-file-upload>
  <!-- <app-file-upload
    btnText="{{ 'uploadCover' | preventHardcoding }}"
    [ratio]="16 / 5"
    [currentPictureUrl]="coverUrl.value"
    [filePath]="coverImagePath"
    (complete)="onCoverUpload($event)"
  >
  </app-file-upload> -->
</div>
