import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class JiraService {
  private static readonly ReportPrefix = 'Report';

  private serviceUrl = 'Obsolete'; // environment.jiraService;

  constructor(private httpClient: HttpClient) { }

  public createBugReport(name: string, email: string, feedback: string): Observable<Blob> {
    return this.httpClient.post(this.serviceUrl, {
      summary: this.parseSummary(JiraService.ReportPrefix, feedback),
      description: '*Name:* ' + name + '\n' +
        '*Email:* ' + email + '\n\n' +
        '*Report:*\n' + feedback
    }, {
      responseType: 'blob'
    });
  }

  private parseSummary(prefix: string, str: string): string {
    const summaryText = str.length > 25 ? str.substr(0, 25) + '...' : str;
    return `[${prefix}] ${summaryText}`;
  }
}
