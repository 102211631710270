import { NgModule } from '@angular/core';
import { FileUploadModule } from 'app/file-upload/file-upload.module';
import { InviteSearchModule } from 'app/search/invite-result/invite-search.module';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { DAO } from 'app/shared-services/db-access/dao';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { ActionBarComponent } from 'app/stepper/shared/components/action-bar/action-bar.component';
import { CountryPickerComponent } from 'app/stepper/shared/components/country-picker/country-picker.component';
import { PropertiesDialogComponent } from 'app/stepper/shared/components/properties-dialog/properties-dialog.component';
import { CvViewerModule } from 'app/viewer/cv/cv-viewer.module';
import { SharedModule } from '../shared/shared.module';
import { ItemSelectorComponent } from './shared/components/action-bar/item-selector/item-selector.component';
import { IntroDialogComponent } from './shared/components/intro-dialog/intro-dialog.component';
import { LanguagePickerComponent } from './shared/components/language-picker/language-picker.component';
import { MembershipSearchComponent } from './shared/components/networks-membership-management';
import { PublishDialogComponent } from './shared/components/publish-dialog/publish-dialog.component';
import { StartStepComponent } from './shared/components/start-step/start-step.component';
import { StepBaseComponent } from './shared/components/step-base/step-base.component';
import { StepFooterComponent } from './shared/components/step-footer/step-footer.component';
import { StepperContainerComponent } from './shared/components/stepper-container/stepper-container.component';
import { CloseBuilderDirective } from './shared/directives/close-builder';
import { CommonStepperService } from './shared/services/common-stepper.service';
import { LanguageComponent } from './shared/steps/language/language.component';

@NgModule({
  imports: [
    SharedModule,
    InviteSearchModule,
    CvViewerModule,
    SharedPipesModule,
    SharedUiModule,
    SharedDirectivesModule,
    FileUploadModule,
  ],
  declarations: [
    StepFooterComponent,
    CountryPickerComponent,
    ActionBarComponent,
    StepperContainerComponent,
    StepBaseComponent,
    StartStepComponent,
    ItemSelectorComponent,
    IntroDialogComponent,
    PropertiesDialogComponent,
    PublishDialogComponent,
    LanguagePickerComponent,
    LanguageComponent,
    CloseBuilderDirective,
    MembershipSearchComponent,
  ],
  exports: [
    SharedModule,
    StepFooterComponent,
    CountryPickerComponent,
    LanguagePickerComponent,
    ActionBarComponent,
    StepperContainerComponent,
    LanguageComponent,
    CloseBuilderDirective,
    MembershipSearchComponent,
  ],
  entryComponents: [
    StartStepComponent,
    LanguageComponent,
    IntroDialogComponent,
    PublishDialogComponent,
    PropertiesDialogComponent,
  ],
  providers: [CommonStepperService, DAO],
})
export class StepperModule {}
