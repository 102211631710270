import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from 'app/core/logging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable()
export class MondoErrorHandler extends ErrorHandler {
  private snackbarRef;

  constructor(private injector: Injector, private snackBar: MatSnackBar) {
    super();
  }

  handleError(error: Error) {
    const logger = <LoggingService>this.injector.get(LoggingService);
    logger.logError(error);
    if (
      !this.snackbarRef &&
      environment.academondoUrl === 'https://demo.academondo.com'
    ) {
      this.showError(error);
    }
    super.handleError(error);
  }

  showError(error) {
    this.snackbarRef = this.snackBar.open(
      `An error occured
      Contact Simon!
      error: ${error}`,
      '',
      { duration: 10000 }
    );
  }
}
