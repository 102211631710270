<ng-container *ngIf="notes && notes.length">
  <ng-container *ngIf="notes.length > 5">
    <mat-form-field appearance="standard" class="filter-container">
      <mat-label>{{'filterNotes'| preventHardcoding}}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]=query
        (keyup)=filterByName()
        class="filter"
        maxlength="30"
        autocomplete="off">
    </mat-form-field>
  </ng-container>
  <perfect-scrollbar style="max-height: calc(100vh - 210px);">
    <ng-container *ngIf="filteredNotes; else noNotes">
      <div *ngFor="let note of filteredNotes; trackBy: trackByFn">
        <app-note [note]="note"></app-note>
      </div>
    </ng-container>
    <ng-template #noNotes>empty</ng-template>
  </perfect-scrollbar>
</ng-container>

