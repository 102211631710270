import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
} from 'app/shared/models';

export class ForumTopic {
  public static fromJson(
    {
      fieldsOfInterests,
      techniques,
      category0,
      category1,
      category2,
      category3,
      category4,
      category5,
    }: IForumTopic = {} as ForumTopic
  ) {
    return new ForumTopic(
      fieldsOfInterests || [],
      techniques || [],
      category0 || [],
      category1 || [],
      category2 || [],
      category3 || [],
      category4 || [],
      category5 || []
    );
  }

  public static toJson(topic: ForumTopic): IForumTopic {
    return {
      fieldsOfInterests:
        topic && topic.fieldsOfInterests ? topic.fieldsOfInterests : [],
      techniques: topic && topic.techniques ? topic.techniques : [],
      category0: topic && topic.category0 ? topic.category0 : [],
      category1: topic && topic.category1 ? topic.category1 : [],
      category2: topic && topic.category2 ? topic.category2 : [],
      category3: topic && topic.category3 ? topic.category3 : [],
      category4: topic && topic.category4 ? topic.category4 : [],
      category5: topic && topic.category5 ? topic.category5 : [],
    };
  }

  constructor(
    public fieldsOfInterests: FieldOfInterest[] = [],
    public techniques: Technique[] = [],
    public category0: Category0[] = [],
    public category1: Category1[] = [],
    public category2: Category0[] = [],
    public category3: Category0[] = [],
    public category4: Category0[] = [],
    public category5: Category0[] = []
  ) {}
}

export interface IForumTopic {
  fieldsOfInterests: FieldOfInterest[];
  techniques: Technique[];
  category0: Category0[];
  category1: Category1[];
  category2: Category0[];
  category3: Category0[];
  category4: Category0[];
  category5: Category0[];
}
