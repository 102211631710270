<mat-form-field appearance="standard" *ngIf="languages">
  <mat-select
    type="language"
    placeholder="{{ 'Language' | preventHardcoding }}"
    [(ngModel)]="selectedLanguage"
  >
    <mat-option
      *ngFor="let language of languages; trackBy: trackByFn"
      (click)="onLanguageSelected(language)"
      (onSelectionChange)="onLanguageSelectionChanged($event, language)"
      [value]="language"
      >{{ language.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
