<ng-container>
  <div *ngIf="!formSubmitted && !recipientExists" class="row">
    <div class="col-md-12">
      <h4>
        {{
          'PleaseProvideNameEmailForAccountThisEmailInvoices'
            | preventHardcoding
        }}
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-area">
      <div *ngIf="formSubmitted || recipientExists">
        <div class="row">
          <div
            *ngIf="formSubmitted"
            class="col-md-12 text-center bug-reported-success"
          >
            {{ 'ThankYouYourAccountingEmailHasBeenSet' | preventHardcoding }}
          </div>
          <div
            *ngIf="recipientExists"
            class="col-md-12 text-center bug-reported-success"
          >
            <h4>
              {{ 'ContinueToCheckout' | preventHardcoding }}
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="openPaymentSite()"
            >
              {{ 'Checkout' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="formError">
        <div class="row">
          <div class="col-md-12 text-center bug-reported-failed">
            {{ formErrorMessage }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button mat-raised-button color="primary" (click)="retry()">
              {{ 'TryAgain' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <form
        [formGroup]="recipientForm"
        *ngIf="recipientForm && !formSubmitted && !recipientExists"
      >
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'CompanyName' | preventHardcoding }}</mat-label>
              <input matInput formControlName="name" />
              <mat-error *ngIf="recipientForm.get('name').hasError('required')">
                {{ 'Name' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard" class="email-mat-field">
              <mat-label>{{ 'AccountingEmail' | preventHardcoding }}</mat-label>
              <input
                matInput
                type="email"
                name="email"
                formControlName="email"
              />
              <mat-error
                *ngIf="recipientForm.get('email').hasError('required')"
              >
                {{ 'Email' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
              <mat-error *ngIf="recipientForm.get('email').hasError('email')">
                {{
                  'EmailMustBeAnEmailExSupportAtAcademondoCom'
                    | preventHardcoding
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-checkbox color="primary" formControlName="receive_invoice">
              {{ 'ReceiveInvoice' | preventHardcoding }}
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-checkbox
              color="primary"
              formControlName="receive_payment_confirmation"
            >
              {{ 'ReceivePaymentConfirmation' | preventHardcoding }}
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-checkbox
              color="primary"
              formControlName="receive_subscription_notifications"
            >
              {{ 'ReceiveSubscriptionNotifications' | preventHardcoding }}
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-button (click)="cancel()" [disabled]="formLoading">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="createRecipient()"
              [disabled]="formLoading || recipientForm.invalid"
            >
              {{ 'Submit' | preventHardcoding }}
            </button>
          </div>
        </div>
        <app-splash-screen *ngIf="formLoading"></app-splash-screen>
      </form>
    </div>
  </div>
</ng-container>
