<div *ngIf="job.contact">
  <div *ngIf="infoHTML || researchLeaderName || contactEmail || contactPhone">
    <app-icon-title
      title="{{ 'contactInformation' | preventHardcoding }}"
      iconPath="/assets/icons/Contact1.svg"
    >
    </app-icon-title>
    <div class="profile-card__title" *ngIf="researchLeaderName">
      <mat-icon>person</mat-icon
      ><span
        >&nbsp;&nbsp;<span *ngIf="researchLeaderName">{{
          researchLeaderName
        }}</span></span
      >
    </div>
    <div class="profile-card__title" *ngIf="contactEmail">
      <mat-icon>email</mat-icon
      ><span
        >&nbsp;&nbsp;<a [href]="'mailto:' + contactEmail" target="_blank">{{
          contactEmail
        }}</a></span
      >
    </div>
    <div class="profile-card__title" *ngIf="contactPhone">
      <mat-icon>phone</mat-icon
      ><span
        >&nbsp;&nbsp;<a [href]="'tel:' + contactPhone" target="_blank">{{
          contactPhone
        }}</a></span
      >
    </div>
    <span [innerHtml]="infoHTML | safeHtml"></span>
  </div>

  <div *ngIf="urlJobApplication || urlJobSite">
    <app-icon-title
      title="{{ 'relevantLinks' | preventHardcoding }}"
      iconPath="/assets/icons/Link1.svg"
    >
    </app-icon-title>
    <table class="labeled-data-table smaller-light">
      <tr *ngIf="urlJobApplication">
        <td>{{ 'jobApplication' | preventHardcoding }}:</td>
        <td>
          <button
            type="button"
            *ngIf="urlJobApplication | safeAngularLink as applicationHref"
            title="apply"
            mat-raised-button
            color="danger"
            (click)="apply(applicationHref)"
          >
            {{ 'Apply' | preventHardcoding }}
          </button>
        </td>
      </tr>
      <tr *ngIf="urlJobSite">
        <td>{{ 'jobSite' | preventHardcoding }}:</td>
        <td>
          <a target="_blank" href="{{ urlJobSite | safeAngularLink }}">
            {{ 'officialJobSite' | preventHardcoding }}
          </a>
        </td>
      </tr>
    </table>
  </div>

  <div *ngIf="deadline">
    <app-icon-title
      title="{{ 'applicationDeadline' | preventHardcoding }}"
      iconPath="/assets/icons/Calendar1.svg"
    >
    </app-icon-title>
    <span class="smaller-light"><app-date [date]="deadline"></app-date></span>
  </div>
</div>
