<div *ngIf="jobInfo">
  <div class="label t_t_u" *ngIf="position">
    <p>{{ position }}</p>
  </div>

  <div *ngIf="teaser">
    <div [innerHTML]="teaser | safeHtml"></div>
    <div class="divider clearfix"></div>
  </div>

  <div *ngIf="aboutCompany">
    <app-icon-title
      title="{{ 'aboutTheCompany' | preventHardcoding }}"
      iconPath="/assets/icons/About1.svg"
    ></app-icon-title>
    <div [innerHTML]="aboutCompany | safeHtml"></div>
  </div>

  <div *ngIf="description">
    <app-icon-title
      title="{{ 'aboutThePosition' | preventHardcoding }}"
      iconPath="/assets/icons/Description1.svg"
    ></app-icon-title>
    <div [innerHTML]="description | safeHtml"></div>
  </div>
  <div *ngIf="youtubeId">
    <app-youtube-video [link]="youtubeId"></app-youtube-video>
    <div class="divider clearfix"></div>
  </div>
  <div class="image-placement" *ngIf="imageLink">
    <img title="imgLink" [src]="imageLink" />
    <div class="divider clearfix"></div>
  </div>
  <div class="image-placement" *ngIf="imageUrl">
    <img title="imgUrl" [src]="imageUrl" />
    <div class="divider clearfix"></div>
  </div>
</div>
