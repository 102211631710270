<div class="cv_publications" *ngIf="publicationsList">
  <h5 *ngIf="!cv.personalDetails.exclude" class="title_icon normal-regular">{{'publications'| preventHardcoding}} - {{cv.personalDetails.firstName}} {{cv.personalDetails.lastName}}</h5>
  <h5 *ngIf="cv.personalDetails.exclude" class="title_icon normal-regular">{{'publications'| preventHardcoding}}</h5>
  <ol>
    <li *ngFor="let published of publicationsList; trackBy: trackByFn">
      <span *ngIf="published.authors && published.authors.length> 0">
        <span *ngFor="let author of published.authors; let i = index; last as isLast; trackBy: trackByFn">
          <span class="smaller-regular" *ngIf="author.foreName"
            [ngClass]="cv.personalDetails.firstName && cv.personalDetails.lastName && author.foreName === cv.personalDetails.firstName && author.lastName === cv.personalDetails.lastName ? 'isAuthor': ''">
            {{author.foreName | shortenFirstCharInEveryWord}}
          </span>
          <span class="smaller-regular" *ngIf="author.lastName"
            [ngClass]="cv.personalDetails.firstName && cv.personalDetails.lastName && author.foreName === cv.personalDetails.firstName && author.lastName === cv.personalDetails.lastName ? 'isAuthor': ''">
            {{author.lastName}}</span>
          <span *ngIf="(author.foreName || author.lastName) && !isLast">, </span>
        </span>
      </span>
      <span class="smaller-light">&nbsp;"<i>{{published.title}}</i>"&nbsp;&nbsp;- {{published.journal}}, {{published.date?.getFullYear()}}</span>
    </li>
  </ol>
  <div class="divider clearfix"></div>
</div>