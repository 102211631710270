import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import {
  MondoAbstractControl,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import {
  DateOptions,
  PickerMode,
} from 'app/shared/components/date-picker/date-picker.component';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { AuthService } from 'app/core/auth.service';
import { CV } from 'app/shared/models/cv/cv';
import { MatDialog } from '@angular/material';
import { acaConfig } from 'aca-config';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsComponent implements OnInit {
  @Input() form!: MondoFormGroup<PersonalDetails>;
  @Input() cvForm!: MondoFormGroup<CV>;
  @Input() showBasicStep = false;
  showUploader = false;
  yearDateOffset = 365 * 24 * 60 * 60 * 1000;
  now = new Date();
  max = new Date(this.now.getTime() - this.yearDateOffset * 15);
  startDate = new Date(this.now.getTime() - this.yearDateOffset * 30);

  readonly dobPickerOptions: DateOptions = {
    startDate: this.startDate,
    pickerMode: PickerMode.day,
    disabled: false,
    placeholder: 'dateOfBirth',
    timePickerLabel: '',
    optionalHint: 'dateOfBirthHint',
    startView: 'multi-year',
    showClearBtn: true,
    maxDate: this.max,
  };

  constructor(private authService: AuthService, private dialog: MatDialog) {}

  ngOnInit() {
    if (!this.authService.canBeAnonymous) {
      this.exclude.setValue(false);
    }
    if (!acaConfig.showApplicationProcessFunctions) {
      this.cpr.setValue(null);
      this.graduateProgram.setValue(null);
      this.dimittendsats.setValue(null);
      this.akademikerkampagnen.setValue(null);
    }
  }

  onProfilePicUpload(url: string) {
    this.showUploader = false;
    this.pictureUrl.setValue(url);
  }

  toggleUploader() {
    this.showUploader = !this.showUploader;
  }

  canBeAnonymous(): boolean {
    return this.authService.canBeAnonymous;
  }

  onCoverUpload(url: string) {
    this.coverUrl.setValue(url);
  }

  get coverPath(): string {
    return `/cv/${this.cvKey}/cover`;
  }

  get imagePath(): string {
    return `/cv/${this.cvKey}`;
  }

  get cvKey(): string {
    return this.cvForm.getSafeControl((x) => x.key).value;
  }

  get pictureUrl(): MondoAbstractControl<string> {
    return this.form.getSafeControl((x) => x.pictureUrl);
  }

  get location(): MondoFormGroup<MondoLocation> {
    return this.form.getSafeGroup((x) => x.address);
  }

  get dateOfBirth(): MondoFormGroup<Date> {
    return this.form.getSafeGroup((x) => x.dob);
  }

  get email(): MondoFormGroup<String> {
    return this.form.getSafeGroup((x) => x.contactInfo.email);
  }

  get exclude(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.exclude);
  }

  get cpr(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.cpr);
  }

  get graduateProgram(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.graduateProgram);
  }

  get dimittendsats(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.dimittendsats);
  }

  get akademikerkampagnen(): MondoAbstractControl<boolean> {
    return this.form.getSafeControl((x) => x.akademikerkampagnen);
  }

  get coverUrl(): MondoAbstractControl<string> {
    return this.form.getSafeControl((x) => x.coverUrl);
  }
}
