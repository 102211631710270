import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Note } from 'app/shared/models/note';
import { NotesService } from 'app/core/notes.service';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent implements OnInit {
  @Input() note: Note;
  constructor(private noteService: NotesService, private dialog: MatDialog) {}

  ngOnInit() {}

  delete(key: string, entityUid: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'delete';
    dialogRef.componentInstance.text = 'areYouSureYouWantToX';
    dialogRef.componentInstance.textX = 'delete';
    dialogRef.componentInstance.yesText = 'delete';
    dialogRef.componentInstance.noText = 'cancel';
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.noteService.deleteNote(key, entityUid);
      }
    });
  }
}
