import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MondoFormGroup,
  MondoAbstractControl,
} from 'app/core/mondo-form-builder';
import { Country } from 'app/shared/models';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { Job } from 'app/stepper/job/model/job';
import { AuthService } from 'app/core/auth.service';

@Component({
  selector: 'app-job-builder-company',
  templateUrl: './job-builder-company.component.html',
  styleUrls: ['./job-builder-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderCompanyComponent {
  @Input() form: MondoFormGroup<Job>;

  constructor(private authService: AuthService) {}

  get name(): MondoFormGroup<string> {
    return this.form.getSafeGroup((x) => x.company.name);
  }

  get cvr(): MondoFormGroup<string> {
    return this.form.getSafeGroup((x) => x.company.cvr);
  }

  get email(): MondoFormGroup<string> {
    return this.form.getSafeGroup((x) => x.company.email);
  }

  get maxApplicants(): MondoFormGroup<number> {
    return this.form.getSafeGroup((x) => x.company.maxApplicants);
  }

  get country(): MondoFormGroup<Country> {
    return this.form.getSafeGroup((x) => x.company.location.country);
  }

  get pictureUrl(): MondoAbstractControl<string> {
    return this.form.getSafeControl((x) => x.company.pictureUrl);
  }

  get coverUrl(): MondoAbstractControl<string> {
    return this.form.getSafeControl((x) => x.company.coverUrl);
  }

  get jobKey(): string {
    return this.form.getSafeControl((x) => x.key).value;
  }

  get location(): MondoFormGroup<MondoLocation> {
    return this.form.getSafeGroup((x) => x.company.location);
  }

  onPicUpload(url: string) {
    this.pictureUrl.setValue(url);
  }

  onCoverUpload(url: string) {
    this.coverUrl.setValue(url);
  }

  get imagePath(): string {
    return `/job/${this.jobKey}/logo`;
  }

  get coverImagePath(): string {
    return `/job/${this.jobKey}/cover`;
  }

  get canEditCompanyDetails() {
    return this.authService.canEditCompanyDetails;
  }
}
