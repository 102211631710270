import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-viewer',
  templateUrl: './job-viewer.component.html',
  styleUrls: ['./job-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobViewerComponent {
  @Input() showMetaData = true;
  @Input() job: Job;
}
