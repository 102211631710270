import { Component, Input, OnInit } from '@angular/core';
import { SsoAuthService } from 'app/core/sso-auth.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  @Input() introText: string;
  @Input() text: string;
  @Input() outroText: string;
  loading = false;
  constructor(private ssoAuthService: SsoAuthService) {}

  ngOnInit() {}

  loginSSO() {
    this.loading = true;
    this.ssoAuthService.login();
  }
}
