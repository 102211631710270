import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MondoRoutes } from '../../../app.routing-model';
import { MondoFormGroup } from '../../../core/mondo-form-builder';
import { Job } from '../model/job';
import { JobService } from '../services/job.service';
import { JobFormService } from 'app/stepper/job/services/job-form.service';
import { MondoStep } from 'app/stepper/shared/model/MondoStep';
import { JobStepIndex } from '../../shared/model/stepIndex';
import { StartStepComponent } from 'app/stepper/shared/components/start-step/start-step.component';
import { JobBuilderInfoComponent } from './steps/job-builder-info/job-builder-info.component';
import { JobBuilderCompanyComponent } from './steps/job-builder-company/job-builder-company.component';
import { JobBuilderResearchQualificationsComponent } from './steps/job-builder-research-qualifications/job-builder-research-qualifications.component';
import { JobBuilderContactComponent } from './steps/job-builder-contact/job-builder-contact.component';
import { JobBuilderVisibilityComponent } from './steps/job-builder-visibility/job-builder-visibility.component';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-job-builder',
  templateUrl: './job-builder.component.html',
  styleUrls: ['./job-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() jobKey: string;
  @Output() stepChanged = new EventEmitter<number>();
  @Output() jobChanged = new EventEmitter<Job>();

  formCreated = false;
  steps: MondoStep[];
  rootUrl = MondoRoutes.jobBuilder;
  jobData$: Observable<Job>;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    public jobFormService: JobFormService,
    private jobService: JobService
  ) {}

  private setupSteps() {
    this.steps = [
      new MondoStep(
        JobStepIndex.start,
        StartStepComponent,
        'jobStartTitle',
        new Map()
          .set('firstLineText', 'jobStartStep1')
          .set('secondLineText', 'jobStartStep2')
          .set('btnText', 'beginBuildingYourJobPost'),
        [true, true]
      ),
      new MondoStep(
        JobStepIndex.company,
        JobBuilderCompanyComponent,
        'Company',
        new Map().set('form', this.jobFormService.getForm())
      ),
      new MondoStep(
        JobStepIndex.jobInfo,
        JobBuilderInfoComponent,
        'jobInformation',
        new Map().set('form', this.jobFormService.getForm())
      ),
      new MondoStep(
        JobStepIndex.researchQualifications,
        JobBuilderResearchQualificationsComponent,
        'researchQualifications',
        new Map().set('form', this.jobFormService.getForm())
      ),
      new MondoStep(
        JobStepIndex.contactInfomation,
        JobBuilderContactComponent,
        'contactInformation',
        new Map().set('form', this.jobFormService.getForm())
      ),
      new MondoStep(
        JobStepIndex.deadlines,
        JobBuilderVisibilityComponent,
        'PublishJob',
        new Map().set('form', this.jobFormService.getForm()),
        [true, true]
      ),
    ];
  }

  get jobForm(): MondoFormGroup<Job> {
    return this.jobFormService.getForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobKey && changes.jobKey.currentValue) {
      this.setupJob(this.jobKey);
    }
  }

  setupJob(key: string) {
    if (key !== MondoRoutes.none) {
      this.setupSteps();
      this.formCreated = false;
      this.jobData$ = this.jobService.getDraftJob(key).pipe(
        map((jobData) => {
          if (!this.formCreated) {
            this.formCreated = true;
            this.jobFormService.patchJob(jobData);
          }
          return jobData;
        })
      );
    }
  }

  ngOnInit() {
    this.jobFormService
      .getFormChangeStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((jobFormChange) => {
        if (jobFormChange) {
          this.jobChanged.emit(jobFormChange);
        }
      });
  }

  onStepChanged(index: JobStepIndex) {
    this.stepChanged.emit(index);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
