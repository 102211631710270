import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Job } from '../../model/job';
import { JobService } from '../../services/job.service';

@Component({
  selector: 'app-job-preview-dialog',
  templateUrl: './job-preview-dialog.component.html',
  styleUrls: ['./job-preview-dialog.component.scss']
})
export class JobPreviewDialogComponent implements OnInit {

  job$: Observable<Job>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { key: string },
    private jobService: JobService
  ) { }

  ngOnInit() {
    this.job$ = this.jobService.getPublishedJob(this.data.key)
  }

}
