<div [formGroup]="inviteForm">
  <p
    *ngIf="showExpander"
    class="invite-button"
    (click)="showInvite = !showInvite"
  >
    {{ 'inviteToAcademondoText' | preventHardcoding }}
    <mat-icon>{{ showInvite ? 'expand_less' : 'expand_more' }}</mat-icon>
  </p>
  <div *ngIf="showInvite" class="invite-form">
    <mat-form-field appearance="standard">
      <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
      <input
        matInput
        #invitationMail
        title="email"
        formControlName="email"
        type="email"
        required
        autocomplete="off"
      />
      <mat-error *ngIf="email?.errors?.email">{{
        'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'inviteName' | preventHardcoding }}</mat-label>
      <input
        matInput
        #inviteName
        title="inviteName"
        formControlName="inviteName"
        type="text"
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'personalText' | preventHardcoding }}</mat-label>
      <input
        matInput
        #personalText
        title="personalText"
        formControlName="personalText"
        type="text"
        autocomplete="off"
      />
    </mat-form-field>
    <div class="submit-button">
      <button
        mat-raised-button
        color="primary"
        [disabled]="inviteForm.invalid"
        (click)="sendInvite(); inviteName.value = ''; invitationMail.value = ''"
      >
        {{ 'Invite' | preventHardcoding }}
      </button>
    </div>
  </div>
</div>
