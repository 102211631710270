import { SnapshotAction } from '@angular/fire/database';
import * as _ from 'lodash';
import {
  combineLatest as observableCombineLatest,
  Observable,
  throwError,
  of,
} from 'rxjs';
import {
  debounceTime,
  map,
  mergeMap,
  take,
  takeUntil,
  catchError,
} from 'rxjs/operators';
import { AuthService } from '../../../core/auth.service';
import { DataConstants } from '../../../shared/consts/dataConstants';
import { Guid } from 'app/shared/models/guid';
import { IBaseStepItem } from '../model/IBaseStepItem';
import { ItemType } from '../model/ItemType';
import { ItemStatus } from '../model/mondo-status/db-strings/ItemStatus';
import { StatusContext } from '../model/mondo-status/status-context';
import { MondoRoutes } from '../../../app.routing-model';
import { HelperService } from 'app/core/helper.service';
import { environment } from 'environments/environment';
import { DAO } from 'app/shared-services/db-access/dao';

export abstract class DatabaseHandlerService {
  userId: string;
  userPublishedCvId: string;

  constructor(
    public dao: DAO,
    public authService: AuthService,
    public draftPath: string,
    public userItemPath: string,
    public publishedPath: string,
    public itemStatusPath: string,
    private fnsHelper: HelperService,
    public perUserItem = true
  ) {
    this.authService.getCurrentUser$().subscribe((user) => {
      if (user) {
        this.userId = user.uid;
        this.userPublishedCvId = user.publishedCv;
      } else {
        this.userId = undefined;
      }
    });
  }

  checkAndPublishAllJobsNormalFN = this.fnsHelper.createFunctionPromise<
    void,
    string
  >(environment.checkAndPublishAllJobsNormal);

  checkAndPublishAllCVsNormalFN = this.fnsHelper.createFunctionPromise<
    void,
    string
  >(environment.checkAndPublishAllCVsNormal);

  checkAndPublishAllSitesNormalFN = this.fnsHelper.createFunctionPromise<
    void,
    string
  >(environment.checkAndPublishAllSitesNormal);

  dublicateItemAndStatus<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any,
    postfixName = ' (1)'
  ): Promise<string> {
    const dublicated = _.cloneDeep(item);
    dublicated.name += postfixName;
    const newKey = Guid.newGuid();
    dublicated.key = newKey;
    dublicated.status = new StatusContext();
    return this.createItemAnStatus(dublicated, toJson);
  }

  createItem<T extends IBaseStepItem>(
    item: T,
    prefix: string
  ): Promise<string> {
    const itemToCreate = _.cloneDeep(item);
    const newKey = prefix + Guid.newGuid();
    itemToCreate.key = newKey;
    itemToCreate.ownerId = this.userId;
    const now = new Date();
    itemToCreate.created = now;
    return this.dao
      .object(this.getDraftPath(itemToCreate.key))
      .update(itemToCreate)
      .then(() => newKey);
  }

  createItemAnStatus<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any,
    published = false
  ): Promise<string> {
    const itemToCreate = _.cloneDeep(item);
    const newKey = !_.isEmpty(itemToCreate.key)
      ? itemToCreate.key
      : Guid.newGuid();
    itemToCreate.key = newKey;
    itemToCreate.ownerId = this.userId;
    const now = new Date();
    itemToCreate.created = now;
    return this.dao
      .object(this.getUserItemPath(itemToCreate.key))
      .set(true)
      .then(() => this.updateStatus(itemToCreate.key, item.status))
      .then(() =>
        this.dao
          .object(
            published
              ? this.getPublishedPath(itemToCreate.key)
              : this.getDraftPath(itemToCreate.key)
          )
          .update(toJson(itemToCreate))
          .then(() => newKey)
      );
  }

  update<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any
  ): Promise<void> {
    if (!item) {
      return;
    }
    const now = new Date();
    item.lastUpdate = now;
    return this.dao.object(this.getDraftPath(item.key)).update(toJson(item));
  }

  updateStatus(key: string, status: StatusContext): Promise<void> {
    if (status) {
      return this.dao
        .object(this.itemStatusPath + key)
        .set(status.getDbString());
    }
    return Promise.resolve();
  }

  exists(key: string, published = false) {
    if (key === undefined || key === 'none') {
      return Promise.resolve(false);
    }
    const path = published
      ? this.getPublishedPath(key)
      : this.getDraftPath(key);
    return this.dao
      .object(path)
      .snapshotChanges()
      .pipe(
        take(1),
        // catch (error => {
        //   return Observable.throw(new Error('firebase error'))
        // }),
        takeUntil(this.authService.userLogged),
        map((snap: SnapshotAction<IBaseStepItem>) => {
          return !!snap.key;
        })
      )
      .toPromise()
      .catch(() => {
        return false;
      });
  }

  removeDraft(key: string): Promise<void> {
    return this.dao.object(this.getDraftPath(key)).remove();
  }

  removeItemAndStatus(key: string): Promise<void> {
    return this.dao
      .object(this.getDraftPath(key))
      .remove()
      .then(() => {
        this.dao.object(this.itemStatusPath + key).remove();
      })
      .then(() => {
        this.dao.object(this.publishedPath + key).remove();
      })
      .then(() => this.dao.object(this.getUserItemPath(key)).remove());
  }

  async removePublicItem(key: string) {
    await this.dao.object(this.itemStatusPath + key).remove();
    return this.dao.object(this.publishedPath + key).remove();
    // await this.dao.object(this.publishedPath + key).remove();
    // send notification that admin remove the public item.
  }

  getItems<T extends IBaseStepItem>(
    amount = 50,
    fromJson: (item: any, key: string) => T,
    deprecatedPath?: string
  ): Observable<T[]> {
    return this.dao
      .list(deprecatedPath ? deprecatedPath : this.draftPath, (ref) =>
        ref.orderByKey().limitToLast(amount)
      )
      .snapshotChanges()
      .pipe(
        catchError((error) => {
          return throwError(new Error('firebase error'));
        }),
        mergeMap((snaps: any) => {
          if (snaps.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            snaps.map((snap) =>
              this.getItemDeprecated(fromJson, this.getDraftPath(snap.key))
            )
          );
        }),
        takeUntil(this.authService.userLogged)
      );
  }

  getDraftItems<T extends IBaseStepItem>(
    fromJson: (item: any, key: string) => T
  ): Observable<T[]> {
    return this.dao
      .list(this.getUserItemPath(), (ref) => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        mergeMap((jobIds) => {
          if (jobIds.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            jobIds.map((jobIdSnap) => this.getItem<T>(fromJson, jobIdSnap.key))
          ).pipe(
            map((a: any) =>
              a
                .filter((b) => !!b)
                .sort((first, second) => (first.name < second.name ? -1 : 1))
            ),
            takeUntil(this.authService.userLogged)
          );
        }),
        catchError((error) => {
          return throwError(new Error(error));
        }),
        takeUntil(this.authService.userLogged)
      );
  }

  public getItemDeprecated<T extends IBaseStepItem>(
    fromJson: (item: any, key: string) => T,
    path: string,
    key?: string
  ): Observable<T> {
    return this.authService.logged
      ? this.dao
          .object(key ? path + this.userId + '/' + key : path)
          .snapshotChanges()
          .pipe(
            debounceTime(250),
            map((snap: SnapshotAction<T>) => {
              if (snap.key) {
                const item = fromJson(snap.payload.val(), snap.key) as any;
                item.status = status;
                return item as T;
              }
              return null;
            }),
            catchError((error) => {
              return throwError(new Error('firebase error'));
            }),
            takeUntil(this.authService.userLogged)
          )
      : null;
  }

  public getItem<T extends IBaseStepItem>(
    fromJson: (item: any, key: string) => T,
    key: string,
    published = false
  ): Observable<T> {
    return this.dao
      .object(published ? this.getPublishedPath(key) : this.getDraftPath(key))
      .snapshotChanges()
      .pipe(
        debounceTime(250),
        mergeMap((snap: SnapshotAction<T>) => {
          return this.getItemStatus(key).pipe(
            map((status) => {
              if (snap.key && status) {
                const item = fromJson(snap.payload.val(), snap.key);
                item.status = status;
                return item as T;
              }
              return null;
            })
          );
        }),
        catchError((error) => {
          // console.log(error);
          return of(undefined);
        }),
        takeUntil(this.authService.userLogged)
      );
  }

  logRead(key: string): Promise<void> {
    if (this.userPublishedCvId !== key) {
      return this.incrementItem(DataConstants.ITEM_READS, key);
    }
  }

  logViewWithKey(key: string): Promise<void> {
    if (this.userPublishedCvId !== key) {
      return this.incrementItem(DataConstants.ITEM_VIEWS, key);
    }
  }

  logView<T extends IBaseStepItem>(item: T): Promise<void> {
    return this.incrementItemLog(DataConstants.ITEM_VIEWS, item);
  }

  private incrementItemLog(path: string, item: IBaseStepItem): Promise<any> {
    if (item.ownerId !== this.userId && item.status.isPublished()) {
      return this.incrementItem(path, item.key);
    }
    return Promise.resolve();
  }

  private incrementItem(path: string, key: string): Promise<any> {
    return this.dao
      .object(DataConstants.ITEM_LOGS + path + key)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()
      .then((snap) => {
        const newVal = _.isNumber(snap.payload.val())
          ? (snap.payload.val() as number) + 1
          : 1;
        return this.dao
          .object(DataConstants.ITEM_LOGS + path + key)
          .set(newVal)
          .then(() => {
            return this.dao
              .object(
                DataConstants.ITEM_LOGS +
                  DataConstants.ITEM_USER_ACTIONS +
                  key +
                  '/' +
                  path +
                  this.userId
              )
              .set(true);
          });
      });
  }

  public getItemStatus(key: string): Observable<StatusContext> {
    if (key === MondoRoutes.none) {
      return of(new StatusContext());
    }
    return this.dao
      .object(this.itemStatusPath + key)
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          if (snap.key) {
            return StatusContext.fromDbString(snap.payload.val() as string);
          } else {
            const status = new StatusContext(ItemStatus.unpublished);
            return status;
          }
        }),
        takeUntil(this.authService.userLogged)
      );
  }

  public publish<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (item) {
        const now = new Date();
        item.madePublic = now;
        item.status.setInSync();
        await this.createItemAnStatus(item, toJson, true);
        return this.updateStatus(item.key, item.status).then(async () => {
          if (item.type === ItemType.Job) {
            await this.checkforJobsToPublish();
          }
          if (item.type === ItemType.CV) {
            await this.checkforCVsToPublish();
          }
          if (item.type === ItemType.Site) {
            await this.checkforSitesToPublish();
          }
          return this.authService.notEnoughPermission('doneEmoji');
        });
      } else {
        return this.authService.notEnoughPermission('defaultError');
      }
    });
  }

  private checkforJobsToPublish() {
    return this.checkAndPublishAllJobsNormalFN(); // if immediate publish is needed!
  }

  private checkforCVsToPublish() {
    return this.checkAndPublishAllCVsNormalFN(); // if immediate publish is needed!
  }

  private checkforSitesToPublish() {
    return this.checkAndPublishAllSitesNormalFN(); // if immediate publish is needed!
  }

  public unpublish<T extends IBaseStepItem>(item: T): Promise<void> {
    item.status.unpublish();
    return this.updateStatus(item.key, item.status);
  }

  private getDraftPath(key = '') {
    return this.draftPath + '/' + key;
  }

  private getPublishedPath(key = '') {
    return this.publishedPath + '/' + key;
  }

  private getUserItemPath(key?) {
    if (key) {
      return this.perUserItem
        ? this.userItemPath + this.userId + '/' + key
        : this.userItemPath + 'common' + '/' + key;
    } else {
      return this.perUserItem
        ? this.userItemPath + this.userId
        : this.userItemPath + 'common';
    }
  }

  public getExistItems(): Promise<number> {
    if (!this.authService.logged) {
      return new Promise((resolve) => resolve(0));
    }
    return new Promise((resolve) => {
      this.dao
        .list$(this.getUserItemPath())
        .pipe(take(1), takeUntil(this.authService.userLogged))
        .subscribe((res) => resolve(res.length));
    });
  }

  public ownItem(key): Promise<any> {
    return this.dao
      .object$(this.getUserItemPath(key))
      .pipe(take(1), takeUntil(this.authService.userLogged))
      .toPromise();
  }

  public getCurrentApplicants(currentApplicantsPath: string) {
    return this.dao.list$(currentApplicantsPath);
  }

  public getPublishedItems(batch, lastKey?, sortingValue = '', limiter = null) {
    if (!sortingValue) {
      return this.dao.list$(this.publishedPath, (ref) =>
        ref
          .orderByKey()
          .limitToFirst(batch)
          .startAt(lastKey ? lastKey : '')
      );
    }
    if (sortingValue && limiter) {
      return this.dao.list$(this.publishedPath, (ref) =>
        ref.orderByChild(sortingValue).equalTo(limiter)
      );
    }
    if (sortingValue) {
      return this.dao.list$(this.publishedPath, (ref) =>
        ref
          .orderByChild(sortingValue)
          .limitToFirst(batch)
          .startAt(lastKey ? lastKey : '')
      );
    }
  }
}
