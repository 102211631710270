<ng-container *ngIf="editMode$ | async"></ng-container>
<ng-container *ngIf="site$ | async as site">
  <div
    [class.site-viewer-action-bar]="isOwner && isShowActionBar && !editMode"
    [class.site-viewer]="editMode"
    class="full-height"
  >
    <ng-container *ngIf="isOwner && isShowActionBar">
      <app-new-action-bar
        [userRole]="isOwner"
        [isPublished]="isPublished"
        [itemName]="site?.siteInfo?.nameSite"
        [actionBarType]="'site'"
        [itemId]="siteId"
        [editMode]="editMode"
        (edit)="setEditMode($event)"
        (delete)="removeSite(site)"
      >
      </app-new-action-bar>
    </ng-container>
    <perfect-scrollbar>
      <!-- <app-site-viewer-cover
        [coverUrl]="
          showIntroViewer && editMode
            ? siteService.introSite.siteInfo.coverUrl
            : site.siteInfo.coverUrl
        "
      ></app-site-viewer-cover> -->
      <app-site-viewer-header
        [site]="showIntroViewer && editMode ? siteService.introSite : site"
      ></app-site-viewer-header>
      <app-site-viewer-content
        [siteInfo]="
          showIntroViewer && editMode
            ? siteService.introSite.siteInfo
            : site.siteInfo
        "
      ></app-site-viewer-content>
      <app-site-viewer-technique
        [site]="showIntroViewer && editMode ? siteService.introSite : site"
      ></app-site-viewer-technique>
      <app-site-viewer-footer
        [site]="
          showIntroViewer && editMode
            ? siteService.introSite.siteInfo
            : site.siteInfo
        "
      ></app-site-viewer-footer>
      <div style="text-align: center; padding-bottom: 30px">
        <button
          *ngIf="canCreateJob(site)"
          mat-raised-button
          color="primary"
          (click)="createJob(site)"
        >
          {{ 'Create' | preventHardcoding }} {{ 'job' | preventHardcoding }}
        </button>
        <app-notes [uid]="site.key"></app-notes>
      </div>
      <app-related-jobs
        [showJobs]="true"
        [resultId]="siteId"
      ></app-related-jobs>
    </perfect-scrollbar>
  </div>
</ng-container>
