import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CV } from 'app/shared/models/cv/cv';
import {
  DateOptions,
  PickerMode,
} from 'app/shared/components/date-picker/date-picker.component';
import {
  MondoFormGroup,
  MondoAbstractControl,
} from 'app/core/mondo-form-builder';
import { MondoLocation } from 'app/stepper/job/model/mondoLocation';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import {
  Experience,
  FieldOfInterest,
  Category0,
  Category1,
  Technique,
} from 'app/shared/models';
import { StaticItemsService } from 'app/core/static-items.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CvFormService } from 'app/stepper/cv/services';
import { DirectMatchService } from 'app/core/direct-match.service';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-direct-match-builder',
  templateUrl: './direct-match-builder.component.html',
  styleUrls: ['./direct-match-builder.component.scss'],
})
export class DirectMatchBuilderComponent implements OnInit, OnDestroy {
  @Input() showCandidateInput: false;
  @Input() directMatch: CV;
  @Output() dmReady: EventEmitter<CV | string> = new EventEmitter<
    CV | string
  >();

  destroy$: Subject<boolean> = new Subject();

  form: MondoFormGroup<CV>;
  showUploader = false;
  yearDateOffset = 365 * 24 * 60 * 60 * 1000;
  now = new Date();
  max = new Date(this.now.getTime() - this.yearDateOffset * 15);
  startDate = new Date(this.now.getTime() - this.yearDateOffset * 30);

  // formChange$: Observable<any>;

  readonly dobPickerOptions: DateOptions = {
    startDate: this.startDate,
    pickerMode: PickerMode.day,
    disabled: false,
    placeholder: 'dateOfBirth',
    timePickerLabel: '',
    optionalHint: '',
    startView: 'multi-year',
    showClearBtn: true,
    maxDate: this.max,
  };

  private patchStreamInterest = new BehaviorSubject<FieldOfInterest[]>([]);
  private patchStreamCategory0 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory1 = new BehaviorSubject<Category1[]>([]);
  private patchStreamCategory2 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory3 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory4 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory5 = new BehaviorSubject<Category0[]>([]);
  private patchStreamTech = new BehaviorSubject<Technique[]>([]);

  constructor(
    private cvFormService: CvFormService,
    private staticItemsService: StaticItemsService,
    private dmService: DirectMatchService
  ) {}

  ngOnInit() {
    this.form = this.cvFormService.getForm();
    // this.formChange$ = this.cvFormService.getFormChangeStream()
    // .pipe(
    //   tap((cvFormChange => {
    //     if (cvFormChange) {
    //       cvFormChange.name = this.directMatch.name;
    //       cvFormChange.status = this.directMatch.status;
    //       this.dmService.updateDraftCv(cvFormChange);
    //       this.checkMinReq(cvFormChange);
    //     }
    //   }))
    // );
    this.cvFormService
      .getFormChangeStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((cvFormChange) => {
        if (cvFormChange) {
          cvFormChange.name = this.directMatch.name;
          cvFormChange.status = this.directMatch.status;
          this.dmService.updateDraftCv(cvFormChange);
          this.checkMinReq(cvFormChange);
        }
      });
    this.cvFormService.patchCV(this.directMatch);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  checkMinReq(cv: CV) {
    const reqText = this.dmService.minRequirementForPublish(cv);
    if (reqText === '') {
      this.dmReady.emit(cv);
    } else {
      this.dmReady.emit(reqText);
    }
  }

  // deletePictureUrl() {
  //   this.pictureUrl.setValue('');
  // }

  // onProfilePicUpload(url: string) {
  //   this.showUploader = false;
  //   this.pictureUrl.setValue(url);
  // }

  // toggleUploader() {
  //   this.showUploader = !this.showUploader;
  // }

  get personalDetails(): MondoFormGroup<PersonalDetails> {
    return this.form.getSafeGroup<PersonalDetails>((x) => x.personalDetails);
  }

  get email(): MondoFormGroup<string> {
    return this.form.getSafeGroup<string>(
      (x) => x.personalDetails.contactInfo.email
    );
  }

  get experience(): MondoFormGroup<Experience> {
    return this.form.getSafeGroup<Experience>((x) => x.experience);
  }

  get pictureUrl(): MondoAbstractControl<string> {
    return this.personalDetails.getSafeControl((x) => x.pictureUrl);
  }

  get location(): MondoFormGroup<MondoLocation> {
    return this.personalDetails.getSafeGroup((x) => x.address);
  }

  get dateOfBirth(): MondoFormGroup<Date> {
    return this.personalDetails.getSafeGroup((x) => x.dob);
  }

  get patchInterest$() {
    return this.patchStreamInterest.asObservable();
  }

  get patchCategory0$() {
    return this.patchStreamCategory0.asObservable();
  }

  get patchCategory1$() {
    return this.patchStreamCategory1.asObservable();
  }

  get patchCategory2$() {
    return this.patchStreamCategory2.asObservable();
  }

  get patchCategory3$() {
    return this.patchStreamCategory3.asObservable();
  }

  get patchCategory4$() {
    return this.patchStreamCategory4.asObservable();
  }

  get patchCategory5$() {
    return this.patchStreamCategory5.asObservable();
  }

  get patchTech$() {
    return this.patchStreamTech.asObservable();
  }

  get fieldsOfInterests$() {
    return this.staticItemsService.getFieldOfInterest$();
  }

  get labTechniques$() {
    return this.staticItemsService.getTechniques$();
  }

  get getCategories0$() {
    return this.staticItemsService.getCategories0$();
  }

  get getCategories1$() {
    return this.staticItemsService.getCategories1$();
  }

  get getCategories2$() {
    return this.staticItemsService.getCategories2$();
  }

  get getCategories3$() {
    return this.staticItemsService.getCategories3$();
  }

  get getCategories4$() {
    return this.staticItemsService.getCategories4$();
  }

  get getCategories5$() {
    return this.staticItemsService.getCategories5$();
  }

  get text() {
    return this.form.getSafeControl((x) => x.intro.text);
  }
}
