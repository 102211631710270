import { Injectable } from '@angular/core';
import { Job } from '../../job/model/job';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { DatabaseHandlerService } from 'app/stepper/shared/services/database-handler.service';
import { AuthService } from 'app/core/auth.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { IndustryUser } from 'app/shared/models/user/industryUser';
import { Company } from '../model/company';
import { Observable, of } from 'rxjs';
import { hardcodedValues } from 'hardcodedValues';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { StatusContext } from 'app/stepper/shared/model/mondo-status/status-context';
import { TimePeriod } from '../model/timePeriod';
import { JobInfo } from '../model/jobInfo';
import { ResearchQualifications } from '../model/researchQualifications';
import { Languages } from 'app/shared/models/languages/languages';
import { Contact } from '../model/contact';
import { JobLinks } from '../model/JobLinks';
import { ResearchGroup } from '../model/researchGroup';
import { HelperService } from 'app/core/helper.service';
import { canSeeElement, isCVRValid } from '@helpers';
import { Site } from 'app/shared/models';
import { RoutingService } from 'app/core/routing.service';
import { RoutingModel } from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';
import { DAO } from 'app/shared-services/db-access/dao';
import { StorageService } from 'app/core/storage.service';

@Injectable()
export class JobService extends DatabaseHandlerService {
  private firstLoad = false;
  constructor(
    public dao: DAO,
    authService: AuthService,
    fnsHelper: HelperService,
    private routingService: RoutingService,
    private storageService: StorageService
  ) {
    super(
      dao,
      authService,
      DataConstants.DRAFT_JOBS,
      DataConstants.USER_JOBS,
      DataConstants.PUBLISHED_JOBS,
      DataConstants.JOB_STATUS,
      fnsHelper
    );
  }

  updateDraftJob(job: Job): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    return this.update(job, Job.toJson);
  }

  jobExists(id: string): Promise<boolean> {
    return this.exists(id);
  }

  getPublishedJob(jobKey: string): Observable<Job> {
    return this.getItem<Job>(Job.fromJson, jobKey, true);
  }

  getNumberPublishedJobs(): Observable<number> {
    return this.getDraftJobs().pipe(
      map((jobs) => {
        const published = jobs.filter((job) => job.status.isPublished());
        return published.length;
      })
    );
  }

  getAllowedNumberOfJobs(): Observable<number> {
    return this.dao
      .object(DataConstants.ACTIVE_JOBS_ALLOWED + this.userId)
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.key && snap.payload.val() ? +snap.payload.val() : 0
        )
      );
  }

  canPublishJob(): Observable<boolean> {
    return this.getAllowedNumberOfJobs().pipe(
      switchMap((allowedNumberOfJobs) => {
        return this.getNumberPublishedJobs().pipe(
          map(
            (numberOfPublishedJobs) =>
              numberOfPublishedJobs < allowedNumberOfJobs
          )
        );
      })
    );
  }

  getDraftJob(jobKey: string): Observable<Job> {
    return this.getItem<Job>(Job.fromJson, jobKey);
  }

  getDraftJobs(): Observable<Job[]> {
    return this.getDraftItems(Job.fromJson);
  }

  private loadJob(key: string) {
    this.routingService.navigateToRoute(RoutingModel.jobBuilder.route, [
      key,
      0,
    ]);
  }

  getRelatedJobIds$(resultId: string) {
    return resultId
      ? this.dao.object$(DataConstants.JOB_LIST + resultId).pipe(
          map((ids) => (ids ? Object.keys(ids).map((id) => id) : [])),
          filter((id) => id.length !== 0)
        )
      : of([]);
  }

  private updateJobIdInListOfJobs(job: Job, active = true) {
    const parentId = job.company.uid;
    const jobId = job.key;
    if (parentId && jobId) {
      return this.dao
        .object(DataConstants.JOB_LIST + parentId + '/' + jobId)
        .set(active ? active : {});
    }
  }

  unpublishJob(job: Job) {
    this.updateJobIdInListOfJobs(job, false);
    return this.unpublish(job);
  }

  createJobFromSite(site: Site) {
    if (
      this.authService.canCreateJobFromSite ||
      this.authService.getCurrentUser().uid === site.ownerId
    ) {
      const { nameSite, addressSite, pictureUrl, email, phone, coverUrl, cvr } =
        site.siteInfo;
      const {
        interests,
        category0,
        category1,
        category2,
        category3,
        category4,
        category5,
        techniques,
      } = site.experience;
      const companyDetails = new Company(
        site.key,
        nameSite,
        cvr,
        email,
        2,
        addressSite,
        pictureUrl,
        coverUrl
      );
      const researchQualificationDetails = new ResearchQualifications(
        interests,
        category0,
        category1,
        category2,
        category3,
        category4,
        category5,
        techniques
      );
      const contactInfo = new Contact(
        '',
        new JobLinks(),
        new ResearchGroup(),
        email,
        phone
      );

      this.createJob(
        companyDetails,
        researchQualificationDetails,
        contactInfo
      ).then((jobkey) => {
        this.loadJob(jobkey);
      });
    }
  }

  private getFormattedDate(today?: Date): string {
    const date = today ? today : new Date();
    switch (hardcodedValues.dateFormat) {
      case 'en-US':
        return `${date.getMonth() + 1}/${date.getDate()}-${date.getFullYear()}`;
      case 'da-DK':
        return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
      default:
        return `${date.getMonth() + 1}/${date.getDate()}-${date.getFullYear()}`;
    }
  }

  createJob(
    initCompanyDetails?: Company,
    initResearchQualifications?: ResearchQualifications,
    initContact?: Contact
  ): Promise<string> {
    const jobName = `${
      initCompanyDetails
        ? initCompanyDetails.name
        : hardcodedValues.newJobPosition
    } ${this.getFormattedDate()}`;
    const job = new Job(
      new StatusContext(),
      jobName,
      '',
      '',
      null,
      null,
      null,
      new TimePeriod(new Date(), null, true),
      new JobInfo(),
      initCompanyDetails ? initCompanyDetails : this.initialCompanyDetails,
      initResearchQualifications
        ? initResearchQualifications
        : new ResearchQualifications(),
      new Languages(),
      new TimePeriod(),
      initContact ? initContact : this.initContact,
      false
    );
    return this.createItemAnStatus(job, Job.toJson);
  }

  get initContact(): Contact {
    if (this.authService.isScientist) {
      const scientist = this.authService.getCurrentUser() as ScientistUser;
      if (scientist.personalDetails && scientist.personalDetails.contactInfo) {
        const { phone, email } = scientist.personalDetails.contactInfo;
        return new Contact(
          '',
          new JobLinks(),
          new ResearchGroup(),
          email,
          phone
        );
      }
      // } else if (this.authService.isCompany) {
      //   const industry = this.authService.getCurrentUser() as IndustryUser;
      //   if (industry.)
      //   return new Contact('', new JobLinks(), new ResearchGroup(), '', '');
    } else {
      return new Contact();
    }
  }

  get initialCompanyDetails(): Company {
    if (this.authService.isScientist) {
      const scientist = this.authService.getCurrentUser() as ScientistUser;
      if (scientist.personalDetails) {
        const { companyName, address, pictureUrl, coverUrl } =
          scientist.personalDetails;
        const { email } = scientist.personalDetails.contactInfo;
        return new Company(
          scientist.publishedCv,
          companyName,
          '',
          email,
          2,
          address,
          pictureUrl,
          coverUrl
        );
      }
    } else if (this.authService.isCompany) {
      const industry = this.authService.getCurrentUser() as IndustryUser;
      return industry.company ? industry.company : new Company();
    } else {
      return new Company();
    }
  }

  removeJob(id: string): Promise<void> {
    const jobProfilePath = `job/${id}/${id}`;
    const imgLinkPath = `job/${id}/imageLink/imageLink`;
    this.storageService.deleteFile(jobProfilePath).toPromise();
    this.storageService.deleteFile(imgLinkPath).toPromise();
    return this.removeItemAndStatus(id);
  }

  dublicateJob(job: Job): void {
    this.dublicateItemAndStatus(job, Job.toJson);
  }

  public getNumberOfCurrentApplicants(jobId: string) {
    return this.getCurrentApplicants(DataConstants.JOB_APPLICANTS + jobId);
  }

  public minRequirementForPublish(job: Job): string {
    let text = '';
    if (!this.hasCompanyName(job)) {
      text += `⛔ ${hardcodedValues.YouHaveToFillOut} ${hardcodedValues.companyName}\n`;
    }
    if (!this.hasTitle(job)) {
      text += `⛔ ${hardcodedValues.titleOfThePosition} ${hardcodedValues.required}\n`;
    }
    if (!this.hasType(job)) {
      text += `⛔ ${hardcodedValues.JobType} ${hardcodedValues.required}\n`;
    }
    if (!this.hasFoi(job)) {
      text += `⛔ ${hardcodedValues.youHaveToAddLeast1Foi}\n`;
    }
    if (!this.hasCVR(job) && hardcodedValues.showApplicationProcessFunctions) {
      text += `⛔ ${hardcodedValues.cvr} ${hardcodedValues.required}\n`;
    }
    if (
      !isCVRValid(job.company.cvr) &&
      hardcodedValues.showApplicationProcessFunctions
    ) {
      text += `⛔ ${hardcodedValues.cvr} ${hardcodedValues.invalid}\n`;
    }
    if (
      !this.hasEmail(job) &&
      hardcodedValues.showApplicationProcessFunctions
    ) {
      text += `⛔ ${hardcodedValues.applicationEmail} ${hardcodedValues.required}\n`;
    }
    // if (!this.hasApplicationLink(job)) {
    //   text += `⛔ ${hardcodedValues.URLToTheOfficialJobApplicationForm} ${hardcodedValues.required}\n`;
    // }
    return text;
  }

  private hasCVR(job: Job): boolean {
    return job.company.cvr ? job.company.cvr.length === 8 : false;
  }

  private hasTitle(job: Job): boolean {
    return !!job.jobInfo.position.trim();
  }

  private hasEmail(job: Job): boolean {
    return !!job.company.email.trim();
  }

  private hasApplicationLink(job: Job): boolean {
    return !!job.contact.jobLinks.urlJobApplication;
  }

  private hasCompanyName(job: Job): boolean {
    return !!job.company.name.trim();
  }

  private hasType(job: Job): boolean {
    return job.jobInfo.jobType !== null;
  }

  private hasFoi(job: Job): boolean {
    return !!job.researchQualifications.wantedFieldOfInterest.length;
  }

  private hasTech(job: Job): boolean {
    return !!job.researchQualifications.wantedTechniques.length;
  }

  canSeeCat1() {
    const validAccountTypes = [
      AccountType.scientist,
      AccountType.labTech,
      AccountType.medLabTech,
      AccountType.ssoAuthedUser,
      AccountType.industry,
      AccountType.university,
    ];

    if (
      this.authService.getCurrentUser() &&
      this.authService.getCurrentUser().type
    ) {
      return canSeeElement(
        validAccountTypes,
        'Category1',
        this.authService.getCurrentUser().type
      );
    } else {
      return false;
    }
  }
}
