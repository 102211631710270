export enum CvStepIndex {
  start,
  basisStep,
  personalDetails,
  introText,
  workExperience,
  education,
  researchStay,
  workshops,
  teaching,
  fois,
  techs,
  categories0,
  categories2,
  categories1,
  categories3,
  languages,
  additionalInfo,
  publications,
  network,
  publish,
  hiddenStep,
}

export enum JobStepIndex {
  start,
  jobInfo,
  company,
  researchGroup,
  researchQualifications,
  languages,
  employmentPeriod,
  contactInfomation,
  deadlines,
  publish,
}

export enum NetworkStepIndex {
  start,
  general,
  type,
}

export enum CommunityStepIndex {
  start,
  general,
  privacy,
  media,
  type,
  publish,
}

export enum IdeaStepIndex {
  start,
  general,
  privacy,
  media,
  type,
  publish,
}

export enum EventStepIndex {
  start,
  general,
  privacy,
  media,
  type,
}

export enum PostStepIndex {
  general,
  media,
  attachments,
  topics,
  view,
}

export enum SiteStepIndex {
  start,
  main,
  publish,
  hiddenStep,
}

export enum BillboardStepIndex {
  start,
  relations,
  request,
  topic,
}
