import { Component, Input } from '@angular/core';
import { Job } from 'app/stepper/job/model/job';
import { CV } from 'app/shared/models/cv/cv';
import { DirectMatchService } from 'app/core/direct-match.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-direct-match',
  templateUrl: './direct-match.component.html',
  styleUrls: ['./direct-match.component.scss'],
})
export class DirectMatchComponent {
  @Input() job: Job;
  @Input() directMatch: CV;

  showCandidateInput = false;
  showEndDirectMatch = false;

  finalCV: CV;
  reqText: string;

  constructor(
    private dmService: DirectMatchService,
    private dialogRef: MatDialogRef<DirectMatchComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  hideBuilder() {
    this.showCandidateInput = false;
  }

  showBuilder() {
    this.showCandidateInput = true;
  }

  jobMatchDetailsReady(event: CV | string) {
    if (event && event['key']) {
      this.finalCV = event as CV;
      this.showEndDirectMatch = true;
    } else {
      this.reqText = event as string;
      this.showEndDirectMatch = false;
    }
  }

  endDirectMatch(directMatchAdded: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'RegisterDirectMatch';
    dialogRef.componentInstance.text = 'areYouSureYouWantToAddX';
    dialogRef.componentInstance.textX = 'directMatch';
    dialogRef.componentInstance.yesText = 'yes';
    dialogRef.componentInstance.noText = 'no';
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((agree) => {
        if (agree) {
          this.dmService
            .createDirectMatch(this.finalCV, this.job)
            .then(() => {
              this.dmService
                .deleteDraftCV(this.finalCV.key)
                .then(() => {
                  this.snackBar.open(directMatchAdded, null, {
                    duration: 3000,
                    panelClass: ['snackbar-position-center'],
                  });
                  this.dialogRef.close();
                })
                .catch((err) => {
                  this.dialogRef.close();
                });
            })
            .catch((err) => {});
        }
      });
  }
}
