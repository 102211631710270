import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { StorageService } from '../../../core/storage.service';
import { Observable } from 'rxjs';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Input() public filePath!: string;
  @Input() public ratio: string | undefined;
  @Input() public btnText: string | undefined;
  @Input() public icon: string | undefined;
  @Input() public inlineStyling = false;
  @Input() public tooltip: string | undefined;
  @Input() public maintainAspectRatio: boolean | undefined;
  @Input() public containWithinAspectRatio = false;
  @Input() public currentPictureUrl: string | undefined;
  @Input() public defaultPicture: string | undefined;
  @Input() public resizeToWidth: number | undefined;
  @Input() public resizeToHeight: number | undefined;
  @Input() public compressQuality: number | undefined;
  @Output() public complete: EventEmitter<string> = new EventEmitter<string>();

  public percentage: number | undefined;
  public snapshot: Observable<any> | undefined;
  public downloadURL: Observable<string> | undefined;
  public isHovering: boolean | undefined;
  constructor(
    private storageService: StorageService,
    private dialog: MatDialog
  ) {}

  public fileChangeEvent(event: any): void {
    if (event && !_.isEmpty(event.target.value)) {
      const dialogRef = this.dialog.open(ImageCropperComponent, {
        width: '450px',
        maxHeight: '90vh',
      });

      if (this.ratio) {
        dialogRef.componentInstance.aspectRatio = this.ratio;
        dialogRef.componentInstance.maintainAspectRatio = true;
        dialogRef.componentInstance.containWithinAspectRatio = true;
      }

      if (this.maintainAspectRatio !== undefined) {
        dialogRef.componentInstance.maintainAspectRatio =
          this.maintainAspectRatio;
      }

      if (this.containWithinAspectRatio !== undefined) {
        dialogRef.componentInstance.containWithinAspectRatio =
          this.containWithinAspectRatio;
      }

      dialogRef.componentInstance.image = event;

      if (this.resizeToWidth !== undefined) {
        dialogRef.componentInstance.resizeToWidth = this.resizeToWidth;
      }

      if (this.resizeToHeight !== undefined) {
        dialogRef.componentInstance.resizeToHeight = this.resizeToHeight;
      }

      dialogRef.componentInstance.compressQuality =
        this.compressQuality &&
        this.compressQuality < 100 &&
        this.compressQuality > 35
          ? this.compressQuality
          : 50;

      dialogRef.afterClosed().subscribe((image: Blob) => {
        if (image) {
          const name = this.filePath.split('/').pop();
          this.storageService
            .uploadImage(
              image,
              this.filePath,
              name
              // percent => (this.percentage = percent)
            )
            .then((url) => this.complete.emit(url));
        }
      });
    }
  }

  public toggleHover(event: boolean) {
    this.isHovering = event;
  }

  public removeImg() {
    const deleteUrl = this.currentPictureUrl;
    this.complete.emit('');
    if (deleteUrl) {
      const name = this.filePath.split('/').pop();
      this.storageService.deleteFile(this.filePath, false, name);
    }
  }
}
