import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ViewMode } from 'app/stepper/shared/model/ViewMode';
import { ActivatedRoute } from '@angular/router';
import { SiteStepIndex } from 'app/stepper/shared/model/stepIndex';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth.service';
import { MondoRoutes } from 'app/app.routing-model';
import { Site } from 'app/shared/models/site';
import { SiteService } from 'app/core/site.service';
import { JobService } from 'app/stepper/job/services/job.service';

@Component({
  selector: 'app-site-viewer',
  templateUrl: './site-viewer.component.html',
  styleUrls: ['./site-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteViewerComponent implements OnInit {
  @Output() editorMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() siteId: string;
  @Input() isShowActionBar = true;
  @Input() viewMode: ViewMode = ViewMode.DRAFT;
  showIntroViewer: boolean;
  editMode: boolean;
  editMode$: Observable<boolean>;
  site$: Observable<Site>;
  public isOwner = null;

  constructor(
    private route: ActivatedRoute,
    public siteService: SiteService,
    private authService: AuthService,
    private jobJervice: JobService
  ) {}

  ngOnInit() {
    this.site$ = this.route.paramMap.pipe(
      switchMap((paramMap) => {
        const newSiteId = paramMap.get('siteId');
        this.siteId = newSiteId ? newSiteId : this.siteId;
        this.getIsOwner(this.siteId);
        this.showIntroViewer =
          parseInt(paramMap.get('step'), 10) === SiteStepIndex.start;
        return this.getSite$(this.viewMode, this.siteId);
      })
    );
    this.editMode$ = this.siteService.editMode$.pipe(
      tap((edit) => (this.editMode = edit))
    );
  }

  private getSite$(viewMode: ViewMode, siteId: string) {
    return viewMode === ViewMode.DRAFT
      ? this.siteService.getDraftSite(siteId)
      : this.siteService.getPublishedSite(siteId);
  }

  private async getIsOwner(key: string): Promise<boolean> {
    if (key !== MondoRoutes.none) {
      return this.siteService
        .isOwnSite(key)
        .then((sites) => (this.isOwner = sites))
        .catch(() => {
          return false;
        });
    }
  }

  get isPublished(): boolean {
    return this.viewMode === ViewMode.PUBLISHED;
  }

  setEditMode(value) {
    const edit = value === undefined ? true : value;
    this.siteService.setEditorMode(edit);
    this.siteService.editMode = edit;
  }

  createJob(site: Site) {
    this.jobJervice.createJobFromSite(site);
  }
  canCreateJob(site: Site): boolean {
    const isOwner =
      site && this.authService.getCurrentUser()
        ? site.ownerId === this.authService.getCurrentUser().uid
        : false;
    const isSitePublished = site.status.isPublished();
    // return this.authService.canCreateJobFromSite && isSitePublished;
    return this.authService.canCreateJobFromSite && isOwner && isSitePublished;
  }

  removeSite(site: Site) {
    if (site.key) {
      this.siteService
        .removeSite(site.key, site.siteInfo.nameSite)
        .then((res) => {
          if (res) {
            this.setEditMode(false);
          }
        });
    }
  }
}
