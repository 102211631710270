<div class="action-bar-outer">
  <div class="action-bar-title action-bar-inner" *ngIf="title">
    <div class="title-wrapper">
      <h4 fxHide fxShow.gt-md>{{ title | preventHardcoding }}</h4>
    </div>
  </div>
  <div class="action-bar-inner" *ngIf="item">
    <button
      [matMenuTriggerFor]="menu"
      class="small"
      fxHide="false"
      fxHide.sm
      fxHide.xs
      fxHide.md
      mat-button
      *ngIf="!isSearchItem()"
    >
      <mat-icon>menu</mat-icon>
      <span>{{ getYouListLabel() | preventHardcoding }}</span>
    </button>
    <button
      [matMenuTriggerFor]="menu"
      class="small small-screen"
      fxHide="true"
      fxShow.sm
      fxShow.xs
      fxShow.md
      mat-button
      matTooltip="{{ getYouListLabel() | preventHardcoding }}"
      *ngIf="!isSearchItem()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [class]="'menu-items'">
      <app-item-selector
        [items]="allItems"
        [rootUrl]="rootUrl"
        [currentKey]="item.key"
        (publishItem)="publish($event)"
        (addItem)="add()"
        (deleteItem)="delete($event)"
        (dublicateItem)="dublicate($event)"
        (editItem)="edit($event)"
        (showItem)="show($event)"
        [published]="published"
      ></app-item-selector>
    </mat-menu>
    <button
      class="small small-screen"
      *ngFor="let actionItem of actionItems; trackBy: trackByFn"
      mat-button
      (click)="actionItem.onClick(item, false)"
      matTooltip="{{ actionItem.title | preventHardcoding }}"
    >
      <mat-icon *ngIf="actionItem.icon">{{ actionItem.icon }}</mat-icon>
      <span
        fxHide="false"
        fxHide.xs
        *ngIf="actionItem.title && actionItem.showTitle"
        >{{ actionItem.title | preventHardcoding }}</span
      >
    </button>
    <div class="title-wrapper">
      <div fxHide fxShow.gt-xs *ngIf="item">
        {{ item.name }}
        <small
          *ngIf="item.type === itemTypes.Job || item.type === itemTypes.CV"
        >
          ({{ item.status.getDescription() | preventHardcoding }})</small
        >
      </div>
    </div>
    <mat-button-toggle-group
      appearance="legacy"
      class="border-left"
      #group="matButtonToggleGroup"
      [value]="this.selectedView"
      *ngIf="!isSearchItem()"
    >
      <mat-button-toggle
        fxHide="false"
        fxHide.sm
        fxHide.xs
        (click)="toggleView(true, true)"
        value="sideBySide"
        matTooltip="{{ 'sideBySide' | preventHardcoding }}"
      >
        <mat-icon>vertical_split</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="toggleView(true, false)"
        value="builder"
        matTooltip="{{ 'builderOnly' | preventHardcoding }}"
      >
        <mat-icon>view_headline</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="toggleView(false, true)"
        value="viewer"
        matTooltip="{{ 'viewerOnly' | preventHardcoding }}"
      >
        <mat-icon>insert_drive_file</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <button
      [matMenuTriggerFor]="sortingMenu"
      class="small border-left"
      mat-button
      matTooltip="{{ 'sorting' | preventHardcoding }}"
      *ngIf="sortingModes.length !== 0"
    >
      <mat-icon>sort</mat-icon>
    </button>
    <mat-menu #sortingMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let sortingMode of sortingModes; trackBy: trackByFn"
        (click)="selectSortingMode(sortingMode.mode)"
      >
        <mat-icon *ngIf="sortingModeSelected === sortingMode.mode"
          >check</mat-icon
        >
        {{ sortingMode.displayText }}
      </button>
    </mat-menu>
    <button
      class="small border-left"
      mat-button
      (click)="showHelp()"
      matTooltip="{{ 'help' | preventHardcoding }}"
      *ngIf="!isSearchItem()"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
  </div>
</div>
