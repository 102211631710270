import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { environment } from 'environments/environment';
Parse.initialize(environment.firebase.projectId);
(Parse as any).serverURL = environment.parse.serverURL;

@Injectable({
  providedIn: 'root'
})
export class ParseService {
  constructor() { }

  public async list<T>(className: string): Promise<T[]> {
    if (!className) {
      return Promise.reject('no className supplied');
    }
    return new Parse.Query(Parse.Object.extend(className))
    .find()
    .then(results => results.map(result => result.attributes as T))
    .catch(error => Promise.reject(error));
  }
  public async getObject<T>(id: string, className: string): Promise<T> {
    if (!className) {
      return Promise.reject('no className supplied');
    }
    return new Parse.Query(Parse.Object.extend(className))
    .get(id)
    .then(result => result.attributes as T)
    .catch(error => Promise.reject(error));
  }

  public async saveObject(obj: Parse.Object): Promise<string> {
    if (!obj) {
      return Promise.reject('no object supplied');
    }
    return obj.save()
    .then(result => {
      // Execute any logic that should take place after the object is saved.
      // alert('New object created with objectId: ' + result.id);
      return result.id;
    })
    .catch(error => Promise.reject(error));
  }

  public async saveObjectFromNameAndProps<T>(className: string, classObj: T): Promise<string> {
    if (!className || !classObj) {
      return Promise.reject('no object supplied');
    }
    const obj = this.createParseObject(className, classObj);
    return obj.save()
    .then(result => {
      // Execute any logic that should take place after the object is saved.
      // alert('New object created with objectId: ' + result.id);
      return result.id;
    })
    .catch(error => Promise.reject(error));
  }

  createParseObject(className, classObj) {
    return Parse.Object.extend(className);
    const parseClass = Parse.Object.extend(className);
    // for each in class obj parseClass.set()
    

  }

}
