import { acaConfig } from './aca-config';

const names = {
  auser: 'en profil',
  user: 'profil',
  User: 'Profil',
  users: 'profiler',
  Users: 'Profiler',
  thesite: 'virksomheden',
  asite: 'en virksomhed',
  newsite: 'ny virksomhed',
  Newsite: 'Ny virksomhed',
  companyName: `virksomhedsnavn`,
  CompanyName: `Virksomhedsnavn`,
  site: 'virksomhed',
  Site: 'Virksomhed',
  sites: 'virksomheder',
  Sites: 'Virksomheder',
};

export const acaNamesSALES = {
  category0: 'kat0',
  Category0: '', // leave blank if it should not be shown
  categories0: 'kats0',
  Categories0: 'Kats0',
  category1: 'Ecorp type',
  Category1: '', // leave blank if it should not be shown
  categories1: 'Ecorp types',
  Categories1: 'Ecorp types',
  category2: 'alternativ region',
  Category2: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories2: 'alternativ regioner',
  Categories2: 'Alternativ regioner',
  category3: 'uddannelse',
  Category3: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories3: 'uddannelser',
  Categories3: 'Uddannelser',
  category4: '',
  Category4: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories4: '',
  Categories4: '',
  category5: '',
  Category5: '', // leave blank if it should not be shown - remember builders - stepindex.ts
  categories5: '',
  Categories5: '',
  Category0Hint: '',
  Category0Link: '',
  Category0Dialog: '',
  Category0Tooltip: '',
  Category1Hint: '',
  Category1Link: '',
  Category1Dialog: '',
  Category1Tooltip: '',
  Category2Hint: '',
  Category2Link: '',
  Category2Dialog: false,
  Category2Tooltip: false,
  Category3Hint: '',
  Category3Link: '',
  Category3Dialog: '',
  Category3Tooltip: '',
  Category4Hint: '',
  Category4Link: '',
  Category4Dialog: '',
  Category4Tooltip: '',
  Category5Hint: '',
  Category5Link: '',
  Category5Dialog: '',
  Category5Tooltip: '',
  FoiHint: '',
  FoiLink: '',
  FoiDialog: '',
  FoiTooltip: '',
  TechHint: '',
  TechLink: '',
  TechDialog: '',
  TechTooltip: '',
  GraduateProgram: '',
  GraduateProgramHint: '',
  GraduateProgramLink: '',
  GraduateProgramDialog: '',
  GraduateProgramTooltip: '',
  Dimittendsats: '',
  DimittendsatsHint: '',
  DimittendsatsLink: '',
  DimittendsatsDialog: '',
  DimittendsatsTooltip: '',
  Akademikerkampagnen: '',
  AkademikerkampagnenHint: '',
  AkademikerkampagnenLink: '',
  AkademikerkampagnenDialog: '',
  AkademikerkampagnenTooltip: '',
  experience0: 'academia',
  Experience0: '', // leave blank if it should not be shown
  experience1: 'industry',
  Experience1: '', // leave blank if it should not be shown
  experience2: 'hospital',
  Experience2: '', // leave blank if it should not be shown
  experience3: 'entrepreneurship',
  Experience3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  experience4: 'public sector/health',
  Experience4: '', // 'Public sector/health', // leave blank if it should not be shown
  experience5: '',
  Experience5: '', // leave blank if it should not be shown
  UserType0: 'Profil', // leave blank if it should not be shown
  UserTypes0: 'Profiler',
  UserType1: 'Virksomhed', // leave blank if it should not be shown
  UserTypes1: 'Virksomheder',
  UserType2: '', // leave blank if it should not be shown
  UserTypes2: 'Universities',
  UserType3: '', // leave blank if it should not be shown
  UserTypes3: 'Lab Technicians',
  UserType4: '', // leave blank if it should not be shown
  UserTypes4: 'Biomedical Scientists',
  UserType5: '', // leave blank if it should not be shown
  UserTypes5: 'SSO',
  auser: names.auser,
  user: names.user,
  User: names.User,
  users: names.users,
  Users: names.Users,
  thesite: names.thesite,
  asite: names.asite,
  newsite: names.newsite,
  Newsite: names.Newsite,
  companyName: names.companyName,
  CompanyName: names.CompanyName,
  site: names.site,
  Site: names.Site,
  sites: names.sites,
  Sites: names.Sites,
  theplace: 'stedet',
  place: 'sted',
  Place: 'Sted',
  places: 'steder',
  Places: 'Steder',
  thecv: `CV'et`,
  CVRoute: 'cv',
  Yourcv: 'dit CV',
  yourcv: 'dit CV',
  CV: 'CV',
  cv: 'CV',
  CVs: 'CV',
  cvs: 'CV',

  academicTitle: 'Stillingsbetegnelse',

  JobType0: 'Lagerplads', // leave blank if it should not be shown
  JobType1: 'Overskudsprodukt', // leave blank if it should not be shown
  JobType2: 'Projekt', // leave blank if it should not be shown
  JobType3: '', // 'Entrepreneurship', // leave blank if it should not be shown
  JobType4: '', // 'Public sector/health', // leave blank if it should not be shown
  JobType5: '', // leave blank if it should not be shown

  jobType0Desc: '', // leave blank if it should not be shown
  jobType1Desc: '', // leave blank if it should not be shown
  jobType2Desc: '', // leave blank if it should not be shown
  jobType3Desc: '', // leave blank if it should not be shown
  jobType4Desc: '', // leave blank if it should not be shown
  jobType5Desc: '', // leave blank if it should not be shown
  thejob: 'annoncen',
  ajob: 'en annonce',
  yourjob: 'din annonce',
  Yourjob: 'Din annonce',
  Oldjob: 'Gammel annonce',
  job: 'annonce',
  Job: 'Annonce',
  jobs: 'annoncer',
  Jobs: 'Annoncer',
  JobType: 'Type',

  shortFoi: 'branche',
  ShortFoi: 'Branche',
  foi: 'branche',
  Foi: 'Branche', // leave blank if it should not be shown
  fois: 'branche',
  Fois: 'Brancher',
  tech: 'produkt eller ydelse',
  Tech: '', // leave blank if it should not be shown
  techs: 'produkter og ydelser',
  Techs: 'Produkter og ydelser',
  companySubtype: 'type',
  CompanySubtype: '', // leave blank if it should not be shown
  companySubtypes: 'typer',
  CompanySubtypes: 'Typer',

  thenetwork: 'netværket',
  network: 'netværk',
  Network: 'Netværk',
  networks: 'netværk',
  Networks: 'Netværk',
  community: 'netværk',
  Community: 'Netværk',
  communities: 'netværk',
  Communities: 'Netværk',
  YourCommunities: 'Dine netværk',
  communitiesTitle: 'Netværk',
  newSubgroup: 'nyt undernetværk',
  subgroup: 'undernetværk',
  Subgroup: 'Undernetværk',
  subgroups: 'undernetværk',
  Subgroups: 'Undernetværk',
  idea: 'mentorforløb',
  Idea: 'Mentorforløb', // leave blank if it should not be shown
  ideas: 'mentorforløb',
  Ideas: 'Mentorforløb',
  YourIdeas: 'Dine mentorforløb',
  yourIdea: 'dit mentorforløb',
  IdeasTitle: 'Mentorforløb',
  websiteCollaboratoryUrl: '',
  theevent: 'begivenheden',
  Theevent: 'Begivenheden',
  thisevent: 'denne begivenheden',
  Thisevent: 'Denne begivenheden',
  newEvent: 'ny begivenhed',
  event: 'begivenhed',
  Event: 'Begivenhed',
  events: 'begivenheder',
  Events: 'Begivenheder',
  YourEvents: 'Dine begivenheder',
  yourEvent: 'din begivenhed',
  EventsTitle: 'Begivenheder',
  Moderator: 'Moderator',
  Manager: 'Manager',
  member: 'medlem',
  Member: 'Medlem',
  members: 'medlemmer',
  Members: 'Medlemmer',
  participant: 'deltager',
  Participant: 'Deltager',
  participants: 'deltagere',
  Participants: 'Deltagere',
  participantInvite: 'inviteret',
  ParticipantInvite: 'Inviteret',
  participantsInvite: 'inviterede',
  ParticipantsInvite: 'Inviterede',

  Public: 'Offentlig',
  OpenPrivate: 'Åben',
  Private: 'Privat',
  Hidden: 'Skjult',
  InviteOnly: 'kun ved invitation',
  public: 'offentlig',
  openPrivate: 'åben',
  private: 'privat',
  inviteOnly: 'kun ved invitation',
  hidden: 'skjult',

  mailSubject: `${acaConfig.academondo}`,
  notEnoughPermission: `Velkommen til ${acaConfig.academondo}. Opret din profil for at få adgang`,
  dashboardWelcomeTitle: `${acaConfig.academondo}`,
  scienceMatchRoute: 'søgning',
  scienceMatch: 'Søgning',
  scienceMatchSubtitle: 'Sammen gør vi verden grønnere',
  accountSelector1: `Hvem er ${acaConfig.academondo} lavet til?`, // `Who is ${acaConfig.academondo} for?`
  accountSelector2: 'Alle i dansk erhvervsliv', // 'Everybody with a passion within Life Science, Health- or Foodtech',
  accountSelector3: 'Jeg er en person', // `I'm an individual`
  accountSelector4: 'Jeg er en virksomhed', // `I'm a company, university, organisation or hospital`
  inviteToAcademondoText: `Invitér dine kontakter til ${acaConfig.academondo}`,

  showGithub: false,
  showTwitter: false,
  thisWillMakeCVSearchable: 'Dette gør din profil synlig.',
  additionalInformation: 'Supplerende information',
  writeInfoAboutCompany: `Beskriv ${names.thesite}. (prøv copy/paste fra eks. word) 😉`, // `Beskriv ${acaNames.site}en.`,
  writeQualificationsPosition: 'Beskriv kategorierne.', // 'Beskriv kategorierne.',
  permanentContract: 'Fast kontrakt',
  dateOfBirthHint: '', // 'This will not be published - only for statistics',
  // intro
  writeAboutYourself: 'Skriv om dig selv, din karriere og dit fokusområde',
  titleOfEducationHint: 'eks. Molecular Nanotechnology',

  dashboardRoute: 'start-side',
  dashboardTitle: 'Startside',
  jobBuilderRoute: 'annoncer',
  jobBuilderTitle: 'Annoncer',
  siteBuilderRoute: names.Sites,

  Basic: 'Basis',
  Extended: 'Event koordinator',
  Full: 'Netværker',
  Admin: 'Admin',

  PlacesTypes0: 'Steder',
  PlacesTypes1: 'Hub',
  PlacesTypes2: '',
  PlacesTypes3: '',

  signUp: 'Sign up',

  // intro dialog - cv
  helpheaderCV: 'Du er nu klar til at bygge din profil!',
  helpLineOneCV:
    'Denne interaktive profil bygger tillader dig, at se din profil mens du bygger det.',
  helpLineTwop1CV: 'Husk at trykke',
  helpLineTwop2CV: 'for at gøre det',
  helpLineTwop3CV: `Synlig på ${acaConfig.academondo}`,
  helpLineThreep1CV: '',
  helpLineThreep2CV: '',

  // intro dialog - job
  helpheaderJob:
    'Du er nu klar til at lave annoncer! Denne interaktive feature tilader dig at se dit opslag mens du laver det. Når du er klar, så starter du bare og se din annonce tage form mens du skriver.',
  helpLineOneJob:
    'Når du er færdig, så husk at trykke "gem" for at gøre annoncen synlig for alle i netværket.',
  helpLineTwoJob:
    'Igen, Velkommen til, Vi er glade for at kunne hjælpe dig på vej mod at finde de bedste samarbejdspartnere!',

  // map
  mapSiteTooltip: '',
  mapSiteHint: '',
};
