import { Injectable } from '@angular/core';
import { decryptBase64, encryptBase64 } from '@helpers';
import { DAO } from 'app/shared-services/db-access/dao';
import {
  ChatMessage,
  ChatMessageJson,
} from 'app/shared/models/chat/chat-message';
import { ChatRoom, ChatRoomJson } from 'app/shared/models/chat/chat-room';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { DataConstants } from '../shared/consts/dataConstants';
import { ChatRoomState } from '../shared/models/chat/chat-room-state';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    public db: DAO,
    public authService: AuthService,
    private userService: UserService
  ) {}

  getChatInvites() {}
  getChatRoomUsers(roomId) {
    return this.db.object(DataConstants.CHAT_USER_ROOMS + roomId);
  }

  getChatRoom(roomId: string): Observable<ChatRoom> | Observable<any> {
    return this.db
      .object(DataConstants.CHAT_ROOMS + roomId)
      .snapshotChanges()
      .pipe(
        mergeMap((snap) => {
          if (snap.payload.exists()) {
            const chatRoomJson = snap.payload.val() as ChatRoomJson<
              number,
              string
            >;
            return this.userService.getUserByUid$(chatRoomJson.creator).pipe(
              map((user: MondoUser) => {
                return ChatRoom.fromJson(chatRoomJson, user, [], null);
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }

  getUserChatRooms(): Observable<ChatRoom[]> {
    return this.db
      .list(
        DataConstants.CHAT_USER_ROOMS + this.authService.getCurrentUser().uid
      )
      .snapshotChanges()
      .pipe(
        mergeMap((chatRoomIds) => {
          if (chatRoomIds.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            chatRoomIds.map((chatRoomSnap) =>
              this.getChatRoom(chatRoomSnap.key)
            )
          );
        }),
        filter((b) => !!b)
      );
  }

  getRoomIdFromUser(userId: string): string {
    const currentUser = this.authService.getCurrentUser().uid;
    if (userId && userId === currentUser) {
      return null;
    }
    const roomId =
      currentUser < userId
        ? currentUser + '_' + userId
        : userId + '_' + currentUser;
    const hashedRoomId = encryptBase64(roomId);
    // const dehashedRoomId = decryptBase64(hashedRoomId);

    return hashedRoomId;
  }

  extractUsers(roomId): string[] {
    return decryptBase64(roomId).split('_');
  }

  async openOrCreateNewChatRoom(roomId: string) {
    if (roomId) {
      const exists = await this.db
        .object(DataConstants.CHAT_ROOMS + roomId)
        .snapshotChanges()
        .pipe(
          take(1),
          map((snap) => snap.payload.exists())
        )
        .toPromise();

      const chatRoom = exists
        ? this.db.object(DataConstants.CHAT_ROOMS + roomId).valueChanges()
        : this.createChatRoom(roomId).then((roomKey) =>
            this.db.object(DataConstants.CHAT_ROOMS + roomKey).valueChanges()
          );
      return chatRoom;
    }
  }

  createChatRoom(roomId: string): Promise<string> {
    const chatRoom = {
      key: roomId,
      created: new Date(),
      updated: new Date(),
      userIds: this.extractUsers(roomId),
      chatRoomState: ChatRoomState.private,
      lastMsg: null,
    };
    return (
      this.db
        .object(DataConstants.CHAT_ROOMS + roomId)
        // .set(ChatRoom.toJson(chatRoom))
        .set(chatRoom)
        .then(() => {
          const promises = [];
          chatRoom.userIds.forEach((userId) =>
            promises.push(
              this.db
                .object(
                  DataConstants.CHAT_USER_ROOMS + userId + '/' + chatRoom.key
                )
                .set(true)
            )
          );
          return Promise.all(promises);
        })
        .then(() => {
          return chatRoom.key;
        })
    );
  }

  createNewChatRoom(
    name: string,
    users: MondoUser[] = [],
    chatRoomState: ChatRoomState
  ): Promise<string> {
    const chatRoom = new ChatRoom(
      this.db.createPushId(),
      this.authService.getCurrentUser(),
      users,
      new Date(),
      chatRoomState,
      name,
      null
    );
    return this.db
      .object(DataConstants.CHAT_ROOMS + chatRoom.key)
      .set(ChatRoom.toJson(chatRoom))
      .then(async () => {
        await this.db
          .object(
            DataConstants.CHAT_USER_ROOMS +
              chatRoom.creator.uid +
              '/' +
              chatRoom.key
          )
          .set(true);
        await users.forEach((user) =>
          this.db
            .object(
              DataConstants.CHAT_USER_ROOMS + user.uid + '/' + chatRoom.key
            )
            .set(false)
        );
      })
      .then(() => chatRoom.key);
  }

  deleteChatRoom(chatRoomId: string) {}

  getChatRoomsMessages(chatRoomId: string): Observable<ChatMessage[]> {
    return this.db
      .list(DataConstants.CHAT_ROOMS_MESSAGES + chatRoomId, (ref) =>
        ref.limitToLast(50)
      )
      .snapshotChanges()
      .pipe(
        mergeMap((snaps) => {
          if (snaps.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            snaps.map((messageSnap) => {
              const messageJson = messageSnap.payload.val() as ChatMessageJson<
                number,
                string
              >;
              return this.userService.getUserByUid$(messageJson.sender).pipe(
                map((user) => {
                  return ChatMessage.fromJson(messageJson, user);
                })
              );
            })
          );
        })
      );
  }

  sendMessage(chatMessage: string, chatRoomId: string): Promise<ChatMessage> {
    const message = new ChatMessage(
      this.db.createPushId(),
      chatMessage,
      new Date(),
      this.authService.getCurrentUser()
    );
    return this.db
      .object(
        DataConstants.CHAT_ROOMS_MESSAGES + chatRoomId + '/' + message.key
      )
      .set(ChatMessage.toJson(message))
      .then(() => message);
  }
}
