import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { BehaviorSubject } from 'rxjs';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import { MatDialog } from '@angular/material/dialog';
import { SuggestTechniqueDialogComponent } from 'app/shared/components/suggest-technique-dialog/suggest-technique-dialog.component';
import { SuggestFieldOfInterestDialogComponent } from 'app/shared/components/suggest-field-of-interest-dialog/suggest-field-of-interest-dialog.component';
import { StaticItemsService } from 'app/core/static-items.service';
import { Job } from 'app/stepper/job/model/job';
import { tap, debounceTime } from 'rxjs/operators';
import { SuggestCategory0DialogComponent } from 'app/shared/components/suggest-category0-dialog/suggest-category0-dialog.component';
import { SuggestCategory1DialogComponent } from 'app/shared/components/suggest-category1-dialog/suggest-category1-dialog.component';
import { SuggestCategory3DialogComponent } from 'app/shared/components/suggest-category3-dialog/suggest-category3-dialog.component';
import { JobService } from 'app/stepper/job/services/job.service';

@Component({
  selector: 'app-job-builder-research-qualifications',
  templateUrl: './job-builder-research-qualifications.component.html',
  styleUrls: ['./job-builder-research-qualifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderResearchQualificationsComponent implements OnInit {
  private interestsPatchStream = new BehaviorSubject(null);
  private techniquesPatchStream = new BehaviorSubject(null);
  private category0PatchStream = new BehaviorSubject(null);
  private category1PatchStream = new BehaviorSubject(null);
  private category2PatchStream = new BehaviorSubject(null);
  private category3PatchStream = new BehaviorSubject(null);
  @Input() form: MondoFormGroup<Job>;
  educationLevels = EducationLevel;
  valueChanges$;

  constructor(
    private staticItemsService: StaticItemsService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.valueChanges$ = this.researchQualifications.valueChanges.pipe(
      tap(() => this.cdr.detectChanges())
    );
  }

  get labTechniques$() {
    return this.staticItemsService.getTechniques$();
  }

  get fieldsOfInterests$() {
    return this.staticItemsService.getFieldOfInterest$();
  }

  get category0$() {
    return this.staticItemsService.getCategories0$();
  }

  get category1$() {
    return this.staticItemsService.getCategories1$();
  }

  get category2$() {
    return this.staticItemsService.getCategories0$();
  }

  get category3$() {
    return this.staticItemsService.getCategories3$();
  }

  get techniquesPatch$() {
    return this.techniquesPatchStream.asObservable();
  }

  get category0Patch$() {
    return this.category0PatchStream.asObservable();
  }

  get category1Patch$() {
    return this.category1PatchStream.asObservable();
  }

  get category2Patch$() {
    return this.category2PatchStream.asObservable();
  }

  get category3Patch$() {
    return this.category3PatchStream.asObservable();
  }

  get interestsPatch$() {
    return this.interestsPatchStream.asObservable();
  }

  get researchQualifications() {
    return this.form.getSafeGroup((x) => x.researchQualifications);
  }

  get qualificationFreeText() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.qualificationFreeText
    );
  }

  get wantedDaysOfExperience0() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience0
    );
  }

  get wantedDaysOfExperience1() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience1
    );
  }

  get wantedDaysOfExperience2() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience2
    );
  }

  get wantedDaysOfExperience3() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience3
    );
  }

  get wantedDaysOfExperience4() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience4
    );
  }

  get wantedDaysOfExperience5() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.wantedDaysOfExperience5
    );
  }

  get minimumEducationalLevel() {
    return this.form.getSafeControl(
      (x) => x.researchQualifications.minimumEducationalLevel
    );
  }

  get languages() {
    return this.form.getSafeControl((x) => x.languages);
  }

  openSuggestInterestDialog(): void {
    this.dialog.open(SuggestFieldOfInterestDialogComponent, { width: '500px' });
  }

  openSuggestCategory0Dialog(): void {
    this.dialog.open(SuggestCategory0DialogComponent, { width: '500px' });
  }

  openSuggestCategory1Dialog(): void {
    this.dialog.open(SuggestCategory1DialogComponent, { width: '500px' });
  }

  openSuggestCategory3Dialog(): void {
    this.dialog.open(SuggestCategory3DialogComponent, { width: '500px' });
  }

  openSuggestTechniqueDialog(): void {
    this.dialog.open(SuggestTechniqueDialogComponent, { width: '500px' });
  }

  canSeeCat1() {
    return this.jobService.canSeeCat1();
  }
}
