<perfect-scrollbar>
  <div *ngIf="job" class="a4-card">
    <app-job-viewer-header
      *ngIf="showMetaData"
      [job]="job"
    ></app-job-viewer-header>
    <app-job-viewer-about [jobInfo]="job.jobInfo"></app-job-viewer-about>
    <app-job-viewer-qualifications
      [qualifications]="job.researchQualifications"
    ></app-job-viewer-qualifications>
    <app-viewer-languages
      languageTitle="{{
        'wantedX'
          | preventHardcodingFormatText: ('languages' | preventHardcoding)
      }}"
      [languages]="job.languages"
    >
    </app-viewer-languages>
    <app-job-viewer-employment-period
      [employmentPeriod]="job.employmentPeriod"
    ></app-job-viewer-employment-period>
    <app-job-viewer-contact [job]="job"></app-job-viewer-contact>
  </div>
</perfect-scrollbar>
