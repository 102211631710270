import { map, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MondoFormBuilder, MondoFormGroup } from 'app/core/mondo-form-builder';
import { Company } from 'app/stepper/job/model/company';
import { Contact } from 'app/stepper/job/model/contact';
import { Job } from 'app/stepper/job/model/job';
import { JobInfo } from '../model/jobInfo';
import { ResearchGroup } from 'app/stepper/job/model/researchGroup';
import { ResearchQualifications } from 'app/stepper/job/model/researchQualifications';
import { TimePeriod } from 'app/stepper/job/model/timePeriod';
import { MondoLocation } from '../model/mondoLocation';
import { ResearchLeader } from '../model/researchLeader';
import { FormControl, Validators } from '@angular/forms';
import { JobLinks } from 'app/stepper/job/model/JobLinks';
import { ItemType } from 'app/stepper/shared/model/ItemType';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import {
  FieldOfInterest,
  Technique,
  Category0,
  Category1,
  Country,
} from 'app/shared/models';
import { Languages } from 'app/shared/models/languages/languages';
import { LanguageDetails } from 'app/shared/models/languages/language-details';
import { CVRValidator, StrictEmailValidator } from '@helpers';

@Injectable()
export class JobFormService {
  private jobForm: MondoFormGroup<Job> = this.formBuilder.group<Job>({
    type: ItemType.Job,
    status: new FormControl(null),
    name: '',
    key: '',
    ownerId: '',
    lastUpdate: new FormControl(null),
    created: new FormControl(null),
    madePublic: new FormControl(null),
    jobInfo: this.formBuilder.group<JobInfo>({
      position: '',
      teaser: '',
      description: '',
      youtubeId: new FormControl(null, Validators.minLength(11)),
      imageLink: '',
      imageUrl: '',
      about: '',
      jobType: new FormControl(null, Validators.required),
    }),

    company: this.formBuilder.group<Company>({
      uid: '',
      name: '',
      cvr: new FormControl(null, [
        Validators.minLength(8),
        Validators.maxLength(8),
        CVRValidator,
      ]),
      email: new FormControl(null, [StrictEmailValidator]),
      maxApplicants: 2,
      pictureUrl: '',
      coverUrl: '',
      location: this.formBuilder.group<MondoLocation>({
        street: '',
        number: '',
        formattedAddress: '',
        placeId: '',
        city: '',
        postalCode: '',
        country: new FormControl(new Country()),
        latLng: new FormControl(null),
      }),
    }),
    researchQualifications: this.formBuilder.group<ResearchQualifications>({
      wantedDaysOfExperience0: 0,
      wantedDaysOfExperience1: 0,
      wantedDaysOfExperience2: 0,
      wantedDaysOfExperience3: 0,
      wantedDaysOfExperience4: 0,
      wantedDaysOfExperience5: 0,
      wantedTechniques: this.formBuilder.array<Technique[]>([]),
      wantedFieldOfInterest: this.formBuilder.array<FieldOfInterest[]>([]),
      category0: this.formBuilder.array<Category0[]>([]),
      category1: this.formBuilder.array<Category1[]>([]),
      category2: this.formBuilder.array<Category0[]>([]),
      category3: this.formBuilder.array<Category0[]>([]),
      category4: this.formBuilder.array<Category0[]>([]),
      category5: this.formBuilder.array<Category0[]>([]),
      qualificationFreeText: '',
      minimumEducationalLevel: EducationLevel.none,
    }),

    languages: this.formBuilder.group<Languages>({
      languages: this.formBuilder.array<LanguageDetails[]>([]),
    }),

    employmentPeriod: this.formBuilder.group<TimePeriod>({
      startDate: null,
      endDate: null,
      openEnded: new FormControl(false),
      startNow: new FormControl(false),
    }),

    jobVisibilityPeriod: this.formBuilder.group<TimePeriod>({
      startDate: null,
      endDate: null,
      openEnded: new FormControl(true),
      startNow: new FormControl(false),
    }),

    contact: this.formBuilder.group<Contact>({
      info: '',
      researchGroup: this.formBuilder.group<ResearchGroup>({
        uid: '',
        name: '',
        researchLeader: this.formBuilder.group<ResearchLeader>({
          uid: '',
          name: '',
        }),
      }),
      jobLinks: this.formBuilder.group<JobLinks>({
        urlJobSite: '',
        urlJobApplication: '',
      }),
      contactEmail: new FormControl(null, [StrictEmailValidator]),
      contactPhone: '',
    }),
    contactConsent: new FormControl(false),
    expireWarned: new FormControl(false),
  });
  constructor(private formBuilder: MondoFormBuilder) {}

  public getForm(): MondoFormGroup<Job> {
    return this.jobForm;
  }

  public patchJob(job: Job): void {
    if (!job) {
      return;
    }
    this.jobForm.patchValue(job);
    this.patchResearchQualifications(job.researchQualifications);
    this.patchLanguages(job.languages);
  }

  private patchResearchQualifications(researchQual: ResearchQualifications) {
    if (researchQual) {
      this.researchQualifications.setControlSafe(
        (x) => x.wantedFieldOfInterest,
        this.getFormArray<FieldOfInterest>(
          researchQual.wantedFieldOfInterest || []
        )
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category0,
        this.getFormArray<Category0>(researchQual.category0 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category1,
        this.getFormArray<Category1>(researchQual.category1 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category2,
        this.getFormArray<Category0>(researchQual.category2 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category3,
        this.getFormArray<Category0>(researchQual.category3 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category4,
        this.getFormArray<Category0>(researchQual.category4 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.category5,
        this.getFormArray<Category0>(researchQual.category5 || [])
      );
      this.researchQualifications.setControlSafe(
        (x) => x.wantedTechniques,
        this.getFormArray<Technique>(researchQual.wantedTechniques || [])
      );
    }
  }

  private patchLanguages(languagues: Languages) {
    if (languagues) {
      this.languages.setControlSafe(
        (x) => x.languages,
        this.getFormArray<LanguageDetails>(languagues.languages || [])
      );
    }
  }

  public getFormArray<T>(list: T[]): any {
    const formGroups = list.map((item) => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    return formArray;
  }

  public getFormChangeStream(): Observable<Job> {
    return this.jobForm.valueChanges.pipe(debounceTime(500));
  }

  get researchQualifications(): MondoFormGroup<ResearchQualifications> {
    return this.jobForm.getSafeGroup<ResearchQualifications>(
      (x) => x.researchQualifications
    );
  }

  get languages(): MondoFormGroup<Languages> {
    return this.jobForm.getSafeGroup<Languages>((x) => x.languages);
  }
}
