<ng-container>
  <div class="text-button-container">
    <p>{{ introText | preventHardcoding }}</p>
    <button
      class="sso-login-button"
      mat-raised-button
      [disabled]="loading"
      color="primary"
      (click)="loginSSO()"
    >
      {{ text | preventHardcoding }}
    </button>
  </div>
  <div class="divider">
    <strong>{{ 'Or' | preventHardcoding }}</strong>
  </div>
  <div class="outro-text">
    <p>{{ outroText | preventHardcoding }}</p>
  </div>
</ng-container>

<app-splash-screen *ngIf="loading"></app-splash-screen>
