import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth.service';
import { DirectMatchService } from 'app/core/direct-match.service';
import { PublishJobDialogComponent } from '../components/publish-job-dialog/publish-job-dialog.component';
import { Job } from '../model/job';
import { WantedStatus } from '../model/wantedStatus';
import { JobService } from './job.service';

@Injectable({
  providedIn: 'root',
})
export class JobPublishService {
  constructor(
    private jobService: JobService,
    public authService: AuthService,
    private dialog: MatDialog,
    private directMatchService: DirectMatchService
  ) {}

  public async publishJob(job: Job) {
    if (job) {
      const dialogRef = this.dialog.open(PublishJobDialogComponent, {
        width: '500px',
      });
      dialogRef.componentInstance.draftJob = job;

      dialogRef.afterClosed().subscribe((wantedStatus) => {
        switch (wantedStatus) {
          case WantedStatus.publish:
          case WantedStatus.republish:
            // this.handleCompanyChange(job);
            job.status.queueForPublish();
            this.jobService.publish(job, Job.toJson);
            this.directMatchService.createRegistrationVisit(job);
            break;
          case WantedStatus.unpublish:
            this.jobService.unpublishJob(job);
        }
      });
    }
  }

  // private handleCompanyChange(job: Job) {
  //   const user = this.authService.getCurrentUser() as IndustryUser;
  //   if (!_.isEqual(user.company, job.company)) {
  //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //       width: '500px'
  //     });
  //     dialogRef.componentInstance.title = 'updateCompanyDetails';
  //     dialogRef.componentInstance.text = 'YouHavePublishedJobWithNewDetails';
  //     dialogRef.componentInstance.yesText = 'yes';
  //     dialogRef.componentInstance.noText = 'no';

  //     dialogRef.afterClosed()
  //       .subscribe(wantsUpdate => {
  //         if (wantsUpdate) {
  //           user.company = job.company;
  //           this.userService.updateUser(user);
  //         }
  //       });
  //   }
  // }
}
