// import { configACADEMONDO } from '../src/aca-config-ACADEMONDO';
// import { configAC } from '../src/aca-config-AC';
// import { configOE } from '../src/aca-config-OE';
// import { configEBRAINS } from '../src/aca-config-EBRAINS';
// import { configSUSTAINABLE } from '../src/aca-config-SUSTAINABLE';
// import { configSYNAPSE } from '../src/aca-config-SYNAPSE';
// import { configMAGISTER } from '../src/aca-config-MAGISTER';
import { configSALES } from '../src/aca-config-SALES';
// import { configDLS } from '../src/aca-config-DLS-KLYNGE';
// import { configDESMV } from '../src/aca-config-DESMV';

// export const acaConfig = configACADEMONDO
// export const acaConfig = configAC
// export const acaConfig = configOE
// export const acaConfig = configEBRAINS
// export const acaConfig = configSUSTAINABLE
// export const acaConfig = configSYNAPSE
// export const acaConfig = configMAGISTER
export const acaConfig = configSALES;
// export const acaConfig = configDLS;
// export const acaConfig = configDESMV;
