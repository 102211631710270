import { Component, OnInit, Input } from '@angular/core';
import { MondoFormControl } from 'app/core/mondo-form-builder';
import { Country } from 'app/shared/models';
import { CountryService } from 'app/core/country.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss']
})
export class CountryPickerComponent implements OnInit {
  selectedCountry: Country;
  @Input()
  form: MondoFormControl<Country>;
  countries: Country[];
  constructor(private countryService: CountryService) { }

  ngOnInit() {
    this.countryService
      .getCountries$()
      .pipe(take(1))
      .subscribe(countries => {
        this.countries = countries;
        this.findAndSelectCountry();
        this.form.registerOnChange(formChange => this.findAndSelectCountry());
      });
  }

  private findAndSelectCountry() {
    if (this.form.value) {
      this.selectedCountry = this.countries.find(
        c => c.id === (this.form.value as Country).id
      );
    }
  }

  onCountrySelected(country: Country) {
    this.selectedCountry = country;
    this.form.patchValue(country);
  }

  onCountrySelectionChanged(event, country) {
    if (event.source.selected) {
      this.onCountrySelected(country);
    }
  }

  trackByFn(index, item) {
    return (item.key || item.id || item.uid);
  }
}
