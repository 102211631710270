<div class="form-group" [formGroup]="form">
  <!-- <div [formGroup]="jobLinks" *ngIf="jobType === 0"> -->
  <div>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'URLToTheOfficialJobSite' | preventHardcoding }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="urlJobSite"
        placeholder="{{ 'pasteURLHere' | preventHardcoding }}"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{
        'URLToTheOfficialJobApplicationForm' | preventHardcoding
      }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="urlJobApplication"
        placeholder="{{ 'pasteURLHere' | preventHardcoding }}"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'reseachGroup' | preventHardcoding }}</mat-label>
      <input matInput [formControl]="researchGroupName" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'researchLeader' | preventHardcoding }}</mat-label>
      <input matInput [formControl]="researchLeaderName" />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'contactEmail' | preventHardcoding }}</mat-label>
      <input
        matInput
        type="email"
        name="email"
        [formControl]="contactEmail"
        autocomplete="off"
      />
      <mat-error *ngIf="contactEmail?.errors?.email">{{
        'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'contactPhone' | preventHardcoding }}</mat-label>
      <input
        matInput
        type="tel"
        name="phone"
        [formControl]="contactPhone"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <app-text-editor [textFormControl]="info" textEditorFormControlName="info">
    {{ 'writeNamesAndInformationOnThePeopleToContact' | preventHardcoding }}
  </app-text-editor>
</div>
