import {
  FieldOfInterest,
  Technique,
  Subtypes,
  Category0,
  Category1,
  NetworkSubtypes,
} from 'app/shared/models';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import { LanguageMultiSelector } from 'app/shared/models/languages/language-multiselector';

export interface SearchFormV2 extends BaseSearchForm {
  availability: string;
  fullName: string;
  facilityName: string;
  description: string;
  type: string;
  siteSubtype: Subtypes[];
  language: LanguageMultiSelector[];
  // educationalLevel: string | string[];
  educationalLevel: EducationLevel[];
  wantedDaysOfExperience0: number;
  wantedDaysOfExperience1: number;
  wantedDaysOfExperience2: number;
  wantedDaysOfExperience3: number;
  wantedDaysOfExperience4: number;
  wantedDaysOfExperience5: number;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  searchSettings: SearchSettings;
  rows: number;
  page: number;
}
export enum SearchSettings {
  hidePast = 'hidePast',
  hideStarted = 'hideStarted',
  showOngoing = 'showOngoing',
  showExpired = 'showExpired',
  show24Hours = 'show24Hours',
  show1Week = 'show1Week',
  show4Weeks = 'show4Weeks',
  showAll = 'showAll',
}

export interface CvSearchForm extends BaseSearchForm {
  educationalLevel: string | string[];
  yearsOfAcademicWorkExperience: number;
  yearsOfIndustrialWorkExperience: number;
  yearsOfHospitalWorkExperience: number;
  yearsOfExperience0: number;
  yearsOfExperience1: number;
  languages: LanguageMultiSelector[];
  networkKey: string;
  siteType: number[];
}

export interface CandidateSearchForm extends BaseSearchForm {
  educationalLevel: string | string[];
  yearsOfAcademicWorkExperience: number;
  yearsOfIndustrialWorkExperience: number;
  yearsOfHospitalWorkExperience: number;
  yearsOfExperience0: number;
  yearsOfExperience1: number;
  languages: LanguageMultiSelector[];
  networkKey: string;
  siteType: number[];
}

export interface CvSearchResult extends BaseSearchResult {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  currentJobSituation: number;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  languages: Array<string>;
  educationalLevels: Array<string>;
  score: number;
  status: string;
  ownerId: string;
}

export interface BaseSearchForm {
  interests: FieldOfInterest[];
  techniques: Technique[];
  category0: Category0[];
  category1: Category1[];
  category2: Category0[];
  category3: Category0[];
  category4: Category0[];
  category5: Category0[];
  radius: number;
  coordinates: string;
}

export interface BaseSearchResult {
  id: string;
  interests: Array<string>;
  techniques: Array<string>;
  category0: Array<string>;
  category1: Array<string>;
  latlon: string;
  distance: number;
}

export interface CompanySearchForm extends BaseSearchForm {
  subtypes: Subtypes[];
  siteType: number[];
}

export interface CompanySearchResult extends BaseSearchResult {
  subtypes: Array<string>;
  address: string;
  email: string;
  emplNetworkId: string;
  info: string;
  logo: string;
  name: string;
  score: number;
  siteName: string;
  siteType: string;
  url: string;
  status: string;
}

export interface NetworkSearchResult extends BaseSearchResult {
  accessNumber: string;
  accessWithOutCode: string;
  address: string;
  email: string;
  global: string;
  info: string;
  logo: string;
  name: string;
  networkModel: string;
  networkStatus: string;
  score: number;
  subtypes: Array<string>;
  userId: string;
  websiteUrl: string;
}

export interface SearchResultV2 extends BaseSearchResult {
  fullName: string;
  facilityName: string;
  description: string;
  type: SearchableTypes;
  score: number;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  wantedDaysOfExperience0: number;
  wantedDaysOfExperience1: number;
  wantedDaysOfExperience2: number;
  wantedDaysOfExperience3: number;
  wantedDaysOfExperience4: number;
  wantedDaysOfExperience5: number;
  category2: Array<string>;
  category3: Array<string>;
  category4: Array<string>;
  category5: Array<string>;
  startDate: number;
  endDate: number;
  duration: number;
  customAttributes: any;
  country: string;
  availability: string;
  expiryDate: number;
  status; // invite
}

export enum ResultSortingMode {
  TECHNIQUES_DESCENDING,
  INTERESTS_DESCENDING,
}

export enum SearchableTypes {
  All = 'All',
  Community = 'Community',
  Event = 'Event',
  Idea = 'Idea',
  GroupPublicPost = 'GroupPublicPost',
  User = 'User',
  Site = 'Site',
  Job = 'Job',
}

export interface ResultSortingItem {
  displayText: string;
  mode: ResultSortingMode;
}

export interface SolrResult<T> {
  hits: number;
  totalHits: number;
  elapsed: number;
  results: T;
}

export class SolrResult<T> {
  constructor(
    public results: T,
    public hits = 0,
    public totalHits = 0,
    public elapsed = 0
  ) {}
}

export class SolrQuery {
  constructor(
    public filter: iSolrQueryFilter[] = [],
    public conditions: iSolrQueryFilter[] = [],
    public spatial: {} = {}
  ) {}
}

export class SolrQueryV2 {
  constructor(
    public filter: iSolrQueryFilter[] = [],
    public conditions: iSolrQueryFilter[] = [],
    public spatial: {} = {},
    public rows: number = 50,
    public page: number = 0
  ) {}
}

interface iSolrQueryFilter {
  operation: string;
  field: string;
  value: string | string[];
}
