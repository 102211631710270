import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-viewer-contact',
  templateUrl: './job-viewer-contact.component.html',
  styleUrls: ['./job-viewer-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobViewerContactComponent implements OnInit {
  @Input() job: Job;

  constructor() {}

  ngOnInit() {}

  public apply(href) {
    window.open(href);
  }

  get infoHTML(): string {
    return this.getStringOrNull(this.job.contact.info);
  }

  get urlJobApplication(): string {
    return this.getStringOrNull(this.job.contact.jobLinks.urlJobApplication);
  }
  get contactEmail(): string {
    return this.getStringOrNull(this.job.contact.contactEmail);
  }
  get contactPhone(): string {
    return this.getStringOrNull(this.job.contact.contactPhone);
  }

  get urlJobSite(): string {
    return this.getStringOrNull(this.job.contact.jobLinks.urlJobSite);
  }

  get deadline(): Date {
    return this.job.jobVisibilityPeriod.endDate;
  }

  get researchLeaderName(): string {
    return this.getStringOrNull(
      this.job.contact.researchGroup.researchLeader.name
    );
  }

  private getStringOrNull(string: string): string {
    return string && string !== '' ? string : null;
  }
}
