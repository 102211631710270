<div class="form-group" [formGroup]="form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'titleOfThePosition' | preventHardcoding }}</mat-label>
    <input
      matInput
      name="job-title"
      [formControl]="position"
      autocomplete="off"
    />
  </mat-form-field>
  <mat-radio-group
    *ngIf="
      ('JobType0' | preventHardcoding) ||
      ('JobType1' | preventHardcoding) ||
      ('JobType2' | preventHardcoding) ||
      ('JobType3' | preventHardcoding) ||
      ('JobType4' | preventHardcoding) ||
      ('JobType5' | preventHardcoding)
    "
    [formControl]="jobType"
  >
    <mat-label>{{ 'JobType' | preventHardcoding }}</mat-label>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType0' | preventHardcoding"
      color="primary"
      [value]="0"
    >
      {{ 'JobType0' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType0Desc' | preventHardcoding">
        {{ 'jobType0Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType1' | preventHardcoding"
      color="primary"
      [value]="1"
    >
      {{ 'JobType1' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType1Desc' | preventHardcoding">
        {{ 'jobType1Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType2' | preventHardcoding"
      color="primary"
      [value]="2"
    >
      {{ 'JobType2' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType2Desc' | preventHardcoding">
        {{ 'jobType2Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType3' | preventHardcoding"
      color="primary"
      [value]="3"
    >
      {{ 'JobType3' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType3Desc' | preventHardcoding">
        {{ 'jobType3Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType4' | preventHardcoding"
      color="primary"
      [value]="4"
    >
      {{ 'JobType4' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType4Desc' | preventHardcoding">
        {{ 'jobType4Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
    <mat-radio-button
      class="reverse-radio-button"
      *ngIf="'JobType5' | preventHardcoding"
      color="primary"
      [value]="5"
    >
      {{ 'JobType5' | preventHardcoding }}
      <div class="type-desc" *ngIf="'jobType5Desc' | preventHardcoding">
        {{ 'jobType5Desc' | preventHardcoding }}
      </div>
    </mat-radio-button>
  </mat-radio-group>
  <app-text-editor
    [textFormControl]="description"
    textEditorFormControlName="description"
  >
    {{
      'writeInformationAboutThePositionTasksResponsibilities'
        | preventHardcoding
    }}
  </app-text-editor>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'youtubeVideoInputLabel' | preventHardcoding }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="youtubeId"
      placeholder="{{ 'youtubeVideoInput' | preventHardcoding }}"
      autocomplete="off"
    />
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'imageLinkInputLabel' | preventHardcoding }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="imageLink"
      placeholder="{{ 'imageLinkInput' | preventHardcoding }}"
      autocomplete="off"
    />
  </mat-form-field>
  <app-file-upload
    btnText="{{ 'uploadImageLink' | preventHardcoding }}"
    [currentPictureUrl]="imageLink.value"
    [filePath]="imageLinkPath"
    (complete)="onPicUpload($event)"
  >
  </app-file-upload>
</div>
