import { SiteType } from './siteType';
import { Subtypes } from './site-subtypes';
import { MondoLocation } from '../../stepper/job/model/mondoLocation';

export class SiteInfo implements ISiteInfo {
  public static fromJson(
    {
      type,
      pictureUrl,
      nameSite,
      catchPhrase,
      addressSite,
      websiteUrl,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      subtypes,
      coverUrl,
      logoUrl,
      cvr,
      contactEmail,
    }: ISiteInfo = {} as SiteInfo
  ) {
    return new SiteInfo(
      type,
      pictureUrl,
      nameSite,
      catchPhrase,
      MondoLocation.fromJson(addressSite),
      websiteUrl,
      email,
      phone,
      youtubeId,
      linkedin,
      info,
      subtypes,
      coverUrl,
      logoUrl,
      cvr,
      contactEmail
    );
  }

  constructor(
    public type = SiteType.company,
    public pictureUrl = '',
    public nameSite = '',
    public catchPhrase = '',
    public addressSite: MondoLocation = new MondoLocation(),
    public websiteUrl = '',
    public email = '',
    public phone = '',
    public youtubeId = '',
    public linkedin = '',
    public info = '',
    public subtypes: Subtypes[] = [],
    public coverUrl = '',
    public logoUrl = '',
    public cvr = '',
    public contactEmail = ''
  ) {}
}

export interface ISiteInfo {
  type: SiteType;
  pictureUrl: string;
  nameSite: string;
  catchPhrase: string;
  addressSite: MondoLocation;
  websiteUrl: string;
  email: string;
  phone: string;
  youtubeId: string;
  linkedin: string;
  info: string;
  subtypes: Subtypes[];
  coverUrl: string;
  logoUrl: string;
  cvr: string;
  contactEmail: string;
}
