<div class="container-action-man" *ngIf="actionCode && mode">
  <div *ngIf="mode === modes.emailVerify">
    <p>{{ 'pressBelowToValidateEmail' | preventHardcoding }}</p>
    <button
      *ngIf="!error"
      mat-raised-button
      color="primary"
      (click)="validateEmail()"
    >
      {{ 'validateEmail' | preventHardcoding }}
    </button>
    <div *ngIf="error || !(actionCode && mode)">
      <mat-error>{{
        'validationFailedResendEmail' | preventHardcoding
      }}</mat-error>
      <form [formGroup]="form" (ngSubmit)="resendValidationEmail()">
        <mat-form-field appearance="standard">
          <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
          <input
            type="email"
            name="email"
            matInput
            formControlName="email"
            required
          />
          <mat-error *ngIf="email?.errors?.email">{{
            'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>{{ 'password' | preventHardcoding }}</mat-label>
          <input type="password" matInput formControlName="password" required />
        </mat-form-field>
        <div class="footer">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!form.valid"
          >
            {{ 'resendValidationEmail' | preventHardcoding }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="mode === modes.passwordReset">
    <p>{{ 'enterNewPassword' | preventHardcoding }}.</p>
    <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'password' | preventHardcoding }}</mat-label>
        <input type="password" matInput formControlName="password1" required />
        <mat-error *ngIf="password1?.errors?.strong">{{
          'containLeastOneNumberAndOneCapitalLetter' | preventHardcoding
        }}</mat-error>
        <mat-error
          *ngIf="password1?.errors?.minlength || password1?.errors?.maxlength"
        >
          {{ 'mustBetween6And24Characters' | preventHardcoding }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'confirmPassword' | preventHardcoding }}</mat-label>
        <input type="password" matInput formControlName="password2" required />
        <mat-error *ngIf="password2?.errors?.strong">{{
          'containLeastOneNumberAndOneCapitalLetter' | preventHardcoding
        }}</mat-error>
        <mat-error
          *ngIf="password2?.errors?.minlength || password2?.errors?.maxlength"
        >
          {{ 'mustBetween6And24Characters' | preventHardcoding }}
        </mat-error>
      </mat-form-field>
      <div class="footer">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!isValidPassword()"
        >
          {{ 'resetPassword' | preventHardcoding }}
        </button>
        <mat-error *ngIf="password1.valid && !isValidPassword()">
          {{ 'ThePasswordsMustMatch' | preventHardcoding }}
        </mat-error>
      </div>
    </form>
  </div>
</div>
