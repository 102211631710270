import { Component, OnInit, Input } from '@angular/core';
import { MondoFormControl } from 'app/core/mondo-form-builder';
import { take } from 'rxjs/operators';
import { LanguageMultiSelector } from 'app/shared/models/languages/language-multiselector';
import { MatOptionSelectionChange } from '@angular/material/core';
import { StaticItemsService } from 'app/core/static-items.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit {
  selectedLanguage: LanguageMultiSelector;
  @Input() form: MondoFormControl<LanguageMultiSelector>;
  languages: LanguageMultiSelector[];
  constructor(private staticItemsService: StaticItemsService) { }

  ngOnInit() {
    this.staticItemsService
      .getLanguage$()
      .pipe(take(1))
      .subscribe(languages => {
        this.languages = languages;
        this.findAndSelectLanguage();
        this.form.registerOnChange(() => this.findAndSelectLanguage());
      });
  }

  private findAndSelectLanguage() {
    if (this.form.value && this.languages) {
      this.selectedLanguage = this.languages.find(
        c => c.id === (this.form.value as LanguageMultiSelector).id
      );
    }
  }

  onLanguageSelected(Language: LanguageMultiSelector) {
    this.selectedLanguage = Language;
    this.form.patchValue(Language);
  }

  onLanguageSelectionChanged(event: MatOptionSelectionChange, Language: LanguageMultiSelector) {
    if (event.source.selected) {
      this.onLanguageSelected(Language);
    }
  }

  trackByFn(index: number, item: LanguageMultiSelector) {
    return (item.id);
  }
}
