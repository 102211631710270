import { Injectable } from '@angular/core';
import { Job } from 'app/stepper/job/model/job';
import { CV } from 'app/shared/models/cv/cv';
import { hardcodedValues } from 'hardcodedValues';
import * as _ from 'lodash';
import { IBaseStepItem } from 'app/stepper/shared/model/IBaseStepItem';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class DirectMatchService {
  firstLoad = false;

  constructor(private db: DAO, private authService: AuthService) {}

  private createDraftDirectMatch(job: Job) {
    const dmCV = new CV();
    dmCV.name = `${job.company.name} - ${job.jobInfo.position} - ${hardcodedValues.directMatch}`;
    dmCV.ownerId = this.authService.getCurrentUser().uid;
    dmCV.key = job.key;
    return this.createDMInDB(dmCV, CV.toJson);
  }

  private createDMInDB<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any
  ): Promise<T> {
    const itemToCreate = _.cloneDeep(item);
    itemToCreate.created = new Date();
    return this.db
      .object(this.getDirectMatchPath(itemToCreate.key))
      .update(toJson(itemToCreate))
      .then(() => itemToCreate);
  }

  async getCurrentDraftDM(job: Job) {
    return (await this.exists(job.key))
      ? await this.getDraftDM(job.key)
      : await this.createDraftDirectMatch(job);
  }

  private getDraftDM(key: string) {
    return this.db
      .object$(this.getDirectMatchPath(key))
      .pipe(
        take(1),
        map((dm) => CV.fromJson(dm as any, dm['key']) as CV)
      )
      .toPromise();
  }

  createDirectMatch(cv: CV, job: Job) {
    const companyName = job.company.name;
    const jobTitle = job.jobInfo.position;
    const name = `${cv.personalDetails.academicTitle} ${cv.personalDetails.firstName} ${cv.personalDetails.lastName}`;
    // const dob = cv.personalDetails.dob ? cv.personalDetails.dob.getTime() : null;
    const email = cv.personalDetails.contactInfo.email;
    const phone = cv.personalDetails.contactInfo.phone;
    const education = cv.personalDetails.nationality;
    const Fois = cv.experience.interests;
    const Techs = cv.experience.techniques;
    const Category0 = cv.experience.category0;
    const Category1 = cv.experience.category1;
    const Category2 = cv.experience.category2;
    const Category3 = cv.experience.category3;
    const Category4 = cv.experience.category4;
    const Category5 = cv.experience.category5;
    const JobMatchDetails = cv.intro.text;
    const createdAt = new Date().getTime();
    const creatorId = this.authService.getCurrentUser().uid;
    const creatorName = this.authService.getCurrentUser().displayName;

    const directMatchRegistration = {
      companyName,
      jobTitle,
      name,
      education,
      JobMatchDetails,
      email,
      phone,
      // dob,
      Fois,
      Techs,
      Category0,
      Category1,
      Category2,
      Category3,
      Category4,
      Category5,
      createdAt,
      creatorId,
      creatorName,
    };

    return this.db
      .ref(`${DataConstants.DM_RECORDS}`)
      .push()
      .set(directMatchRegistration)
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((e) => {
        return Promise.reject('could not save DM record');
      });
  }

  createRegistrationVisit(job: Job) {
    const companyName = job.company.name;
    const companyEmail = job.company.email;
    const cvr = job.company.cvr;
    const formattedAddress = job.company.location.formattedAddress;
    const jobTitle = job.jobInfo.position;
    const jobType = job.type;
    const Fois = job.researchQualifications.wantedFieldOfInterest;
    const Techs = job.researchQualifications.wantedTechniques;
    const Category0 = job.researchQualifications.category0;
    // const Category1 = job.researchQualifications.category1;
    const createdAt = new Date().getTime();
    const creatorId = this.authService.getCurrentUser().uid;
    const creatorName = this.authService.getCurrentUser().displayName;

    const visitRegistration = {
      companyName,
      companyEmail,
      cvr,
      formattedAddress,
      jobTitle,
      jobType,
      Fois,
      Techs,
      Category0,
      // Category1,
      createdAt,
      creatorId,
      creatorName,
    };

    return this.db
      .ref(`${DataConstants.VISIT_RECORDS}`)
      .push()
      .set(visitRegistration)
      .then(() => {
        return Promise.resolve(visitRegistration);
      })
      .catch((e) => {
        return Promise.reject('could not save VISIT record');
      });
  }

  deleteDraftCV(key) {
    return this.db.object(this.getDirectMatchPath(key)).remove();
  }

  getDMRecords() {
    return this.db.list$(DataConstants.DM_RECORDS);
  }

  getVisitRecords() {
    return this.db.list$(DataConstants.VISIT_RECORDS);
  }

  private exists(key) {
    return this.db
      .object(this.getDirectMatchPath(key))
      .snapshotChanges()
      .pipe(
        take(1),
        map((snapshot) => snapshot.payload.exists())
      )
      .toPromise();
  }

  private getDirectMatchPath(key: string) {
    return DataConstants.DRAFT_DMS + key;
  }

  updateDraftCv(cv: CV): Promise<void> {
    if (!this.firstLoad) {
      this.firstLoad = true;
      return;
    }
    return this.update(cv, CV.toJson);
  }

  update<T extends IBaseStepItem>(
    item: T,
    toJson: (item: T) => any
  ): Promise<void> {
    if (item && item.key) {
      item.lastUpdate = new Date();
      return this.db
        .object(this.getDirectMatchPath(item.key))
        .update(toJson(item));
    } else {
      return;
    }
  }

  minRequirementForPublish(cv: CV) {
    let text = '';
    if (this.checkFirstName(cv)) {
      text += '⛔ ' + hardcodedValues.youHaveToAddFirstName + '\n';
    }
    if (this.checkLastName(cv)) {
      text += '⛔ ' + hardcodedValues.youHaveToAddSurname + '\n';
    }
    if (this.checkDMDetails(cv)) {
      text += `⛔ ${hardcodedValues.JobMatchDetails} ${hardcodedValues.required}\n`;
      // } if (this.checkAddress(cv)) {
      //   text += `⛔ ${hardcodedValues.cvAddress} ${hardcodedValues.required}\n`;
      // } if (!cv.experience.educations.length) {
      //   text += '⛔ ' + hardcodedValues.youHaveToAddLeast1Education + '\n';
      // } if (!cv.experience.interests.length) {
      //   text += '⛔ ' + hardcodedValues.youHaveToAddLeast1Foi + '\n';
    }
    // if (text.length > 0) {
    //   this.authService.notEnoughPermission(text);
    //   return false;
    // } else {
    //   return true;
    // }
    return text;
  }

  private checkFirstName(cv: CV): boolean {
    return !cv.personalDetails.firstName.trim();
  }

  private checkLastName(cv: CV): boolean {
    return !cv.personalDetails.lastName.trim();
  }

  private checkDMDetails(cv: CV): boolean {
    return !cv.intro.text.length;
  }
}
