import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultiSelectorItem } from 'app/shared/models';
import * as _ from 'lodash';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MondoFormBuilder } from '../../../core/mondo-form-builder';
import { MultiSelectorOption } from './multi-selector.model';

@Component({
  selector: 'app-multi-selector',
  templateUrl: './multi-selector.component.html',
  styleUrls: ['./multi-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectorComponent implements OnInit, OnDestroy {
  @Input() focusOnMouseOver = false;
  @Input() readonly = false;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  scrollbarDirective?: PerfectScrollbarDirective;
  @ViewChild('searchResultList', { static: false })
  public searchWrapper: ElementRef;
  @ViewChild('searchInput', { static: false }) public searchInput: ElementRef;
  @ViewChild('selector', { static: false })
  public searchSelectorWrap: ElementRef;

  @Input() public placeholderText: string;
  @Input() public dataStream: Observable<any[]>;
  @Input() public patchStream: Observable<any[]>;
  @Input() public form: FormGroup;
  @Input() public controlName: string;
  @Input() public showSelectedItems = true;
  @Input() restrictSelection = false;
  @Input() maxSelection = 1;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  destroy$: Subject<boolean> = new Subject();
  private _disabled = false;
  public selection: MultiSelectorOption[] = [];
  public data: MultiSelectorOption[] = [];
  public filteredData: MultiSelectorOption[] = [];
  public searchForm: FormGroup;

  private dataSet: Map<string, MultiSelectorItem> = new Map<
    string,
    MultiSelectorItem
  >();

  private throttledUpdate = _.throttle(this.updateFormControl, 100);

  constructor(
    private renderer: Renderer2,
    private formBuilder: MondoFormBuilder,
    private changeDetector: ChangeDetectorRef,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      query: [{ value: '', disabled: this.disabled }],
    });

    this.searchForm.valueChanges
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => {
        const queryString = this.getSearchQuery().toLowerCase();
        this.filteredData = this.data.filter((option) => {
          return (
            option.text.toLowerCase().indexOf(queryString) !== -1 ||
            (option.description
              ? option.description.toLowerCase().indexOf(queryString) !== -1
              : false)
          );
        });
        this.scrollbarDirective.update();
        this.changeDetector.detectChanges();
      });

    const formControl = this.form.get(this.controlName);
    if (
      formControl &&
      formControl.value &&
      formControl.value instanceof Array
    ) {
      const initialSelection = formControl.value;
      this.dataStream
        .pipe(take(1))
        .subscribe((changeSet: MultiSelectorItem[]) => {
          this.dataSet.clear();
          this.data = changeSet.reduce((result, item: MultiSelectorItem) => {
            if (item && item.getId()) {
              this.dataSet.set(item.getId(), item);
              result.push(this.mapToOption(item));
            }
            return result;
          }, []);

          initialSelection.forEach((item) => {
            if (this.dataSet.get(item.uid || item.id)) {
              this.select(
                this.mapToOption(this.dataSet.get(item.uid || item.id))
              );
            }
          });
        });

      if (this.patchStream) {
        this.patchStream
          .pipe(takeUntil(this.destroy$))
          .subscribe((patch: MultiSelectorItem[]) => {
            if (patch) {
              this.selection = patch.reduce((result, item) => {
                if (item && item.getId()) {
                  result.push(this.mapToOption(item));
                }
                return result;
              }, []);
            }
          });
      }
    }
  }

  openSelector() {
    if (this.focusOnMouseOver) {
      this.searchInput.nativeElement.focus();
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  private mapToOption(item: MultiSelectorItem): MultiSelectorOption {
    return {
      value: item.getId(),
      text: item.getDisplayText(),
      description: item.getDescription(),
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getDisplayText(option: MultiSelectorOption): string {
    if (this.isSearchResult(option)) {
      return this.getHighlightedText(option.text);
    }
    return option.text;
  }

  public getDescriptionText(option: MultiSelectorOption): string {
    const descText = option && option.description ? option.description : '';
    return descText && this.isSearchResult(option)
      ? this.getHighlightedText(descText)
      : descText;
  }

  private isSearchResult(option: MultiSelectorOption): boolean {
    return !!this.filteredData.find(
      (filteredOption) => option.value === filteredOption.value
    );
  }

  private getHighlightedText(text: string): string {
    const reg = new RegExp(_.escapeRegExp(this.getSearchQuery()), 'gi');
    return text.replace(
      reg,
      (str) =>
        `<span style="display:inline;background-color:#fbbc05;">${str}</span>`
    );
  }

  public showSearchResults(): void {
    if (
      this.searchWrapper &&
      this.searchSelectorWrap &&
      this.searchInput &&
      !this.readonly
    ) {
      const positionOffset =
        this.searchSelectorWrap.nativeElement.offsetTop +
        this.searchSelectorWrap.nativeElement.offsetHeight -
        (this.searchInput.nativeElement.offsetHeight + 30) / 2;
      this.renderer.setStyle(
        this.searchWrapper.nativeElement,
        'top',
        `${positionOffset}px`
      );
      this.renderer.setStyle(
        this.searchWrapper.nativeElement,
        'display',
        'block'
      );
    }
  }

  public handleKeyPress(event): void {
    if (event.code === 'Escape') {
      this.searchInput.nativeElement.blur();
    }
  }

  public hideSearchResults(event): void {
    if (
      event &&
      event.relatedTarget &&
      event.relatedTarget.id === 'search-result-list'
    ) {
      return;
    }

    if (this.searchWrapper) {
      this.renderer.setStyle(
        this.searchWrapper.nativeElement,
        'display',
        'none'
      );
    }
  }

  public selectTag(result: MultiSelectorOption) {
    this.debouncer(this.select(result), 500);
  }

  public select(result: MultiSelectorOption): void {
    if (this.readonly) {
      return;
    }
    if (this.isSelected(result)) {
      this.remove(result);
    } else if (this.restrictSelection) {
      if (this.selection.length < this.maxSelection) {
        this.add(result);
      } else {
        this.snackBar.open(`Max: ${this.maxSelection}`, null, {
          duration: 3000,
          panelClass: ['snackbar-position-center'],
        });
      }
    } else {
      this.add(result);
    }
  }

  private debouncer = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  public add(result: MultiSelectorOption): void {
    this.selection.push(result);
    this.throttledUpdate();
  }

  public remove(result: MultiSelectorOption): void {
    this.selection = this.selection.filter(
      (option) => option.value !== result.value
    );
    this.throttledUpdate();
  }

  private updateFormControl(): void {
    const mappedItems = this.selection.reduce((result, option) => {
      const value = this.dataSet.get(option.value);
      if (value) {
        result.push(this.formBuilder.group(value));
      }
      return result;
    }, []);
    this.form.setControl(this.controlName, this.formBuilder.array(mappedItems));
  }

  public isSelected(result: MultiSelectorOption): boolean {
    return !!this.selection.find((option) => option.value === result.value);
  }

  public getSearchResults(): MultiSelectorOption[] {
    if (this.filteredData.length === 0 && !this.getSearchQuery()) {
      return this.data;
    } else if (this.filteredData.length > 0) {
      return this.filteredData;
    } else {
      return [];
    }
  }

  private getSearchQuery(): string {
    return this.searchForm.get('query').value;
  }

  public alphabeticalSort(
    arr: Array<MultiSelectorOption>
  ): Array<MultiSelectorOption> {
    return arr.sort((a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      } else if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }

      return 0;
    });
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
