import { throwError, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataConstants } from '../shared/consts/dataConstants';
import {
  NetworkSubtypes,
  FieldOfInterest,
  Technique,
  Subtypes,
  PlaceType,
  Category0,
  Category1,
} from 'app/shared/models';
import { LanguageMultiSelector } from 'app/shared/models/languages/language-multiselector';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class StaticItemsService {
  private networkCache$: Observable<Array<NetworkSubtypes>>;
  private siteCache$: Observable<Array<Subtypes>>;
  private placeCache$: Observable<Array<PlaceType>>;
  private fieldOfInterestCache$: Observable<Array<FieldOfInterest>>;
  private languageCache$: Observable<Array<LanguageMultiSelector>>;
  private techniqueCache$: Observable<Array<Technique>>;
  private categories0Cache$: Observable<Array<Category0>>;
  private categories1Cache$: Observable<Array<Category1>>;
  private categories2Cache$: Observable<Array<Category0>>;
  private categories3Cache$: Observable<Array<Category0>>;
  private categories4Cache$: Observable<Array<Category0>>;
  private categories5Cache$: Observable<Array<Category0>>;

  constructor(private db: DAO) {}

  public getNetworkSubtypes$(): Observable<Array<NetworkSubtypes>> {
    if (!this.networkCache$) {
      this.networkCache$ = this.requestNetworkSubtypes();
      // this.requestItems<NetworkSubtypes>(DataConstants.NETWORKSUBTYPES, 'Network types', NetworkSubtypes);
    }
    return this.networkCache$;
  }

  public getSiteSubtypes$(): Observable<Array<Subtypes>> {
    if (!this.siteCache$) {
      this.siteCache$ = this.requestSiteSubtypes();
    }
    return this.siteCache$;
  }

  public getPlaceType$(): Observable<Array<PlaceType>> {
    if (!this.placeCache$) {
      this.placeCache$ = this.requestItems<PlaceType>(
        DataConstants.PLACE_SUBTYPES,
        'Place types',
        PlaceType
      );
    }
    return this.placeCache$;
  }

  public getFieldOfInterest$(): Observable<Array<FieldOfInterest>> {
    if (!this.fieldOfInterestCache$) {
      this.fieldOfInterestCache$ = this.requestFieldOfInterests();
    }
    return this.fieldOfInterestCache$;
  }

  public getLanguage$(): Observable<Array<LanguageMultiSelector>> {
    if (!this.languageCache$) {
      this.languageCache$ = this.requestLanguages();
    }
    return this.languageCache$;
  }

  public getTechniques$(): Observable<Array<Technique>> {
    if (!this.techniqueCache$) {
      this.techniqueCache$ = this.requestTechniques();
    }
    return this.techniqueCache$;
  }

  public getCategories0$(): Observable<Array<Category0>> {
    if (!this.categories0Cache$) {
      this.categories0Cache$ = this.requestCategories0();
    }
    return this.categories0Cache$;
  }

  public getCategories1$(): Observable<Array<Category1>> {
    if (!this.categories1Cache$) {
      this.categories1Cache$ = this.requestCategories1();
    }
    return this.categories1Cache$;
  }

  public getCategories2$(): Observable<Array<Category0>> {
    if (!this.categories2Cache$) {
      this.categories2Cache$ = this.requestCategories2();
    }
    return this.categories2Cache$;
  }
  public getCategories3$(): Observable<Array<Category0>> {
    if (!this.categories3Cache$) {
      this.categories3Cache$ = this.requestCategories3();
    }
    return this.categories3Cache$;
  }
  public getCategories4$(): Observable<Array<Category0>> {
    if (!this.categories4Cache$) {
      this.categories4Cache$ = this.requestCategories4();
    }
    return this.categories4Cache$;
  }
  public getCategories5$(): Observable<Array<Category0>> {
    if (!this.categories5Cache$) {
      this.categories5Cache$ = this.requestCategories5();
    }
    return this.categories5Cache$;
  }

  private requestItems<T>(path, msg, item) {
    return this.db
      .list(path, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((items) => {
          return items.map((type: any) => {
            return new item(type.payload.val().name, type.key);
          });
        }),
        catchError(() => throwError(`Unable to fetch ${msg} subtypes!`))
      );
  }

  private requestFieldOfInterests(): Observable<Array<FieldOfInterest>> {
    return this.db
      .list(DataConstants.INTERESTS, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((fieldOfInterests: any) => {
          return fieldOfInterests.map((fieldOfInterest) => {
            return new FieldOfInterest(
              fieldOfInterest.key,
              fieldOfInterest.payload.val().name,
              fieldOfInterest.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Field Of Interests!'))
      );
  }

  private requestLanguages(): Observable<Array<LanguageMultiSelector>> {
    return this.db
      .list(DataConstants.LANGUAGES, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((languages: any) => {
          return languages.map((language) => {
            return new LanguageMultiSelector(
              language.payload.val().id,
              language.payload.val().name,
              language.payload.val().nativeName,
              language.payload.val().iso639_2
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Languages!'))
      );
  }

  private requestTechniques(): Observable<Array<Technique>> {
    return this.db
      .list(DataConstants.TECHNIQUES, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((techniques) => {
          return techniques.map((tech: any) => {
            return new Technique(
              tech.payload.val().name,
              tech.key,
              tech.payload.val().category
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Techniques!'))
      );
  }

  private requestCategories0(): Observable<Array<Category0>> {
    return this.db
      .list(DataConstants.CATEGORIES0, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category0(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories0!'))
      );
  }

  private requestCategories1(): Observable<Array<Category1>> {
    return this.db
      .list(DataConstants.CATEGORIES1, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category1(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories1!'))
      );
  }

  private requestCategories2(): Observable<Array<Category0>> {
    return this.db
      .list(DataConstants.CATEGORIES2, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category0(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories2!'))
      );
  }

  private requestCategories3(): Observable<Array<Category0>> {
    return this.db
      .list(DataConstants.CATEGORIES3, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category0(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories3!'))
      );
  }

  private requestCategories4(): Observable<Array<Category0>> {
    return this.db
      .list(DataConstants.CATEGORIES4, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category0(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories4!'))
      );
  }

  private requestCategories5(): Observable<Array<Category0>> {
    return this.db
      .list(DataConstants.CATEGORIES5, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((categories) => {
          return categories.map((cats: any) => {
            return new Category0(
              cats.key,
              cats.payload.val().name,
              cats.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch Categories5!'))
      );
  }

  private requestSiteSubtypes(): Observable<Array<Subtypes>> {
    return this.db
      .list(DataConstants.SITE_SUBTYPES, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((siteSubtypes) => {
          return siteSubtypes.map((siteSubtype: any) => {
            return new Subtypes(
              siteSubtype.key,
              siteSubtype.payload.val().name,
              siteSubtype.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch SiteSubtypes'))
      );
  }

  private requestNetworkSubtypes(): Observable<Array<NetworkSubtypes>> {
    return this.db
      .list(DataConstants.NETWORKSUBTYPES, (ref) => ref.orderByChild('name'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((networkSubtypes) => {
          return networkSubtypes.map((networkSubtype: any) => {
            return new NetworkSubtypes(
              networkSubtype.key,
              networkSubtype.payload.val().name,
              networkSubtype.payload.val().desc
            );
          });
        }),
        catchError(() => throwError('Unable to fetch subtypes'))
      );
  }
}
