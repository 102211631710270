import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { MondoError, MondoErrorTypes } from 'app/shared/models/MondoError';
import { AccountType } from 'app/shared/consts/accountType';
import { StrictEmailValidator } from '@helpers';
import { MondoRoutes } from 'app/app.routing-model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  showEmailSentMsg = false;
  error: boolean;
  loginForm: FormGroup;
  loginInProgress = false;
  errorMsg: string;
  showResetForm = false;
  showResendVerificationMail = false;
  waitingForVerify = false;
  sendingVerification = false;
  accountType: AccountType;

  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    private routingService: RoutingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const accountType = this.route.snapshot.paramMap.get('accountType');
    this.accountType = accountType ? +accountType : AccountType.scientist;
    this.setupForm();
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get canSignup() {
    return MondoRoutes.signup;
  }

  private setupForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, StrictEmailValidator]],
      password: ['', [Validators.required]],
    });
  }

  public login() {
    this.loginInProgress = true;
    this.error = false;
    this.authService
      .emailLogin(this.email.value, this.password.value)
      .then(() => {
        this.error = false;
        this.loginInProgress = false;
      })
      .catch((error: MondoError) => {
        this.error = true;
        this.errorMsg = error.displayText;
        this.loginInProgress = false;
        if (error.errorType === MondoErrorTypes.USER_NOT_VERIFIED) {
          this.showResendVerificationMail = true;
        }
      })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }

  public sendVerificationEmail() {
    if (this.sendingVerification) {
      return;
    } else {
      this.sendingVerification = true;
      this.authService
        .resendVerificationMail(this.email.value, this.password.value)
        .then(() => {
          this.showResendVerificationMail = false;
          this.showEmailSentMsg = true;
          this.cdr.detectChanges();
        })
        .catch(() => {
          this.sendingVerification = false;
        });
    }
  }

  public onResetPasswordClicked(): void {
    this.showResetForm = true;
  }

  public hideResetPassword(): void {
    this.showResetForm = false;
  }

  signup() {
    this.routingService.navigateToUrlWithDataArray(MondoRoutes.signup, [
      this.accountType,
    ]);
  }
}
