import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import 'hammerjs';
import { CKEditorModule } from 'ng2-ckeditor';
import { environment } from '../../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MultiSelectorComponent } from './components/multi-selector/multi-selector.component';
import { SuggestFieldOfInterestDialogComponent } from './components/suggest-field-of-interest-dialog/suggest-field-of-interest-dialog.component';
import { SuggestTechniqueDialogComponent } from './components/suggest-technique-dialog/suggest-technique-dialog.component';
import { ScrollableDirective, DebounceClickDirective } from './directives';
import { ShortenFirstCharInEveryWordPipe } from './pipes/shorten-first-char-in-every-word.pipe';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UrlSizeLimiterPipe, FileSizePipe } from './pipes';
import { AddressSelectorComponent } from './components/address-selector/address-selector.component';
import { MapToIterablePipe } from './pipes/map-to-iterable.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { PluralPipe } from './pipes/pluralize.pipe';
import { IconTitleComponent } from './components/icon-title/icon-title.component';
import { ViewerLanguagesComponent } from '../stepper/shared/components/viewer-languages/viewer-languages.component';
import { AcaRangeComponent } from './components/aca-range/aca-range.component';
import { BugReportComponent } from './components/bug-report/bug-report.component';
import { DropZoneDirective } from './directives/dropzone';
import { UserStatusComponent } from './components/user-status/user-status.component';
import { ScoringBarComponent } from './components/scoring-bar/scoring-bar.component';
import { CreateCustomerComponent } from './components/fenerum/create-customer/create-customer.component';
import { PlanSelectorComponent } from './components/fenerum/plan-selector/plan-selector.component';
import { CreateSubscriptionComponent } from './components/fenerum/create-subscription/create-subscription.component';
import { CreateRecipientComponent } from './components/fenerum/create-recipient/create-recipient.component';
import { PubmedUrlPipe } from './pipes/pubmed-url.pipe';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { LastSeenAsyncPipe } from './pipes/last-seen-async.pipe';
import { JobApplicationComponent } from './components/ac/job-application/job-application.component';
import { JobApplicationHelperComponent } from './components/ac/job-application-helper/job-application-helper.component';
import { InviteComponent } from './components/invite/invite.component';
import { ProfileProgressTrackerComponent } from './components/profile-progress-tracker/profile-progress-tracker.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { PublishCvComponent } from './components/publish-cv/publish-cv.component';
import { RequiredTextCvComponent } from './components/required-text-cv/required-text-cv.component';
import { SiteViewerComponent } from './components/site-viewer/site-viewer.component';
import { SiteViewerCoverComponent } from './components/site-viewer/site-viewer-cover/site-viewer-cover.component';
import { SiteViewerHeaderComponent } from './components/site-viewer/site-viewer-header/site-viewer-header.component';
import { SiteViewerContentComponent } from './components/site-viewer/site-viewer-content/site-viewer-content.component';
import { SiteViewerTechniqueComponent } from './components/site-viewer/site-viewer-technique/site-viewer-technique.component';
import { SiteViewerFooterComponent } from './components/site-viewer/site-viewer-footer/site-viewer-footer.component';
import { NewActionBarComponent } from './components/new-action-bar/new-action-bar.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NotesComponent } from './components/notes/notes.component';
import { NotesViewerComponent } from './components/notes-viewer/notes-viewer.component';
import { NoteComponent } from './components/note/note.component';
import { NotesDialogComponent } from './components/notes-dialog/notes-dialog.component';
import { MatTreeComponent } from './components/mat-tree/mat-tree.component';
import { PlaceCardComponent } from './components/place-card/place-card.component';
import { PlaceCardHeaderComponent } from './components/place-card/place-card-header/place-card-header.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { FooterComponent } from './components/footer/footer.component';
import { AccountPermissionPipe } from './pipes/account-permission.pipe';
import { DirectMatchComponent } from './components/direct-match/direct-match.component';
import { DirectMatchBuilderComponent } from './components/direct-match-builder/direct-match-builder.component';
import { SuggestCategory0DialogComponent } from './components/suggest-category0-dialog/suggest-category0-dialog.component';
import { SuggestCategory1DialogComponent } from './components/suggest-category1-dialog/suggest-category1-dialog.component';
import { SuggestCompanySubtypeDialogComponent } from './components/suggest-company-subtype-dialog/suggest-company-subtype-dialog.component';
import { SuggestNetworkSubtypeDialogComponent } from './components/suggest-network-subtype-dialog/suggest-network-subtype-dialog.component';
import { SignUpComponent } from './components/signup/signup.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RelatedJobsComponent } from './components/related-jobs/related-jobs.component';
import { JobViewerComponent } from './components/job-viewer/job-viewer.component';
import { JobViewerHeaderComponent } from './components/job-viewer/job-viewer-header/job-viewer-header.component';
import { JobViewerAboutComponent } from './components/job-viewer/job-viewer-about/job-viewer-about.component';
import { JobViewerContactComponent } from './components/job-viewer/job-viewer-contact/job-viewer-contact.component';
import { JobViewerEmploymentPeriodComponent } from './components/job-viewer/job-viewer-employment-period/job-viewer-employment-period.component';
import { JobViewerQualificationsComponent } from './components/job-viewer/job-viewer-qualifications/job-viewer-qualifications.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SpecificListMapComponent } from './components/specific-list-map/specific-list-map.component';
import { FilterCvsPipe } from './pipes/filter-cvs.pipe';
import { ParseDAO } from './common/parseDAO';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
import { LeaveCvDialogComponent } from './components/leave-cv-dialog/leave-cv-dialog.component';
import { SuggestCategory3DialogComponent } from './components/suggest-category3-dialog/suggest-category3-dialog.component';
import { SsoComponent } from './components/sso/sso.component';
import { SuggestCategory5DialogComponent } from './components/suggest-category5-dialog/suggest-category5-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialSharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    CKEditorModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    AngularFireFunctionsModule,
    YouTubePlayerModule,
    InfiniteScrollModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places'],
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgGridModule.withComponents([]),
    SharedPipesModule,
    SharedUiModule,
    SharedDirectivesModule,
  ],
  exports: [
    AgmCoreModule,
    GoogleMapsModule,
    CommonModule,
    AngularMaterialSharedModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    LoginComponent,
    UrlSizeLimiterPipe,
    FileSizePipe,
    ShortenFirstCharInEveryWordPipe,
    MapToIterablePipe,
    FilterUsersPipe,
    FilterCvsPipe,
    ReversePipe,
    EnumToArrayPipe,
    PluralPipe,
    PubmedUrlPipe,
    AccountPermissionPipe,
    PageNotFoundComponent,
    NavbarComponent,
    ResetPasswordComponent,
    FlexLayoutModule,
    PerfectScrollbarModule,
    CKEditorModule,
    MultiSelectorComponent,
    ScrollableDirective,
    ReactiveFormsModule,
    TextEditorComponent,
    AddressSelectorComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    IconTitleComponent,
    ViewerLanguagesComponent,
    DebounceClickDirective,
    AcaRangeComponent,
    BugReportComponent,
    RouterModule,
    UserStatusComponent,
    ScoringBarComponent,
    CreateCustomerComponent,
    PlanSelectorComponent,
    CreateSubscriptionComponent,
    CreateRecipientComponent,
    UploadTaskComponent,
    UploaderComponent,
    LastSeenAsyncPipe,
    JobApplicationComponent,
    JobApplicationHelperComponent,
    InviteComponent,
    ProfileProgressTrackerComponent,
    CircleProgressComponent,
    PublishCvComponent,
    SiteViewerComponent,
    SiteViewerCoverComponent,
    SiteViewerHeaderComponent,
    SiteViewerContentComponent,
    SiteViewerTechniqueComponent,
    SiteViewerFooterComponent,
    NewActionBarComponent,
    YouTubePlayerModule,
    NotesComponent,
    MatTreeComponent,
    PlaceCardComponent,
    PlaceCardHeaderComponent,
    ChangeEmailComponent,
    FooterComponent,
    DirectMatchComponent,
    DirectMatchBuilderComponent,
    SignUpComponent,
    RelatedJobsComponent,
    JobViewerComponent,
    CalendarComponent,
    SpecificListMapComponent,
    AgGridAngular,
  ],
  declarations: [
    LoginComponent,
    UrlSizeLimiterPipe,
    FileSizePipe,
    PageNotFoundComponent,
    NavbarComponent,
    ResetPasswordComponent,
    MultiSelectorComponent,
    ScrollableDirective,
    SuggestFieldOfInterestDialogComponent,
    SuggestTechniqueDialogComponent,
    ShortenFirstCharInEveryWordPipe,
    TextEditorComponent,
    ConfirmDialogComponent,
    AddressSelectorComponent,
    MapToIterablePipe,
    DateRangePickerComponent,
    DatePickerComponent,
    FilterUsersPipe,
    FilterCvsPipe,
    ReversePipe,
    EnumToArrayPipe,
    PluralPipe,
    IconTitleComponent,
    ViewerLanguagesComponent,
    DebounceClickDirective,
    AcaRangeComponent,
    BugReportComponent,
    DropZoneDirective,
    UserStatusComponent,
    ScoringBarComponent,
    CreateCustomerComponent,
    PlanSelectorComponent,
    CreateSubscriptionComponent,
    CreateRecipientComponent,
    PubmedUrlPipe,
    UploadTaskComponent,
    UploaderComponent,
    LastSeenAsyncPipe,
    JobApplicationComponent,
    JobApplicationHelperComponent,
    InviteComponent,
    ProfileProgressTrackerComponent,
    CircleProgressComponent,
    PublishCvComponent,
    RequiredTextCvComponent,
    SiteViewerComponent,
    SiteViewerCoverComponent,
    SiteViewerHeaderComponent,
    SiteViewerContentComponent,
    SiteViewerTechniqueComponent,
    SiteViewerFooterComponent,
    NewActionBarComponent,
    NotesComponent,
    NotesViewerComponent,
    NoteComponent,
    NotesDialogComponent,
    MatTreeComponent,
    PlaceCardComponent,
    PlaceCardHeaderComponent,
    ChangeEmailComponent,
    FooterComponent,
    AccountPermissionPipe,
    DirectMatchComponent,
    DirectMatchBuilderComponent,
    SuggestCategory0DialogComponent,
    SuggestCategory1DialogComponent,
    SuggestCategory3DialogComponent,
    SuggestCategory5DialogComponent,
    SuggestCompanySubtypeDialogComponent,
    SuggestNetworkSubtypeDialogComponent,
    SignUpComponent,
    RelatedJobsComponent,
    JobViewerComponent,
    JobViewerHeaderComponent,
    JobViewerAboutComponent,
    JobViewerContactComponent,
    JobViewerEmploymentPeriodComponent,
    JobViewerQualificationsComponent,
    CalendarComponent,
    SpecificListMapComponent,
    LeaveCvDialogComponent,
    SsoComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    PlanSelectorComponent,
    CreateSubscriptionComponent,
    CreateCustomerComponent,
    CreateRecipientComponent,
    JobApplicationComponent,
    JobApplicationHelperComponent,
    PublishCvComponent,
    NotesDialogComponent,
    DirectMatchComponent,
    SuggestCompanySubtypeDialogComponent,
    SuggestCategory0DialogComponent,
    SuggestCategory1DialogComponent,
    SuggestCategory3DialogComponent,
    SuggestCategory5DialogComponent,
    SuggestFieldOfInterestDialogComponent,
    SuggestNetworkSubtypeDialogComponent,
    SuggestTechniqueDialogComponent,
    JobViewerComponent,
    LeaveCvDialogComponent,
  ],
  providers: [
    ParseDAO,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: REGION,
      useValue: environment.region,
    },
    // { provide: FUNCTIONS_ORIGIN, useValue: 'https://academondodemo.web.app' },
  ],
})
export class SharedModule {}
