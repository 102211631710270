import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  NgZone,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MondoRoutes } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { MondoStep } from 'app/stepper/shared/model/MondoStep';
import { fadeInAnimation } from 'app/_animations/fadeInAnimation';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { MondoFormGroup } from '../../../../core/mondo-form-builder';
import { IBaseStepItem } from '../../model/IBaseStepItem';
import { CvStepIndex } from '../../model/stepIndex';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { inRange } from '@helpers';

@Component({
  selector: 'app-stepper-container',
  templateUrl: './stepper-container.component.html',
  styleUrls: ['./stepper-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class StepperContainerComponent<T>
  implements OnInit, AfterContentInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild(PerfectScrollbarComponent, {
    read: PerfectScrollbarComponent,
    static: false,
  })
  scrollComponent?: PerfectScrollbarComponent;

  @Output() stepChanged = new EventEmitter<CvStepIndex>();

  @Input() steps: MondoStep[];
  @Input() item: IBaseStepItem;
  @Input() form: MondoFormGroup<T>;
  @Input() urlRoot: string;
  @Input() key: string;
  @Input() hideAdditionalSteps = true;
  @Input() vertical = true;

  selectedIndex = 0;
  previousSelectedIndex = 0;
  hasSelectedStep = false;
  onClickEvent: JQuery<HTMLElement>;
  destroy$: Subject<boolean> = new Subject();
  constructor(
    private routingService: RoutingService,
    private route: ActivatedRoute,
    private element: ElementRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (this.route.firstChild) {
      this.route.firstChild.params
        .pipe(takeUntil(this.destroy$))
        .subscribe((params) => {
          this.selectedIndex = +params['step'] || 0;
          this.stepChanged.emit(this.selectedIndex);
        });
    } else {
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        this.selectedIndex = +params.step || 0;
        this.stepChanged.emit(this.selectedIndex);
      });
    }
  }

  ngAfterViewInit() {
    this.registerClickListenerOnSteps();
    this.scrollToStep(this.selectedIndex);
  }

  ngAfterContentInit() {
    this.scrollToStep(this.selectedIndex);
  }

  ngOnDestroy(): void {
    if (this.onClickEvent) {
      this.onClickEvent.unbind();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.steps && this.item && this.form) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.hideLastStep();
          this.registerClickListenerOnSteps();
        }, 200);
      });
    }
  }

  onSelectionChanged(index: number) {
    this.previousSelectedIndex = this.selectedIndex;
    this.selectedIndex = index;
    this.hasSelectedStep = true;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => (this.hasSelectedStep = false), 100);
    });
    this.scrollToStep(index);
    const key = this.key ? this.key : this.item.key;
    // ngZone warning became from upgrade to angular 8
    if (this.urlRoot) {
      this.ngZone.run(() =>
        this.routingService.navigateToRoute(this.urlRoot, [key, index])
      );
      return this.ngZone.runOutsideAngular(() => {
        return setTimeout(() => {
          this.previousSelectedIndex = -1;
        }, 2000);
      });
    }
  }

  private scrollToStep(index: number) {
    if (index === this.numberOfSteps) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const stepNext = $(this.element.nativeElement).find(
          '.mat-vertical-stepper-header[tabindex="0"]'
        )[0];
        if (stepNext) {
          const offset = 0;
          const scroll = stepNext.offsetTop + offset;
          this.scrollComponent.directiveRef.scrollTo(0, scroll, 500);
        }
      }, 500);
    });
  }

  private hideLastStep() {
    const lastStep = $(this.element.nativeElement).find(
      '.mat-stepper-vertical .mat-step:last-child'
    );
    const previousStep = lastStep.prev('.mat-step');
    const previousStepVerticalLineDiv = previousStep.find(
      '.mat-vertical-content-container'
    );
    previousStepVerticalLineDiv.removeClass('mat-stepper-vertical-line');
    lastStep.css('display', 'none');
  }

  private registerClickListenerOnSteps() {
    const headerElements = $(this.element.nativeElement).find(
      '.mat-stepper-vertical .mat-step .mat-vertical-stepper-header'
    );
    this.onClickEvent = headerElements.click(this.hasSelectedStep, (event) => {
      if (!this.hasSelectedStep) {
        this.selectedIndex = this.numberOfSteps;
      }
    });
  }

  stepClicked(stepIndex) {}

  get numberOfSteps() {
    return this.steps ? this.steps.length : 0;
  }

  showStepContent(index: number): boolean {
    if (this.hideAdditionalSteps) {
      return (
        inRange(index, this.selectedIndex - 2, this.selectedIndex + 2) ||
        index === this.previousSelectedIndex
      );
    } else {
      return true;
    }
  }

  trackByFn(index, item) {
    return item.title;
  }
}
