import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-step-base',
  templateUrl: './step-base.component.html',
  styleUrls: ['./step-base.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepBaseComponent<T> implements AfterViewInit, OnChanges {
  @Input() contentComponent: any;
  @Input() properties: Map<string, any>;
  @Input() open: boolean;
  @Input() hideFooterBtns;
  @Input()
  vertical = true;
  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: false })
  dynamicComponent: ViewContainerRef;
  componentCreated = false;

  constructor(
    private componentResolver: ComponentFactoryResolver,
    private ngZone: NgZone
  ) {}

  ngAfterViewInit(): void {
    if (!this.componentCreated) {
      this.createComponent();
    }
  }

  private createComponent() {
    this.componentCreated = true;
    const factory = this.componentResolver.resolveComponentFactory(
      this.contentComponent
    );
    setTimeout(() => {
      const ref = this.dynamicComponent.createComponent(factory);
      if (this.properties) {
        this.properties.forEach((value, key) => {
          (ref.instance as any)[key] = value;
        });
      }
    }, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.open &&
      !changes.open.previousValue &&
      changes.open.currentValue
    ) {
      if (!this.componentCreated) {
        this.createComponent();
      }
    }
  }
}
