import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { acaConfig } from 'aca-config';
import { MondoFormControl } from 'app/core/mondo-form-builder';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements OnInit, OnDestroy {
  @Input() textFormControl: MondoFormControl<string>;
  @Input() textEditorFormControlName: string;
  @Input() startupExpanded = true;
  isShowEditor = true;
  popOut = false;
  private editorConfiguration;

  private createConfig() {
    return {
      contentsCss: [
        // '/src/assets/skins/lightgray/content.min.css',
        // '/src/app/shared/components/text-editor/text-editor.component.scss'
      ],
      language: acaConfig.languageCKEditor,
      disableNativeSpellChecker: false,
      width: '100%',
      toolbarCanCollapse: true,
      extraPlugins: 'autogrow,divarea',
      autoGrow_onStartup: true,
      removePlugins: 'elementspath,wysiwygarea', // autoGrow_bottomSpace: 1,
      resize_enabled: false,
      toolbarStartupExpanded: this.startupExpanded,
      autoGrow_maxHeight: 5,
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        {
          name: 'editing',
          groups: ['find', 'selection', 'spellchecker', 'editing'],
        },
        { name: 'forms', groups: ['forms'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        '/',
        {
          name: 'paragraph',
          groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
        },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] },
      ],
      removeButtons: `ExportPdf,About,Maximize,ShowBlocks,Source,Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,PasteFromWord,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Unlink,Anchor,Image,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock`,
      disallowedContent: 'span',
      // colorButton_enableAutomatic: false,
      // colorButton_enableMore: false,
      // colorButton_colors: '000000,167951,93C0BD,F48E00'
    };
  }

  get editorConfig() {
    return this.editorConfiguration;
  }

  constructor() {}

  togglePopOut() {
    this.popOut = !this.popOut;
  }

  ngOnInit() {
    this.editorConfiguration = this.createConfig();
  }

  ngOnDestroy() {
    this.isShowEditor = false;
  }
}
