import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { Category0, Country, Experience } from 'app/shared/models';
import { EducationLevel } from 'app/shared/models/cv/education-level';
import { Education } from 'app/shared/models/cv/education';
import { DateRangeOptions } from 'app/shared/components/date-range-picker/date-range-picker.component';
import { PickerMode } from 'app/shared/components/date-picker/date-picker.component';
import { CV } from 'app/shared/models/cv/cv';
import { BehaviorSubject, Subject } from 'rxjs';
import { CvFormService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { StaticItemsService } from 'app/core/static-items.service';
import { MatDialog } from '@angular/material';
import { SuggestCategory3DialogComponent } from 'app/shared/components/suggest-category3-dialog/suggest-category3-dialog.component';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EducationComponent implements OnInit, OnDestroy {
  @Input() form: MondoFormGroup<Experience>;

  destroy$: Subject<boolean> = new Subject();
  private patchStreamCategory3 = new BehaviorSubject<Category0[]>([]);
  educationLevels = EducationLevel;
  public activeIndex: number;
  readonly dateRangOptions: DateRangeOptions = {
    allowFutureDates: false,
    allowPastDates: true,
    openEndedCheckboxText: 'currentEducation',
    hideStartNowCheckBox: true,
    showClearBtn: true,
    pickerMode: PickerMode.month,
  };

  constructor(
    private formBuilder: MondoFormBuilder,
    private cvFormService: CvFormService,
    private staticItemsService: StaticItemsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.cvFormService
      .getFormChangeStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((t: CV) => {
        if (t) {
          if (t.experience.category3) {
            this.patchStreamCategory3.next(
              t.experience.category3.map((entry) => Category0.fromJson(entry))
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get patchCategory3$() {
    return this.patchStreamCategory3.asObservable();
  }

  get getCategories3$() {
    return this.staticItemsService.getCategories3$();
  }

  get educationsArray(): MondoFormArray<Education[]> {
    return this.form.getSafeArray((x) => x.educations);
  }

  getDescription(education: MondoFormGroup<Education>) {
    return education.getSafeControl((x) => x.description);
  }

  getCountryFormGroup(
    formGroup: MondoFormGroup<Education>
  ): MondoFormGroup<Country> {
    return formGroup.getSafeGroup((x) => x.country);
  }

  getHeaderText(formGroup: MondoFormGroup<Education>): string {
    return formGroup.getSafe<string>((x) => x.eduTitle);
  }

  add(): void {
    this.educationsArray.push(this.formBuilder.group(new Education() as any));
    this.activeIndex = this.educationsArray.length - 1;
  }

  delete(i: number, text: string): void {
    if (confirm(text)) {
      this.educationsArray.removeAt(i);
      this.activeIndex = this.educationsArray.length - 1;
    }
  }

  openSuggestionDialog(dialog: string): void {
    if (dialog === 'category3') {
      this.dialog.open(SuggestCategory3DialogComponent, { width: '500px' });
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
