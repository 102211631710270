<br />
{{ firstLineText | preventHardcoding }} <br />
{{ secondLineText | preventHardcoding }}
<br />
<br />
<button
  mat-raised-button
  style="margin-bottom: 10px"
  color="primary"
  matStepperNext
>
  {{ btnText | preventHardcoding }}
</button>
