import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IBaseStepItem } from 'app/stepper/shared/model/IBaseStepItem';
import { RoutingService } from 'app/core/routing.service';
import { MondoRoutes } from 'app/app.routing-model';
import { ItemType } from 'app/stepper/shared/model/ItemType';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSelectorComponent implements OnInit {
  @Input() items: IBaseStepItem[] = [];
  @Input() currentKey: string;
  @Input() published: string[];
  @Input() rootUrl: string;
  @Output() addItem = new EventEmitter();
  @Output() deleteItem = new EventEmitter<string>();
  @Output() editItem = new EventEmitter<IBaseStepItem>();
  @Output() dublicateItem = new EventEmitter<IBaseStepItem>();
  @Output() publishItem = new EventEmitter<IBaseStepItem>();
  @Output() showItem = new EventEmitter<IBaseStepItem>();
  itemTypes = ItemType;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {}

  ngOnInit() {}

  isCurrent(key: string): boolean {
    return this.currentKey === key;
  }

  public navigateToItem(key: string) {
    const currentStep = +this.route.snapshot.paramMap.get('step');
    this.routingService.navigateToRoute(this.rootUrl, [key, currentStep]);
  }

  add(event) {
    this.addItem.emit();
  }

  isCvPublished(item: IBaseStepItem) {
    return this.published.find((key) => item && key === item.key);
  }

  delete(item: IBaseStepItem, event) {
    event.stopPropagation();
    this.deleteItem.emit(item.key);
  }

  edit(item: IBaseStepItem, event) {
    this.editItem.emit(item);
  }

  dublicate(item: IBaseStepItem, event) {
    event.stopPropagation();
    this.dublicateItem.emit(item);
  }

  publish(item: IBaseStepItem, event) {
    this.publishItem.emit(item);
  }

  show(item: IBaseStepItem) {
    this.showItem.emit(item);
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
