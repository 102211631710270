<ng-container>
  <div *ngIf="!formSubmitted && !accountExists" class="row">
    <div class="col-md-12">
      <h4>
        {{
          'PleaseCheckThatThisIsYourCorrectBillingInformation'
            | preventHardcoding
        }}
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-area">
      <div *ngIf="formSubmitted || accountExists">
        <div class="row">
          <div
            *ngIf="accountExists"
            class="col-md-12 text-center bug-reported-success"
          >
            <h4>
              {{ 'SetupAccountingEmail' | preventHardcoding }}
            </h4>
          </div>
          <div
            *ngIf="formSubmitted"
            class="col-md-12 text-center bug-reported-success"
          >
            {{ 'ThankYouYourAccountHasBeenCreated' | preventHardcoding }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="openPaymentSite()"
            >
              {{ 'Continue' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="formError">
        <div class="row">
          <div class="col-md-12 text-center bug-reported-failed">
            {{ formErrorMessage }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button mat-raised-button color="primary" (click)="retry()">
              {{ 'TryAgain' | preventHardcoding }}
            </button>
          </div>
        </div>
      </div>
      <form
        [formGroup]="accountForm"
        *ngIf="accountForm && !formSubmitted && !accountExists"
      >
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'CompanyName' | preventHardcoding }}</mat-label>
              <input matInput formControlName="company_name" />
              <mat-error
                *ngIf="accountForm.get('company_name').hasError('required')"
              >
                {{ 'Name' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'address' | preventHardcoding }}</mat-label>
              <input matInput formControlName="legal_address" />
              <mat-error
                *ngIf="accountForm.get('legal_address').hasError('required')"
              >
                {{ 'address' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'Zipcode' | preventHardcoding }}</mat-label>
              <input matInput formControlName="legal_zipcode" />
              <mat-error
                *ngIf="accountForm.get('legal_zipcode').hasError('required')"
              >
                {{ 'Zipcode' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'city' | preventHardcoding }}</mat-label>
              <input matInput formControlName="legal_city" />
              <mat-error
                *ngIf="accountForm.get('legal_city').hasError('required')"
              >
                {{ 'city' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'country' | preventHardcoding }}</mat-label>
              <input matInput formControlName="legal_country" />
              <mat-error
                *ngIf="
                  accountForm.get('legal_country').hasError('maxlength') ||
                  (accountForm.get('legal_country').hasError('minlength') &&
                    !accountForm.get('legal_country').hasError('required'))
                "
              >
                {{ 'country' | preventHardcoding }}
                {{ 'mustBe2Characters' | preventHardcoding }} (ISO Alpha-2 code)
              </mat-error>
              <mat-error
                *ngIf="accountForm.get('legal_country').hasError('required')"
              >
                {{ 'country' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              mat-raised-button
              (click)="cancel()"
              [disabled]="formLoading"
            >
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="createAccount()"
              [disabled]="formLoading || accountForm.invalid"
            >
              {{ 'Submit' | preventHardcoding }}
            </button>
          </div>
        </div>
        <app-splash-screen *ngIf="formLoading"></app-splash-screen>
      </form>
    </div>
  </div>
</ng-container>
