import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { Country } from 'app/shared/models';
import { Experience } from 'app/shared/models/cv/experience';
import { ResearchStay } from 'app/shared/models/cv/research-stay';
import { PickerMode } from 'app/shared/components/date-picker/date-picker.component';
import { DateRangeOptions } from 'app/shared/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'app-research-stay',
  templateUrl: './research-stay.component.html',
  styleUrls: ['./research-stay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResearchStayComponent {
  @Input() form: MondoFormGroup<Experience>;
  public activeIndex: number;
  readonly dateRangOptions: DateRangeOptions = {
    allowFutureDates: false,
    allowPastDates: true,
    openEndedCheckboxText: 'currentResearchStay',
    hideStartNowCheckBox: true,
    pickerMode: PickerMode.month,
  };

  constructor(private formBuilder: MondoFormBuilder) {}

  getDescription(researchStay: MondoFormGroup<ResearchStay>) {
    return researchStay.getSafeControl((x) => x.description);
  }

  get researchStayArray(): MondoFormArray<ResearchStay[]> {
    return this.form.getSafeArray((x) => x.researches);
  }

  getCountryFormGroup(
    formGroup: MondoFormGroup<ResearchStay>
  ): MondoFormGroup<Country> {
    return formGroup.getSafeGroup((x) => x.country);
  }

  getHeaderText(formGroup: MondoFormGroup<ResearchStay>): string {
    return formGroup.getSafe<string>((x) => x.projectTitle);
  }

  add(): void {
    this.researchStayArray.push(this.formBuilder.group(new ResearchStay()));
    this.activeIndex = this.researchStayArray.length - 1;
  }

  delete(i: number, text: string): void {
    if (confirm(text)) {
      this.researchStayArray.removeAt(i);
      this.activeIndex = this.researchStayArray.length - 1;
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
