<div class="form-group" [formGroup]="form">
  <app-text-editor [textFormControl]="text" textEditorFormControlName="text">
    {{ 'writeAboutYourself' | preventHardcoding }}
  </app-text-editor>
  <div>
    <mat-checkbox color="primary" formControlName="exclude">{{
      'excludeFromCV' | preventHardcoding
    }}</mat-checkbox>
  </div>
</div>
