import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataConstants } from 'app/shared/consts/dataConstants';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DAO } from 'app/shared-services/db-access/dao';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  messaging;
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  constructor(private dao: DAO, private afAuth: AngularFireAuth) {
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    } else {
      console.log(
        `this browser doesn't support notifications, you are probably using Safari`
      );
    }
  }

  async updateToken(token: string) {
    const user = await this.afAuth.authState.pipe(take(1)).toPromise();
    if (!user) {
      return;
    }
    const data = { [user.uid]: token };
    this.dao.object(DataConstants.NOTIFICATIONS_TOKENS).update(data);
  }

  getPermission() {
    if (this.messaging) {
      Notification.requestPermission()
        .then(() => {
          // console.log('Notification permission granted.');
          return this.messaging.getToken();
        })
        .then((token) => {
          this.updateToken(token);
        })
        .catch((err) => {
          // console.log('Unable to get permission to send push messages.', err);
        });
    }
  }

  receiveMessage() {
    if (this.messaging) {
      this.messaging.onMessage((payload) => {
        // console.log('Message received. ', payload);
        this.messageSource.next(payload);
      });
    }
  }
}
