import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './shared/components/login/login.component';
import { SignUpComponent } from './shared/components/signup/signup.component';
import { EmailActionManagerComponent } from './email-action-manager/email-action-manager.component';
import { MondoRoutes, RoutingModel } from './app.routing-model';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: MondoRoutes.login,
    data: { animation: 'root' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'login' },
  },
  {
    path: 'signup/:accountType',
    component: SignUpComponent,
    data: { animation: 'signup' },
  },
  {
    path: 'signup',
    redirectTo: 'signup/0',
  },
  {
    path: 'email-action-manager',
    component: EmailActionManagerComponent,
    data: { animation: 'email-action-manager' },
  },
  {
    path: MondoRoutes.landingPage,
    loadChildren:
      './landing-page-wrapper/landing-page-wrapper.module#LandingPageWrapperModule',
  },
  {
    path: RoutingModel.home.path,
    loadChildren: './home/home.module#HomeModule',
  },
  {
    path: 'job',
    loadChildren: './job-linker/job-linker.module#JobLinkerModule',
  },
  {
    path: 'link',
    loadChildren: './profile/profile.module#ProfileModule',
  },
  {
    path: RoutingModel.adminPage.path,
    loadChildren: './admin-page/admin-page.module#AdminPageModule',
  },
  {
    path: RoutingModel.dashboard.path,
    loadChildren: './dashboard/dashboard.module#DashboardModule',
  },
  {
    path: RoutingModel.events.path,
    loadChildren: './groups/events/events.module#EventsModule',
  },
  {
    path: RoutingModel.communities.path,
    loadChildren: './groups/communities/communities.module#CommunitiesModule',
  },
  {
    path: RoutingModel.ideas.path,
    loadChildren: './groups/project-idea/ideas.module#IdeasModule',
  },
  {
    path: 'about',
    loadChildren: './about-us/about-us.module#AboutUsModule',
  },
  {
    path: RoutingModel.map.path,
    loadChildren: './map/map.module#MapModule',
  },
  {
    path: RoutingModel.scienceMatch.path,
    loadChildren: './science-match/science-match.module#ScienceMatchModule',
  },
  {
    path: 'new-user',
    loadChildren: './new-user/new-user.module#NewUserModule',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: MondoRoutes.login,
    data: { animation: '**' },
  },
];

export const routing = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
  preloadingStrategy: PreloadAllModules,
});
