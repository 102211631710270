<div class="form-group" [formGroup]="form">
  <ng-container *ngIf="'showApplicationProcessFunctions' | preventHardcoding">
    <div class="form-group cate-3">
      <app-mondo-hint type="Category3"></app-mondo-hint>
      <app-multi-selector
        placeholderText="{{
          'chooseX'
            | preventHardcodingFormatText: ('categories3' | preventHardcoding)
        }}"
        [form]="form"
        [patchStream]="patchCategory3$"
        [controlName]="'category3'"
        [dataStream]="getCategories3$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('category3')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('category3' | preventHardcoding)
        }}
      </button>
    </div>
  </ng-container>
  <div>
    <div formArrayName="educations">
      <mat-accordion>
        <mat-expansion-panel
          [expanded]="activeIndex === i"
          (opened)="activeIndex = i"
          *ngFor="
            let education of educationsArray.controls;
            let i = index;
            trackBy: trackByFn
          "
          [formGroupName]="i"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <ng-container *ngIf="getHeaderText(education); else noTitle">
                {{ getHeaderText(education) | urlSizeLimiter: 25 }}
              </ng-container>
              <ng-template #noTitle>
                {{ 'newEducation' | preventHardcoding }}
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div class="tab-view-content">
              <mat-form-field appearance="standard">
                <mat-label>{{
                  'titleOfEducation' | preventHardcoding
                }}</mat-label>
                <input matInput type="text" formControlName="eduTitle" />
                <mat-hint>{{
                  'titleOfEducationHint' | preventHardcoding
                }}</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-select
                  formControlName="level"
                  placeholder="{{ 'educationalLevel' | preventHardcoding }}"
                >
                  <mat-option [value]="educationLevels.academy">{{
                    'Academy Profession' | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="educationLevels.bachelor">{{
                    'Bachelor' | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="educationLevels.master">{{
                    'Master' | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="educationLevels.phd">{{
                    'Ph.D' | preventHardcoding
                  }}</mat-option>
                  <mat-option [value]="educationLevels.other">{{
                    'Other' | preventHardcoding
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{
                  'lineOfEducation' | preventHardcoding
                }}</mat-label>
                <input matInput type="text" formControlName="line" />
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{
                  'educationalInstitution' | preventHardcoding
                }}</mat-label>
                <input matInput type="text" formControlName="university" />
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>{{ 'Department' | preventHardcoding }}</mat-label>
                <input matInput type="text" formControlName="department" />
              </mat-form-field>

              <app-country-picker
                [form]="getCountryFormGroup(education)"
              ></app-country-picker>

              <app-date-range-picker
                [options]="dateRangOptions"
                [dateRangeForm]="education"
              ></app-date-range-picker>

              <app-text-editor
                [textFormControl]="getDescription(education)"
                textEditorFormControlName="description"
              >
                {{
                  'writeProjectsQualificationsDuringStudies' | preventHardcoding
                }}
              </app-text-editor>
            </div>

            <mat-action-row class="button-row">
              <ng-container
                *ngIf="
                  'areYouSureWantToDeleteX'
                    | preventHardcodingFormatText
                      : ('education' | preventHardcoding) as displayText
                "
              >
                <button
                  mat-raised-button
                  color="warn"
                  (click)="delete(i, displayText)"
                >
                  <mat-icon>delete</mat-icon>{{ 'delete' | preventHardcoding }}
                </button>
              </ng-container>
              <button mat-raised-button (click)="activeIndex = -1">
                <mat-icon>check</mat-icon>{{ 'done' | preventHardcoding }}
              </button>
            </mat-action-row>
          </ng-template>
        </mat-expansion-panel>
        <mat-action-row>
          <button (click)="add()" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>{{ 'add' | preventHardcoding }}
          </button>
        </mat-action-row>
      </mat-accordion>
    </div>
  </div>
</div>
