import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { Country } from 'app/shared/models';
import { PickerMode } from 'app/shared/components/date-picker/date-picker.component';
import { DateRangeOptions } from 'app/shared/components/date-range-picker/date-range-picker.component';
import { Experience } from 'app/shared/models/cv/experience';
import { TeachingExperience } from 'app/shared/models/cv/teaching-experience';

@Component({
  selector: 'app-teaching-experience',
  templateUrl: './teaching-experience.component.html',
  styleUrls: ['./teaching-experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeachingExperienceComponent {
  @Input()
  form: MondoFormGroup<Experience>;
  public activeIndex: number;
  readonly dateRangOptions: DateRangeOptions = {
    allowFutureDates: false,
    allowPastDates: true,
    openEndedCheckboxText: 'currentlyTeaching',
    hideStartNowCheckBox: true,
    pickerMode: PickerMode.month,
  };

  constructor(private formBuilder: MondoFormBuilder) {}

  getDescription(teachingExp: MondoFormGroup<TeachingExperience>) {
    return teachingExp.getSafeControl((x) => x.description);
  }

  get teachingExperienceArray(): MondoFormArray<TeachingExperience[]> {
    return this.form.getSafeArray((x) => x.teachingExperiences);
  }

  getCountryFormGroup(
    formGroup: MondoFormGroup<TeachingExperience>
  ): MondoFormGroup<Country> {
    return formGroup.getSafeGroup((x) => x.country);
  }

  getHeaderText(formGroup: MondoFormGroup<TeachingExperience>): string {
    return formGroup.getSafe<string>((x) => x.title);
  }

  add(): void {
    this.teachingExperienceArray.push(
      this.formBuilder.group(new TeachingExperience())
    );
    this.activeIndex = this.teachingExperienceArray.length - 1;
  }

  delete(i: number, text: string): void {
    if (confirm(text)) {
      this.teachingExperienceArray.removeAt(i);
      this.activeIndex = this.teachingExperienceArray.length - 1;
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
