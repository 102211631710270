import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/auth.service';
import { PropertiesDialogComponent } from 'app/stepper/shared/components/properties-dialog/properties-dialog.component';
import { CvStepIndex } from 'app/stepper/shared/model/stepIndex';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MondoRoutes, RoutingModel } from '../../app.routing-model';
import { LoggingService } from '../../core/logging.service';
import { RoutingService } from '../../core/routing.service';
import { UserService } from '../../core/user.service';
import { ActionItem } from '../../shared/models/action-item';
import { Log, LogAction } from '../../shared/models/log';
import { IntroDialogComponent } from '../shared/components/intro-dialog/intro-dialog.component';
import { ItemType } from '../shared/model/ItemType';
import { CvService } from './services/cv.service';
import { CV } from 'app/shared/models/cv/cv';
import { ViewMode } from '../shared/model/ViewMode';
import { CVViewerComponent } from '../../viewer/cv/cv-viewer.component';
import { StartUpInfo } from '../../shared/models/user/mondoUser';
import { LeaveCvDialogComponent } from 'app/shared/components/leave-cv-dialog/leave-cv-dialog.component';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CvComponent implements OnInit, OnDestroy {
  selectedStep: CvStepIndex = CvStepIndex.start;
  introCv: CV;
  private subscriptions: Subscription[] = [];
  destroy$: Subject<boolean> = new Subject();

  rootUrl = RoutingModel.cvBuilder.route;
  actionBarItems: ActionItem[] = [];
  cvData$: Observable<CV>;
  cv: CV;
  cvId: string;
  showIntroViewer = false;
  showViewer = false;
  showBuilder = false;
  showProgressTracker = true;
  allCvs$: Observable<CV[]>;
  published: string[] = [];
  viewMode: ViewMode = ViewMode.DRAFT;
  loaded = false;
  madeDecisionToLeave = false;

  constructor(
    private authService: AuthService,
    private cvService: CvService,
    private userService: UserService,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private routingService: RoutingService
  ) {}

  ngOnInit(): void {
    const user = this.authService.getCurrentUser();
    if (!user.startUpInfo.hasSeenBuilderInfo) {
      user.startUpInfo = new StartUpInfo();
      this.userService.updateUser(user);
    }
    if (
      this.authService.getCurrentUser().startUpInfo.hasSeenBuilderInfo === false
    ) {
      this.openStartInfo();
    }
    this.showBuilder = true;
    this.showViewer = true;

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const key = this.route.snapshot.paramMap.get('cvId');
      if (key !== this.cvId || !key) {
        this.cvId = key;
        this.unsubscribe();
        this.setupActionBar();
        if (!this.loaded || key === MondoRoutes.none) {
          this.loaded = true;
          this.setupSubscriptions();
        } else {
          this.loadNewCv(this.cvId);
        }
      }
    });
  }

  private setupSubscriptions() {
    this.allCvs$ = this.cvService.getDraftCvs();

    this.cvService.draftCvExists(this.cvId).then((exists) => {
      if (!exists) {
        this.allCvs$.pipe(take(1), debounceTime(1000)).subscribe((cvs) => {
          if (cvs.length <= 0) {
            this.cvService
              .createDraftCV()
              .then((newKey) => this.loadNewCv(newKey));
          } else {
            const publicCv = cvs.find(
              (cv) => cv.key === this.authService.getCurrentUser().publishedCv
            );
            this.loadNewCv(publicCv ? publicCv.key : cvs[0].key);
          }
        });
      } else {
        this.loadNewCv(this.cvId);
      }
    });
  }

  private loadNewCv(key: string): Observable<CV> {
    this.cvData$ = this.cvService.getDraftCv(key);
    this.subscriptions.push(this.cvData$.subscribe((cv) => (this.cv = cv)));

    this.subscriptions.push(
      this.cvService
        .getIntroCV()
        .subscribe((introCv) => (this.introCv = introCv))
    );

    if (
      this.selectedStep === CvStepIndex.start &&
      this.authService.getCurrentUser().startUpInfo.hasSeenBuilderInfo === true
    ) {
      this.selectedStep = CvStepIndex.personalDetails;
    }
    this.routingService.navigateToRoute(RoutingModel.cvBuilder.route, [
      key,
      this.selectedStep,
    ]);
    return this.cvData$;
  }

  private setupActionBar() {
    this.subscriptions.push(
      this.authService
        .getCurrentUser$()
        .subscribe(
          (user) =>
            (this.published = user.publishedCv ? [user.publishedCv] : [])
        )
    );
    this.actionBarItems = [
      new ActionItem('rename', 'edit', this.openProperties.bind(this)),
      new ActionItem('deleteCV', 'delete', this.deleteCv.bind(this)),
      // new ActionItem(hardcodedValues.duplicate, 'file_copy', this.dublicateCv.bind(this)),
      new ActionItem('print', 'print', this.printCv.bind(this)),
      // new ActionItem(hardcodedValues.saveToAcademondo, 'publish', this.publishCv.bind(this), true),
    ];
    // this.subscriptions.push(
    //   this.cvService.isCvPublished(this.cvId).subscribe((published) => {
    //     if (published) {
    //       const publishButton = this.actionBarItems.find(
    //         (item) => item.title === hardcodedValues.saveToAcademondo
    //       );
    //       if (publishButton) {
    //         publishButton.title = hardcodedValues.updateCV;
    //         publishButton.icon = 'public';
    //       }
    //     }
    //   })
    // );
  }

  private openProperties(newCv?: boolean, cv?: CV) {
    const dialogRef = this.dialog.open(PropertiesDialogComponent, {
      width: '500px',
    });

    dialogRef.componentInstance.itemType = ItemType.CV;
    dialogRef.componentInstance.item = cv ? cv : this.cv;
    dialogRef.componentInstance.newItem = !!newCv;
    dialogRef
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((cvToUpdate) => this.cvService.updateDraftCv(cvToUpdate));
  }

  private openStartInfo() {
    const dialogRef = this.dialog.open(IntroDialogComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.itemType = ItemType.CV;

    dialogRef
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        const user = this.authService.getCurrentUser();
        user.startUpInfo.hasSeenBuilderInfo = true;
        this.userService.updateUser(user);
      });
  }

  exitWarning = {
    show: false,
    resolve: null,
  };

  decide(decision: boolean) {
    this.exitWarning.show = false;
    this.exitWarning.resolve(decision);
  }

  leaveDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(LeaveCvDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.cvKey = this.cvId;
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .toPromise()
        .then((publishCv) => {
          if (publishCv) {
            this.madeDecisionToLeave = true;
            return true;
          } else {
            this.madeDecisionToLeave = true;
            return true;
          }
        });
    });
  }

  canDeactivateold(): Promise<boolean> {
    this.exitWarning.show = true;
    return new Promise((resolve) => {
      this.exitWarning.resolve = resolve;
    });
  }

  async canDeactivate(): Promise<boolean> {
    return true;
    // console.log('deactive ping cvid', this.cvId)
    // const key = this.route.snapshot.paramMap.get('cvId');
    // console.log('deactive ping key', key)
    // console.log('loaded', this.loaded)
    // console.log('this.published[0]', this.published[0])

    // // if (key === this.cvId) {
    // //   console.log('new step?')
    // //   return true;
    // // }
    // if (!this.loaded || this.cvId === MondoRoutes.none) {
    //   console.log('!this.loaded || this.cvId === MondoRoutes.none', !this.loaded , this.cvId === MondoRoutes.none)
    //   return true;
    // }
    // // if (this.published[0] && this.published[0] !== key) {
    // //   console.log('this.published[0] !== key', this.published[0] !== key)
    // //   return true;
    // // }
    // // if (this.published[0]) {
    // //   console.log('this.published[0]', this.published[0])
    // //   return true;
    // // }
    // // if (this.cvId) {
    // //   return true;
    // // }
    // if (!this.madeDecisionToLeave && !this.published[0]) {
    // //   const answer = await this.leaveDialog()
    // // console.log(answer)
    // console.log('leave dialog!')
    // // return answer;
    // // await this.leaveDialog();
    // return true;
    // } else {
    //   console.log('candeactive else')
    //   return true;
    // }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.unsubscribe();
  }

  private unsubscribe(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  printCv() {
    if (this.cv) {
      const timeout = this.showViewer ? 0 : 1200;
      this.showViewer = true;
      setTimeout(() => {
        (window as any).print();
      }, timeout);
      this.loggingService.logAction(new Log(LogAction.cvPrinted, this.cv.key));
    }
  }

  onCvChanged(cv: CV) {
    if (cv) {
      this.cvService.updateDraftCv(cv);
    }
  }

  onStepChanged(index: CvStepIndex) {
    this.selectedStep = index;
    this.showIntroViewer = index === CvStepIndex.start;
  }

  onPrintEvent() {
    this.printCv();
  }

  showBuilderChanged(state: boolean) {
    this.showBuilder = state;
  }

  showViewerChanged(state: boolean) {
    this.showViewer = state;
  }

  addNewCv() {
    this.cvService.createDraftCV().then((key) => {
      this.loadNewCv(key)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((job) => {
          this.openProperties(true, job);
        });
    });
  }

  deleteCv(cv: CV) {
    const keyToDelete = cv && cv.key ? cv.key : this.cvId;
    this.cvService.removeCv(keyToDelete).then((deleted) => {
      if (deleted && (this.cvId === keyToDelete || !keyToDelete)) {
        this.cvId = MondoRoutes.none;
        this.setupSubscriptions();
      }
    });
  }

  dublicateCv(cv: CV) {
    this.cvService.dublicateDraftCv(cv).then((cvId) => this.loadNewCv(cvId));
  }

  editCv(cv: CV) {
    this.openProperties(false, cv);
  }

  publishOtherCv(cv: CV) {
    // this.cvService.publishCv(cv);
  }

  showCV(cvKey: string): void {
    const dialogRef = this.dialog.open(CVViewerComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.cvKey = cvKey;
    dialogRef.componentInstance.viewMode = this.viewMode;
  }
}
