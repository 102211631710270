import { Injectable } from '@angular/core';
import { getJsonUser } from '@helpers';
import { DAO } from 'app/shared-services/db-access/dao';
import { DataConstants } from 'app/shared/consts/dataConstants';
import { PersonalDetails } from 'app/shared/models/cv/personal-details';
import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { MondoUser } from '../shared/models/user/mondoUser';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  constructor(private db: DAO, private authService: AuthService) {}

  updateUser(user: MondoUser): Promise<void> {
    return this.db
      .object(DataConstants.USERS + user.uid)
      .update(getJsonUser(user));
  }

  removePublicCVFromUser(userId: string): Promise<void> {
    return this.db
      .object(DataConstants.USERS + userId)
      .update({ publishedCv: '' });
  }

  removePublicSiteFromUser(userId: string): Promise<void> {
    return this.db.object(DataConstants.USERS + userId).update({ company: '' });
  }

  getUserByUid$(uid: string): Observable<MondoUser> {
    return this.db
      .object(DataConstants.USERS + uid)
      .snapshotChanges()
      .pipe(
        map((userSnap) => {
          if (userSnap.payload.exists()) {
            const user = userSnap.payload.val();
            user['uid'] = userSnap.payload.key;
            return user as MondoUser;
          }
        })
      );
  }

  getUsersByType(type: number): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) =>
        ref.orderByChild('type').equalTo(type)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((s) => {
            const user = s.payload.val();
            user['uid'] = s.payload.key;
            return user as MondoUser;
          });
        })
      );
  }

  getAllUsersWithoutPublicCV(): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) =>
        ref.orderByChild('publishedCv').equalTo('')
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map((s) => {
            const user = s.payload.val();
            user['uid'] = s.payload.key;
            return user as MondoUser;
          });
        }),
        map((users) => {
          return users.filter((user) =>
            AuthService.isUserOfScientistType(user.type)
          );
        })
      );
  }

  getAllUsersWithPublicCV(): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) => ref.orderByChild('lastLogin'))
      .snapshotChanges()
      .pipe(
        take(1),
        map((snaps) => {
          return snaps.map((s) => {
            const user = s.payload.val();
            user['uid'] = s.payload.key;
            return user as MondoUser;
          });
        }),
        map((users) => {
          return users.filter((user) =>
            AuthService.isUserOfScientistType(user.type)
          );
        }),
        map((users) => {
          return users.filter((user) => user.publishedCv);
        })
      );
  }

  getUsersByEmail(email: string): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) =>
        ref.orderByChild('email').equalTo(email)
      )
      .snapshotChanges()
      .pipe(
        mergeMap((userSnaps) => {
          if (userSnaps.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            userSnaps.map((snap) => this.getUserByUid$(snap.payload.key))
          );
        })
      );
  }

  getUsersByName(name: string): Observable<MondoUser[]> {
    return this.db
      .list(DataConstants.USERS, (ref) =>
        ref.orderByChild('displayName').equalTo(name)
      )
      .snapshotChanges()
      .pipe(
        mergeMap((userSnaps) => {
          if (userSnaps.length === 0) {
            return of([]);
          }
          return observableCombineLatest(
            userSnaps.map((snap) => this.getUserByUid$(snap.payload.key))
          );
        })
      );
  }

  setUserCPR(cpr: number, userId: string) {
    return this.db.object(DataConstants.USER_CPR + userId).set({ cpr: cpr });
  }

  getUserCpr(userId: string): Observable<string> {
    return this.db.object$(DataConstants.USER_CPR + userId);
  }

  setUserPersonalDetails(pd: PersonalDetails, userId: string) {
    return this.db
      .object(DataConstants.USER_USER_PERSONAL_DETAILS + userId)
      .set(pd);
  }

  getUserPersonalDetails(userId: string) {
    if (!this.authService.canSeeAnonymous) {
      return of({} as PersonalDetails);
    }
    return this.db
      .object$(DataConstants.USER_USER_PERSONAL_DETAILS + userId)
      .pipe(
        map((pd) => {
          const _pd = pd as any;
          if (_pd) {
            return _pd as PersonalDetails;
          } else {
            return null;
          }
        })
      );
  }
}
