import { Component, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { CvService } from 'app/stepper/cv/services/cv.service';
import { CvBuilderService } from 'app/stepper/cv/services/cv-builder.service';
import { AuthService } from 'app/core/auth.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ActionBarEventService } from 'app/core/action-bar-event.service';
import { MatDialog } from '@angular/material/dialog';
import { PlanSelectorComponent } from '../fenerum/plan-selector/plan-selector.component';
import { CV } from 'app/shared/models/cv/cv';

@Component({
  selector: 'app-publish-cv',
  templateUrl: './publish-cv.component.html',
  styleUrls: ['./publish-cv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishCvComponent implements OnDestroy {
  @Input() cvKey: string;
  @Input() showText = true;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    private actionBarEvent: ActionBarEventService,
    private cvService: CvService,
    private authService: AuthService,
    private builderService: CvBuilderService,
    private dialog: MatDialog,
  ) { }

  publishCv() {
    this.cvService.getDraftCv(this.cvKey)
    .pipe(take(1), takeUntil(this.destroy$))
    .subscribe(cv => {
      if (this.minReqForPublish(cv)) {
        if (!this.authService.canPublishCV) {
          this.openPlans();
        } else if (this.authService.canPublishCV) {
          this.cvService.publishCv(cv);
          this.builderService.toggleBuilder(false);
          this.actionBarEvent.emitEvent(null);
        }
      }
    });
  }

  private minReqForPublish (cv: CV): boolean {
    const text = this.cvService.getRequirementStatusAndText(cv).text;
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else {
      return true;
    }
  }

  openPlans(): void {
    this.dialog
      .open(PlanSelectorComponent,  {
        maxWidth: '1260px',
        maxHeight: '98vh',
        width: '100%',
        panelClass: 'plan-dialog',
        data: {cvKey: this.cvKey}
     });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
