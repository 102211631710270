import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { routerAnimation } from './_animations/routerAnimation';
import { AuthService, UserStatus } from './core/auth.service';
import { DeviceService } from './core/device.service';
import { PresenceService } from './core/presence.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PushNotificationService } from './notifications-new/services/push-notification.service';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { SsoAuthService } from './core/sso-auth.service';
import { authConfig } from './core/auth-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerAnimation,
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush, // not ready yet
})
export class AppComponent implements OnInit {
  isReady = false;
  loggedIn = false;
  notificationsSet = false;
  userStatus$: Observable<UserStatus>;
  public ready$: Observable<boolean>;
  public isAuthed$;
  private isAuthedWithFirebase = false;

  constructor(
    private authService: AuthService,
    private ssoAuthService: SsoAuthService,
    public presence: PresenceService,
    public deviceService: DeviceService,
    private msgService: PushNotificationService,
    private oauthService: OAuthService
  ) {}

  ngOnInit() {
    this.configure();
    this.msgService.getPermission();
    this.msgService.receiveMessage();
    this.authService.afstateHandler().subscribe();
    this.userStatus$ = this.authService.getUserStatus$().pipe(
      tap((userStatus) => {
        if (userStatus) {
          this.isReady = !userStatus.waitingForStatus;
          this.loggedIn = userStatus.loggedIn && this.isReady;
          // if (this.loggedIn && !this.notificationsSet) {
          //   console.log('yolo')
          //   this.msgService.getPermission();
          //   this.msgService.receiveMessage();
          //   this.notificationsSet = true;
          // }
          if (!userStatus.dbAccess) {
            alert('System is under maintenance');
          }
        }
      })
    );
    this.isAuthed$ = this.ssoAuthService.isAuthenticated$.pipe(
      tap(async (isAuthed) => {
        if (
          isAuthed &&
          this.ssoAuthService.accessToken &&
          !this.isAuthedWithFirebase
        ) {
          this.isReady = false;
          this.ssoAuthService
            .signinWithFirebase(this.ssoAuthService.accessToken)
            .then((res) => {
              // console.log('sign in done!', res);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              // console.log(
              //   'do something Extra?!?',
              //   this.ssoAuthService.accessToken
              // );
            });
          this.isAuthedWithFirebase = true;
        }
      })
    );
  }

  private configure() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // this.oauthService.loadDiscoveryDocumentAndLogin();
  }
}
