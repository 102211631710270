<div class="row site-viewer-element">
  <div class="col-6">
    <div class="row">
      <div class="col-12">
        <div
          *ngIf="false"
          class="labeled-data-table smaller-light">
          <a
            href="{{website | safeAngularLink}}"
            target="_blank">
            {{'visitOfficialWebsite'| preventHardcoding}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div *ngIf="isPublic && false">
      <mat-checkbox
        labelPosition="before"
        color="primary">
        {{'applyForMembership'| preventHardcoding}}
      </mat-checkbox>
    </div>
  </div>
</div>
