<div class="form-group" [formGroup]="form">
  <div *ngIf="'Foi' | preventHardcoding">
    <app-multi-selector
      placeholderText="{{
        'chooseX' | preventHardcodingFormatText: ('fois' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'wantedFieldOfInterest'"
      [dataStream]="fieldsOfInterests$"
      [patchStream]="interestsPatch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestInterestDialog()"
    >
      {{
        'suggestNewX' | preventHardcodingFormatText: ('foi' | preventHardcoding)
      }}
    </button>
  </div>
  <div *ngIf="'Category0' | preventHardcoding">
    <app-multi-selector
      placeholderText="{{
        'chooseX'
          | preventHardcodingFormatText: ('categories0' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'category0'"
      [dataStream]="category0$"
      [patchStream]="category0Patch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestCategory0Dialog()"
    >
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('category0' | preventHardcoding)
      }}
    </button>
  </div>
  <div *ngIf="'Category2' | preventHardcoding">
    <app-multi-selector
      placeholderText="{{
        'chooseX'
          | preventHardcodingFormatText: ('categories2' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'category2'"
      [dataStream]="category0$"
      [patchStream]="category2Patch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestCategory0Dialog()"
    >
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('category0' | preventHardcoding)
      }}
    </button>
  </div>
  <div *ngIf="canSeeCat1()">
    <app-multi-selector
      placeholderText="{{
        'chooseX'
          | preventHardcodingFormatText: ('categories1' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'category1'"
      [dataStream]="category1$"
      [patchStream]="category1Patch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestCategory1Dialog()"
    >
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('category1' | preventHardcoding)
      }}
    </button>
  </div>
  <div *ngIf="'Category3' | preventHardcoding">
    <app-multi-selector
      placeholderText="{{
        'chooseX'
          | preventHardcodingFormatText: ('categories3' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'category3'"
      [dataStream]="category3$"
      [patchStream]="category3Patch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestCategory3Dialog()"
    >
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('category3' | preventHardcoding)
      }}
    </button>
  </div>
  <div *ngIf="'Tech' | preventHardcoding">
    <app-multi-selector
      placeholderText="{{
        'chooseX' | preventHardcodingFormatText: ('techs' | preventHardcoding)
      }}"
      [form]="researchQualifications"
      [controlName]="'wantedTechniques'"
      [dataStream]="labTechniques$"
      [patchStream]="techniquesPatch$"
    >
    </app-multi-selector>
    <button
      class="suggest-button"
      mat-raised-button
      (click)="openSuggestTechniqueDialog()"
    >
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('tech' | preventHardcoding)
      }}
    </button>
  </div>
  <br />
  <app-text-editor
    [textFormControl]="qualificationFreeText"
    textEditorFormControlName="qualificationFreeText"
    >{{ 'writeQualificationsPosition' | preventHardcoding }}</app-text-editor
  >
  <div>
    <div class="languages">
      <p>{{ 'Languages' | preventHardcoding }}</p>
      <app-language [form]="languages"></app-language>
    </div>
    <!-- <mat-form-field appearance="standard">
      <mat-label>{{ 'minimumEducationalLevel' | preventHardcoding }}</mat-label>
      <mat-select
        [formControl]="minimumEducationalLevel"
        placeholder="{{ 'minimumEducationalLevel' | preventHardcoding }}"
      >
        <mat-option [value]="educationLevels.none">{{
          'None' | preventHardcoding
        }}</mat-option>
        <mat-option [value]="educationLevels.academy">{{
          'Academy Profession' | preventHardcoding
        }}</mat-option>
        <mat-option [value]="educationLevels.bachelor">{{
          'Bachelor' | preventHardcoding
        }}</mat-option>
        <mat-option [value]="educationLevels.master">{{
          'Master' | preventHardcoding
        }}</mat-option>
        <mat-option [value]="educationLevels.phd">{{
          'Ph.D' | preventHardcoding
        }}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <div class="row">
      <mat-form-field
        *ngIf="'Experience0' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience0Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience0"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="'Experience1' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience1Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience1"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="'Experience2' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience2Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience2"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="'Experience3' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience3Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience3"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="'Experience4' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience4Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience4"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="'Experience5' | preventHardcoding"
        appearance="standard"
        class="col-7"
      >
        <mat-label
          >{{ 'experience5Years' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-label
        >
        <input
          matInput
          type="number"
          [min]="0"
          [formControl]="wantedDaysOfExperience5"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="valueChanges$ | async"></ng-container>
</div>
