import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-builder-info',
  templateUrl: './job-builder-info.component.html',
  styleUrls: ['./job-builder-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderInfoComponent implements OnInit {
  now = new Date();
  @Input() form: MondoFormGroup<Job>;

  constructor() {}

  ngOnInit() {}

  get teaser() {
    return this.form.getSafeControl((x) => x.jobInfo.teaser);
  }

  get description() {
    return this.form.getSafeControl((x) => x.jobInfo.description);
  }

  get about() {
    return this.form.getSafeControl((x) => x.jobInfo.about);
  }

  get position() {
    return this.form.getSafeControl((x) => x.jobInfo.position);
  }

  get youtubeId() {
    return this.form.getSafeControl((x) => x.jobInfo.youtubeId);
  }

  get imageLink() {
    return this.form.getSafeControl((x) => x.jobInfo.imageLink);
  }

  get imageUrl() {
    return this.form.getSafeControl((x) => x.jobInfo.imageUrl);
  }

  get jobType() {
    return this.form.getSafeControl((x) => x.jobInfo.jobType);
  }

  get jobKey(): string {
    return this.form.getSafeControl((x) => x.key).value;
  }

  get imageLinkPath(): string {
    return `/job/${this.jobKey}/imageLink`;
  }

  onPicUpload(url: string) {
    this.imageLink.setValue(url);
  }
}
