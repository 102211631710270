import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreventHardcodingPipe } from './pipes/prevent-hardcoding.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { LastSeenPipe } from './pipes/last-seen.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SafeAngularLinkPipe } from './pipes/safe-angular-link.pipe';
import { PreventHardcodingFormatTextPipe } from './pipes/prevent-hardcoding-format-text.pipe';
import { LinkifyPipe } from './pipes/linkfy.pipe';

@NgModule({
  declarations: [
    PreventHardcodingPipe,
    PreventHardcodingFormatTextPipe,
    SafeHtmlPipe,
    LastSeenPipe,
    SortByPipe,
    SafeAngularLinkPipe,
    LinkifyPipe,
  ],
  imports: [CommonModule],
  exports: [
    PreventHardcodingPipe,
    PreventHardcodingFormatTextPipe,
    SafeHtmlPipe,
    LastSeenPipe,
    SortByPipe,
    SafeAngularLinkPipe,
    LinkifyPipe,
  ],
})
export class SharedPipesModule {}
