<div
  class="contact-consent-container"
  *ngIf="'contactConsentGiven' | preventHardcoding"
>
  <mat-checkbox color="primary" [formControl]="contactConsent">
    {{ 'contactConsentGiven' | preventHardcoding }}
  </mat-checkbox>
  <button
    mat-button
    (click)="showExplanation()"
    matTooltip="{{ 'contactConsentExplanation' | preventHardcoding }}"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
</div>

<div [formGroup]="form">
  <div>
    <p class="sub-title">
      {{ 'employmentPeriod' | preventHardcoding }}
    </p>
    <app-date-range-picker
      [dateRangeForm]="employmentPeriod"
      [options]="employmentDateRangeOptions"
    ></app-date-range-picker>
  </div>
  <div *ngIf="!deployTodayWithEndDate">
    <div class="sub-title">
      {{ 'visibilityPeriod' | preventHardcoding }}
    </div>
    <small>
      <em>{{
        'YourApplicationDeadline6WeeksFromPublicationDate' | preventHardcoding
      }}</em>
    </small>
    <app-date-range-picker
      [dateRangeForm]="jobVisibilityPeriod"
      [options]="visibilityDateRangeOptions"
    ></app-date-range-picker>
  </div>
</div>
<app-job-builder-publish
  [jobKey]="jobKey"
  [deployToday]="deployTodayWithEndDate"
></app-job-builder-publish>
