import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'app/core/user.service';
import { NotificationData } from 'app/notifications-new/models/notification-data';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: NotificationData;
  @Output() routeTo: EventEmitter<NotificationData> = new EventEmitter();
  fromUser$: Observable<MondoUser>;
  constructor( private userService: UserService) { }

  ngOnInit() {
    this.fromUser$ = this.userService.getUserByUid$(this.notification.fromUserId);
  }

}
