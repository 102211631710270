import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { SuggestFieldOfInterestDialogComponent } from 'app/shared/components/suggest-field-of-interest-dialog/suggest-field-of-interest-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { CvFormService } from '../../services/cv-form.service';
import { Experience } from 'app/shared/models/cv/experience';
import { StaticItemsService } from 'app/core/static-items.service';
import { takeUntil } from 'rxjs/operators';
import {
  Category0,
  FieldOfInterest,
  Category1,
  Technique,
} from 'app/shared/models';
import { SuggestTechniqueDialogComponent } from 'app/shared/components/suggest-technique-dialog/suggest-technique-dialog.component';
import { CV } from 'app/shared/models/cv/cv';
import { SuggestCategory0DialogComponent } from 'app/shared/components/suggest-category0-dialog/suggest-category0-dialog.component';
import { SuggestCategory1DialogComponent } from 'app/shared/components/suggest-category1-dialog/suggest-category1-dialog.component';
import { SuggestCategory3DialogComponent } from 'app/shared/components/suggest-category3-dialog/suggest-category3-dialog.component';
import { acaNames } from 'aca-names';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @Input() experience: MondoFormGroup<Experience>;
  @Input() restrictSelection = false;
  @Input() maxSelection = 1;
  @Input() categories: string[] = [];
  destroy$: Subject<boolean> = new Subject();

  private patchStreamInterest = new BehaviorSubject<FieldOfInterest[]>([]);
  private patchStreamCategory0 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory1 = new BehaviorSubject<Category1[]>([]);
  private patchStreamCategory2 = new BehaviorSubject<Category0[]>([]);
  private patchStreamCategory3 = new BehaviorSubject<Category0[]>([]);
  private patchStreamTech = new BehaviorSubject<Technique[]>([]);

  constructor(
    private staticItemsService: StaticItemsService,
    private dialog: MatDialog,
    private cvFormService: CvFormService
  ) {}

  ngOnInit() {
    this.cvFormService
      .getFormChangeStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe((t: CV) => {
        if (t) {
          if (this.checkForCategory(acaNames.Foi)) {
            this.patchStreamInterest.next(
              t.experience.interests.map((entry) =>
                FieldOfInterest.fromJson(entry)
              )
            );
          }
          if (this.checkForCategory(acaNames.Tech)) {
            this.patchStreamTech.next(
              t.experience.techniques.map((entry) => Technique.fromJson(entry))
            );
          }
          if (
            t.experience.category0 &&
            this.checkForCategory(acaNames.Category0)
          ) {
            this.patchStreamCategory0.next(
              t.experience.category0.map((entry) => Category0.fromJson(entry))
            );
          }
          if (
            t.experience.category1 &&
            this.checkForCategory(acaNames.Category1)
          ) {
            this.patchStreamCategory1.next(
              t.experience.category1.map((entry) => Category1.fromJson(entry))
            );
          }
          if (
            t.experience.category2 &&
            this.checkForCategory(acaNames.Category2)
          ) {
            this.patchStreamCategory2.next(
              t.experience.category2.map((entry) => Category0.fromJson(entry))
            );
          }
          if (
            t.experience.category3 &&
            this.checkForCategory(acaNames.Category3)
          ) {
            this.patchStreamCategory3.next(
              t.experience.category3.map((entry) => Category0.fromJson(entry))
            );
          }
        }
      });
  }

  checkForCategory(category: string) {
    return this.categories.indexOf(category) > -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get patchInterest$() {
    return this.patchStreamInterest.asObservable();
  }

  get patchCategory0$() {
    return this.patchStreamCategory0.asObservable();
  }

  get patchCategory1$() {
    return this.patchStreamCategory1.asObservable();
  }

  get patchCategory2$() {
    return this.patchStreamCategory2.asObservable();
  }

  get patchCategory3$() {
    return this.patchStreamCategory3.asObservable();
  }

  get patchTech$() {
    return this.patchStreamTech.asObservable();
  }

  get fieldsOfInterests$() {
    return this.staticItemsService.getFieldOfInterest$();
  }

  get labTechniques$() {
    return this.staticItemsService.getTechniques$();
  }

  get getCategories0$() {
    return this.staticItemsService.getCategories0$();
  }

  get getCategories1$() {
    return this.staticItemsService.getCategories1$();
  }

  get getCategories3$() {
    return this.staticItemsService.getCategories3$();
  }

  openSuggestionDialog(dialog: string): void {
    if (dialog === 'tech') {
      this.dialog.open(SuggestTechniqueDialogComponent, { width: '500px' });
    } else if (dialog === 'interest') {
      this.dialog.open(SuggestFieldOfInterestDialogComponent, {
        width: '500px',
      });
    } else if (dialog === 'category0') {
      this.dialog.open(SuggestCategory0DialogComponent, { width: '500px' });
    } else if (dialog === 'category1') {
      this.dialog.open(SuggestCategory1DialogComponent, { width: '500px' });
    } else if (dialog === 'category3') {
      this.dialog.open(SuggestCategory3DialogComponent, { width: '500px' });
    }
  }
}
