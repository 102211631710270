import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ItemType } from '../../model/ItemType';

@Component({
  selector: 'app-intro-dialog',
  templateUrl: './intro-dialog.component.html',
  styleUrls: ['./intro-dialog.component.scss'],
})
export class IntroDialogComponent implements OnInit {
  @Input() itemType: ItemType;
  @Input() showHeader = true;
  itemTypes = ItemType;

  constructor(public dialogRef: MatDialogRef<IntroDialogComponent>) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
