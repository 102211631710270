import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { AdditionalInfo } from 'app/shared/models/cv/additional-info';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalInformationComponent {
  @Input() form: MondoFormGroup<AdditionalInfo>;

  constructor() {}

  get description() {
    return this.form.getSafeControl(x => x.description);
  }
}
