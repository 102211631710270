import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AccountType } from 'app/shared/consts/accountType';
import { PasswordValidator } from 'app/shared/models/formValidators';
import { MondoAccount } from 'app/shared/models/mondoAccount';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { fadeInAnimation } from 'app/_animations/fadeInAnimation';
import { hardcodedValues } from 'hardcodedValues';
import { StrictEmailValidator } from '@helpers';
import { MondoRoutes, RoutingModel } from 'app/app.routing-model';
import { acaConfig } from 'aca-config';
import { acaNames } from 'aca-names';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class SignUpComponent implements OnInit {
  signupForm: FormGroup;
  detailForm: FormGroup;
  signupInProgress = false;
  errorMsg = '';
  error = false;
  currentAccountType: AccountType;
  accountTypes: MondoAccount[];
  selectedAccount: MondoAccount;
  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.accountTypes = [];
    if (acaNames.UserType0) {
      this.accountTypes.push(
        MondoAccount.fromAccountType(AccountType.scientist)
      );
    }
    if (acaNames.UserType1) {
      this.accountTypes.push(
        MondoAccount.fromAccountType(AccountType.industry)
      );
    }
    if (acaNames.UserType2) {
      this.accountTypes.push(
        MondoAccount.fromAccountType(AccountType.university)
      );
    }
    if (acaNames.UserType3) {
      this.accountTypes.push(MondoAccount.fromAccountType(AccountType.labTech));
    }
    if (acaNames.UserType4) {
      this.accountTypes.push(
        MondoAccount.fromAccountType(AccountType.medLabTech)
      );
    }
    if (acaNames.UserType5) {
      this.accountTypes.push(
        MondoAccount.fromAccountType(AccountType.ssoAuthedUser)
      );
    }
    const accountType = this.route.snapshot.paramMap.get('accountType');
    if (!acaConfig.showChangeAccountTypeInSignup) {
      this.currentAccountType = accountType
        ? +accountType
        : AccountType.scientist;
    }
    this.selectedAccount = MondoAccount.fromAccountType(
      this.currentAccountType
    );
    this.setupForm();
  }

  private setupForm(): void {
    this.signupForm = this.fb.group({
      accountType: [this.currentAccountType, [Validators.required]],
      email: ['', [Validators.required, StrictEmailValidator]],
      password: [
        '',
        [
          PasswordValidator.strong,
          Validators.minLength(6),
          Validators.maxLength(25),
          Validators.required,
        ],
      ],
    });
  }

  get accountType() {
    return this.signupForm.get('accountType');
  }

  get email() {
    return this.signupForm.get('email');
  }

  get password() {
    return this.signupForm.get('password');
  }

  get passwordErrors(): any {
    return this.password.errors;
  }

  accountTypeChanged(account: MondoAccount) {
    this.currentAccountType = account.type;
    this.selectedAccount = account;
    this.signupForm.patchValue({ accountType: this.currentAccountType });
    this.routingService.navigateToUrlWithDataArray(RoutingModel.signup.route, [
      this.currentAccountType,
    ]);
  }

  public signup() {
    this.signupInProgress = true;
    this.authService
      .emailSignUp(
        this.email.value,
        this.password.value,
        this.currentAccountType
      )
      .then((user) => {
        if (user) {
          this.snackbarAndNavigateToLogin();
        }
      })
      .catch((error) => {
        if (
          error.toString() ===
          'Error: The email address is already in use by another account.'
        ) {
          this.snackbarAndNavigateToLogin();
        } else {
          this.error = true;
          this.errorMsg = error;
          this.signupInProgress = false;
          this.authService.notEnoughPermission(
            hardcodedValues[this.errorMsg] || this.errorMsg,
            10000
          );
        }
      })
      .finally(() => {
        this.cdr.detectChanges();
      });
  }

  snackbarAndNavigateToLogin() {
    this.error = false;
    this.signupInProgress = false;
    this.authService.notEnoughPermission('verificationMailSent', 10000);
    this.routingService.navigateToRoute(RoutingModel.login.route);
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
