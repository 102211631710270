<ng-container *ngIf="type + 'Dialog' | preventHardcoding">
  <button
    class="small"
    mat-icon-button
    color="primary"
    (click)="showHint()"
    matTooltip="{{ 'Info' | preventHardcoding }}"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="type + 'Tooltip' | preventHardcoding">
  <button
    class="small not-clickable"
    mat-icon-button
    color="primary"
    matTooltip="{{ type + 'Hint' | preventHardcoding }} {{ '\n \n' }} {{
      type + 'Link' | preventHardcoding
    }}"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
</ng-container>

<ng-container
  *ngIf="
    !(type + 'Tooltip' | preventHardcoding) &&
    !(type + 'Dialog' | preventHardcoding)
  "
>
  <div *ngIf="type + 'Hint' | preventHardcoding">
    <p>{{ type + 'Hint' | preventHardcoding }}</p>
  </div>
  <div *ngIf="type + 'Link' | preventHardcoding">
    <a
      class="cool-link"
      [href]="type + 'Link' | preventHardcoding"
      target="_blank"
      >{{ type + 'Link' | preventHardcoding }}</a
    >
  </div>
</ng-container>
