<h4>{{titleHeader| preventHardcoding}}</h4>
<form [formGroup]="resetForm" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
      <mat-label>{{'Email'| preventHardcoding}}</mat-label>
      <input type="email" name="email" matInput formControlName="email" [readonly]="readOnly" required>
      <mat-error *ngIf="email?.errors?.email">{{'EmailMustBeAnEmailExSupportAtAcademondoCom'| preventHardcoding}}</mat-error>
  </mat-form-field>
  <div class="footer">
    <button mat-raised-button color="primary" type="submit" [disabled]="!resetForm.valid">
      {{'resetPassword'| preventHardcoding}}
      <app-splash-screen *ngIf="loading"></app-splash-screen>
    </button>
    <button *ngIf="showOnBack" mat-button type="button" (click)="onBackClicked()">
      {{'cancel'| preventHardcoding}}
    </button>
    <mat-error class="small" *ngIf="error">
      {{errorMsg}}
    </mat-error>
  </div>
</form>
