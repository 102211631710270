<div class="wrapper">
  <div class="header" *ngIf="showHeader">
    <h2>{{ 'welcomeToHeader' | preventHardcoding }}</h2>
  </div>

  <br />

  <div *ngIf="itemType === itemTypes.CV">
    <p>{{ 'helpheaderCV' | preventHardcoding }}</p>

    <p>1.&nbsp;{{ 'helpLineOneCV' | preventHardcoding }}</p>

    <p *ngIf="'helpLineThreep1CV' | preventHardcoding">
      2.&nbsp;{{ 'helpLineThreep1CV' | preventHardcoding }}
      <span *ngIf="'helpLineThreep2CV' | preventHardcoding"
        ><br />&nbsp;&nbsp;&nbsp;
        {{ 'helpLineThreep2CV' | preventHardcoding }}</span
      >
    </p>
    <p *ngIf="'helpLineTwop1CV' | preventHardcoding">
      3.&nbsp;{{ 'helpLineTwop1CV' | preventHardcoding }}
      <b
        ><u>"{{ 'saveToAcademondo' | preventHardcoding }}"</u></b
      >
      {{ 'helpLineTwop2CV' | preventHardcoding }} <br />&nbsp;&nbsp;&nbsp;
      {{ 'helpLineTwop3CV' | preventHardcoding }}
    </p>
  </div>

  <div *ngIf="itemType === itemTypes.Job">
    <p>
      {{ 'helpheaderJob' | preventHardcoding }}
      <br />
      <br />{{ 'helpLineOneJob' | preventHardcoding }}
      <br />
      <br />{{ 'helpLineTwoJob' | preventHardcoding }}
    </p>
  </div>

  <div *ngIf="itemType === itemTypes.ContactConsent">
    <p>
      {{ 'helpheaderContactConsent' | preventHardcoding }}
      <br />
      <br />{{ 'helpLineOneContactConsent' | preventHardcoding }}
      <br />
      <br />{{ 'helpLineTwoContactConsent' | preventHardcoding }}
    </p>
  </div>

  <div class="footer">
    <button #introBtnOk mat-raised-button color="primary" (click)="close()">
      {{ 'begin' | preventHardcoding }}
    </button>
  </div>
</div>
