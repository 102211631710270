<form
  *ngIf="form && steps && item"
  [formGroup]="form"
  novalidate
  [@fadeInAnimation]
>
  <perfect-scrollbar>
    <mat-horizontal-stepper
      *ngIf="!vertical"
      class="no-print stepper"
      #stepper
      (selectionChange)="onSelectionChanged($event.selectedIndex)"
      [selectedIndex]="selectedIndex"
    >
      <mat-step
        [label]="step.title | preventHardcoding"
        *ngFor="let step of steps; let i = index; trackBy: trackByFn"
      >
        <app-step-base
          [vertical]="false"
          *ngIf="showStepContent(i)"
          [contentComponent]="step.component"
          [properties]="step.properties"
          [open]="step.stepIndex === selectedIndex"
          [hideFooterBtns]="step.hideFooterBtns"
        ></app-step-base>
      </mat-step>
    </mat-horizontal-stepper>
    <mat-vertical-stepper
      *ngIf="vertical"
      class="no-print stepper"
      #stepper
      (selectionChange)="onSelectionChanged($event.selectedIndex)"
      [selectedIndex]="selectedIndex"
    >
      <mat-step
        [label]="step.title | preventHardcoding"
        *ngFor="let step of steps; let i = index; trackBy: trackByFn"
      >
        <app-step-base
          [vertical]="true"
          *ngIf="showStepContent(i)"
          [contentComponent]="step.component"
          [properties]="step.properties"
          [open]="step.stepIndex === selectedIndex"
          [hideFooterBtns]="step.hideFooterBtns"
        ></app-step-base>
      </mat-step>
      <mat-step></mat-step>
    </mat-vertical-stepper>
  </perfect-scrollbar>
</form>
