import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { StrictEmailValidator } from '@helpers';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Output() onBack = new EventEmitter();
  @Input() showOnBack = true;
  @Input() readOnly = false;
  userEmail: string;
  @Input() titleHeader = 'ResetYourPassword';
  public resetForm: FormGroup;
  public error = false;
  public errorMsg: string;
  public requestSent = false;
  public loading = false;

  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userEmail = this.authService.getCurrentUser()
      ? this.authService.getCurrentUser().email
      : '';
    this.resetForm = this.fb.group({
      email: [
        this.userEmail ? this.userEmail : '',
        [Validators.required, StrictEmailValidator],
      ],
    });
  }

  get email() {
    return this.resetForm.get('email');
  }

  public submit() {
    if (this.readOnly) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '500px',
      });
      dialogRef.componentInstance.title = 'resetPassword';
      dialogRef.componentInstance.text = 'areYouSureYouWantToX';
      dialogRef.componentInstance.textX = 'resetYourPassword';
      dialogRef.componentInstance.yesText = 'resetPassword';
      dialogRef.componentInstance.noText = 'cancel';
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.resetPassword();
        }
      });
    } else {
      this.resetPassword();
    }
  }

  private resetPassword() {
    this.loading = true;
    this.authService
      .resetPassword(this.email.value)
      .then((test) => {
        this.requestSent = true;
        this.authService.notEnoughPermission('resetPWEmailSent', 15000);
        this.loading = false;
        this.error = false;
        this.onBackClicked();
      })
      .catch((e) => {
        this.error = true;
        this.errorMsg = e;
      });
  }

  public onBackClicked() {
    this.onBack.emit();
  }
}
