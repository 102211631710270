<div class="cv_workshops cv_section" *ngIf="workshops && workshops[workshops.length - 1].title">
  <h5 class="title_icon normal-regular">{{'conferencesAndWorkshops'| preventHardcoding}}</h5>
  <div class="pdl-32" *ngFor="let workshop of workshops; trackBy: trackByFn">
    <table>
      <tr>
        <td class="smaller-regular" *ngIf="workshop.date" width="100px">{{ workshop.date | date:"yyyy" }}.{{ workshop.date | date:"MM" }}</td>
        <td class="smaller-regular">
          <span *ngIf="workshop.title  && !workshop.city && !workshop.country.code && !workshop.contribution">{{'&nbsp;'}}</span>
          <span class="small-regular" *ngIf="workshop.title">{{workshop.title}}</span>
          <span *ngIf="workshop.title && (workshop.city || workshop.country.code)">, </span>
          <span class="small-regular" *ngIf="workshop.city">{{workshop.city}}</span>
          <span *ngIf="workshop.city && workshop.country.code">, </span>
          <span class="small-regular">{{workshop.country?.code}}</span>
          <span class="small-regular" *ngIf="workshop.contribution && (workshop.country.code || workshop.city || workshop.title)">
          - </span>
          <span>{{workshop.contribution}}</span>
          <span class="smaller-light" [innerHTML]="workshop.description | safeHtml"></span>
          <span class="smaller-light" [innerHTML]="workshop.abstract | safeHtml"></span>
        </td>
      </tr>
    </table>
  </div>
</div>
