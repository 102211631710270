import { MondoUser } from '../user/mondoUser';

export class ChatMessage implements ChatMessageJson<Date, MondoUser> {
  public static fromJson(
    obj: ChatMessageJson<number, string>,
    sender: MondoUser
  ): ChatMessage {
    return new ChatMessage(
      obj.key,
      obj.message,
      new Date(obj.timestamp),
      sender
    );
  }

  public static toJson(message: ChatMessage): ChatMessageJson<number, string> {
    return {
      key: message.key,
      message: message.message,
      sender: message.sender.uid,
      timestamp: message.timestamp.getTime()
    };
  }
  constructor(
    public key: string,
    public message: string,
    public timestamp: Date,
    public sender: MondoUser
  ) {}
}

export interface ChatMessageJson<T, E> {
  key: string;
  message: string;
  sender: E;
  timestamp: T;
}
