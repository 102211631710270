<h2 mat-dialog-title>{{ title | preventHardcoding }}</h2>
<div mat-dialog-content>
  {{ text | preventHardcodingFormatText: (textX | preventHardcoding) }}
</div>
<div *ngIf="hint" mat-dialog-content>
  {{ hint | preventHardcodingFormatText: (hintX | preventHardcoding) }}
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="okCancelClicked()">
    {{ noText | preventHardcoding }}
  </button>
  <button
    *ngIf="specialButtonText"
    mat-raised-button
    color="warn"
    (click)="okSpecialClicked()"
  >
    {{ specialButtonText | preventHardcoding }}
  </button>
  <button
    color="primary"
    mat-raised-button
    (click)="okOkClicked()"
    cdkFocusInitial
  >
    {{ yesText | preventHardcoding }}
  </button>
</div>
