<h1 mat-dialog-title>{{ 'saveToAcademondo' | preventHardcoding }}</h1>
<div mat-dialog-content class="hint">
  <p>{{ 'thisWillMakeCVSearchable' | preventHardcoding }}</p>
  <mat-error *ngIf="havePublicCV"
    >{{ 'thisWillUpdateYourCurrentOnline' | preventHardcoding }}
    {{ 'academondo' | preventHardcoding }}
    {{ 'cv' | preventHardcoding }}.</mat-error
  >
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClicked()">
    {{ 'cancel' | preventHardcoding }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onUploadClicked()"
    cdkFocusInitial
  >
    {{ 'Continue' | preventHardcoding }}
  </button>
</div>
