import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { Author } from 'app/shared/models/cv/author';
import { Network } from 'app/shared/models/cv/network';

@Component({
  selector: 'app-network-step',
  templateUrl: './network-step.component.html',
  styleUrls: ['./network-step.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetworkStepComponent {
  @Input() form: MondoFormGroup<Network>;
  seeCoauthors = false;
  constructor() { }

  get authors() {
    return this.form.getSafeGroup<Author[]>(x => x.authors);
  }

  trackByFn(index, item) {
    return (item.key || item.id || item.uid);
  }

  // addToNetwork(author) {
  //   this.networkArray.push(this.formBuilder.control(author));

  //   setTimeout(() => {
  //     this.getCoAuthors();
  //   }, 300); // let the next ticker excecute
  // }
  // removeFromNetwork(i) {
  //   this.networkArray.removeAt(i);

  //   setTimeout(() => {
  //     this.getCoAuthors();
  //   }, 300); // let the next ticker excecute
  // }
}
