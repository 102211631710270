import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-icon-title',
  templateUrl: './icon-title.component.html',
  styleUrls: ['./icon-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTitleComponent implements OnInit {
  @Input() title = 'title';
  @Input() iconPath = '/assets/icons/About1.svg';

  constructor() {}

  ngOnInit() {}
}
