import { AccountType } from '../consts/accountType';

export class MondoAccount {
  type: AccountType;
  icon: string;
  name: string;
  iconInv: string;

  static fromAccountType(type: AccountType): MondoAccount {
    switch (type) {
      case AccountType.scientist:
        return new MondoAccount(
          AccountType.scientist,
          '../../../assets/pictures/profile.png',
          'UserType0',
          '../../../assets/logos/Mayn-inv.svg'
        );
      case AccountType.university:
        return new MondoAccount(
          AccountType.university,
          '../../../assets/logos/Corporate.svg',
          'UserType2',
          '../../../assets/logos/Corporate-inv.svg'
        );
      case AccountType.industry:
        return new MondoAccount(
          AccountType.industry,
          '../../../assets/logos/Corporate.svg',
          'UserType1',
          '../../../assets/logos/Corporate-inv.svg'
        );
      case AccountType.medLabTech:
        return new MondoAccount(
          AccountType.medLabTech,
          '../../../assets/pictures/profile.png',
          'UserType4',
          '../../../assets/logos/Mayn-inv.svg'
        );
      case AccountType.labTech:
        return new MondoAccount(
          AccountType.labTech,
          '../../../assets/pictures/profile.png',
          'UserType3',
          '../../../assets/logos/Mayn-inv.svg'
        );
      case AccountType.labTech:
        return new MondoAccount(
          AccountType.ssoAuthedUser,
          '../../../assets/pictures/profile.png',
          'UserType5',
          '../../../assets/logos/Mayn-inv.svg'
        );
      default:
        return new MondoAccount(
          AccountType.scientist,
          '../../../assets/pictures/profile.png',
          'UserType0',
          '../../../assets/logos/Mayn-inv.svg'
        );
    }
  }
  private constructor(
    type: AccountType,
    icon: string,
    name: string,
    iconInv: string
  ) {
    this.type = type;
    this.icon = icon;
    this.name = name;
    this.iconInv = iconInv;
  }
}
