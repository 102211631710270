
    <ng-container *ngIf="reqPerm && notes |async"></ng-container>
    <button
      mat-raised-button
      color="primary"
      [class.some-notes]="someNotes"
      *ngIf="reqPerm"
      (click)="openNotes()">
      {{'Notes'| preventHardcoding}}
    </button>
  