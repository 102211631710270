<div class="foot">
  <button
    *ngIf="!hidePrev"
    mat-raised-button
    color="primary"
    matStepperPrevious
  >
    <mat-icon>{{ vertical ? 'keyboard_arrow_up' : 'chevron_left' }}</mat-icon
    >{{ 'Back' | preventHardcoding }}
  </button>
  <button *ngIf="!hideNext" mat-raised-button color="primary" matStepperNext>
    {{ 'Next' | preventHardcoding }}
    <mat-icon>{{
      vertical ? 'keyboard_arrow_down' : 'chevron_right'
    }}</mat-icon>
  </button>
</div>
