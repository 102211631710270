<div style="overflow-x: hidden">
  <form [formGroup]="noteForm" *ngIf="noteForm">
    <mat-form-field
      appearance="standard"
      style="box-sizing: content-box; width: 200px"
    >
      <mat-hint>{{ 'notesHint' | preventHardcoding }}</mat-hint>
      <mat-label>{{ 'noteLabel' | preventHardcoding }}</mat-label>
      <textarea
        matInput
        placeholder="{{ 'notePlaceholder' | preventHardcoding }}"
        formControlName="noteText"
        (keyup)="handleKeyPress($event)"
        autocomplete="off"
        style="box-sizing: content-box"
        cdkTextareaAutosize
        cdkFocusInitial
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
      >
      </textarea>
      <mat-error
        *ngIf="
          noteForm.get('noteText').hasError('minlength') &&
          !noteForm.get('noteText').hasError('required')
        "
      >
        {{ 'noteText' | preventHardcoding }}
        {{ 'mustBe2Characters' | preventHardcoding }}
      </mat-error>
      <mat-error *ngIf="noteForm.get('noteText').hasError('required')">
        {{ 'noteText' | preventHardcoding }}
        {{ 'mustBeFilledOut' | preventHardcoding }}
      </mat-error>
    </mat-form-field>
    <button
      mat-icon-button
      class="close-icon"
      style="position: absolute; top: 0; right: 0"
      (click)="close()"
      matTooltipPosition="right"
      matTooltip="{{ 'Close' | preventHardcoding }}"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-button
      color="primary"
      matTooltip="{{ 'saveNoteHint' | preventHardcoding }}"
      (click)="addNote()"
      [disabled]="
        noteForm.invalid || noteForm.get('noteText').value.trim().length < 2
      "
    >
      {{ 'Save' | preventHardcoding }}
    </button>
    <div>
      <mat-error *ngIf="noteForm.get('uid').hasError('required')">
        {{ 'defaultError' | preventHardcoding }}
      </mat-error>
    </div>
  </form>
</div>
<app-notes-viewer [notes]="notes | async"></app-notes-viewer>
