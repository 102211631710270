import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { JobService } from 'app/stepper/job/services/job.service';
import { JobPublishService } from 'app/stepper/job/services/job-publish.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth.service';
import { Job } from 'app/stepper/job/model/job';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-job-builder-publish',
  templateUrl: './job-builder-publish.component.html',
  styleUrls: ['./job-builder-publish.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderPublishComponent implements OnInit {
  @Input() jobKey: string;
  @Input() deployToday: Date = null;
  job$: Observable<Job>;
  constructor(
    private jobService: JobService,
    private jobPublishService: JobPublishService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.job$ = this.jobService.getDraftJob(this.jobKey).pipe(
      map((job: Job) => {
        if (this.deployToday) {
          job = this.setJobVisibility(job, this.deployToday);
        }
        return job;
      })
    );
  }

  async publishJob(job: Job) {
    if (this.minReqForPublish(job)) {
      return this.jobPublishService.publishJob(this.handleJobBasedOnType(job));
    }
  }

  handleJobBasedOnType(job: Job): Job {
    // if (job.jobInfo.jobType === JobType.hospital) {
    //   const numberOfDays = 7 * 100; // set enddate
    //   const deployTodayWithEndDate = this.timeService.addTimeSpanToDate(new Date(), numberOfDays);
    //   job = this.setJobVisibility(job, deployTodayWithEndDate);
    // }
    return job;
  }

  private minReqForPublish(job): boolean {
    const text = this.jobService.minRequirementForPublish(job);
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else if (!this.authService.canPublishJob) {
      this.authService.notEnoughPermission();
      return false;
    } else {
      return true;
    }
  }

  private setJobVisibility(job: Job, endDate: Date, startDate = new Date()) {
    job.jobVisibilityPeriod.startDate = startDate;
    job.jobVisibilityPeriod.endDate = endDate;
    return job;
  }
}
