<ng-container>
  <mat-chip-list>
    <ng-container *ngIf="!expanded">
      <ng-container *ngFor="let i of filters | slice: 0:4">
        <mat-chip
          [class.clickable]="i.origin"
          (click)="navigateToSearchWithState(i)"
          >{{ i.value }}</mat-chip
        >
      </ng-container>
    </ng-container>
    <mat-chip-list *ngIf="expanded">
      <ng-container *ngFor="let i of filters">
        <mat-chip
          [class.clickable]="i.origin"
          (click)="navigateToSearchWithState(i)"
          >{{ i.value }}</mat-chip
        >
      </ng-container>
    </mat-chip-list>
  </mat-chip-list>
</ng-container>
<ng-container *ngIf="filters?.length > 4">
  <button
    class="more-btn"
    mat-icon-button
    (click)="$event.stopPropagation(); expanded = !expanded"
    [matTooltip]="(expanded ? 'showLess' : 'showMore') | preventHardcoding"
  >
    <mat-icon>{{ expanded ? 'expand_less' : 'more_horiz' }}</mat-icon>
  </button>
</ng-container>
