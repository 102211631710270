<div class="settings">
  <mat-accordion>
    <mat-expansion-panel
      [formGroup]="settingGroup.formGroup"
      [expanded]="false"
      *ngFor="let settingGroup of forms"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-icon>{{ settingGroup.icon }}</mat-icon>
        <mat-panel-title>
          <h6>{{ settingGroup.name | preventHardcoding }}</h6>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- <mat-form-field>
        <mat-label>Email Frequency</mat-label>
        <mat-select
          [formControl]="getMailFrequencyControl(settingGroup.formGroup)"
          [value]="getMailFrequencyControl(settingGroup.formGroup).value"
        >
          <mat-option *ngFor="let frq of getMailFrequencyValues()" [value]="frq">
            {{ frq }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>App Notifications</mat-label>
        <mat-select
          [formControl]="getAppControl(settingGroup.formGroup)"
          [value]="getAppValue(settingGroup.formGroup)"
        >
          <mat-option [value]="true"> On </mat-option>
          <mat-option [value]="false"> Off </mat-option>
        </mat-select>
      </mat-form-field> -->
      <p>
        <b>Email {{ 'Notifications' | preventHardcoding }}</b>
      </p>
      <div *ngFor="let subSettingGroup of settingGroup.subSettings">
        <mat-form-field>
          <mat-label>{{ subSettingGroup.name | preventHardcoding }}</mat-label>
          <mat-select
            [formControl]="getMailFrequencyControl(subSettingGroup.formGroup)"
            [value]="getMailFrequencyControl(subSettingGroup.formGroup).value"
          >
            <mat-option
              *ngFor="let frq of getMailFrequencyValues()"
              [value]="frq"
            >
              {{ frq | preventHardcoding }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <p>
        <b>App {{ 'Notifications' | preventHardcoding }}</b>
      </p>
      <div
        class="sub-setting"
        *ngFor="let subSettingGroup of settingGroup.subSettings"
      >
        <mat-slide-toggle
          [labelPosition]="'after'"
          color="primary"
          [formControl]="getAppControl(subSettingGroup.formGroup)"
        >
          <!-- <mat-icon>
            {{ subSettingGroup.icon }}
          </mat-icon> -->
          <div class="sub-setting-text">
            {{ subSettingGroup.name | preventHardcoding }}
          </div>
        </mat-slide-toggle>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<button mat-raised-button color="primary" (click)="saveSettings()">
  {{ 'SaveNotificationSettings' | preventHardcoding }}
</button>
