<form autocomplete="off" class="form-group">
  <mat-form-field appearance="standard">
    <mat-label>{{ titleTranslation | preventHardcoding }}</mat-label>
    <input
      title="address"
      matInput
      submit
      (focusout)="onFocusOut($event)"
      autocorrect="off"
      [value]="formattedAddress"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
      [formControl]="searchControl"
      [placeholder]="placeholderTranslation | preventHardcoding"
      [readonly]="inputReadonly"
    />
    <mat-hint *ngIf="hint">{{ hint | preventHardcoding }}</mat-hint>
  </mat-form-field>
</form>
