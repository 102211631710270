<ng-container *ngIf="!inlineStyling">
  <div class="picture-uploader">
    <input
      #imgFileInput
      hidden
      type="file"
      accept="image/*"
      (change)="fileChangeEvent($event)"
    />
    <img mat-card-image [src]="currentPictureUrl" />
    <img
      *ngIf="!currentPictureUrl && defaultPicture"
      mat-card-image
      [src]="defaultPicture"
    />
    <button
      *ngIf="!icon"
      [matTooltip]="tooltip"
      type="button"
      mat-raised-button
      (click)="imgFileInput.click()"
    >
      {{ btnText }}
    </button>
    <button
      *ngIf="icon"
      [matTooltip]="tooltip"
      type="button"
      mat-icon-button
      (click)="imgFileInput.click()"
    >
      <mat-icon>{{ icon }}</mat-icon>
    </button>
    &nbsp;
    <button (click)="removeImg()" *ngIf="currentPictureUrl" mat-icon-button>
      <mat-icon mat-icon>delete</mat-icon>
    </button>
  </div>
</ng-container>
<ng-container *ngIf="inlineStyling">
  <div class="picture-uploader">
    <input
      #imgFileInput
      hidden
      type="file"
      accept="image/*"
      (change)="fileChangeEvent($event)"
    />
    <button
      *ngIf="!icon"
      [matTooltip]="tooltip"
      type="button"
      mat-raised-button
      (click)="imgFileInput.click()"
    >
      {{ btnText }}
    </button>
    <button
      *ngIf="icon"
      [matTooltip]="tooltip"
      type="button"
      mat-icon-button
      (click)="imgFileInput.click()"
    >
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  </div>
  <ng-container
    *ngIf="currentPictureUrl || (!currentPictureUrl && defaultPicture)"
  >
    <div class="picture-preview">
      <img mat-card-image [src]="currentPictureUrl" />
      <img
        *ngIf="!currentPictureUrl && defaultPicture"
        mat-card-image
        [src]="defaultPicture"
      />

      <button (click)="removeImg()" *ngIf="currentPictureUrl" mat-icon-button>
        <mat-icon mat-icon>delete</mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
