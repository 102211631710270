import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MondoRoutes, RoutingModel } from '../../app.routing-model';
import { AuthGuard } from '../../core/auth.guard';
import { StepperModule } from '../stepper.module';
import { CvBuilderComponent } from './cv-builder/cv-builder.component';
import { CvComponent } from './cv.component';
import { AdditionalInformationComponent } from './steps/additional-information/additional-information.component';
import { ConferenceWorkshopComponent } from './steps/conference-workshop/conference-workshop.component';
import { EducationComponent } from './steps/education/education.component';
import { IntroComponent } from './steps/intro/intro.component';
import { NetworkStepComponent } from './steps/network/network-step.component';
import { PersonalDetailsComponent } from './steps/personal-details/personal-details.component';
import { PublicationsComponent } from './steps/publications/publications.component';
import { ResearchStayComponent } from './steps/research-stay/research-stay.component';
import { TeachingExperienceComponent } from './steps/teaching-experience/teaching-experience.component';
import { WorkExperienceComponent } from './steps/work-experience/work-experience.component';
import { CvControlsComponent } from './cv-controls';
import { CvViewComponent } from './cv-view';
import { CvBuilderService, CvService, CvFormService } from './services';
import { CvActionsComponent } from './cv-actions';
import { CvViewerModule } from 'app/viewer/cv/cv-viewer.module';
import { CVTreeComponent } from './components/mat-tree/cv-tree.component';
import { SharedModule } from 'app/shared/shared.module';
import { CategoriesComponent } from './steps/categories/categories.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { BasicStepComponent } from './steps/basic-step/basic-step.component';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { FileUploadModule } from 'app/file-upload/file-upload.module';
import { CanDeactiveGuardService } from 'app/can-deactive-guard.service';

export const builder = RoutingModel.cvBuilder;
const routes: Routes = [
  {
    path: builder.path + '/:cvId/:step',
    component: CvComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactiveGuardService],
    data: builder.routeData
  },
  {
    path: builder.path,
    redirectTo: builder.path + '/' + MondoRoutes.none + '/0'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StepperModule,
    SharedModule,
    CvViewerModule,
    SharedPipesModule,
    SharedUiModule,
    FileUploadModule,
  ],
  declarations: [
    CvComponent,
    BasicStepComponent,
    PersonalDetailsComponent,
    IntroComponent,
    WorkExperienceComponent,
    EducationComponent,
    ResearchStayComponent,
    ConferenceWorkshopComponent,
    TeachingExperienceComponent,
    AdditionalInformationComponent,
    PublicationsComponent,
    NetworkStepComponent,
    CvBuilderComponent,
    CvControlsComponent,
    CvViewComponent,
    CvActionsComponent,
    CVTreeComponent,
    CategoriesComponent,
    BasicStepComponent,
  ],
  providers: [
    CvService,
    CvFormService,
    CvBuilderService,
  ],
  exports: [
    PersonalDetailsComponent,
    CvBuilderComponent,
    CvControlsComponent,
    CvViewComponent,
    CVTreeComponent
  ],
  entryComponents: [
    BasicStepComponent,
    PersonalDetailsComponent,
    IntroComponent,
    WorkExperienceComponent,
    EducationComponent,
    ResearchStayComponent,
    ConferenceWorkshopComponent,
    TeachingExperienceComponent,
    AdditionalInformationComponent,
    PublicationsComponent,
    NetworkStepComponent,
    CategoriesComponent,
  ]
})
export class CVModule { }
