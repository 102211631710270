<div
  *ngIf="
    ('Foi' | preventHardcoding) && checkForCategory('Foi' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Foi"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX' | preventHardcodingFormatText: ('fois' | preventHardcoding)
        }}"
        [form]="experience"
        [patchStream]="patchInterest$"
        [controlName]="'interests'"
        [dataStream]="fieldsOfInterests$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('interest')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('foi' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    ('Category0' | preventHardcoding) &&
    checkForCategory('Category0' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Category0"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX'
            | preventHardcodingFormatText: ('categories0' | preventHardcoding)
        }}"
        [form]="experience"
        [patchStream]="patchCategory0$"
        [controlName]="'category0'"
        [dataStream]="getCategories0$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('category0')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('category0' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    ('Category2' | preventHardcoding) &&
    checkForCategory('Category2' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Category2"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX'
            | preventHardcodingFormatText: ('categories2' | preventHardcoding)
        }}"
        [form]="experience"
        [patchStream]="patchCategory2$"
        [controlName]="'category2'"
        [dataStream]="getCategories0$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('category0')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('category0' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    ('Category1' | preventHardcoding) &&
    checkForCategory('Category1' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Category1"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX'
            | preventHardcodingFormatText: ('categories1' | preventHardcoding)
        }}"
        [form]="experience"
        [patchStream]="patchCategory1$"
        [controlName]="'category1'"
        [dataStream]="getCategories1$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('category1')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('category1' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    ('Category3' | preventHardcoding) &&
    checkForCategory('Category3' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Category3"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX'
            | preventHardcodingFormatText: ('categories3' | preventHardcoding)
        }}"
        [form]="experience"
        [patchStream]="patchCategory3$"
        [controlName]="'category3'"
        [dataStream]="getCategories3$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('category3')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('category3' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    ('Tech' | preventHardcoding) && checkForCategory('Tech' | preventHardcoding)
  "
>
  <div>
    <div class="form-group">
      <app-mondo-hint type="Tech"></app-mondo-hint>
      <app-multi-selector
        [restrictSelection]="restrictSelection"
        [maxSelection]="maxSelection"
        placeholderText="{{
          'chooseX' | preventHardcodingFormatText: ('techs' | preventHardcoding)
        }}"
        [form]="experience"
        [controlName]="'techniques'"
        [patchStream]="patchTech$"
        [dataStream]="labTechniques$"
      >
      </app-multi-selector>
    </div>
    <div class="suggest-container">
      <button
        class="suggest-button"
        mat-raised-button
        (click)="openSuggestionDialog('tech')"
      >
        {{
          'suggestNewX'
            | preventHardcodingFormatText: ('tech' | preventHardcoding)
        }}
      </button>
    </div>
  </div>
</div>
<div class="spacer"></div>
