import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';
import { ChatService } from 'app/core/chat.service';
import { Observable } from 'rxjs';
import { ChatRoom } from 'app/shared/models/chat/chat-room';
import { ChatMessage } from 'app/shared/models/chat/chat-message';
import { AuthService } from 'app/core/auth.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss'],
})
export class ChatRoomComponent implements OnInit, OnChanges, AfterViewChecked {
  chatRoom$: Observable<any>;
  activeChatRoom$: Observable<ChatRoom>;
  activeMessages$: Observable<ChatMessage[]>;
  currentUser: MondoUser;
  @Input() activeChatKey: string;
  @ViewChild('messagesContainer', { static: false })
  private messagesContainer: ElementRef;
  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ChatRoomComponent>
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    if (this.activeChatKey) {
      this.setupChatRoom(this.activeChatKey);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.activeChatKey &&
      changes.activeChatKey.currentValue !== changes.activeChatKey.previousValue
    ) {
      this.setupChatRoom(changes.activeChatKey.currentValue);
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  okOkClicked() {
    this.dialogRef.close(true);
  }

  scrollToBottom(): void {
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    }
  }

  private async setupChatRoom(key) {
    this.chatRoom$ = await this.chatService.openOrCreateNewChatRoom(key);
    // this.activeChatKey = key;
    // this.activeMessages$ = this.chatService.getChatRoomsMessages(key);
    // this.activeChatRoom$ = this.chatService.getChatRoom(key);
    // this.scrollToBottom();
  }

  sendMessage(messageEvent: any) {
    this.chatService.sendMessage(messageEvent.value, this.activeChatKey);
    messageEvent.value = '';
  }

  isOwnMessage(uid): boolean {
    return this.authService.getCurrentUser().uid === uid;
  }
}
