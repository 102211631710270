<img class="bryder-img" src="assets/icons/Bryders7Trin.png">
<ul>
  <li>{{'checkPdfAtPage'| preventHardcoding}}</li>
  <li><a href="{{'applicationMaterialLinkUrl'| preventHardcoding}}" target="_blank">{{'applicationMaterialLinkText'| preventHardcoding}}</a></li>
  <li>{{'applicationProcessLine1'| preventHardcoding}}</li>
  <li>{{'applicationProcessLine2'| preventHardcoding}}</li>
  <li>{{'applicationProcessLine3'| preventHardcoding}}</li>
  <li>{{'applicationProcessLine4'| preventHardcoding}}</li>
</ul>
<span *ngIf="data.job.customAttributes['email']">{{'sendYourApplicationToThisEmail'|preventHardcoding}}: <a [href]="'mailto:' + data.job.customAttributes['email']" target="_blank">{{data.job.customAttributes['email']}}</a></span>
