<div class="cv_teaching_exp cv_section" *ngIf="teachingExperiences && teachingExperiences[teachingExperiences.length - 1].title">
  <h5 class="title_icon normal-regular">{{'teachingExperience'| preventHardcoding}}</h5>
  <div class="pdl-32" *ngFor="let teaching of teachingExperiences; trackBy: trackByFn">
    <table>
      <tr>
        <td width="135px">
          <div class="smaller-regular" *ngIf="teaching?.startDate">
            <span>{{ teaching.startDate | date:"yyyy" }}.{{ teaching.startDate | date:"MM" }}</span>
            <span *ngIf="!teaching?.isCurrent && teaching?.endDate?.getFullYear() > 0">
              - {{ teaching.endDate | date:"yyyy" }}.{{ teaching.endDate | date:"MM" }}</span>
            <span *ngIf="teaching.isCurrent">
              - {{'current'| preventHardcoding}}</span>
          </div>
        </td>
        <td>
          <span *ngIf="teaching.title && !teaching.university && !teaching.department && !teaching.country.code">{{'&nbsp;'}}</span>
          <span class="small-regular" *ngIf="teaching.title">{{teaching.title}}</span>
          <span class="small-regular" *ngIf="teaching.title && teaching.department || teaching.university || teaching.country.code">, </span>
          <span class="small-regular" *ngIf="teaching.university">{{teaching.university}}</span>
          <span class="small-regular" *ngIf="teaching.university && teaching.department">, </span>
          <span class="small-regular">{{teaching.department}}</span>
          <span *ngIf="teaching.department && teaching.country.code">, </span>
          <span class="small-regular">{{teaching.country?.code}}</span>
          <span class="smaller-light" *ngIf="teaching.description" [innerHTML]="teaching.description | safeHtml"></span>
        </td>
      </tr>
    </table>
  </div>
  <div class="divider clearfix"></div>
</div>
