import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataConstants } from 'app/shared/consts/dataConstants';
import {
  SuggestedTechnique,
  SuggestedFieldOfInterest,
  Log,
  LogAction,
  ErrorLog,
  SuggestedCategory0,
  SuggestedCategory1,
  SuggestedSubtype,
} from 'app/shared/models';
import { LoggingService } from './logging.service';
import { environment } from 'environments/environment';
import { hardcodedValues } from 'hardcodedValues';
import { DAO } from 'app/shared-services/db-access/dao';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SuggestionService {
  constructor(
    private db: DAO,
    private snackBar: MatSnackBar,
    private loggingService: LoggingService,
    private httpClient: HttpClient
  ) {}

  suggestTechnique(suggestedTechnique: SuggestedTechnique) {
    const newKey = this.db.createPushId();
    const newSuggestedTechnique = new SuggestedTechnique(
      suggestedTechnique.technique,
      suggestedTechnique.email,
      suggestedTechnique.category,
      newKey,
      suggestedTechnique.uid
    );
    this.db.list(DataConstants.SUGGESTEDTECHNIQUE).push(newSuggestedTechnique);
    this.loggingService.logAction(
      new Log(LogAction.suggestedTechnique, newSuggestedTechnique.technique)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Tech +
        ': ' +
        newSuggestedTechnique.technique +
        ' - ' +
        hardcodedValues.Category +
        ': ' +
        newSuggestedTechnique.category,
      newSuggestedTechnique.email
    );
  }

  suggestFieldOfInterest(suggestedFieldOfInterest: SuggestedFieldOfInterest) {
    const newKey = this.db.createPushId();
    const newSuggestedFieldOfInterest = new SuggestedFieldOfInterest(
      suggestedFieldOfInterest.fieldOfInterest,
      suggestedFieldOfInterest.email,
      suggestedFieldOfInterest.description,
      newKey,
      suggestedFieldOfInterest.uid
    );
    this.db
      .list(DataConstants.SUGGESTEDFIELDOFINTEREST)
      .push(newSuggestedFieldOfInterest);
    this.loggingService.logAction(
      new Log(
        LogAction.suggestedFieldOfInterest,
        newSuggestedFieldOfInterest.fieldOfInterest
      )
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Foi +
        ': ' +
        newSuggestedFieldOfInterest.fieldOfInterest +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggestedFieldOfInterest.description,
      newSuggestedFieldOfInterest.email
    );
  }

  suggestCategory0(suggested: SuggestedCategory0) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory0(
      suggested.category0,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY0).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory0, newSuggested.category0)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category0 +
        ': ' +
        newSuggested.category0 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestCategory1(suggested: SuggestedCategory1) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory1(
      suggested.category1,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY1).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory1, newSuggested.category1)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category1 +
        ': ' +
        newSuggested.category1 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestCategory2(suggested: SuggestedCategory0) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory0(
      suggested.category0,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY2).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory2, newSuggested.category0)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category2 +
        ': ' +
        newSuggested.category0 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestCategory3(suggested: SuggestedCategory0) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory0(
      suggested.category0,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY3).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory3, newSuggested.category0)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category3 +
        ': ' +
        newSuggested.category0 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestCategory4(suggested: SuggestedCategory0) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory0(
      suggested.category0,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY4).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory4, newSuggested.category0)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category4 +
        ': ' +
        newSuggested.category0 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestCategory5(suggested: SuggestedCategory0) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedCategory0(
      suggested.category0,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDCATEGORY5).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedCategory5, newSuggested.category0)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.Category5 +
        ': ' +
        newSuggested.category0 +
        ' - ' +
        hardcodedValues.Description +
        ': ' +
        newSuggested.description,
      newSuggested.email
    );
  }

  suggestNetworkSubType(suggested: SuggestedSubtype) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedSubtype(
      suggested.subtype,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDNETWORKSUBTYPE).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedNetworkSubtype, newSuggested.subtype)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.NetworkSubtype + ': ' + newSuggested.subtype,
      newSuggested.email
    );
  }

  suggestSiteSubType(suggested: SuggestedSubtype) {
    const newKey = this.db.createPushId();
    const newSuggested = new SuggestedSubtype(
      suggested.subtype,
      suggested.email,
      suggested.description,
      newKey,
      suggested.uid
    );
    this.db.list(DataConstants.SUGGESTEDSITESUBTYPE).push(newSuggested);
    this.loggingService.logAction(
      new Log(LogAction.suggestedSiteSubtype, newSuggested.subtype)
    );
    this.salute();
    this.suggestionAddedEmail(
      hardcodedValues.CompanySubtype + ': ' + newSuggested.subtype,
      newSuggested.email
    );
  }

  private salute(): void {
    this.snackBar.open(hardcodedValues.suggestionSalute, null, {
      duration: 5000,
      panelClass: ['snackbar-position-center'],
    });
  }

  suggestionAddedEmail(suggestion, email: string) {
    const url = environment.sendgridEmail;
    const body = {
      to: hardcodedValues.reviewMail,
      from: hardcodedValues.senderMail,
      subject: hardcodedValues.mailSubject,
      body: `Please review this suggestion from (${email}) as soon as possible... ${suggestion}`,
    };

    return this.httpClient
      .post(url, body)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        this.loggingService.logAction(new ErrorLog(err));
      });
  }
}
