<!-- <ng-container *ngIf="isBuilderShown$ | async"></ng-container>
<ng-container *ngIf="isBuilderShown || cv"> -->
<ng-container *ngIf="cv">
  <div class="progress-container">
    <div class="progress-grid">
      <div>
        <div
          style="display: flex; justify-content: center"
          *ngIf="getMinRequirementsText(cv).length > 0"
        >
          {{ 'Required' | preventHardcoding }}
        </div>
        <app-circle-progress
          style="display: flex; justify-content: center"
          [value]="getRequiredStatusPercentage(cv)"
        ></app-circle-progress>
      </div>
      <div style="display: grid; justify-content: center">
        <app-required-text-cv [cv]="cv"></app-required-text-cv>
        <app-publish-cv [cvKey]="cv.key" [showText]="false"></app-publish-cv>
      </div>
      <!-- <div>
        <div style="display: flex; justify-content: center;">{{'complete'| preventHardcoding}}</div>
        <app-circle-progress style="display: flex; justify-content: center;" [value]="getStatusPercentage(cv)"></app-circle-progress>
      </div> -->
    </div>

    <!-- <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>profile-progress-tracker!</mat-card-title>
      <mat-card-subtitle>CV</mat-card-subtitle>
    </mat-card-header> -->
    <!-- <mat-card-content >      -->
    <!-- <app-circle-progress style="display: flex; justify-content: center;" [value]="getStatusPercentage(cv)"></app-circle-progress>       -->
    <!-- </mat-card-content>
    <mat-card-actions>
    </mat-card-actions> -->
  </div>
</ng-container>
