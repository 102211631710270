import { Author } from './author';
import { CvField } from './cv-field';

export class Network extends CvField {
  public static fromJson({ authors, exclude }): Network {
    return new Network(
      authors ? authors.map(a => Author.fromJson(a)) : [],
      exclude
    );
  }
  constructor(public authors: Author[] = [], exclude = false) {
    super(exclude);
  }
}
