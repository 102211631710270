import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutingModel, MondoRoutes } from 'app/app.routing-model';
import { AuthGuard } from '../../core/auth.guard';
import { JobBuilderComponent } from './job-builder/job-builder.component';
import { JobComponent } from './job.component';
import { JobFormService } from './services/job-form.service';
import { JobService } from './services/job.service';
import { JobBuilderInfoComponent } from 'app/stepper/job/job-builder/steps/job-builder-info/job-builder-info.component';
import { JobBuilderCompanyComponent } from 'app/stepper/job/job-builder/steps/job-builder-company/job-builder-company.component';
import { JobBuilderResearchQualificationsComponent } from 'app/stepper/job/job-builder/steps/job-builder-research-qualifications/job-builder-research-qualifications.component';
import { JobBuilderContactComponent } from 'app/stepper/job/job-builder/steps/job-builder-contact/job-builder-contact.component';
import { StepperModule } from '../stepper.module';
import { PublishJobDialogComponent } from './components/publish-job-dialog/publish-job-dialog.component';
import { JobBuilderVisibilityComponent } from 'app/stepper/job/job-builder/steps/job-builder-visibility/job-builder-visibility.component';
import { JobBuilderPublishComponent } from './job-builder/steps/job-builder-publish/job-builder-publish.component';
import { JobPreviewDialogComponent } from './components/job-preview-dialog/job-preview-dialog.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';
import { FileUploadModule } from 'app/file-upload/file-upload.module';

export const job = RoutingModel.jobBuilder;
const routes = [
  {
    path: job.path + '/:jobId/:step',
    component: JobComponent,
    canActivate: [AuthGuard],
    data: job.routeData,
  },
  {
    path: job.path,
    redirectTo: job.path + '/' + MondoRoutes.none + '/0',
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StepperModule,
    SharedPipesModule,
    SharedUiModule,
    FileUploadModule,
  ],
  declarations: [
    JobComponent,
    JobBuilderComponent,
    JobBuilderInfoComponent,
    JobBuilderCompanyComponent,
    JobBuilderResearchQualificationsComponent,
    JobBuilderContactComponent,
    JobBuilderVisibilityComponent,
    PublishJobDialogComponent,
    JobBuilderPublishComponent,
    JobPreviewDialogComponent,
  ],
  providers: [JobService, JobFormService],
  entryComponents: [
    JobBuilderInfoComponent,
    JobBuilderCompanyComponent,
    JobBuilderResearchQualificationsComponent,
    JobBuilderContactComponent,
    JobBuilderVisibilityComponent,
    JobBuilderPublishComponent,
    JobPreviewDialogComponent,
    PublishJobDialogComponent,
  ],
  exports: [JobBuilderCompanyComponent],
})
export class JobModule {}
