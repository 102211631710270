import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RoutingModel } from 'app/app.routing-model';
import { RoutingService } from 'app/core/routing.service';
import { IFilter } from 'app/shared/models';

@Component({
  selector: 'app-i-filter-chip-list',
  templateUrl: './i-filter-chip-list.component.html',
  styleUrls: ['./i-filter-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IFilterChipListComponent implements OnInit {
  @Input() filters: IFilter[];

  expanded = false;

  constructor(private routingService: RoutingService) {}

  ngOnInit() {}

  navigateToSearchWithState(filter: IFilter) {
    if (filter.origin) {
      this.routingService.navigateWithNoReuse(
        [RoutingModel.scienceMatch.route],
        {
          state: { data: filter },
        }
      );
    }
  }
}
