<div class="cv_section" *ngIf="workAndEducations">
  <div class="label t_t_u">{{'educationAndWorkExperience'| preventHardcoding}}</div>
  <div class="cv_edu_list clearfix">
    <div *ngFor="let workEdu of workAndEducations; trackBy: trackByFn">
    <div class="cv_edu_sec" 
      [ngClass]="{
        'title-extra-long-no-desc': extraLongTitleNoDesc(workEdu),
        'title-extra-long': extraLongTitle(workEdu),
        'title-long-no-desc': longTitleNoDesc(workEdu),
        'title-long': longTitle(workEdu),
        'title-medium-no-desc': mediumTitleNoDesc(workEdu),
        'title-medium': mediumTitle(workEdu)
      }">        
      <h5 class="cv_edu_title small-regular" [ngClass]="workEdu.type" *ngIf="workEdu.title">{{workEdu.title}}
        <span *ngIf="getEducationLine(workEdu)">
          <small class="small-regular">{{getEducationLine(workEdu)}}</small>
          <br />
        </span>
        <div class="smaller-light" *ngIf="workEdu.exp?.startDate">
          <small class="smaller-light">{{ workEdu.exp.startDate | date:"yyyy" }}.{{ workEdu.exp.startDate | date:"MM" }}</small>
          <small class="smaller-light" *ngIf="!workEdu.exp?.isCurrent && workEdu.exp?.endDate">
            - {{ workEdu.exp.endDate | date:"yyyy" }}.{{ workEdu.exp.endDate | date:"MM" }}</small>
          <small class="smaller-light" *ngIf="workEdu.exp.isCurrent"> - {{'current'| preventHardcoding}}</small>
        </div>
      </h5>
      <div [id]="cvSteps.workExperience" *ngIf="getWork(workEdu); let work">
        <span *ngIf="work.jobTitle && !work.company && !work.country.code">{{'&nbsp;'}}</span>
        <span class="small-regular"> {{work.company}}</span>
        <span *ngIf="work.company && work.country.code">, </span>
        <span class="small-regular">{{work.country?.code}}</span>
        <span class="smaller-light" [innerHTML]="work.description | safeHtml"></span>
      </div>
      <div [id]="cvSteps.education" *ngIf="getEducation(workEdu); let edu">
        <span class="small-regular" *ngIf="edu.eduTitle && !edu.university && !edu.department && !edu.country.code">{{'&nbsp;'}}</span>
        <span class="small-regular" *ngIf="edu.university">{{edu.university}}</span>
        <span *ngIf="edu.university && (edu.department || edu.country.code)">, </span>
        <span class="small-regular" *ngIf="edu.department">{{edu.department}}</span>
        <span *ngIf="edu.department && edu.country.code">, </span>
        <span class="small-regular">{{edu.country?.code}}</span>
        <span class="smaller-light" [innerHTML]="edu.description | safeHtml"></span>
      </div>
      <div [id]="cvSteps.researchStay" *ngIf="getResearch(workEdu); let research">
        <span class="small-regular" *ngIf="research.projectTitle && !research.university && !research.department && !research.lab && !research.country.code">
          {{'&nbsp;'}}
        </span>
        <span class="small-regular" *ngIf="research.university">{{research.university}}</span>
        <span *ngIf="research.university && (research.department || research.lab || research.country.code)">, </span>
        <span class="small-regular" *ngIf="research.department">{{research.department}}</span>
        <span *ngIf="research.department && (research.lab || research.country.code)">, </span>
        <span class="small-regular" *ngIf="research.lab">{{research.lab}}</span>
        <span *ngIf="research.lab && research.country.code">, </span>
        <span class="small-regular">{{research.country?.code}}</span>
        <span class="smaller-light" [innerHTML]="research.description | safeHtml"></span>
      </div>
    </div>
    </div>
  </div>
  <div class="divider clearfix"></div>
</div>
