import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { JobLinks } from 'app/stepper/job/model/JobLinks';
import { ResearchLeader } from 'app/stepper/job/model/researchLeader';
import { ResearchGroup } from 'app/stepper/job/model/researchGroup';
import { Job } from 'app/stepper/job/model/job';

@Component({
  selector: 'app-job-builder-contact',
  templateUrl: './job-builder-contact.component.html',
  styleUrls: ['./job-builder-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderContactComponent implements OnInit {
  @Input() form: MondoFormGroup<Job>;

  constructor() {}

  ngOnInit() {}

  get jobType() {
    return this.form.getSafeControl(x => x.jobInfo.jobType).value;
  }

  get jobLinks(): MondoFormGroup<JobLinks> {
    return this.form.getSafeGroup(x => x.contact.jobLinks);
  }

  get urlJobSite() {
    return this.form.getSafeControl(x => x.contact.jobLinks.urlJobSite);
  }

  get urlJobApplication() {
    return this.form.getSafeControl(x => x.contact.jobLinks.urlJobApplication);
  }

  get info() {
    return this.form.getSafeControl(x => x.contact.info);
  }

  get contactEmail() {
    return this.form.getSafeControl(x => x.contact.contactEmail);
  }

  get contactPhone() {
    return this.form.getSafeControl(x => x.contact.contactPhone);
  }

  get researchGroup(): MondoFormGroup<ResearchGroup> {
    return this.form.getSafeGroup(x => x.contact.researchGroup);
  }

  get researchGroupName() {
    return this.form.getSafeControl(x => x.contact.researchGroup.name);
  }

  get researchLeader(): MondoFormGroup<ResearchLeader> {
    return this.form.getSafeGroup(x => x.contact.researchGroup.researchLeader);
  }

  get researchLeaderName() {
    return this.form.getSafeControl(x => x.contact.researchGroup.researchLeader.name);
  }
}
