<div *ngIf="draftJob">
  <!-- <h2 mat-dialog-title>{{'publishMenu'| preventHardcoding}}</h2> -->
  <div mat-dialog-content>
    <p>
      <strong>{{ 'status' | preventHardcoding }}: </strong
      >{{ draftJob.status.getDescription() | preventHardcoding }}
    </p>
    <!-- <p>
      <strong>{{'numberOfPublishedJobs'| preventHardcoding}}: </strong>{{numberOfPubishedJobs$ | async}}/{{allowedNumberOfJobs$ | async}}
    </p> -->

    <div *ngIf="draftJob.status.canPublish()">
      <i>{{ 'youCanPublishToMakeThisJobPublic' | preventHardcoding }}</i>
    </div>

    <!-- <div *ngIf="draftJob.status.isPublished() && !draftJob.status.isInSync()">
      <i>{{ 'draftHasBeenEdited' | preventHardcoding }}</i>
    </div> -->

    <div *ngIf="draftJob.status.canUnpublish()">
      <i>{{ 'unpublishToRemove' | preventHardcoding }}</i>
    </div>
    <div *ngIf="'registerVisitFunctions' | preventHardcoding">
      <i>
        <b>{{ 'VisitRegistrationsStoredAutomatically' | preventHardcoding }}</b>
      </i>
    </div>

    <div *ngIf="draftJob.jobVisibilityPeriod.startDate">
      <em>
        <small>
          {{ 'publicFrom' | preventHardcoding }}:
          <app-date
            [date]="draftJob?.jobVisibilityPeriod?.startDate"
            [endDate]="draftJob?.jobVisibilityPeriod?.endDate"
          ></app-date>
        </small>
        <!-- <small *ngIf="draftJob.jobVisibilityPeriod.endDate">
          - {{draftJob.jobVisibilityPeriod.endDate | date:"shortDate"}}
        </small> -->
      </em>
      <br />

      <!-- <div *ngIf="publishedJob$ | async as publishedJob">
        <em>
          <small *ngIf="draftJob.status.isPublished()">{{'yourCurrentPublicPeriod'| preventHardcoding}}:</small>
          <small *ngIf="!draftJob.status.isPublished()">{{'yourOldPublicPeriod'| preventHardcoding}}:</small>
          <small *ngIf="publishedJob?.jobVisibilityPeriod.startDate">
            {{publishedJob.jobVisibilityPeriod.startDate | date:"shortDate"}}</small>
          <small *ngIf="publishedJob?.jobVisibilityPeriod.endDate">
            - {{publishedJob.jobVisibilityPeriod.endDate | date:"shortDate"}}
          </small>
        </em>
        <br>
      </div> -->
    </div>

    <mat-error *ngIf="!canPublish(draftJob)">{{
      'youNeedToProvideVisibilityPeriod' | preventHardcoding
    }}</mat-error>
    <mat-error
      *ngIf="
        (canPublishJob$ | async) === false && !draftJob.status.isPublished()
      "
      >{{ 'youHaveReachedMaximumPublishedJobs' | preventHardcoding }}</mat-error
    >
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div
      class="top-actions"
      *ngIf="'registerVisitFunctions' | preventHardcoding"
    >
      <div
        *ngIf="'RegisterDirectMatch' | preventHardcoding"
        class="centeredBtnContainer"
      >
        <button
          mat-raised-button
          color="primary"
          (click)="directMatch(draftJob)"
        >
          {{ 'RegisterDirectMatch' | preventHardcoding }}
        </button>
      </div>
    </div>

    <div class="bottom-actions">
      <div *ngIf="draftJob.status.canUnpublish()">
        <button
          color="warn"
          mat-raised-button
          (click)="onUnpublishClicked()"
          tabindex="-1"
        >
          <mat-icon>get_app</mat-icon>
          {{ 'unpublish' | preventHardcoding }}
        </button>
      </div>

      <div
        *ngIf="
          draftJob.status.canRepublish() ||
          (draftJob.status.canUnpublish() && !draftJob.status.canRepublish())
        "
      >
        <span
          matTooltip="{{ 'NoChangesHasBeenMade' | preventHardcoding }}"
          [matTooltipDisabled]="draftJob.status.canRepublish()"
        >
          <button
            color="primary"
            mat-raised-button
            (click)="onRepublishClicked()"
            [disabled]="
              !canPublish(draftJob) || !draftJob.status.canRepublish()
            "
          >
            <mat-icon>autorenew</mat-icon>{{ 'republish' | preventHardcoding }}
          </button>
        </span>
      </div>

      <div *ngIf="draftJob.status.canQueueForPublish()">
        <button
          color="primary"
          mat-raised-button
          (click)="onPublishClicked()"
          [disabled]="
            !canPublish(draftJob) || (canPublishJob$ | async) === false
          "
          color="primary"
          cdkFocusInitial
        >
          <mat-icon>publish</mat-icon>{{ 'PublishJob' | preventHardcoding }}
        </button>
      </div>
      <button mat-button mat-dialog-close tabindex="-1">
        {{ 'cancel' | preventHardcoding }}
      </button>
    </div>
  </div>
</div>
