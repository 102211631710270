<div class="date-picker-form-container">
  <mat-form-field
    class="date-picker-form"
    appearance="standard"
    (click)="openPicker()"
  >
    <mat-label>{{ dateOptions.placeholder | preventHardcoding }}</mat-label>
    <input
      [readonly]="true"
      [formControl]="displayDate"
      type="text"
      #dateInputField
      (click)="picker.open()"
      (dateChange)="dateChanged($event.value)"
      [min]="dateOptions.minDate"
      [max]="dateOptions.maxDate"
      matInput
      [matDatepicker]="picker"
      [placeholder]="dateOptions.placeholder | preventHardcoding"
    />
    <mat-datepicker
      disabled="false"
      [panelClass]="getBtnClass()"
      (opened)="pickerOpened()"
      (monthSelected)="monthSelected($event)"
      (yearSelected)="yearSelected($event)"
      [startAt]="dateOptions.startDate"
      startView="multi-year"
      #picker
    ></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-hint>{{ 'dateOfBirthHint' | preventHardcoding }}</mat-hint>
  </mat-form-field>
  <button *ngIf="dateOptions.showClearBtn" (click)="clear()" mat-icon-button>
    <mat-icon>clear</mat-icon>
  </button>
</div>
