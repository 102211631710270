import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LanguageDetails } from 'app/shared/models/languages/language-details';
import { Languages } from 'app/shared/models/languages/languages';


@Component({
  selector: 'app-viewer-languages',
  templateUrl: './viewer-languages.component.html',
  styleUrls: ['./viewer-languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewerLanguagesComponent implements OnInit {
  @Input() languages: Languages;
  @Input() languageTitle;

  constructor() { }

  ngOnInit() { }

  get languageDetails(): LanguageDetails[] {
    return this.languages.languages
      ? this.languages.languages.map(
        lang => new LanguageDetails(lang.uid, lang.cvId, lang.language, lang.level)
      )
      : null;
  }

  trackByFn(index, item) {
    return (item.key || item.id || item.uid);
  }
}
