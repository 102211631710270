<ng-container>
  <div class="row">
    <h4>
      {{
        'suggestNewX'
          | preventHardcodingFormatText: ('category3' | preventHardcoding)
      }}
    </h4>
    <p>
      {{ 'ifYouHaveSuggestion' | preventHardcoding }}
    </p>
  </div>
  <div class="row">
    <div class="col-md-12 form-area">
      <form [formGroup]="suggestionForm" *ngIf="suggestionForm">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'Category3' | preventHardcoding }}</mat-label>
              <input matInput formControlName="suggested" autocomplete="off" />
              <mat-error
                *ngIf="
                  suggestionForm.get('suggested').hasError('minlength') &&
                  !suggestionForm.get('suggested').hasError('required')
                "
              >
                {{ 'Category3' | preventHardcoding }}
                {{ 'mustBe2Characters' | preventHardcoding }}
              </mat-error>
              <mat-error
                *ngIf="suggestionForm.get('suggested').hasError('required')"
              >
                {{ 'Category3' | preventHardcoding }}
                {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'contactEmail' | preventHardcoding }}</mat-label>
              <input matInput formControlName="email" />
              <mat-error
                *ngIf="
                  suggestionForm.get('email').hasError('email') &&
                  !suggestionForm.get('email').hasError('required')
                "
              >
                {{ 'enterValidMail' | preventHardcoding }}
              </mat-error>
              <mat-error
                *ngIf="suggestionForm.get('email').hasError('required')"
              >
                Email {{ 'mustBeFilledOut' | preventHardcoding }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'Description' | preventHardcoding }}</mat-label>
              <mat-hint>{{ 'optional' | preventHardcoding }}</mat-hint>
              <input
                matInput
                formControlName="description"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-button (click)="close()">
              {{ 'cancel' | preventHardcoding }}
            </button>
            <button
              mat-raised-button
              color="primary"
              (click)="sendSuggestion()"
              [disabled]="suggestionForm.invalid"
            >
              {{ 'sendSuggestion' | preventHardcoding }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
