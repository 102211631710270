import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { NotificationCenterComponent } from './components/notification-center/notification-center.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NotificationItemComponent } from './components/notification-center/notification-item/notification-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
@NgModule({
  declarations: [
    NotificationSettingsComponent,
    NotificationCenterComponent,
    NotificationItemComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    PerfectScrollbarModule,
    SharedPipesModule,
    RouterModule,
    InfiniteScrollModule,
    MatCardModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ScrollingModule,
  ],
  exports: [NotificationCenterComponent, NotificationSettingsComponent],
  entryComponents: [NotificationCenterComponent],
})
export class NotificationsNewModule {}
