<div *ngIf="qualifications">
  <div *ngIf="qualificationFreeText">
    <app-icon-title
      title="{{ 'qualifications' | preventHardcoding }}"
      iconPath="/assets/icons/Qualifications1.svg"
    >
    </app-icon-title>
    <span [innerHTML]="qualificationFreeText | safeHtml"></span>
  </div>

  <div
    class="skills-wrap"
    *ngIf="
      wantedDaysOfExperience0 ||
      wantedDaysOfExperience1 ||
      wantedDaysOfExperience2 ||
      wantedDaysOfExperience3 ||
      wantedDaysOfExperience4 ||
      wantedDaysOfExperience5 ||
      minimumEducationalLevel
    "
  >
    <app-icon-title
      title="{{
        'wantedX'
          | preventHardcodingFormatText: ('experience' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Calendar1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngIf="wantedDaysOfExperience0">
        {{ wantedDaysOfExperience0 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience0 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience0Experience' | preventHardcoding }}
      </li>
      <li *ngIf="wantedDaysOfExperience1">
        {{ wantedDaysOfExperience1 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience1 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience1Experience' | preventHardcoding }}
      </li>
      <li *ngIf="wantedDaysOfExperience2">
        {{ wantedDaysOfExperience2 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience2 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience2Experience' | preventHardcoding }}
      </li>
      <li *ngIf="wantedDaysOfExperience3">
        {{ wantedDaysOfExperience3 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience3 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience3Experience' | preventHardcoding }}
      </li>
      <li *ngIf="wantedDaysOfExperience4">
        {{ wantedDaysOfExperience4 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience4 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience4Experience' | preventHardcoding }}
      </li>
      <li *ngIf="wantedDaysOfExperience5">
        {{ wantedDaysOfExperience5 }} {{ 'year' | preventHardcoding
        }}<span *ngIf="wantedDaysOfExperience5 != 1">{{
          's' | preventHardcoding
        }}</span>
        {{ 'ofExperience5Experience' | preventHardcoding }}
      </li>
      <li *ngIf="minimumEducationalLevel">
        {{ 'minimumEducationalLevel' | preventHardcoding }}:
        {{ minimumEducationalLevel | preventHardcoding }}
      </li>
    </ul>
  </div>

  <div
    class="skills-wrap"
    *ngIf="('Foi' | preventHardcoding) && wantedFieldOfInterest.length"
  >
    <app-icon-title
      title="{{
        'wantedX' | preventHardcodingFormatText: ('fois' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let interest of wantedFieldOfInterest; trackBy: trackByFn">
        {{ interest.name }}
      </li>
    </ul>
  </div>

  <div
    class="skills-wrap"
    *ngIf="('Category0' | preventHardcoding) && category0.length"
  >
    <app-icon-title
      title="{{
        'wantedX'
          | preventHardcodingFormatText: ('categories0' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let cat of category0; trackBy: trackByFn">{{ cat.name }}</li>
    </ul>
  </div>

  <div
    class="skills-wrap"
    *ngIf="('Category2' | preventHardcoding) && category2.length"
  >
    <app-icon-title
      title="{{
        'wantedX'
          | preventHardcodingFormatText: ('categories2' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let cat of category2; trackBy: trackByFn">{{ cat.name }}</li>
    </ul>
  </div>

  <div class="skills-wrap" *ngIf="canSeeCat1() && category1.length">
    <app-icon-title
      title="{{
        'wantedX'
          | preventHardcodingFormatText: ('categories1' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let cat of category1; trackBy: trackByFn">{{ cat.name }}</li>
    </ul>
  </div>

  <div
    class="skills-wrap"
    *ngIf="('Category3' | preventHardcoding) && category3.length"
  >
    <app-icon-title
      title="{{
        'wantedX'
          | preventHardcodingFormatText: ('categories3' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let cat of category3; trackBy: trackByFn">{{ cat.name }}</li>
    </ul>
  </div>

  <div
    class="skills-wrap"
    *ngIf="('Tech' | preventHardcoding) && wantedTechniques.length"
  >
    <app-icon-title
      title="{{
        'wantedX' | preventHardcodingFormatText: ('techs' | preventHardcoding)
      }}"
      iconPath="/assets/icons/Fields1.svg"
    >
    </app-icon-title>
    <ul class="double-column smaller-light">
      <li *ngFor="let technique of wantedTechniques; trackBy: trackByFn">
        {{ technique.name }}
      </li>
    </ul>
  </div>
</div>
