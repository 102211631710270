<ng-container *ngIf="'showRelatedJobsFunctions' | preventHardcoding">
  <div class="related-container" *ngIf="relatedIds$ | async as jobs">
    <button
      mat-button
      class="toggle-button"
      [matTooltip]="tooltip | preventHardcoding"
      (click)="showJobs = !showJobs"
    >
      <mat-icon *ngIf="!showJobs">expand_more</mat-icon>
      <mat-icon *ngIf="showJobs">expand_less</mat-icon>
    </button>
    <ng-container *ngIf="showJobs">
      <div class="related-jobs-container">
        <ng-container *ngFor="let job of jobs">
          <ng-container *ngIf="job | async as job">
            <ng-container *ngIf="true as toggled">
              <mat-card (click)="showPreview(job.key)">
                <mat-card-content class="job-teaser-title"
                  ><div>
                    <h5>{{ job?.jobInfo?.position | titlecase }}</h5>
                    <div
                      class="job-address"
                      *ngIf="job?.contact?.researchGroup?.name"
                    >
                      {{ job?.contact?.researchGroup?.name
                      }}<span *ngIf="job?.company?.location?.city"
                        >, {{ job?.company?.location?.city }}</span
                      >
                    </div>
                    <div
                      class="job-address"
                      *ngIf="
                        !job?.contact?.researchGroup?.name &&
                        job?.company?.location?.city
                      "
                    >
                      {{ job?.company?.location?.city }}
                    </div>
                    <div class="job-type">
                      {{
                        'JobType' + job?.jobInfo?.jobType | preventHardcoding
                      }}
                    </div>
                  </div>
                  <div>
                    {{ 'applicationDeadline' | preventHardcoding }}:
                    <app-date
                      [date]="job?.jobVisibilityPeriod?.endDate?.getTime()"
                    ></app-date>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
