<div class="item-selector">
  <mat-list>
    <mat-list-item
      class="item-line"
      [ngClass]="isCurrent(item?.key) ? 'current' : 'not-current'"
      *ngFor="let item of items; trackBy: trackByFn"
      (click)="navigateToItem(item.key)"
    >
      <span class="item-name">{{ item?.name }}</span>
      <span class="mx-auto"></span>
      <div class="text-right" *ngIf="item">
        <button
          mat-icon-button
          *ngIf="item.type === itemTypes.CV"
          (click)="dublicate(item, $event)"
        >
          <mat-icon matTooltip="{{ 'duplicate' | preventHardcoding }}"
            >file_copy</mat-icon
          >
        </button>
        <!-- <button mat-icon-button (click)="edit(item, $event)">
          <mat-icon matTooltip="{{'rename'| preventHardcoding}}">edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(item, $event)">
          <mat-icon matTooltip="{{'delete'| preventHardcoding}}">delete</mat-icon>
        </button> -->

        <!-- Backwards compatible CV solution -->
        <!-- <button *ngIf="item.type === itemTypes.CV" mat-icon-button (click)="publish(item, $event)">
          <mat-icon matTooltip="{{'saveToAcademondo'| preventHardcoding}}">publish</mat-icon>
        </button> -->
        <button *ngIf="item.type === itemTypes.CV" mat-icon-button disabled>
          <img
            matTooltip="{{ 'PublishedCVProfileTag' | preventHardcoding }}"
            class="am-logo"
            *ngIf="item.status.isPublished() && item.status.isPubliclyVisible()"
            src="/assets/logos/AM.svg"
          />
          <img
            matTooltip="{{ 'queuedForPublishing' | preventHardcoding }}"
            class="am-logo pending"
            *ngIf="
              item.status.isPublished() && !item.status.isPubliclyVisible()
            "
            src="/assets/logos/AM.svg"
          />
        </button>

        <!-- Generic future solution -->
        <!-- <button *ngIf="item.type === itemTypes.Job" mat-icon-button (click)="publish(item, $event)">
          <mat-icon *ngIf="!item.status.isPublished()" matTooltip="{{'saveToAcademondo'| preventHardcoding}}">publish</mat-icon>
          <mat-icon *ngIf="item.status.isPublished() && !item.status.isInSync()" matTooltip="{{'republish'| preventHardcoding}}">autorenew</mat-icon>
          <mat-icon *ngIf="item.status.isPublished() && item.status.isInSync()" matTooltip="{{'unpublished'| preventHardcoding}}">get_app</mat-icon>
        </button> -->
        <button *ngIf="item.type === itemTypes.Job" mat-icon-button disabled>
          <img
            matTooltip="{{ 'published' | preventHardcoding }}"
            class="am-logo"
            *ngIf="item.status.isPublished() && item.status.isPubliclyVisible()"
            src="/assets/logos/AM.svg"
          />
          <img
            matTooltip="{{ 'queuedForPublishing' | preventHardcoding }}"
            class="am-logo pending"
            *ngIf="
              item.status.isPublished() && !item.status.isPubliclyVisible()
            "
            src="/assets/logos/AM.svg"
          />
        </button>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <button class="add-btn" mat-button (click)="add($event)">
        <mat-icon>add</mat-icon>
        <span>{{ 'New' | preventHardcoding }}</span>
      </button>
    </mat-list-item>
  </mat-list>
</div>
