<div class="form-group" [formGroup]="form">
  <ng-container *ngIf="!showBasicStep">
    <mat-form-field appearance="standard">
      <mat-label>{{ 'firstName' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'firstName' | preventHardcoding }}"
        matInput
        name="fname"
        formControlName="firstName"
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="'showMiddleName' | preventHardcoding"
      appearance="standard"
    >
      <mat-label>{{ 'middleName' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'middleName' | preventHardcoding }}"
        matInput
        name="mname"
        formControlName="middleName"
      />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>{{ 'surname' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'surname' | preventHardcoding }}"
        matInput
        name="lname"
        formControlName="lastName"
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="'showSexInPersonalDetails' | preventHardcoding"
      appearance="standard"
    >
      <mat-label>{{ 'Sex' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'Sex' | preventHardcoding }}"
        matInput
        name="sex"
        formControlName="sex"
      />
    </mat-form-field>
    <ng-container *ngIf="canBeAnonymous()">
      <div>
        <mat-checkbox color="primary" formControlName="exclude">
          {{ 'Anonymise' | preventHardcoding }}
        </mat-checkbox>
      </div>
      {{ 'descOfAnonymous' | preventHardcoding }}
    </ng-container>
    <mat-form-field
      appearance="standard"
      *ngIf="'showApplicationProcessFunctions' | preventHardcoding"
    >
      <mat-label>{{ 'EnterCprWithoutDash' | preventHardcoding }}</mat-label>
      <input
        title="cpr"
        matInput
        formControlName="cpr"
        input="number"
        maxlength="10"
        minlength="10"
        required
      />
      <mat-error
        *ngIf="
          form.get('cpr').hasError('maxLength') ||
          form.get('cpr').hasError('minLength')
        "
      >
        {{ 'CprMustBeFilledOut' | preventHardcoding }}
      </mat-error>
      <mat-error *ngIf="form.get('cpr').hasError('pattern')">
        {{ 'OnlyNumbersAllowedDontUseDash' | preventHardcoding }}
      </mat-error>
      <mat-error *ngIf="form.get('cpr').hasError('CPR')">
        {{ 'cpr' | preventHardcoding }} {{ 'invalid' | preventHardcoding }}
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="'GraduateProgram' | preventHardcoding">
      <div>
        <mat-label>{{ 'GraduateProgram' | preventHardcoding }}</mat-label>
        <app-mondo-hint type="GraduateProgram"></app-mondo-hint>
      </div>
      <div class="graduate-dimittend-selector">
        <mat-radio-group formControlName="graduateProgram">
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="true"
            >{{ 'yes' | preventHardcoding }}</mat-radio-button
          >
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="false"
            >{{ 'no' | preventHardcoding }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </ng-container>
    <ng-container *ngIf="'Dimittendsats' | preventHardcoding">
      <div>
        <mat-label>{{ 'Dimittendsats' | preventHardcoding }}</mat-label>
        <app-mondo-hint type="Dimittendsats"></app-mondo-hint>
      </div>
      <div class="graduate-dimittend-selector">
        <mat-radio-group formControlName="dimittendsats">
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="true"
            >{{ 'yes' | preventHardcoding }}</mat-radio-button
          >
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="false"
            >{{ 'no' | preventHardcoding }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </ng-container>
    <ng-container *ngIf="'Akademikerkampagnen' | preventHardcoding">
      <div>
        <mat-label>{{ 'Akademikerkampagnen' | preventHardcoding }}</mat-label>
        <app-mondo-hint type="Akademikerkampagnen"></app-mondo-hint>
      </div>
      <div class="graduate-dimittend-selector">
        <mat-radio-group formControlName="akademikerkampagnen">
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="true"
            >{{ 'yes' | preventHardcoding }}</mat-radio-button
          >
          <mat-radio-button
            labelPosition="after"
            style="display: block"
            color="primary"
            [value]="false"
            >{{ 'no' | preventHardcoding }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </ng-container>
  </ng-container>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'academicTitle' | preventHardcoding }}</mat-label>
    <input
      title="{{ 'academicTitle' | preventHardcoding }}"
      matInput
      formControlName="academicTitle"
    />
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'CompanyName' | preventHardcoding }}</mat-label>
    <input
      title="{{ 'CompanyName' | preventHardcoding }}"
      matInput
      formControlName="companyName"
    />
  </mat-form-field>
  <mat-form-field
    *ngIf="'showDepartmentInPersonalDetails' | preventHardcoding"
    appearance="standard"
  >
    <mat-label>{{ 'Department' | preventHardcoding }}</mat-label>
    <input
      title="{{ 'Department' | preventHardcoding }}"
      matInput
      formControlName="department"
    />
  </mat-form-field>
  <div style="position: relative">
    <app-date-picker
      [dateOptions]="dobPickerOptions"
      [fControl]="dateOfBirth"
    ></app-date-picker>
  </div>
  <ng-container *ngIf="!showBasicStep">
    <div class="sub-group" formGroupName="address">
      <app-address-selector
        [locationForm]="location"
        hint="cvAddressDisclaimer"
      ></app-address-selector>
    </div>
  </ng-container>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'personalLinkedin' | preventHardcoding }}</mat-label>
    <input
      matInput
      type="url"
      formControlName="linkedin"
      placeholder="{{ 'linkedUrl' | preventHardcoding }}"
    />
  </mat-form-field>
  <mat-form-field
    *ngIf="'showGithub' | preventHardcoding"
    appearance="standard"
  >
    <mat-label>{{ 'personalGithub' | preventHardcoding }}</mat-label>
    <input
      matInput
      type="url"
      formControlName="github"
      placeholder="{{ 'githubUrl' | preventHardcoding }}"
    />
  </mat-form-field>
  <mat-form-field
    *ngIf="'showTwitter' | preventHardcoding"
    appearance="standard"
  >
    <mat-label>{{ 'personalTwitter' | preventHardcoding }}</mat-label>
    <input
      matInput
      type="url"
      formControlName="twitter"
      placeholder="{{ 'twitterUrl' | preventHardcoding }}"
    />
  </mat-form-field>

  <!-- <app-file-upload
    btnText="{{ 'uploadCoverImage' | preventHardcoding }}"
    [containWithinAspectRatio]="false"
    [ratio]="16 / 5"
    [currentPictureUrl]="coverUrl.value"
    [filePath]="coverPath"
    (complete)="onCoverUpload($event)"
  >
  </app-file-upload> -->

  <app-file-upload
    [ratio]="3 / 4"
    btnText="{{ 'uploadProfilePicture' | preventHardcoding }}"
    [defaultPicture]="'/assets/logos/Mayn.svg'"
    [currentPictureUrl]="pictureUrl.value"
    [filePath]="imagePath"
    (complete)="onProfilePicUpload($event)"
  ></app-file-upload>

  <div class="sub-group" formGroupName="contactInfo">
    <mat-form-field appearance="standard">
      <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'Email' | preventHardcoding }}"
        matInput
        type="email"
        name="email"
        formControlName="email"
      />
      <mat-error *ngIf="email?.errors?.email">{{
        'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>{{ 'phone' | preventHardcoding }}</mat-label>
      <input
        title="{{ 'phone' | preventHardcoding }}"
        matInput
        type="tel"
        name="phone"
        formControlName="phone"
      />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>{{ 'visitOfficialWebsite' | preventHardcoding }}</mat-label>
      <input
        title="website"
        matInput
        type="text"
        name="website"
        formControlName="website"
      />
    </mat-form-field>
  </div>
</div>
