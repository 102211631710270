<h4>{{title| preventHardcoding}}</h4>
<form [formGroup]="resetForm" (ngSubmit)="submit()">
    <mat-form-field appearance="standard">
        <mat-label>{{'NewEmail'| preventHardcoding}}</mat-label>
    <input type="email" name="email" matInput formControlName="email" required autocomplete="off">
  </mat-form-field>
  <mat-error class="small" *ngIf="resetForm.get('email').hasError('email')">
    {{'EmailMustBeAnEmailExSupportAtAcademondoCom'| preventHardcoding}}
  </mat-error>  
  <div class="footer">
    <button mat-raised-button color="primary" type="submit" [disabled]="!resetForm.valid">
      {{'changeEmail'| preventHardcoding}}
    </button>
    <mat-error class="small" *ngIf="error">
      {{errorMsg}}
    </mat-error>
  </div>
</form>
<app-splash-screen *ngIf="loading"></app-splash-screen>