import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { CvViewerModule } from 'app/viewer/cv/cv-viewer.module';
import { CandidateResultViewerComponent } from '../candidate_search-viewer/viewer/candidate-result-viewer.component';
import { SearchService } from '../../core/search.service';
import { PreviewCvComponent } from '../common/templates/preview-cv/preview-cv.component';
import { CompanyCardComponent } from '../common/templates/company-card/company-card.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
import { PreviewSiteComponent } from '../common/templates/preview-site/preview-site.component';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';

@NgModule({
  imports: [
    SharedModule,
    SharedUiModule,
    CvViewerModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
  declarations: [
    CandidateResultViewerComponent,
    CompanyCardComponent,
    PreviewCvComponent,
    PreviewSiteComponent,
  ],
  providers: [SearchService],
  exports: [
    CandidateResultViewerComponent,
    CompanyCardComponent,
    PreviewCvComponent,
    PreviewSiteComponent,
  ],
  entryComponents: [PreviewCvComponent, PreviewSiteComponent],
})
export class InviteSearchModule {}
