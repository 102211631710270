<div class="form-group" [formGroup]="form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'firstName' | preventHardcoding }}</mat-label>
    <input
      title="first name"
      matInput
      name="fname"
      formControlName="firstName"
    />
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-label>{{ 'surname' | preventHardcoding }}</mat-label>
    <input title="last name" matInput name="lname" formControlName="lastName" />
  </mat-form-field>
  <ng-container *ngIf="canBeAnonymous()">
    <div>
      <mat-checkbox color="primary" formControlName="exclude">
        {{ 'Anonymise' | preventHardcoding }}
      </mat-checkbox>
    </div>
    {{ 'descOfAnonymous' | preventHardcoding }}
  </ng-container>
  <mat-form-field
    appearance="standard"
    *ngIf="'showApplicationProcessFunctions' | preventHardcoding"
  >
    <mat-label>{{ 'EnterCprWithoutDash' | preventHardcoding }}</mat-label>
    <input
      title="cpr"
      matInput
      formControlName="cpr"
      input="number"
      maxlength="10"
      minlength="10"
      required
    />
    <mat-error
      *ngIf="
        form.get('cpr').hasError('maxLength') ||
        form.get('cpr').hasError('minLength')
      "
    >
      {{ 'CprMustBeFilledOut' | preventHardcoding }}
    </mat-error>
    <mat-error *ngIf="form.get('cpr').hasError('pattern')">
      {{ 'OnlyNumbersAllowedDontUseDash' | preventHardcoding }}
    </mat-error>
    <mat-error *ngIf="form.get('cpr').hasError('CPR')">
      {{ 'cpr' | preventHardcoding }} {{ 'invalid' | preventHardcoding }}
    </mat-error>
  </mat-form-field>
  <div class="sub-group" formGroupName="address">
    <app-address-selector
      [locationForm]="location"
      titleTranslation="cvAddress"
      hint="cvAddressDisclaimer"
    ></app-address-selector>
  </div>

  <div
    *ngIf="
      ('Foi' | preventHardcoding) && checkForCategory('Foi' | preventHardcoding)
    "
  >
    <div>
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('fois' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchInterest$"
          [controlName]="'interests'"
          [dataStream]="fieldsOfInterests$"
        >
        </app-multi-selector>
      </div>
      <div class="suggest-container">
        <button
          class="suggest-button"
          mat-raised-button
          (click)="openSuggestionDialog('interest')"
        >
          {{
            'suggestNewX'
              | preventHardcodingFormatText: ('foi' | preventHardcoding)
          }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      ('Category0' | preventHardcoding) &&
      checkForCategory('Category0' | preventHardcoding)
    "
  >
    <div>
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories0' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory0$"
          [controlName]="'category0'"
          [dataStream]="getCategories0$"
        >
        </app-multi-selector>
      </div>
      <div class="suggest-container">
        <button
          class="suggest-button"
          mat-raised-button
          (click)="openSuggestionDialog('category0')"
        >
          {{
            'suggestNewX'
              | preventHardcodingFormatText: ('category0' | preventHardcoding)
          }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      ('Category1' | preventHardcoding) &&
      checkForCategory('Category1' | preventHardcoding)
    "
  >
    <div>
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('categories1' | preventHardcoding)
          }}"
          [form]="experience"
          [patchStream]="patchCategory1$"
          [controlName]="'category1'"
          [dataStream]="getCategories1$"
        >
        </app-multi-selector>
      </div>
      <div class="suggest-container">
        <button
          class="suggest-button"
          mat-raised-button
          (click)="openSuggestionDialog('category1')"
        >
          {{
            'suggestNewX'
              | preventHardcodingFormatText: ('category1' | preventHardcoding)
          }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      ('Tech' | preventHardcoding) &&
      checkForCategory('Tech' | preventHardcoding)
    "
  >
    <div>
      <div class="form-group">
        <app-multi-selector
          placeholderText="{{
            'chooseX'
              | preventHardcodingFormatText: ('techs' | preventHardcoding)
          }}"
          [form]="experience"
          [controlName]="'techniques'"
          [patchStream]="patchTech$"
          [dataStream]="labTechniques$"
        >
        </app-multi-selector>
      </div>
      <div class="suggest-container">
        <button
          class="suggest-button"
          mat-raised-button
          (click)="openSuggestionDialog('tech')"
        >
          {{
            'suggestNewX'
              | preventHardcodingFormatText: ('tech' | preventHardcoding)
          }}
        </button>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
  <div class="next-button">
    <!-- <app-publish-cv *ngIf="cvForm" [cvKey]="cvKey" [showText]="false"></app-publish-cv> -->
    <button mat-raised-button color="primary" matStepperNext>
      {{ btnText | preventHardcoding }}
      <mat-icon>
        {{ vertical ? 'keyboard_arrow_down' : 'chevron_right' }}
      </mat-icon>
    </button>
  </div>

  <!-- <app-file-upload [ratio]="3/4" btnText="{{'uploadProfilePicture'| preventHardcoding}}" [defaultPicture]="'/assets/logos/Mayn.svg'"
      [currentPictureUrl]="pictureUrl.value" [filePath]="imagePath" compressQuality="35" (complete)="onProfilePicUpload($event)"></app-file-upload> -->
</div>
