<div class="seeCoauthors" *ngIf="authors.value.length">
  <mat-checkbox
    color="primary"
    [(ngModel)]="seeCoauthors"
    labelPosition="before"
  >
    {{ 'seeCoauthors' | preventHardcoding }}
  </mat-checkbox>
</div>
<div class="form-group" [formGroup]="form">
  <div class="row co-worker-list" *ngIf="seeCoauthors">
    <div formArrayName="authors">
      <ul class="authors">
        <ng-container *ngFor="let author of authors.value; trackBy: trackByFn">
          <li mat-line>{{ author.foreName }} {{ author.lastName }}</li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-invite [showExpander]="false" [showInvite]="true"></app-invite>

<!-- <div class="form-group" [formGroup]="form">
<p>List of co authors not present on your network</p>

<div class="authors-wrapper" formArrayName="authors">
  <ul class="co-authors">
    <li *ngFor="let author of authors.value">
      {{author.ForeName}} {{author.LastName}}
      <button mat-button class="pull-right" color="primary" (click)="addToNetwork(author)">Add</button>
      <div style="clear:both;"></div>
    </li>
  </ul>
</div>

<p>Add colleagues to your network</p>
<h5>Invite by email</h5>
<div class="row">
  <div class="col-md-8">
    <mat-form-field>
      <input matInput #invitationMail type="email" placeholder="Email address" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button mat-raised-button color="primary" (click)="sendInvite(invitationMail)">Send Invitation</button>
  </div>
</div>

<div *ngIf="networkArray.value.length > 0">
  <h4>List of Connections:</h4>
  <div class="show-added">
    <ul>
      <li *ngFor="let author of networkArray.value; let i = index">
        {{author.ForeName}} {{author.LastName}}
        <button mat-button color="warn" class="pull-right" (click)="removeFromNetwork(i)">Remove</button>
        <div style="clear:both;"></div>
      </li>
    </ul>
  </div>
</div>
</div> -->
