import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MondoErrorHandler } from './shared/models/mondoErrorHandler';
import { EmailActionManagerComponent } from './email-action-manager/email-action-manager.component';
import { CookieModule } from './cookie/cookie.module';
import { ProfileEditorModule } from './profile-editor/profile-editor.module';
import { CvViewerModule } from './viewer/cv/cv-viewer.module';
import 'hammerjs';
import { SharedUiModule } from './shared-ui/shared-ui.module';
import { SharedPipesModule } from './shared-pipes/shared-pipes.module';
import { NotificationsNewModule } from './notifications-new/notifications-new.module';

@NgModule({
  declarations: [AppComponent, EmailActionManagerComponent],
  providers: [{ provide: ErrorHandler, useClass: MondoErrorHandler }],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    routing,
    ProfileEditorModule,
    CookieModule,
    CvViewerModule,
    NotificationsNewModule,
    SharedPipesModule,
    SharedUiModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
