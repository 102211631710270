import { Education, JsonEducation } from './education';
import { Publication } from './publication';
import { Publications } from './publications';
import { JsonResearchStay, ResearchStay } from './research-stay';
import {
  JsonTeachingExperience,
  TeachingExperience,
} from './teaching-experience';
import { JsonWorkExperience, WorkExperience } from './work-experience';
import { JsonWorkShop, WorkShop } from './work-shop';
import { FieldOfInterest } from '../fieldOfInterest';
import { Technique } from '../technique';
import { Category0 } from '../category0';
import { Category1 } from '../category1';

export class Experience implements JsonExperience<Date> {
  public static fromJson(
    {
      workExperiences,
      educations,
      researches,
      workshops,
      teachingExperiences,
      techniques,
      interests,
      category0,
      category1,
      category2,
      category3,
      category4,
      category5,
      publications,
    }: JsonExperience<number>,
    cvId: string
  ): Experience {
    const exp = new Experience();
    exp.workExperiences = workExperiences
      ? workExperiences.map((w) => WorkExperience.fromJson(w, cvId))
      : [];
    exp.interests = interests
      ? interests.map((i) => FieldOfInterest.fromJson(i))
      : [];
    exp.techniques = techniques
      ? techniques.map((t) => Technique.fromJson(t))
      : [];
    exp.category0 = category0
      ? category0.map((c0) => Category0.fromJson(c0))
      : [];
    exp.category1 = category1
      ? category1.map((c1) => Category1.fromJson(c1))
      : [];
    exp.category2 = category2
      ? category2.map((c0) => Category0.fromJson(c0))
      : [];
    exp.category3 = category3
      ? category3.map((c0) => Category0.fromJson(c0))
      : [];
    exp.category4 = category4
      ? category4.map((c0) => Category0.fromJson(c0))
      : [];
    exp.category4 = category5
      ? category5.map((c0) => Category0.fromJson(c0))
      : [];
    if (publications) {
      exp.publications.bibliographicStyle = publications.bibliographicStyle
        ? publications.bibliographicStyle
        : '';
      exp.publications.publicationsList = publications.publicationsList
        ? publications.publicationsList.map((p) => Publication.fromJson(p))
        : [];
    }
    exp.teachingExperiences = teachingExperiences
      ? teachingExperiences.map((w) => TeachingExperience.fromJson(w, cvId))
      : [];
    exp.workshops = workshops
      ? workshops.map((w) => WorkShop.fromJson(w, cvId))
      : [];
    exp.educations = educations
      ? educations.map((w) => Education.fromJson(w, cvId))
      : [];
    exp.researches = researches
      ? researches.map((w) => ResearchStay.fromJson(w, cvId))
      : [];
    return exp;
  }

  public static toJson(experice: Experience): JsonExperience<number> {
    return {
      workExperiences: experice.workExperiences.map((a) =>
        WorkExperience.toJson(a)
      ),
      educations: experice.educations.map((a) => Education.toJson(a)),
      researches: experice.researches.map((a) => ResearchStay.toJson(a)),
      workshops: experice.workshops.map((a) => WorkShop.toJson(a)),
      teachingExperiences: experice.teachingExperiences.map((a) =>
        TeachingExperience.toJson(a)
      ),
      techniques: experice.techniques,
      interests: experice.interests,
      category0: experice.category0,
      category1: experice.category1,
      category2: experice.category2,
      category3: experice.category3,
      category4: experice.category4,
      category5: experice.category5,
      publications: experice.publications,
    };
  }

  public constructor(
    public workExperiences: WorkExperience[] = [],
    public educations: Education[] = [],
    public researches: ResearchStay[] = [],
    public workshops: WorkShop[] = [],
    public teachingExperiences: TeachingExperience[] = [],
    public techniques: Technique[] = [],
    public interests: FieldOfInterest[] = [],
    public category0: Category0[] = [],
    public category1: Category1[] = [],
    public category2: Category0[] = [],
    public category3: Category0[] = [],
    public category4: Category0[] = [],
    public category5: Category0[] = [],
    public publications: Publications = new Publications()
  ) {}
}

interface JsonExperience<T> {
  workExperiences: JsonWorkExperience<T>[];
  educations: JsonEducation<T>[];
  researches: JsonResearchStay<T>[];
  workshops: JsonWorkShop<T>[];
  teachingExperiences: JsonTeachingExperience<T>[];
  techniques: Technique[];
  interests: FieldOfInterest[];
  category0: Category0[];
  category1: Category1[];
  category2: Category0[];
  category3: Category0[];
  category4: Category0[];
  category5: Category0[];
  publications: Publications;
}
