import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AcaPageHeaderComponent } from './display-components/aca-page-header/aca-page-header.component';
import { MondoTileComponent } from './display-components/mondo-tile/mondo-tile.component';
import { UserCardComponent } from './display-components/user-card/user-card.component';
import { DestroyComponent } from './base/destroy/destroy.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { YouTubeVideoComponent } from './display-components/youtube-video/youtube-video.component';
import { UploadsViewerComponent } from './display-components/uploads-viewer/uploads-viewer.component';
import { PresenceIndicatorComponent } from './display-components/presence-indicator/presence-indicator.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { EventViewerComponent } from './event-viewer/event-viewer.component';
import { CommunityViewerComponent } from './community-viewer/community-viewer.component';
import { PostComponent } from './post/post.component';
import { SharedDirectivesModule } from 'app/shared-directives/shared-directives.module';
import { CommentsComponent } from './comments/comments.component';
import { NewCommentComponent } from './comments/new-comment/new-comment.component';
import { CommentComponent } from './comments/comment/comment.component';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploadModule } from 'app/file-upload/file-upload.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialSharedModule } from 'app/angular-material-shared/angular-material-shared.module';
import { ClickableUsernameComponent } from './display-components/clickable-username/clickable-username.component';
import { DateComponent } from './date/date.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { CprComponent } from './display-components/cpr/cpr.component';
import { MondoHintComponent } from './mondo-hint/mondo-hint.component';
import { IdeaViewerComponent } from './idea-viewer/idea-viewer.component';
import { SimpleTypingCarouselComponent } from './simple-typing-carousel/simple-typing-carousel.component';
import { SsoUserMarkComponent } from './sso-user-mark/sso-user-mark.component';
import { CalendarFileComponent } from './calendar-file/calendar-file.component';
import { LandingFooterComponent } from './landing-footer/landing-footer.component';
import { IFilterChipListComponent } from './i-filter-chip-list/i-filter-chip-list.component';
import { CommunityResultViewerComponent } from './community-result-viewer/community-result-viewer.component';
import { EventResultViewerComponent } from './event-result-viewer/event-result-viewer.component';
import { IdeaResultViewerComponent } from './idea-result-viewer/idea-result-viewer.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { EventCardComponent } from './display-components/event-card/event-card.component';
@NgModule({
  declarations: [
    AcaPageHeaderComponent,
    MondoTileComponent,
    UserCardComponent,
    DestroyComponent,
    YouTubeVideoComponent,
    UploadsViewerComponent,
    PresenceIndicatorComponent,
    PrivacyComponent,
    EventViewerComponent,
    CommunityViewerComponent,
    IdeaViewerComponent,
    PostComponent,
    CommentsComponent,
    NewCommentComponent,
    CommentComponent,
    ClickableUsernameComponent,
    DateComponent,
    InfoDialogComponent,
    CprComponent,
    MondoHintComponent,
    SimpleTypingCarouselComponent,
    SsoUserMarkComponent,
    CalendarFileComponent,
    LandingFooterComponent,
    IFilterChipListComponent,
    CommunityResultViewerComponent,
    EventResultViewerComponent,
    IdeaResultViewerComponent,
    SplashScreenComponent,
    EventCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
    SharedPipesModule,
    PerfectScrollbarModule,
    SharedDirectivesModule,
    RouterModule,
    InfiniteScrollModule,
    FileUploadModule,
    TextFieldModule,
    AngularMaterialSharedModule,
  ],
  exports: [
    AcaPageHeaderComponent,
    MondoTileComponent,
    UserCardComponent,
    DestroyComponent,
    YouTubeVideoComponent,
    UploadsViewerComponent,
    PresenceIndicatorComponent,
    PrivacyComponent,
    EventViewerComponent,
    CommunityViewerComponent,
    IdeaViewerComponent,
    PostComponent,
    CommentsComponent,
    NewCommentComponent,
    CommentComponent,
    ClickableUsernameComponent,
    DateComponent,
    InfoDialogComponent,
    CprComponent,
    MondoHintComponent,
    SimpleTypingCarouselComponent,
    SsoUserMarkComponent,
    CalendarFileComponent,
    LandingFooterComponent,
    IFilterChipListComponent,
    CommunityResultViewerComponent,
    EventResultViewerComponent,
    IdeaResultViewerComponent,
    SplashScreenComponent,
    EventCardComponent,
  ],
  entryComponents: [InfoDialogComponent],
})
export class SharedUiModule {}
