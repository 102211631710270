<div>
  <div *ngIf="chatRoom$ | async as chatRoom">
    {{ chatRoom | json }}
  </div>
  <div *ngIf="activeChatRoom$ | async as activeChatRoom">
    <h4>{{ activeChatRoom.name }}</h4>
    <div class="message-list" #messagesContainer>
      <mat-chip-list>
        <div
          class="message-entry"
          *ngFor="let message of activeMessages$ | async"
        >
          <mat-chip
            selected
            [color]="
              message.sender.uid === currentUser.uid ? 'primary' : 'accent'
            "
          >
            {{ message.message }}
          </mat-chip>
          <div>
            <small
              >{{
                message.sender.displayName
                  ? message.sender.displayName
                  : message.sender.email
              }}
              (<app-date [date]="message.timestamp"></app-date>)
            </small>
          </div>
        </div>
      </mat-chip-list>
    </div>
    <div class="message-form">
      <form class="row">
        <mat-form-field appearance="standard" class="message col-8">
          <mat-label>Message</mat-label>
          <input title="message" #msg matInput />
        </mat-form-field>
        <div class="col-4">
          <button
            type="submit"
            submit
            [disabled]="msg.value === ''"
            (click)="sendMessage(msg)"
            mat-raised-button
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
