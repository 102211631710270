<div class="signup-container">
  <!-- <div class="aca-logo">
    <img />
  </div> -->
  <div class="aca-name">
    <img title="logo" src="/assets/logos/Academondo.svg" />
  </div>
  <div>
    <app-sso
      *ngIf="'SSOEnabled' | preventHardcoding"
      [introText]="'SSOSignupIntro'"
      [text]="'SSOSignup'"
      [outroText]="'SSOSignupOutro'"
    ></app-sso>
    <form [formGroup]="signupForm" (ngSubmit)="signup()">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
        <input
          title="email"
          type="email"
          name="email"
          matInput
          formControlName="email"
          required
        />
        <mat-error *ngIf="email?.errors?.email">{{
          'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
        }}</mat-error>
        <mat-error *ngIf="email?.errors?.required"
          >{{ 'Email' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'password' | preventHardcoding }}</mat-label>
        <input
          title="password"
          type="password"
          matInput
          formControlName="password"
          required
        />
        <mat-error *ngIf="passwordErrors?.strong">{{
          'containLeastOneNumberAndOneCapitalLetter' | preventHardcoding
        }}</mat-error>
        <mat-error
          *ngIf="passwordErrors?.minlength || passwordErrors?.maxlength"
        >
          {{ 'mustBetween6And24Characters' | preventHardcoding }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        appearance="standard"
        *ngIf="'showChangeAccountTypeInSignup' | preventHardcoding"
      >
        <mat-select
          formControlName="accountType"
          type="text"
          placeholder="{{ 'accountType' | preventHardcoding }}"
          required
        >
          <mat-option
            *ngFor="let type of accountTypes; trackBy: trackByFn"
            (click)="accountTypeChanged(type)"
            [value]="type.type"
            >{{ type.name | preventHardcoding }}</mat-option
          >
        </mat-select>
        <mat-error
          >{{ 'accountType' | preventHardcoding }}
          {{ 'required' | preventHardcoding }}</mat-error
        >
      </mat-form-field>
      <div class="footer">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!signupForm.valid"
        >
          {{ 'SignupAs' | preventHardcoding }}
          {{ selectedAccount.name | preventHardcoding }}
        </button>
        <mat-error class="small" *ngIf="error">
          {{ errorMsg | preventHardcoding }}
        </mat-error>
      </div>
      <div class="terms">
        <div>
          <small
            >{{ 'byContinuingYouConsent' | preventHardcoding }}
            <a target="_blank" href="{{ 'termsLink' | preventHardcoding }}">{{
              'terms' | preventHardcoding
            }}</a>
          </small>
        </div>
        <div>
          <small
            >{{ 'howWeTreatYourData' | preventHardcoding }}
            <a
              target="_blank"
              href="{{ 'privacyPolicyLink' | preventHardcoding }}"
            >
              {{ 'privacyPolicy' | preventHardcoding }}
            </a>
          </small>
        </div>
      </div>
    </form>
  </div>
</div>
