import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { DateRangeOptions } from 'app/shared/components/date-range-picker/date-range-picker.component';
import { PickerMode } from 'app/shared/components/date-picker/date-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { IntroDialogComponent } from 'app/stepper/shared/components/intro-dialog/intro-dialog.component';
import { ItemType } from 'app/stepper/shared/model/ItemType';
import { Job } from 'app/stepper/job/model/job';
import { TimeService } from 'app/core/time.service';

@Component({
  selector: 'app-job-builder-visibility',
  templateUrl: './job-builder-visibility.component.html',
  styleUrls: ['./job-builder-visibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobBuilderVisibilityComponent {
  @Input() form: MondoFormGroup<Job>;
  TimeService: TimeService;

  private readonly maxTimeInDays = 7 * 12;
  public deployTodayWithEndDate = false; // = TimeService.addTimeSpanToDate(new Date(), this.maxTimeInDays);

  readonly visibilityDateRangeOptions: DateRangeOptions = {
    allowFutureDates: true,
    allowPastDates: false,
    hideOpenEndedCheckBox: true,
    startNowCheckboxText: 'makeVisibleToday',
    pickerMode: PickerMode.day,
    maxTimeSpanInDays: this.maxTimeInDays,
    showClearBtn: true,
    endPlaceHolder: 'applicationDeadline',
    startPlaceholder: 'publicationDate',
    startValue: new Date(),
  };

  readonly employmentDateRangeOptions: DateRangeOptions = {
    allowFutureDates: true,
    allowPastDates: false,
    openEndedCheckboxText: 'permanentPosition',
    startNowCheckboxText: 'startAsSoonAsPossible',
    showClearBtn: true,
    pickerMode: PickerMode.day,
  };

  constructor(private dialog: MatDialog) {}

  get jobType() {
    return this.form.getSafeControl((x) => x.jobInfo.jobType).value;
  }

  get contactConsent() {
    return this.form.getSafeControl((x) => x.contactConsent);
  }

  get jobVisibilityPeriod() {
    return this.form.getSafeControl((x) => x.jobVisibilityPeriod);
  }

  get employmentPeriod() {
    return this.form.getSafeControl((x) => x.employmentPeriod);
  }

  get jobKey() {
    return this.form.getSafeControl((x) => x.key).value;
  }

  showExplanation() {
    const dialogRef = this.dialog.open(IntroDialogComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.itemType = ItemType.ContactConsent;
    dialogRef.componentInstance.showHeader = false;
  }
}
