import { AccountPermission } from './shared/consts/accountPermission';
import { AccountType } from './shared/consts/accountType';
import { hardcodedValues } from '../hardcodedValues';
import { acaConfig } from '../aca-config';

const onlyScientistTypes = [
  AccountType.scientist,
  AccountType.medLabTech,
  AccountType.labTech,
  AccountType.ssoAuthedUser,
];
const onlyCompanyTypes = [AccountType.industry, AccountType.university];
const allTypes = onlyScientistTypes.concat(onlyCompanyTypes);

export enum MondoRoutes {
  dashboard = 'dashboard',
  // home = 'home',
  // aboutUs = 'about',
  // jobBuilder = 'jobBuilder',
  scienceMatch = 'scienceMatch',
  map = 'map',
  // calendar = 'calendar',
  jobBuilder = 'jobBuilder',
  cvBuilder = 'cvBuilder',
  aboutUs = '',
  tutorials = 'tutorials',
  events = 'events',
  profileEditor = 'profile-editor',
  job = 'job',
  none = 'none',
  root = '/',
  link = 'link',
  // cvList = 'cvList',
  // siteList = 'siteList',
  // jobList = 'jobList',
  messages = 'messages',
  network = 'network',
  communities = 'communities',
  ideas = 'ideas',
  homeProfile = 'homeProfile',
  siteBuilder = 'siteBuilder',
  cookies = 'cookies',
  newUser = 'new-user',
  emailActionManager = 'email-action-manager',
  login = 'login',
  signup = '', // 'signup',
  landingPage = 'landing-page',
  // chat = 'chat',
  // cvListHome = 'cvListHome',
  // siteListHome = 'siteListHome',
  // jobListHome = 'jobListHome',
  home = 'home',
  adminPage = 'adminPage',
}

export const NewUserRoutes = {
  scienceMatch: 'scienceMatch',
  map: 'map',
  // aboutUs: 'about',
  // tutorials: 'tutorials',
  // aboutJobSearch: 'aboutJobSearch',
  // aboutJobBuilder: 'aboutJobBuilder',
  login: 'login',
};

export const RoutingModel = {
  cvBuilder: {
    excludeFromNavBar: true,
    title: hardcodedValues.CVBuilderTitle,
    path: hardcodedValues.CVBuilderRoute,
    route: '/' + hardcodedValues.CVBuilderRoute,
    icon: 'home',
    routeData: {
      validAccountTypes: onlyScientistTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'cv-builder',
    },
  },
  jobBuilder: {
    title: hardcodedValues.jobBuilderTitle,
    path: hardcodedValues.jobBuilderRoute,
    route: '/' + hardcodedValues.jobBuilderRoute,
    icon: 'work',
    routeData: {
      validAccountTypes: acaConfig.enableJobs ? onlyCompanyTypes : [],
      minimumPermission: AccountPermission.basic,
      animation: 'job-builder',
    },
  },
  adminPage: {
    excludeFromNavBar: true,
    title: hardcodedValues.adminRoute,
    path: hardcodedValues.adminRoute,
    route: '/' + hardcodedValues.adminRoute,
    icon: 'miscellaneous_services',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.admin,
      animation: 'admin-page',
    },
    lazy: './admin-page/admin-page.module#AdminPageModule',
  },
  profileEditor: {
    excludeFromNavBar: true,
    title: hardcodedValues.profileEditorRoute,
    path: 'profile-editor',
    route: '/profile-editor',
    icon: 'insert_drive_file',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'profile-editor',
    },
  },
  communities: {
    excludeFromNavBar: false,
    title: hardcodedValues.CommunitiesTitle,
    path: '_communities',
    route: '/_communities',
    icon: 'groups',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: '_communities',
    },
  },
  ideas: {
    excludeFromNavBar: hardcodedValues.Idea ? false : true,
    title: hardcodedValues.IdeasTitle,
    path: '_ideas',
    route: '/_ideas',
    icon: 'lightbulb',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: '_ideas',
    },
  },
  events: {
    excludeFromNavBar: false,
    title: hardcodedValues.EventTitle,
    path: '_events',
    route: '/_events',
    icon: 'events',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: '_events',
    },
  },
  link: {
    excludeFromNavBar: true,
    title: hardcodedValues.profileRoute,
    path: 'link',
    route: '/link',
    icon: 'emoji_people',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'link',
    },
  },
  job: {
    excludeFromNavBar: true,
    title: hardcodedValues.jobRoute,
    path: 'job',
    route: '/job',
    icon: 'insert_drive_file',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'job',
    },
  },
  map: {
    title: hardcodedValues.mapTitle,
    path: hardcodedValues.mapRoute,
    route: '/' + hardcodedValues.mapRoute,
    icon: 'map',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'map',
    },
  },
  newUser: {
    excludeFromNavBar: true,
    title: hardcodedValues.newUserRoute,
    path: 'new-user',
    route: '/new-user',
    icon: 'group',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'new-user',
    },
  },
  aboutUs: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutUsRoute,
    path: 'about/us',
    route: '/about/us',
    icon: 'group',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutUs',
    },
  },
  tutorials: {
    title: hardcodedValues.about,
    path: 'tutorials',
    route: '/tutorials',
    icon: 'group',
    external: true,
    routeData: {
      validAccountTypes: [],
      minimumPermission: AccountPermission.basic,
      animation: 'tutorials',
    },
  },
  aboutJobSearch: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutJobSearchRoute,
    path: 'about/jobSearch',
    route: 'about/jobSearch',
    icon: 'account_balance',
    showSpacer: false,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutJobSearch',
    },
  },
  aboutJobBuilder: {
    excludeFromNavBar: true,
    title: hardcodedValues.aboutJobBuilderRoute,
    path: 'about/jobBuilder',
    route: 'about/jobBuilder',
    icon: 'work',
    showSpacer: true,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'aboutJobBuilder',
    },
  },
  login: {
    excludeFromNavBar: true,
    title: hardcodedValues.login,
    path: 'login',
    route: 'login',
    icon: 'login',
    showSpacer: false,
    routeData: {
      // validAccountTypes: allTypes,
      // minimumPermission: AccountPermission.basic,
      animation: 'login',
    },
  },
  signup: {
    excludeFromNavBar: true,
    title: hardcodedValues.signUp,
    path: 'signup',
    route: 'signup',
    icon: 'how_to_reg',
    showSpacer: true,
    routeData: {
      // validAccountTypes: allTypes,
      // minimumPermission: AccountPermission.basic,
      animation: 'signup',
    },
  },
  // chat: {
  //   title: hardcodedValues.chatRoute,
  //   path: 'chat',
  //   route: 'chat',
  //   icon: 'chat',
  //   routeData: {
  //     validAccountTypes: [
  //       AccountType.scientist,
  //       AccountType.medLabTech,
  //       AccountType.labTech
  //     ],
  //     minimumPermission: AccountPermission.admin,
  //     animation: 'chat'
  //   }
  // },
  cookies: {
    excludeFromNavBar: true,
    title: hardcodedValues.cookiesRoute,
    path: 'cookies',
    route: '/cookies',
    icon: 'cookies',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'cookies',
    },
  },
  dashboard: {
    excludeFromNavBar: false,
    title: hardcodedValues.dashboardTitle,
    path: hardcodedValues.dashboardRoute,
    route: '/' + hardcodedValues.dashboardRoute,
    icon: 'home',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'dashboard',
    },
  },
  home: {
    excludeFromNavBar: true,
    title: hardcodedValues.siteBuilderRoute,
    path: 'home',
    route: '/home',
    icon: 'home',
    routeData: {
      validAccountTypes: onlyCompanyTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'home',
    },
  },
  search: {
    excludeFromNavBar: true,
    title: hardcodedValues.searchRoute,
    path: 'search',
    route: '/search', // '/search/init', //  set init component in search-wrapper
    icon: 'search',
    showSpacer: false,
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'search',
    },
  },
  messages: {
    excludeFromNavBar: true,
    title: hardcodedValues.messagesRoute,
    path: 'messages',
    route: '/messages',
    icon: 'insert_drive_file',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'messages',
    },
  },
  siteBuilder: {
    excludeFromNavBar: true,
    root: 'Site',
    hint: hardcodedValues.Site,
    title: hardcodedValues.siteBuilderRoute,
    path: 'site-builder',
    route: '/home/site-builder',
    icon: 'edit',
    routeData: {
      validAccountTypes: onlyCompanyTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'siteBuilder',
    },
    page: 'home',
  },
  homeProfile: {
    excludeFromNavBar: true,
    hint: hardcodedValues.CV,
    root: 'profile',
    title: hardcodedValues.homeProfileRoute,
    path: 'profile',
    route: '/home/profile',
    icon: 'edit',
    routeData: {
      validAccountTypes: onlyScientistTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'homeProfile',
    },
    page: 'home',
  },
  scienceMatch: {
    excludeFromNavBar: false,
    title: hardcodedValues.scienceMatch,
    path: hardcodedValues.scienceMatchRoute,
    route: '/' + hardcodedValues.scienceMatchRoute,
    icon: 'search',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'scienceMatch',
    },
  },
  calendar: {
    title: hardcodedValues.calendar,
    path: 'calendar',
    route: 'calendar',
    icon: 'calendar_today',
    routeData: {
      validAccountTypes: allTypes,
      minimumPermission: AccountPermission.basic,
      animation: 'calendar',
    },
  },
};

export interface INavigationItem {
  title: string;
  path: string;
  icon: string;
  route: string;
  routeData: IRouteData;
  excludeFromNavBar: boolean;
  external: boolean;
  root: string;
  page: string;
  hint: string;
  showSpacer: boolean;
}

export interface IRouteData {
  validAccountTypes: AccountType[];
  minimumPermission: AccountPermission;
  animation: string;
}
