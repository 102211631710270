export interface FenerumSubscription {
  account: string;
  terms: string;
  collection_method: FenerumCollectionMethods;
  payment_terms: number;
}

export enum FenerumCollectionMethods {
  invoice = 'invoice',
  payment_card = 'payment_card'
}
