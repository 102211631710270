import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  CvSearchForm,
  CandidateSearchForm,
  SolrResult,
  SearchFormV2,
  SearchResultV2,
} from '../shared/common/search.model';
import { AuthService } from 'app/core/auth.service';
import { FormArray } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { hardcodedValues } from 'hardcodedValues';
import { HelperService } from 'app/core/helper.service';
import {
  createInviteSearchNameQuery,
  createScienceMatchQuery,
} from '@searchQuery';
import { ForumPost } from 'app/forum/models/forum-post';
import { MatSnackBar } from '@angular/material';
import { RoutingService } from 'app/core/routing.service';
import { RoutingModel } from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';

@Injectable()
export class SearchService {
  public static currentJobSearch = null;
  public static currentCvSearch: CvSearchForm = null;
  public static currentInviteSearch: SearchFormV2 = null;
  public static currentCandidateSearch: CandidateSearchForm = null;
  public static currentCompanySearch: SearchFormV2 = null;
  public static currentPostSearch: SearchFormV2 = null;
  public static currentScienceMatchSearch: SearchFormV2 = null;

  public postResults: BehaviorSubject<SolrResult<ForumPost[]>> =
    new BehaviorSubject(null);
  public postResult$: Observable<SolrResult<ForumPost[]>> =
    this.postResults.asObservable();
  public candidateResult: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public candidateResult$: Observable<SolrResult<SearchResultV2[]>> =
    this.candidateResult.asObservable();
  public companyResult: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public companyResult$: Observable<SolrResult<SearchResultV2[]>> =
    this.companyResult.asObservable();
  public inviteResult: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public inviteResult$: Observable<SolrResult<SearchResultV2[]>> =
    this.inviteResult.asObservable();
  public scienceMatchResults: BehaviorSubject<SolrResult<SearchResultV2[]>> =
    new BehaviorSubject(null);
  public scienceMatchResults$: Observable<SolrResult<SearchResultV2[]>> =
    this.scienceMatchResults.asObservable();

  public showResult: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public showResult$: Observable<boolean> = this.showResult.asObservable();

  public initCompanySearch = true;
  public initCandiateSearch = true;

  public searchInitiated = false;
  public searchInProgress = false;
  public _membersArray: FormArray = new FormArray([]);
  public lastViewEnable = 0;
  public isSearchPage = true;
  private inviteSearch = this.fnsHelper.createFunctionPromise<
    any,
    SolrResult<SearchResultV2[]>
  >(environment.searchHandler);
  private scienceMatchSearchHandler = this.fnsHelper.createFunctionPromise<
    any,
    SolrResult<SearchResultV2[]>
  >(environment.searchHandler);

  public static getMatchingPercentage<T>(
    result: T,
    searchType: string,
    tag: string,
    ofResult = false
  ): number {
    if (this.checkIfUndefinedOrEmpty(result, tag)) {
      return 0;
    }
    const amountMatching = this.getAmountOfMatches(
      this[searchType][tag],
      result[tag]
    );
    if (ofResult) {
      return this.getPercentage(amountMatching, result[tag].length);
    }
    return this.getPercentage(amountMatching, this[searchType][tag].length);
  }

  public static getMatching<T>(
    result: T,
    searchType: string,
    tag: string
  ): string[] {
    if (this.checkIfUndefinedOrEmpty(result, tag)) {
      return [];
    }
    return this.getMatches(this[searchType][tag], result[tag]);
  }

  public static getAmountOfMatching<T>(
    result: T,
    searchType: string,
    tag: string
  ): number {
    if (this.checkIfUndefinedOrEmpty(result, tag)) {
      return 0;
    }
    return this.getAmountOfMatches(this[searchType][tag], result[tag]);
  }

  private static checkIfUndefinedOrEmpty(result, tag) {
    return !result[tag] || (result[tag] && result[tag].length === 0);
  }

  public static getDistance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  public static isExperienceFulfilled(
    result: SearchResultV2,
    searchFormExp: string,
    resultExp?: string
  ): boolean {
    return (
      this.currentJobSearch[searchFormExp] * 365 >=
      result[resultExp ? resultExp : searchFormExp]
    );
  }

  public static getSearchEducationalLevel() {
    return this.currentJobSearch.educationalLevel;
  }

  private static getAmountOfMatches(
    searchArray: Array<any>,
    matchArray: Array<string>
  ): number {
    const searchArrayMapped = searchArray.map((ft) => ft.id || ft.uid);
    let amountMatching = 0;
    matchArray.forEach((id) => {
      if (searchArrayMapped.includes(id)) {
        amountMatching += 1;
      }
    });

    return amountMatching;
  }
  private static getMatches(
    searchArray: Array<any>,
    matchArray: Array<string>
  ): string[] {
    const searchArrayMapped = searchArray.map((ft) => ft.id || ft.uid);
    const amountMatching = [];
    matchArray.forEach((id) => {
      if (searchArrayMapped.includes(id)) {
        const match = searchArray.find(
          (item) => item.id === id || item.uid === id
        );
        if (match.name) {
          amountMatching.push(match.name);
        }
      }
    });

    return amountMatching;
  }

  private static getPercentage(n: number, d: number): number {
    if (d === 0) {
      return n === 0 ? 0 : 0;
    }
    return Math.trunc(Math.floor((n / d) * 100));
  }

  public static getYearTextFromDays(days: number): string {
    if (days <= 0 || undefined) {
      return '0 ' + hardcodedValues.yearss;
    } else {
      const years = Math.floor(days / 365);
      if (years === 1) {
        return '1 ' + hardcodedValues.year;
      } else {
        return `${years} ${hardcodedValues.yearss}`;
      }
    }
  }

  public static getYearTextFromYears(years: number): string {
    if (years <= 0 || undefined) {
      return '0 ' + hardcodedValues.yearss;
    } else {
      if (years === 1) {
        return '1 ' + hardcodedValues.year;
      } else {
        return `${years} ${hardcodedValues.yearss}`;
      }
    }
  }

  public static getMonthTextFromMonths(months: number): string {
    if (months <= 0 || undefined) {
      return '0 ' + hardcodedValues.monthss;
    } else {
      if (months === 1) {
        return '1 ' + hardcodedValues.month;
      } else {
        return `${months} ${hardcodedValues.monthss}`;
      }
    }
  }

  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private snackBar: MatSnackBar,
    public fnsHelper: HelperService
  ) {
    this.resetScienceMatchSearch();
  }

  public nameInviteSearch(name: string): Promise<SolrResult<SearchResultV2[]>> {
    this.resetInviteSearch();
    const query = createInviteSearchNameQuery(name);
    const partialUrl = environment.solrV2;
    return this.inviteSearch({
      state: 'search',
      query: query,
      partialUrl: partialUrl,
    });
  }
  public scienceMatchSearch(
    form: SearchFormV2
  ): Promise<SolrResult<SearchResultV2[]>> {
    this.resetScienceMatchSearch();
    const specialCase = this.authService.isUserPartOfGivenAccountType([
      AccountType.medLabTech,
      AccountType.labTech,
      AccountType.industry,
    ]);
    const query = createScienceMatchQuery(
      // check science-filters whether to show it as an option.
      form,
      this.authService.logged, // specialCase,
      true,
      true,
      true,
      true,
      true,
      hardcodedValues.enableJobs
    );

    const partialUrl = environment.solrV2;
    return this.scienceMatchSearchHandler({
      state: 'search',
      query: query,
      partialUrl: partialUrl,
    });
  }

  public checkPermissionSearch() {
    if (!this.authService.canSearch) {
      this.snackBar
        .open(
          `${hardcodedValues.notEnoughPermission}\n`,
          this.authService.isScientist
            ? hardcodedValues.CVBuilderRoute
            : hardcodedValues.siteBuilderRoute,
          {
            duration: 10000,
            panelClass: ['snackbar-position-center'],
          }
        )
        .onAction()
        .subscribe(() => {
          this.authService.isScientist
            ? this.routingService.navigateToRoute(RoutingModel.cvBuilder.route)
            : this.routingService.navigateToRoute(
                RoutingModel.siteBuilder.route
              );
        });
    }
    return this.authService.canSearch;
  }

  private resetInviteSearch(): void {
    SearchService.currentCvSearch = {
      techniques: [],
      interests: [],
      category0: [],
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      educationalLevel: [],
      languages: [],
      yearsOfAcademicWorkExperience: undefined,
      yearsOfIndustrialWorkExperience: undefined,
      yearsOfHospitalWorkExperience: undefined,
      yearsOfExperience0: undefined,
      yearsOfExperience1: undefined,
      radius: undefined,
      coordinates: undefined,
      networkKey: '',
      siteType: [],
    };
  }

  private resetScienceMatchSearch(): void {
    SearchService.currentScienceMatchSearch = {
      availability: '',
      fullName: '',
      facilityName: '',
      description: '',
      type: '',
      interests: [],
      techniques: [],
      category0: [],
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      language: [],
      siteSubtype: [],
      educationalLevel: [],
      wantedDaysOfExperience0: undefined,
      wantedDaysOfExperience1: undefined,
      wantedDaysOfExperience2: undefined,
      wantedDaysOfExperience3: undefined,
      wantedDaysOfExperience4: undefined,
      wantedDaysOfExperience5: undefined,
      experience0: undefined,
      experience1: undefined,
      experience2: undefined,
      experience3: undefined,
      experience4: undefined,
      experience5: undefined,
      radius: undefined,
      coordinates: undefined,
      searchSettings: undefined,
      rows: hardcodedValues.maxSearchResults,
      page: 0,
    };
  }
}
