<ng-container *ngIf="userStatus$ | async as userStatus">
  <ng-container *ngIf="userStatus.loggedIn"
    ><ng-template
      *ngTemplateOutlet="loggedInWrapper; context: { user: userStatus.user }"
    ></ng-template
  ></ng-container>
  <ng-container *ngIf="!userStatus.loggedIn"
    ><ng-template *ngTemplateOutlet="notLoggedInWrapper"></ng-template
  ></ng-container>
</ng-container>

<ng-template #loggedInWrapper let-user="user">
  <mat-toolbar class="aca-navbar">
    <div class="toolbar-items-container">
      <div class="logo-auth">
        <img class="aca-logo" alt="logo" (click)="loggedInLogoClick()" />
      </div>
      <div class="icon-auth">
        <img class="aca-icon" alt="icon" (click)="loggedInLogoClick()" />
      </div>
      <div class="navitems">
        <ng-template *ngTemplateOutlet="navItems"></ng-template>
      </div>
      <div class="notification-and-user">
        <div [class.notification-border]="showBorderedButtons">
          <ng-container #notificationCenter></ng-container>
        </div>
        <div class="profile-block">
          <button class="more-btn" mat-button [matMenuTriggerFor]="menu">
            <span class="account-img">
              <img
                title="photoURL"
                [src]="user.photoURL"
                [class.border-radius]="
                  !isUserOfScientistType(user?.type) ? false : true
                "
                [default]="
                  isUserOfScientistType(user?.type)
                    ? 'assets/pictures/profile.png'
                    : 'assets/logos/Corporate.svg'
                "
              />
            </span>
            <span fxHide fxShow.gt-xs class="display-name">{{
              user?.displayName
                ? user?.displayName
                : ('myProfile' | preventHardcoding)
            }}</span>
          </button>
          <mat-menu #menu="matMenu" class="more-menu">
            <button
              mat-menu-item
              (click)="getProfileFromAccountType(user.type)"
            >
              <img
                title="photoURL"
                class="profile-pic"
                [class.border-radius]="
                  !isUserOfScientistType(user?.type) ? false : true
                "
                mat-card-avatar
                [src]="user.photoURL"
                [default]="
                  isUserOfScientistType(user?.type)
                    ? 'assets/pictures/profile.png'
                    : 'assets/logos/Corporate.svg'
                "
              />
              <span>{{ 'Profile' | preventHardcoding }}</span>
            </button>
            <button mat-menu-item (click)="navigateToUserDefault()">
              <mat-icon>home</mat-icon>
              <span>{{ 'Home' | preventHardcoding }}</span>
            </button>
            <button mat-menu-item [routerLink]="[routes.profileEditor, 0]">
              <mat-icon>settings</mat-icon>
              <span>{{ 'settings' | preventHardcoding }}</span>
            </button>
            <button
              *ngIf="isAdmin()"
              mat-menu-item
              (click)="navigateToAdminPage()"
            >
              <mat-icon>miscellaneous_services</mat-icon>
              <span>{{ 'adminRoute' | preventHardcoding }}</span>
            </button>
            <button mat-menu-item (click)="signOut()">
              <mat-icon>power_settings_new</mat-icon>
              <span>{{ 'logOut' | preventHardcoding }}</span>
            </button>
            <ng-container *ngIf="'showTermsInNavbarMenu' | preventHardcoding">
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="showTerms()">
                <mat-icon>fact_check</mat-icon>
                <span>{{ 'terms' | preventHardcoding }}</span>
              </button>
              <button mat-menu-item (click)="showPrivacyPolicy()">
                <mat-icon>fact_check</mat-icon>
                <span>{{ 'privacyPolicy' | preventHardcoding }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</ng-template>

<ng-template #notLoggedInWrapper>
  <mat-toolbar class="mat-toolbar-un-auth">
    <div class="toolbar-items-container-un-auth">
      <div class="logo-un-auth">
        <img class="aca-logo" alt="logo" (click)="navigateToSolutions()" />
      </div>
      <div class="navitems">
        <ng-template *ngTemplateOutlet="navItems"></ng-template>
        <ng-container *ngIf="routes['signup']">
          <a
            [class.bordered]="showBorderedButtons"
            [class.capitalized]="showCapitalizedButtons"
            class="nav-item-big"
            (auxclick)="
              onAuxClick($event, routingModel.signup.route + '/' + accountType)
            "
            [routerLink]="routingModel.signup.route + '/' + accountType"
            routerLinkActive="link-active"
          >
            <mat-icon [matTooltip]="routingModel.signup.title">{{
              routingModel.signup.icon
            }}</mat-icon>
            <span fxHide fxShow.gt-md mat-line>{{
              routingModel.signup.title
            }}</span>
          </a>
          <span
            *ngIf="routingModel.signup.showSpacer && showNavSpacers"
            class="nav-spacer"
            >|</span
          >
        </ng-container>
      </div>
      <ng-container>
        <div
          *ngIf="showAccountChangerInNavBar"
          class="account-changer navitems"
        >
          <a
            [class.bordered]="showBorderedButtons"
            [class.capitalized]="showCapitalizedButtons"
            class="nav-item-big"
            (click)="changeAccountType(accountType)"
          >
            <span fxHide fxShow.gt-md mat-line>{{
              getChangeAccountTypeText(accountType) | preventHardcoding
            }}</span>
            <img
              alt="account-icon"
              fxShow
              fxHide.gt-sm
              [class.border-radius]="
                !isUserOfScientistType(accountType) ? false : true
              "
              [matTooltip]="
                getChangeAccountTypeText(accountType) | preventHardcoding
              "
              [src]="account.icon"
            />
          </a>
        </div>
      </ng-container>
    </div>
  </mat-toolbar>
</ng-template>

<ng-template #navItems>
  <span *ngIf="showNavSpacers" class="nav-spacer first-spacer">|</span>
  <ng-container
    *ngFor="let navItem of navigationItems$ | async; trackBy: trackByFn"
  >
    <ng-container *ngIf="!navItem.external">
      <a
        [class.bordered]="showBorderedButtons"
        [class.capitalized]="showCapitalizedButtons"
        class="nav-item-big"
        (auxclick)="onAuxClick($event, navItem.route)"
        [routerLink]="navItem.route"
        routerLinkActive="link-active"
      >
        <mat-icon [matTooltip]="navItem.title">{{ navItem.icon }}</mat-icon>
        <span fxHide fxShow.gt-md mat-line>{{ navItem.title }}</span>
      </a>
    </ng-container>
    <ng-container *ngIf="navItem.external">
      <a
        [class.bordered]="showBorderedButtons"
        [class.capitalized]="showCapitalizedButtons"
        class="nav-item-big"
        (auxclick)="onAuxClick($event, navItem.route)"
        (click)="navigateToExternal($event, navItem.route)"
        routerLinkActive="link-active"
      >
        <mat-icon [matTooltip]="navItem.title">{{ navItem.icon }}</mat-icon>
        <span fxHide fxShow.gt-md mat-line>{{ navItem.title }}</span>
      </a>
    </ng-container>
    <span *ngIf="navItem.showSpacer && showNavSpacers" class="nav-spacer"
      >|</span
    >
  </ng-container>
</ng-template>
