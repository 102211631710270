import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StrictEmailValidator } from '@helpers';
import { AuthService } from 'app/core/auth.service';
import { SuggestionService } from 'app/core/suggestion.service';
import { SuggestedCategory0 } from 'app/shared/models';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-suggest-category5-dialog',
  templateUrl: './suggest-category5-dialog.component.html',
  styleUrls: ['./suggest-category5-dialog.component.scss'],
})
export class SuggestCategory5DialogComponent implements OnInit {
  suggestionForm: FormGroup;
  formError = false;
  formErrorMessage = '';
  loggedIn$;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SuggestCategory5DialogComponent>,
    private suggestionService: SuggestionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.suggestionForm = this.formBuilder.group({
      suggested: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      email: new FormControl('', [Validators.required, StrictEmailValidator]),
      description: new FormControl('', []),
      uid: new FormControl('', []),
    });
    const user$ = this.authService.getCurrentUser$();
    this.loggedIn$ = this.authService
      .getUserStatus$()
      .pipe(take(1))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          user$.pipe(take(1)).subscribe((user) => {
            this.suggestionForm.get('email').setValue(user.email);
            this.suggestionForm.get('uid').setValue(user.uid);
          });
        }
      });
  }

  sendSuggestion(): void {
    const suggested = this.suggestionForm.get('suggested').value;
    const email = this.suggestionForm.get('email').value;
    const description = this.suggestionForm.get('description').value;
    const uid = this.suggestionForm.get('uid').value;
    this.suggestionService.suggestCategory5(
      new SuggestedCategory0(suggested, email, description, '', uid)
    );
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
