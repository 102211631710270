import { Injectable } from '@angular/core';
import { hardcodedValues } from 'hardcodedValues';
import { padEnd, padStart } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {}

  public static getDateText(date: Date, showTime = false): string {
    let dateText = '';
    const checkedDate = new Date(date);

    dateText = checkedDate.toLocaleDateString(hardcodedValues.dateFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (showTime) {
      dateText += `${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${padStart(checkedDate.getHours().toString(), 2, '0')}:${padStart(
        checkedDate.getMinutes().toString(),
        2,
        '0'
      )}`;
    }
    return dateText;
  }

  public static getDateIntervalText(
    startDate: Date,
    endDate: Date,
    showTime = false
  ): string {
    let dateText = '';
    const checkedStartDate = new Date(startDate);
    const checkedEndDate = new Date(endDate);
    const from = checkedStartDate.toLocaleDateString(
      hardcodedValues.dateFormat,
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    );
    if (showTime) {
      dateText += `${from} ${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${padStart(checkedStartDate.getHours().toString(), 2, '0')}:${padStart(
        checkedStartDate.getMinutes().toString(),
        2,
        '0'
      )}`;
    } else {
      dateText += from;
    }
    const to = checkedEndDate.toLocaleDateString(hardcodedValues.dateFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (showTime) {
      dateText += ` - ${to} ${
        hardcodedValues.datePrefix ? ` ${hardcodedValues.datePrefix} ` : ''
      }${padStart(checkedEndDate.getHours().toString(), 2, '0')}:${padStart(
        checkedEndDate.getMinutes().toString(),
        2,
        '0'
      )}`;
    } else {
      dateText += ` - ${to}`;
    }

    return dateText;
  }

  public static getHoursFromDate(date: Date): number {
    const result = new Date(date);
    return result.getHours();
  }

  public static getMinutesFromDate(date: Date): number {
    const result = new Date(date);
    return result.getMinutes();
  }

  public static addTimeToDate(date: Date, hours = 0, minutes = 0): Date {
    const result = new Date(date);
    result.setHours(hours);
    result.setMinutes(minutes);
    return result;
  }

  public static addTimeSpanToDate(date: Date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public static subtractTimeSpanToDate(date: Date, days): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }
}
