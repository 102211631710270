<div class="cv-builder-container">
  <app-action-bar
    class="no-print"
    [allItems]="allCvs$ | async"
    [actionItems]="actionBarItems"
    [item]="cv"
    (showBuilder)="showBuilderChanged($event)"
    (showViewer)="showViewerChanged($event)"
    (addItem)="addNewCv()"
    (deleteItem)="deleteCv($event)"
    (dublicateItem)="dublicateCv($event)"
    (publishItem)="publishOtherCv($event)"
    (editItem)="editCv($event)"
    (showItem)="showCV($event)"
    [published]="published"
    [rootUrl]="rootUrl"
  >
  </app-action-bar>
  <div
    *ngIf="cvId"
    class="cv-container"
    [ngClass]="!showViewer || !showBuilder ? 'solo' : ''"
  >
    <div *ngIf="showBuilder" class="no-print">
      <ng-container *ngIf="showProgressTracker">
        <app-profile-progress-tracker [cv]="cv"></app-profile-progress-tracker>
      </ng-container>
      <app-cv-builder
        (stepChanged)="onStepChanged($event)"
        (cvChanged)="onCvChanged($event)"
        [cvKey]="cvId"
      >
      </app-cv-builder>
    </div>
    <div
      *ngIf="showViewer"
      class="viewer-wrapper"
      [ngClass]="showBuilder ? 'big-viewer-wrapper' : ''"
    >
      <app-cv-viewer
        [viewMode]="viewMode"
        [selectedStepInBuilder]="selectedStep"
        [cvKey]="showIntroViewer ? 'intro' : cvId"
      >
      </app-cv-viewer>
    </div>
  </div>
</div>
