import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { CV } from '../../shared/models/cv/cv';
import { CvStepIndex } from 'app/stepper/shared/model/stepIndex';
import { easeInAndOutAnimation } from 'app/_animations/easeInAndOutAnimation';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable, of, Subscription } from 'rxjs';
import { CvService } from '../../stepper/cv/services/cv.service';
import { CvStepIds, CvStepId } from '../../stepper/cv/model/cv-steps';
import { ViewMode } from '../../stepper/shared/model/ViewMode';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from 'app/core/user.service';
import { ChatService } from 'app/core/chat.service';
import { MatDialog } from '@angular/material';
import { ChatRoomComponent } from 'app/chat/chat-room/chat-room.component';
import { AuthService } from 'app/core/auth.service';
import { environment } from 'environments/environment';
import { RoutingModel } from 'app/app.routing-model';

@Component({
  selector: 'app-cv-viewer',
  templateUrl: './cv-viewer.component.html',
  styleUrls: ['./cv-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [easeInAndOutAnimation],
})
export class CVViewerComponent implements OnInit, OnChanges {
  cv$: Observable<CV>;
  @Input() cvKey: string;
  currentCvKey: string;
  @Input() selectedStepInBuilder: CvStepIndex;
  @Input() viewMode: ViewMode;
  @Input() rating: number;
  cvSteps = CvStepId;
  cvSubscription: Subscription;
  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  constructor(
    private cvService: CvService,
    private userService: UserService,
    private authService: AuthService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscribeToCv(this.cvKey);
    if (this.selectedStepInBuilder === 0) {
      // this.cvKey = 'intro';
      this.subscribeToCv('intro');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedStepInBuilder) {
      if (changes.selectedStepInBuilder.currentValue === 0) {
        this.subscribeToCv('intro');
      } else {
        this.subscribeToCv(this.cvKey);
      }
      // TODO lav til et komponent
      if (this.componentRef) {
        this.componentRef.directiveRef.scrollToElement(
          '#' + CvStepIds.get(changes.selectedStepInBuilder.currentValue),
          -50,
          500
        );
      }
    }
    if (changes.cvKey) {
      if (this.selectedStepInBuilder === 0) {
        this.subscribeToCv('intro');
      } else {
        this.subscribeToCv(changes.cvKey.currentValue);
      }
    }
  }

  showProfile(id: string) {
    const url = environment.academondoUrl + RoutingModel.link.route + '/' + id;
    window.open(url, '_blank');
  }

  canSeePrintableCV() {
    return this.authService.isAdmin;
    // ||
    // this.authService.isUserPartOfGivenAccountType([
    //   AccountType.industry,
    //   AccountType.university,
    //   AccountType.medLabTech,
    // ])
  }

  getScoring(): number {
    return Math.trunc(this.rating * 100);
  }

  getScoringClass(): string {
    const score = this.getScoring();
    if (score <= 25) {
      return 'match-display-25';
    } else if (score <= 75) {
      return 'match-display-75';
    } else {
      return 'match-display-100';
    }
  }

  subscribeToCv(cvId: string) {
    if (cvId && this.currentCvKey !== cvId) {
      this.currentCvKey = cvId;
      if (this.viewMode === ViewMode.DRAFT) {
        this.cv$ = this.cvService.getDraftCv(cvId);
      } else if (this.viewMode === ViewMode.PUBLISHED) {
        this.cv$ = this.cvService.getPublishedCv(cvId).pipe(
          switchMap((cv) => {
            if (cv) {
              if (cv.personalDetails.exclude) {
                return this.getPDIfNeeded(cv);
              } else {
                return of(cv);
              }
            } else {
              this.authService.notEnoughPermission(
                'UserDoesntExistAnymore',
                4000
              );
              return of(null);
            }
          })
        );
      }
    }
  }

  getPDIfNeeded(cv: CV) {
    return this.userService.getUserPersonalDetails(cv.ownerId).pipe(
      map((personalDetails) => {
        if (
          personalDetails &&
          personalDetails.exclude &&
          cv.personalDetails.exclude
        ) {
          cv.personalDetails = personalDetails;
        }
        return cv;
      })
    );
  }

  getEmailLength(email: string): number {
    if (email) {
      return email.length;
    } else {
      return 0;
    }
  }

  getNameLength(cv: CV): number {
    return (
      (cv.personalDetails.firstName ? cv.personalDetails.firstName.length : 0) +
      (cv.personalDetails.middleName
        ? cv.personalDetails.middleName.length
        : 0) +
      (cv.personalDetails.lastName ? cv.personalDetails.lastName.length : 0)
    );
  }

  showNameSmall(cv: CV) {
    const nameLength = this.getNameLength(cv);
    if (nameLength > 29 && nameLength <= 32) {
      return true;
    } else if (
      cv.personalDetails.pictureUrl &&
      nameLength > 19 &&
      nameLength <= 22
    ) {
      return true;
    } else {
      return false;
    }
  }

  showNameSmaller(cv: CV) {
    const nameLength = this.getNameLength(cv);
    if (nameLength > 32 && nameLength <= 36) {
      return true;
    } else if (
      cv.personalDetails.pictureUrl &&
      nameLength > 22 &&
      nameLength <= 27
    ) {
      return true;
    } else {
      return false;
    }
  }
  showNameSmallest(cv: CV) {
    const nameLength = this.getNameLength(cv);
    if (nameLength > 36 && nameLength <= 43) {
      return true;
    } else if (
      cv.personalDetails.pictureUrl &&
      nameLength > 27 &&
      nameLength < 32
    ) {
      return true;
    } else {
      return false;
    }
  }

  getRecentLocation(cv: CV) {
    return cv && this.cvService.getMostRecentExp(cv)
      ? this.cvService.getMostRecentExp(cv).locationName
      : '';
  }

  getRecentTitle(cv: CV) {
    return cv && this.cvService.getMostRecentExp(cv)
      ? this.cvService.getMostRecentExp(cv).title
      : '';
  }

  openChat(cv: CV) {
    const roomId = this.chatService.getRoomIdFromUser(cv.ownerId);
    if (roomId) {
      const dialogRef = this.dialog.open(ChatRoomComponent, {
        width: '500px',
      });

      dialogRef.componentInstance.activeChatKey = roomId;

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
