import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { hardcodedValues } from 'hardcodedValues';

@Injectable()
export class DeviceService {
  constructor(
    private deviceService: DeviceDetectorService,
    private snackBar: MatSnackBar
  ) {
    if (
      this.deviceService.isDesktop() &&
      (this.deviceService.browser === 'IE' ||
        this.deviceService.browser === 'MS-Edge')
    ) {
      const main = document.getElementsByTagName('body')[0];
      main.setAttribute('class', 'ie');
      this.snackBar
        .open(
          `${hardcodedValues.thankYouForUsing}\n
        ${hardcodedValues.internetExplorerEdgeNotSupported}\n
        ⛔ ${hardcodedValues.pleaseUseModernBrowser}`,
          hardcodedValues.getChrome,
          { duration: 20000 }
        )
        .onAction()
        .subscribe(() => {
          const url = 'https://www.google.com/chrome/';
          window.open(url, '_blank').focus();
        });
    } else {
      const main = document.getElementsByTagName('body')[0];
      main.setAttribute('class', 'not-ie');
    }
  }
}
