import { TimeService } from 'app/core/time.service';
import {
  SearchFormV2,
  SearchableTypes,
  SolrQuery,
  SolrQueryV2,
  SearchSettings,
} from 'app/shared/common/search.model';

export const createInviteSearchNameQuery = (fullName: string): SolrQuery => {
  const query = new SolrQueryV2();

  query.filter.push({
    operation: 'eq',
    field: 'type',
    value: SearchableTypes.User,
  });
  query.filter.push({
    operation: 'eq',
    field: 'type',
    value: SearchableTypes.Site,
  });
  if (fullName) {
    query.conditions.push({
      operation: 'str-contains',
      field: 'fullName',
      value: fullName,
    });
  }
  return query;
};

export const createScienceMatchQuery = (
  form: SearchFormV2,
  showUsers: boolean,
  showPosts: boolean,
  showCommunities: boolean,
  showEvents: boolean,
  showIdeas: boolean,
  showCompanies: boolean,
  showJobs: boolean
): SolrQuery => {
  const query = new SolrQueryV2();
  if (form) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const today = now.getTime();
    if (form.rows) {
      query.rows = form.rows;
    }
    if (form.page) {
      query.page = form.page;
    }
    if (form.type && form.type !== SearchableTypes.All) {
      switch (form.type) {
        case SearchableTypes.User:
          if (showUsers) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: form.type,
            });
          }
          break;
        case SearchableTypes.Site:
          if (showCompanies) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.Site,
            });
          }
          break;
        case SearchableTypes.Community:
          if (showCommunities) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.Community,
            });
          }
          break;
        case SearchableTypes.Event:
          if (showEvents) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.Event,
            });
            if (form.searchSettings) {
              if (form.searchSettings === SearchSettings.hideStarted) {
                query.conditions.push({
                  operation: 'gte',
                  field: 'startDate',
                  value: `${today}`,
                });
              }
              if (form.searchSettings === SearchSettings.hidePast) {
                query.conditions.push({
                  operation: 'gte',
                  field: 'endDate',
                  value: `${today}`,
                });
              }
              if (form.searchSettings === SearchSettings.showOngoing) {
                query.conditions.push({
                  operation: 'lte',
                  field: 'startDate',
                  value: `${today}`,
                });
                query.conditions.push({
                  operation: 'gte',
                  field: 'endDate',
                  value: `${today}`,
                });
              }
              if (form.searchSettings === SearchSettings.showExpired) {
                query.conditions.push({
                  operation: 'lte',
                  field: 'endDate',
                  value: `${today}`,
                });
              }
            }
          }
          break;
        case SearchableTypes.Idea:
          if (showIdeas) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.Idea,
            });
          }
          break;
        case SearchableTypes.GroupPublicPost:
          if (showPosts) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.GroupPublicPost,
            });
            if (form.searchSettings) {
              if (form.searchSettings === SearchSettings.show24Hours) {
                const hours24FromNow = TimeService.subtractTimeSpanToDate(
                  new Date(),
                  1
                );
                query.conditions.push({
                  operation: 'gte',
                  field: 'endDate',
                  value: `${hours24FromNow.getTime()}`,
                });
              }
              if (form.searchSettings === SearchSettings.show1Week) {
                const week1FromNow = TimeService.subtractTimeSpanToDate(
                  new Date(),
                  7
                );
                query.conditions.push({
                  operation: 'gte',
                  field: 'endDate',
                  value: `${week1FromNow.getTime()}`,
                });
              }
              if (form.searchSettings === SearchSettings.show4Weeks) {
                const week4FromNow = TimeService.subtractTimeSpanToDate(
                  new Date(),
                  28
                );
                query.conditions.push({
                  operation: 'gte',
                  field: 'endDate',
                  value: `${week4FromNow.getTime()}`,
                });
              }
            }
          }
          break;
        case SearchableTypes.Job:
          if (showJobs) {
            query.filter.push({
              operation: 'eq',
              field: 'type',
              value: SearchableTypes.Job,
            });
            if (form.searchSettings) {
              if (form.searchSettings === SearchSettings.hidePast) {
                query.conditions.push({
                  operation: 'gte',
                  field: 'expiryDate',
                  value: `${today}`,
                });
              }
              if (form.searchSettings === SearchSettings.showExpired) {
                query.conditions.push({
                  operation: 'lt',
                  field: 'expiryDate',
                  value: `${today}`,
                });
                query.conditions.push({
                  operation: 'neq',
                  field: 'openEnded',
                  value: '0',
                });
              }
            }
          }
          break;
        default:
          break;
      }
    } else if (form.type === SearchableTypes.All) {
      if (showUsers) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.User,
        });
      }
      if (showCompanies) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.Site,
        });
      }
      if (showCommunities) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.Community,
        });
      }
      if (showEvents) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.Event,
        });
      }
      if (showIdeas) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.Idea,
        });
      }
      if (showPosts) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.GroupPublicPost,
        });
      }
      if (showJobs) {
        query.filter.push({
          operation: 'eq',
          field: 'type',
          value: SearchableTypes.Job,
        });
      }
    }
    if (form.availability) {
      query.conditions.push({
        operation: 'str-contains',
        field: 'availability',
        value: form.availability,
      });
    }
    if (form.fullName) {
      query.conditions.push({
        operation: 'str-contains',
        field: 'fullName',
        value: form.fullName,
      });
    }
    if (form.facilityName) {
      query.conditions.push({
        operation: 'str-contains',
        field: 'facilityName',
        value: form.facilityName,
      });
    }
    if (form.description) {
      query.conditions.push({
        operation: 'str-contains',
        field: 'description',
        value: form.description,
      });
    }
    if (
      form.radius &&
      form.radius > 0 &&
      form.coordinates &&
      form.coordinates.length > 3
    ) {
      query.spatial['pt'] = form.coordinates;
      query.spatial['d'] = form.radius;
    } else {
      query.spatial = null;
    }
    if (form.interests && form.interests.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'interests',
        value: form.interests.map((interest) => {
          const indexText = interest.uid ? interest.uid : interest.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.language && form.language.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'languages',
        value: form.language.map((language) => language.id),
      });
    }
    if (form.techniques && form.techniques.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'techniques',
        value: form.techniques.map((tech) => {
          const indexText = tech.id ? tech.id : tech.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category0 && form.category0.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category0',
        value: form.category0.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category1 && form.category1.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category1',
        value: form.category1.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category2 && form.category2.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category2',
        value: form.category2.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category3 && form.category3.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category3',
        value: form.category3.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category4 && form.category4.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category4',
        value: form.category4.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.category5 && form.category5.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'category5',
        value: form.category5.map((cat) => {
          const indexText = cat.uid ? cat.uid : cat.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.siteSubtype && form.siteSubtype.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'siteSubtype',
        value: form.siteSubtype.map((subtype) => {
          const indexText = subtype.id ? subtype.id : subtype.name;
          return indexText.replace(/\s+/g, '');
        }),
      });
    }
    if (form.educationalLevel && form.educationalLevel.length > 0) {
      query.conditions.push({
        operation: 'contains',
        field: 'educationalLevels',
        value: form.educationalLevel,
      });
    }
    if (form.experience0) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience0',
        value: `${form.experience0 * 365}`,
      });
    }
    if (form.experience1) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience1',
        value: `${form.experience1 * 365}`,
      });
    }
    if (form.experience2) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience2',
        value: `${form.experience2 * 365}`,
      });
    }
    if (form.experience3) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience3',
        value: `${form.experience3 * 365}`,
      });
    }
    if (form.experience4) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience4',
        value: `${form.experience4 * 365}`,
      });
    }
    if (form.experience5) {
      query.conditions.push({
        operation: 'gte',
        field: 'experience5',
        value: `${form.experience5 * 365}`,
      });
    }
  }
  return query;
};
