<div class="form-group" [formGroup]="form">
  <div formArrayName="teachingExperiences">
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="activeIndex === i"
        (opened)="activeIndex = i"
        *ngFor="
          let formItem of teachingExperienceArray.controls;
          let i = index;
          trackBy: trackByFn
        "
        [formGroupName]="i"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="getHeaderText(formItem); else noTitle">
              {{ getHeaderText(formItem) | urlSizeLimiter: 25 }}
            </ng-container>
            <ng-template #noTitle>
              {{ 'newTeachingExperience' | preventHardcoding }}
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="tab-view-content">
            <mat-form-field appearance="standard">
              <mat-label>{{
                'titleOfTeachingExperience' | preventHardcoding
              }}</mat-label>
              <input matInput type="text" formControlName="title" />
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>{{
                'universityInstitution' | preventHardcoding
              }}</mat-label>
              <input matInput type="text" formControlName="university" />
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>{{ 'Department' | preventHardcoding }}</mat-label>
              <input matInput type="text" formControlName="department" />
            </mat-form-field>

            <app-country-picker
              [form]="getCountryFormGroup(formItem)"
            ></app-country-picker>

            <app-date-range-picker
              [options]="dateRangOptions"
              [dateRangeForm]="formItem"
            ></app-date-range-picker>

            <app-text-editor
              [textFormControl]="getDescription(formItem)"
              textEditorFormControlName="description"
            >
              {{ 'writeAboutSpecificTopics' | preventHardcoding }}
            </app-text-editor>
          </div>
          <mat-action-row class="button-row">
            <ng-container
              *ngIf="
                'areYouSureWantToDeleteX'
                  | preventHardcodingFormatText
                    : ('teachingExperience' | preventHardcoding) as displayText
              "
            >
              <button
                mat-raised-button
                color="warn"
                (click)="delete(i, displayText)"
              >
                <mat-icon>delete</mat-icon>{{ 'delete' | preventHardcoding }}
              </button>
            </ng-container>
            <button mat-raised-button (click)="activeIndex = -1">
              <mat-icon>check</mat-icon>{{ 'done' | preventHardcoding }}
            </button>
          </mat-action-row>
        </ng-template>
      </mat-expansion-panel>
      <mat-action-row>
        <button (click)="add()" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>{{ 'add' | preventHardcoding }}
        </button>
      </mat-action-row>
    </mat-accordion>
  </div>
</div>
