import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { JobPreviewDialogComponent } from 'app/stepper/job/components/job-preview-dialog/job-preview-dialog.component';
import { Job } from 'app/stepper/job/model/job';
import { JobService } from 'app/stepper/job/services/job.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-related-jobs',
  templateUrl: './related-jobs.component.html',
  styleUrls: ['./related-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedJobsComponent implements OnChanges {
  @Input() resultId: string;
  @Input() showJobs = false;
  relatedIds$: Observable<Observable<Job>[]>;

  constructor(private jobService: JobService, private dialog: MatDialog) {}

  ngOnChanges() {
    this.relatedIds$ = this.getRelatedIds$();
  }

  getRelatedIds$() {
    return this.jobService.getRelatedJobIds$(this.resultId).pipe(
      map((ids) => {
        return ids.map((id) => {
          return this.getPublishedJob(id);
        });
      })
    );
  }

  getPublishedJob(id: string): Observable<Job> {
    return this.jobService
      .getPublishedJob(id)
      .pipe(filter((job) => job !== null));
  }

  showPreview(key: string): void {
    this.jobService.logRead(key);
    this.dialog.open(JobPreviewDialogComponent, {
      maxWidth: '85vw',
      maxHeight: '90vh',
      panelClass: 'preview-dialog',
      data: { key: key },
    });
  }

  get tooltip() {
    return this.showJobs ? 'hideRelatedJobs' : 'showRelatedJobs';
  }
}
