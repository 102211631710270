import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MondoFormGroup } from 'app/core/mondo-form-builder';
import { Intro } from 'app/shared/models/cv/intro';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroComponent {
  @Input() form: MondoFormGroup<Intro>;
  constructor() {}

  get text() {
    return this.form.getSafeControl(x => x.text);
  }
}
