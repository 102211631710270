import { Component, OnInit } from '@angular/core';
import {
  MondoAbstractControl,
  MondoFormBuilder,
  MondoFormControl,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { MailFrequency } from 'app/notifications-new/models/mail-frequency';
import {
  CommentNotificationSettings,
  DefaultNotificationSettings,
  CommunityNotificationSettings,
  CommunityOwnerNotificationSettings,
  NotificationSettings,
  PostNotificationSettings,
  EventOwnerNotificationSettings,
  EventNotificationSettings,
  ExpirationNotificationSettings,
} from 'app/notifications-new/models/notification-settings';
import { AppNotificationService } from 'app/notifications-new/services/app-notification.service';
import { DestroyComponent } from 'app/shared-ui/base/destroy/destroy.component';
import { isObject } from 'lodash';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent
  extends DestroyComponent
  implements OnInit
{
  public settingsForm: MondoFormGroup<NotificationSettings>;
  forms: NotificationSetting[];
  constructor(
    public fb: MondoFormBuilder,
    private appNotificationService: AppNotificationService
  ) {
    super();
    this.settingsForm = this.fb.group<NotificationSettings>({
      app: new MondoFormControl(true),
      emailFrequency: new MondoFormControl(MailFrequency.Asap),
      community: this.fb.group<CommunityNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        acceptedRequest: this.getDefaulSetting(),
        invited: this.getDefaulSetting(),
        kicked: this.getDefaulSetting(),
        rejectedRequest: this.getDefaulSetting(),
        promoted: this.getDefaulSetting(),
      }),
      communityOwners: this.fb.group<CommunityOwnerNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        acceptedInvite: this.getDefaulSetting(),
        memberJoined: this.getDefaulSetting(),
        memberLeft: this.getDefaulSetting(),
        rejectedInvite: this.getDefaulSetting(),
        requestedMembership: this.getDefaulSetting(),
      }),
      event: this.fb.group<EventNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        invited: this.getDefaulSetting(),
        acceptedRequest: this.getDefaulSetting(),
        rejectedRequest: this.getDefaulSetting(),
        kicked: this.getDefaulSetting(),
        promoted: this.getDefaulSetting(),
      }),
      eventOwners: this.fb.group<EventOwnerNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        interested: this.getDefaulSetting(),
        declined: this.getDefaulSetting(),
        maybe: this.getDefaulSetting(),
        attending: this.getDefaulSetting(),
        requestedAttendance: this.getDefaulSetting(),
      }),
      posts: this.fb.group<PostNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        newPostInGroupYouAreMember: this.getDefaulSetting(),
        newPostInGroupYouOwn: this.getDefaulSetting(),
      }),
      comments: this.fb.group<CommentNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        newCommentInPostYouCommented: this.getDefaulSetting(),
        newCommentInPostYouCreated: this.getDefaulSetting(),
        newReplyToYourComment: this.getDefaulSetting(),
      }),
      expiration: this.fb.group<ExpirationNotificationSettings>({
        app: new MondoFormControl(true),
        emailFrequency: new MondoFormControl(MailFrequency.Asap),
        cvExpiration: this.getDefaulSetting(),
        siteExpiration: this.getDefaulSetting(),
        jobExpiration: this.getDefaulSetting(),
      }),
    });
  }

  ngOnInit() {
    this.forms = this.getSettings();
    this.safeSubscribe(
      this.appNotificationService.getNotificationSettings$(),
      (settings) => {
        if (isObject(settings)) {
          this.settingsForm.patchValue(settings);
          this.forms = this.getSettings();
        }
      }
    );
  }

  async saveSettings() {
    await this.appNotificationService.updateNotificationSettings(
      this.settingsForm.value
    );
  }

  private getDefaulSetting(MailFreq = MailFrequency.Asap) {
    return this.fb.group<DefaultNotificationSettings>({
      app: new MondoFormControl(true),
      emailFrequency: new MondoFormControl(MailFreq),
    });
  }

  get groupOwner() {
    return this.settingsForm.getSafeGroup<CommunityOwnerNotificationSettings>(
      (c) => c.communityOwners
    );
  }

  getAppValue(group: MondoFormGroup<DefaultNotificationSettings>): boolean {
    return group.getSafeControl((x) => x.app)!.value;
  }

  getMailFrequencyControl(
    group: MondoFormGroup<DefaultNotificationSettings>
  ): MondoAbstractControl<MailFrequency> {
    return group.getSafeControl((x) => x.emailFrequency);
  }

  getAppControl(
    group: MondoFormGroup<DefaultNotificationSettings>
  ): MondoAbstractControl<boolean> {
    return group.getSafeControl((x) => x.app);
  }

  getMailFrequencyValues(): Array<string> {
    return Object.values(MailFrequency);
  }

  getSettings(): Array<NotificationSetting> {
    const uniqueGroups = Object.keys(this.settingsForm.controls).filter(
      (c) => c !== 'app' && c !== 'emailFrequency'
    );
    return uniqueGroups.map((id) => {
      const subgroup = this.settingsForm.controls[
        id
      ] as MondoFormGroup<DefaultNotificationSettings>;
      return {
        formGroup: subgroup,
        name: id + '_notificationSetting',
        icon: IconEnum[id],
        subSettings: this.getSubSettings(subgroup),
      };
    });
  }

  getSubSettings(
    group: MondoFormGroup<DefaultNotificationSettings>
  ): Array<NotificationSetting> {
    const uniqueGroups = Object.keys(group.controls).filter(
      (c) => c !== 'app' && c !== 'emailFrequency'
    );
    return uniqueGroups.map((id) => {
      return {
        formGroup: group.controls[
          id
        ] as MondoFormGroup<DefaultNotificationSettings>,
        name: id + '_notificationSetting',
        icon: IconEnum[id],
      };
    });
  }
}

interface NotificationSetting {
  name: string;
  formGroup: MondoFormGroup<DefaultNotificationSettings>;
  icon: string;
  subSettings?: Array<NotificationSetting>;
}

enum IconEnum {
  comments = 'comment',
  community = 'groups',
  communityOwners = 'admin_panel_settings',
  posts = 'post_add',
  event = 'events',
  eventOwners = 'admin_panel_settings',
  expiration = 'hourglass_empty',
}
