import { CvStepIndex } from 'app/stepper/shared/model/stepIndex';

export enum CvStepId {
  start = 'cv-start',
  basisStep = 'cv-basisStep',
  personalDetails = 'cv-personalDetails',
  introText = 'cv-introText',
  workExperience = 'cv-workExperience',
  education = 'cv-education',
  researchStay = 'cv-researchStay',
  workshops = 'cv-workshops',
  teaching = 'cv-teaching',
  fois = 'cv-fois',
  techs = 'cv-techs',
  categories0 = 'cv-categories0',
  categories2 = 'cv-categories2',
  categories1 = 'cv-categories1',
  categories3 = 'cv-categories3',
  languages = 'cv-languages',
  additionalInfo = 'cv-additionalInfo',
  publications = 'cv-publications',
  network = 'cv-network',
}

export const CvStepIds: Map<CvStepIndex, string> = new Map()
  .set(CvStepIndex.start, CvStepId.start)
  .set(CvStepIndex.personalDetails, CvStepId.personalDetails)
  .set(CvStepIndex.introText, CvStepId.introText)
  // .set(CvStepIndex.workExperience, CvStepId.workExperience) // edu and we are mixed
  .set(CvStepIndex.workExperience, CvStepId.education)
  .set(CvStepIndex.education, CvStepId.education)
  .set(CvStepIndex.researchStay, CvStepId.researchStay)
  .set(CvStepIndex.workshops, CvStepId.workshops)
  .set(CvStepIndex.teaching, CvStepId.teaching)
  .set(CvStepIndex.fois, CvStepId.fois)
  .set(CvStepIndex.techs, CvStepId.techs)
  .set(CvStepIndex.categories0, CvStepId.categories0)
  .set(CvStepIndex.categories2, CvStepId.categories2)
  .set(CvStepIndex.categories1, CvStepId.categories1)
  .set(CvStepIndex.categories3, CvStepId.categories3)
  .set(CvStepIndex.languages, CvStepId.languages)
  .set(CvStepIndex.additionalInfo, CvStepId.additionalInfo)
  .set(CvStepIndex.publications, CvStepId.publications);

export default CvStepIds;
