<ng-container *ngIf="routeParams$ | async"></ng-container>
<ng-container *ngIf="job$ | async as job">
  <div class="job-builder-container">
    <app-action-bar
      class="no-print"
      [actionItems]="actionBarItems"
      [item]="job"
      [allItems]="allJobs$ | async"
      (showBuilder)="showBuilderChanged($event)"
      (showViewer)="showViewerChanged($event)"
      [rootUrl]="rootUrl"
      (addItem)="addNewJob()"
      (dublicateItem)="dublicateJob($event)"
      (deleteItem)="deleteJob($event)"
      (publishItem)="publishJob($event)"
      (editItem)="editJob($event)"
      (showItem)="showJob($event)"
    >
    </app-action-bar>
    <div
      *ngIf="job.key"
      class="job-container"
      [ngClass]="!showViewer || !showBuilder ? 'solo' : ''"
    >
      <div *ngIf="showBuilder" class="no-print job-builder">
        <app-job-builder
          [jobKey]="job.key"
          (stepChanged)="onStepChanged($event)"
          (jobChanged)="onJobChanged($event)"
        >
        </app-job-builder>
      </div>
      <div
        *ngIf="showViewer"
        class="viewer-wrapper"
        [ngClass]="showBuilder ? 'big-viewer' : 'small-viewer'"
      >
        <app-job-viewer [job]="job"></app-job-viewer>
      </div>
    </div>
  </div>
</ng-container>
