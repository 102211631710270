import { Component, Inject, OnInit } from '@angular/core';
import { ViewMode } from 'app/stepper/shared/model/ViewMode';
import { CV } from 'app/shared/models/cv/cv';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-cv',
  template: `
    <app-cv-viewer
      [cvKey]="data?.key"
      [viewMode]="viewMode">
    </app-cv-viewer>
  `,
})
export class PreviewCvComponent implements OnInit {

  public viewMode: ViewMode = ViewMode.PUBLISHED;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { key: string }
  ) { }

  ngOnInit() {
  }

}
