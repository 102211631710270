import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import {
  DateOptions,
  PickerMode,
} from 'app/shared/components/date-picker/date-picker.component';
import { Country } from 'app/shared/models';
import { Experience } from 'app/shared/models/cv/experience';
import { WorkShop } from 'app/shared/models/cv/work-shop';

@Component({
  selector: 'app-conference-workshop',
  templateUrl: './conference-workshop.component.html',
  styleUrls: ['./conference-workshop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferenceWorkshopComponent {
  @Input() form: MondoFormGroup<Experience>;
  now = new Date();
  public activeIndex: number;
  readonly datePickerOptions: DateOptions = {
    startDate: this.now,
    pickerMode: PickerMode.month,
    disabled: false,
    placeholder: 'date',
    timePickerLabel: '',
    optionalHint: '',
    startView: 'multi-year',
    showClearBtn: true,
    maxDate: this.now,
  };
  constructor(private formBuilder: MondoFormBuilder) {}

  get workshopsArray(): MondoFormArray<WorkShop[]> {
    return this.form.getSafeArray((x) => x.workshops);
  }

  getDateControl(workshop: MondoFormGroup<WorkShop>) {
    return workshop.getSafeControl((x) => x.date);
  }

  getAbstract(workshop: MondoFormGroup<WorkShop>) {
    return workshop.getSafeControl((x) => x.abstract);
  }

  getDescription(workshop: MondoFormGroup<WorkShop>) {
    return workshop.getSafeControl((x) => x.description);
  }

  getCountryFormGroup(
    formGroup: MondoFormGroup<WorkShop>
  ): MondoFormGroup<Country> {
    return formGroup.getSafeGroup((x) => x.country);
  }

  getHeaderText(formGroup: MondoFormGroup<WorkShop>): string {
    return formGroup.getSafe<string>((x) => x.title);
  }

  add(): void {
    this.workshopsArray.push(this.formBuilder.group(new WorkShop()));
    this.activeIndex = this.workshopsArray.length - 1;
  }

  delete(i: number, text: string): void {
    if (confirm(text)) {
      this.workshopsArray.removeAt(i);
      this.activeIndex = this.workshopsArray.length - 1;
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
