<perfect-scrollbar class="direct-match-container">
  <div class="direct-container">
    <div class="selected-job valid">
      <div>"{{ job.company.name }}"</div>
      <div>"{{ job.jobInfo.position }}"</div>
    </div>

    <div class="candidate" [ngClass]="showEndDirectMatch ? 'valid' : 'invalid'">
      <button
        *ngIf="!showCandidateInput"
        mat-raised-button
        color="primary"
        (click)="showBuilder()"
      >
        <mat-icon>expand_more</mat-icon
        >{{ 'RegisterNewCandidate' | preventHardcoding }}
      </button>
      <button
        *ngIf="showCandidateInput"
        mat-raised-button
        color="primary"
        (click)="hideBuilder()"
      >
        <mat-icon>expand_less</mat-icon>{{ 'Hide' | preventHardcoding }}
      </button>
      <ng-container>
        <app-direct-match-builder
          [showCandidateInput]="showCandidateInput"
          [directMatch]="directMatch"
          (dmReady)="jobMatchDetailsReady($event)"
        ></app-direct-match-builder>
      </ng-container>
    </div>
    <div
      class="description"
      [ngClass]="showEndDirectMatch ? 'valid' : 'invalid'"
    >
      <ng-container *ngIf="!showEndDirectMatch">
        <div class="req-text">
          {{ reqText }}
        </div>
      </ng-container>
      <ng-container *ngIf="showEndDirectMatch">
        <div>
          {{ finalCV.personalDetails.firstName }}
          {{ finalCV.personalDetails.lastName }}
        </div>
        {{ 'JobMatchDetails' | preventHardcoding }}:
        <div [innerHTML]="finalCV.intro.text | safeHtml"></div>
      </ng-container>
      <button
        *ngIf="'directMatchAdded' | preventHardcoding as confirmText"
        mat-raised-button
        color="primary"
        (click)="endDirectMatch(confirmText)"
        [disabled]="!showEndDirectMatch"
      >
        {{ 'EndDirectMatch' | preventHardcoding }}
      </button>
    </div>
  </div>
</perfect-scrollbar>
