

export class Guid {
  /**
  * @description
  * pass string with different length by default key
  *
  * @param guid key with different  length default 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  */
  static newGuid(guid: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'): string {
    return guid.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0,
        // tslint:disable-next-line:no-bitwise
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
