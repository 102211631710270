import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FenerumService } from 'app/core/fenerum.service';
import { FenerumSubscription } from 'app/shared/models/fenerum/fenerumSubscription';
import { FenerumCollectionMethods } from 'app/shared/models/fenerum/fenerumSubscription';
import { AuthService } from 'app/core/auth.service';

import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { hardcodedValues } from 'hardcodedValues';
import { SiteService } from 'app/core/site.service';
import { CvBuilderService, CvService } from 'app/stepper/cv/services';

@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss'],
})
export class CreateSubscriptionComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  subscriptionForm: FormGroup;
  formSubmitted = false;
  formError = false;
  formErrorMessage = '';
  formLoading = false;
  subscriptionExists: boolean;
  stripeUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userId: string;
      terms: string;
      siteKey: string;
      cvKey: string;
    },
    private dialogRef: MatDialogRef<CreateSubscriptionComponent>,
    private fenerumService: FenerumService,
    private siteService: SiteService,
    private cvService: CvService,
    private builderService: CvBuilderService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.fenerumService
      .subscriptionAlreadyCreated(this.authService.getCurrentUser().uid)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((res) => {
        this.subscriptionExists = res;
        if (!this.subscriptionExists) {
          this.subscriptionForm = this.createFormGroup();
          this.populateForm();
        }
      });
  }

  private populateForm() {
    this.subscriptionForm.get('account').setValue(this.data.userId);
    this.subscriptionForm.get('terms').setValue(this.data.terms);
    this.subscriptionForm.get('payment_terms').setValue(7);
  }

  private createFormGroup() {
    return new FormGroup({
      account: new FormControl('', [Validators.required]),
      terms: new FormControl('', [Validators.required]),
      collection_method: new FormControl('', [Validators.required]),
      payment_terms: new FormControl('', [Validators.required]),
    });
  }

  retry(): void {
    this.formError = false;
    this.subscriptionForm.enable({
      onlySelf: false,
      emitEvent: true,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async createSubscription() {
    this.subscriptionForm.disable({
      onlySelf: false,
      emitEvent: true,
    });
    this.formLoading = true;
    const account = this.subscriptionForm.get('account').value;
    const terms = this.subscriptionForm.get('terms').value;
    const collection_method =
      this.subscriptionForm.get('collection_method').value;
    const payment_terms = this.subscriptionForm.get('payment_terms').value;
    const fenerumSubscription: FenerumSubscription = {
      account,
      terms,
      collection_method,
      payment_terms,
    };
    if (collection_method === FenerumCollectionMethods.invoice) {
      this.fenerumService
        .createSubscriptionAtFenerum(fenerumSubscription)
        // .pipe(takeUntil(this.destroy$))
        .then(
          (response) => {
            this.formLoading = false;
            this.formSubmitted = true;
            this.publish();
          },
          (err) => {
            this.formLoading = false;
            this.formErrorMessage =
              hardcodedValues.SomethingWentWrongPaymentMethod;
            this.formError = true;
          }
        );
    } else if (collection_method === FenerumCollectionMethods.payment_card) {
      this.stripeUrl = await this.fenerumService.getAccountSpecificStripeUrl(
        account
      );
      window.open(this.stripeUrl, '_blank');
      this.formSubmitted = true;

      // const stripeResult = await this.fenerumService.openStripe(stripeUrl)
      // stripeResult
      //   .subscribe((response) => {
      //     console.log('success: ', response)
      //     this.formLoading = false;
      //     this.formSubmitted = true;
      //   }, err => {
      //     console.log('failed: ', err)
      //     this.formLoading = false;
      //     this.formErrorMessage = 'Something went wrong when attempting to submit payment account. Please, try again.';
      //     this.formError = true;
      //   })
    }
  }

  private publish() {
    if (this.data.siteKey) {
      this.siteService
        .getSiteData(this.data.siteKey)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(
          (site) => {
            this.siteService.publishSite(site);
            this.closeSiteBuilder();
          },
          (err) => {
            console.error('Oops:', err.message);
          }
        );
    } else if (this.data.cvKey) {
      this.cvService
        .getDraftCv(this.data.cvKey)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(
          (cv) => {
            this.cvService.publishCv(cv);
            this.builderService.toggleBuilder(false);
          },
          (err) => {
            console.error('Oops:', err.message);
          }
        );
    }
  }

  closeSiteBuilder() {
    this.siteService.setEditorMode(false);
    this.siteService.editMode = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
