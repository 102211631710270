import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CV } from 'app/shared/models/cv/cv';
import { CvService } from 'app/stepper/cv/services';

@Component({
  selector: 'app-required-text-cv',
  templateUrl: './required-text-cv.component.html',
  styleUrls: ['./required-text-cv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequiredTextCvComponent implements OnInit {
  @Input() cv: CV;

  constructor(private cvService: CvService) {}

  ngOnInit() {}

  getMinRequirementsText(cv: CV): string {
    return this.cvService.getRequirementStatusAndText(cv).text;
  }
}
