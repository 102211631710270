import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-start-step',
  templateUrl: './start-step.component.html',
  styleUrls: ['./start-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartStepComponent {
  @Input() firstLineText = 'defaultStartStep1';
  @Input() secondLineText = 'defaultStartStep2';
  @Input() btnText: string;

  constructor() {}
}
