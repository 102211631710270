<h2 mat-dialog-title>{{ title | preventHardcoding }}</h2>
<div mat-dialog-content>
  {{ text | preventHardcoding }}
</div>
<div *ngIf="hint" mat-dialog-content>
  {{ hint | preventHardcoding }}
</div>
<div mat-dialog-actions>
  <div class="decision-container">
    <button mat-raised-button mat-dialog-close (click)="okCancelClicked()">
      {{ noText | preventHardcoding }}
    </button>
    <div (click)="okOkClicked()">
      <app-publish-cv [cvKey]="cvKey" [showText]="false"></app-publish-cv>
    </div>
  </div>
  <!-- <button color="warn" mat-raised-button (click)="okOkClicked()" cdkFocusInitial>{{yesText| preventHardcoding }}</button> -->
</div>
