<ng-container>
  <div *ngIf="!formSubmitted && !subscriptionExists" class="row">
    <div class="col-md-12">
      <h4>
        {{'PleaseSelectYourFormOfPayment'| preventHardcoding}}
      </h4>
    </div>      
  </div>
  <div class="row">
    <div class="col-md-12 form-area">
      <div *ngIf="formSubmitted || subscriptionExists">
        <div class="row">
          <div *ngIf="formSubmitted  || !formError" class="col-md-12 text-center bug-reported-success">
            <p>{{'ThankYouYourSubscriptionHasBeenSubmitted'| preventHardcoding}}</p>
            <p>{{'welcomeToAcademondo'| preventHardcoding}}!</p>
            <p>{{'YouAreNowReadyToExploreOurUniverse'| preventHardcoding}}</p>
          </div>
          <div *ngIf="subscriptionExists" class="col-md-12 text-center bug-reported-success">
            <h4>
              {{'YouAlreadyHaveASubscriptionReachOut'| preventHardcoding}}
            </h4>            
          </div>          
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()">{{'Continue'| preventHardcoding}}</button> 
            <!-- <button mat-raised-button color="primary" (click)="">FIXME</button>  -->
          </div>
        </div>
      </div>     
      <div *ngIf="formError">
        <div class="row">
          <div class="col-md-12 text-center bug-reported-failed">
            {{formErrorMessage}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">                
            <button mat-raised-button color="primary" (click)="cancel()">{{'cancel'| preventHardcoding}}</button>
            <button mat-button (click)="retry()">{{'TryAgain'| preventHardcoding}}</button>
          </div>
        </div>
      </div>
      <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm && !formSubmitted && !subscriptionExists">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="standard">
              <mat-select
              placeholder="{{'PaymentMethod'| preventHardcoding}}"
              formControlName="collection_method">
              <mat-option [value]="'payment_card'" disabled="true" >{{'PaymentCard'| preventHardcoding}}</mat-option>
              <mat-option [value]="'invoice'">{{'Invoice'| preventHardcoding}}</mat-option>
            </mat-select>
              <mat-error *ngIf="subscriptionForm.get('collection_method').hasError('required')">
                {{'PaymentMethodMustBeSelected'| preventHardcoding}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>  
        <div class="row">
          <div class="col-md-12 text-right">
            <button mat-raised-button (click)="cancel()" [disabled]="formLoading">{{'cancel'| preventHardcoding}}</button>
            <button mat-raised-button color="primary" (click)="createSubscription()" [disabled]="formLoading || subscriptionForm.invalid">
              {{'Submit'| preventHardcoding}}
            </button>
          </div>
        </div>
        <app-splash-screen *ngIf="formLoading"></app-splash-screen>
      </form>
    </div>
  </div>
</ng-container>
  