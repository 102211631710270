import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MondoStep } from 'app/stepper/shared/model/MondoStep';
import { MondoRoutes } from '../../../app.routing-model';
import { MondoFormGroup } from '../../../core/mondo-form-builder';
import { easeInAndOutAnimation } from '../../../_animations/easeInAndOutAnimation';
import { StartStepComponent } from '../../shared/components/start-step/start-step.component';
import { CvStepIndex } from '../../shared/model/stepIndex';
import { CvFormService } from '../services/cv-form.service';
import { CvService } from '../services/cv.service';
import { CV } from '../../../shared/models/cv/cv';
import { AdditionalInformationComponent } from '../steps/additional-information/additional-information.component';
import { ConferenceWorkshopComponent } from '../steps/conference-workshop/conference-workshop.component';
import { EducationComponent } from '../steps/education/education.component';
import { IntroComponent } from '../steps/intro/intro.component';
import { PersonalDetailsComponent } from '../steps/personal-details/personal-details.component';
import { PublicationsComponent } from '../steps/publications/publications.component';
import { ResearchStayComponent } from '../steps/research-stay/research-stay.component';
import { TeachingExperienceComponent } from '../steps/teaching-experience/teaching-experience.component';
import { WorkExperienceComponent } from '../steps/work-experience/work-experience.component';
import { LanguageComponent } from '../../shared/steps/language/language.component';
import { Subscription } from 'rxjs';
import { NetworkStepComponent } from '../steps/network/network-step.component';
import { CategoriesComponent } from '../steps/categories/categories.component';
import { BasicStepComponent } from '../steps/basic-step/basic-step.component';
import { acaConfig } from 'aca-config';
import { acaNames } from 'aca-names';

@Component({
  selector: 'app-cv-builder',
  templateUrl: './cv-builder.component.html',
  styleUrls: ['./cv-builder.component.scss'],
  animations: [easeInAndOutAnimation],
})
export class CvBuilderComponent implements OnInit, OnDestroy, OnChanges {
  steps: MondoStep[];

  subscriptions: Subscription[] = [];
  cvForm: MondoFormGroup<CV>;
  formCreated = false;
  public cv: CV;

  @Input() rootUrl = MondoRoutes.cvBuilder;
  @Input() cvKey: string;
  @Output() print = new EventEmitter<CV>();
  @Output() stepChanged = new EventEmitter<CvStepIndex>();
  @Output() cvChanged = new EventEmitter<CV>();
  @Output() publish = new EventEmitter<CV>();
  cvSubscription: Subscription;
  subscribedKey: string;

  constructor(
    public cvFormService: CvFormService,
    private cvService: CvService
  ) {}

  private setupSteps() {
    const start = new MondoStep(
      CvStepIndex.start,
      StartStepComponent,
      'viewTemplate',
      new Map().set('btnText', 'beginBuildingYourCV'),
      [true, true]
    );
    const basisSteps = new MondoStep(
      CvStepIndex.basisStep,
      BasicStepComponent,
      'basisStepTitle',
      new Map()
        .set('form', this.cvFormService.personalDetails)
        .set('experience', this.cvFormService.experience)
        .set('categories', [acaNames.Foi])
        .set('cvForm', this.cvFormService.getForm())
        .set('btnText', 'basisStepNextBtnText'),
      [true, true]
    );
    const personalDetails = new MondoStep(
      CvStepIndex.personalDetails,
      PersonalDetailsComponent,
      'personalDetails',
      new Map()
        .set('form', this.cvFormService.personalDetails)
        .set('cvForm', this.cvFormService.getForm())
        .set('showBasicStep', acaConfig.showBasicStep),
      [true, true]
    );
    const intro = new MondoStep(
      CvStepIndex.introText,
      IntroComponent,
      'introductoryText',
      new Map().set('form', this.cvFormService.intro),
      [true, true]
    );
    const fois = new MondoStep(
      CvStepIndex.fois,
      CategoriesComponent,
      'Fois',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 1)
        .set('categories', [acaNames.Foi]),
      [true, true]
    );
    const techs = new MondoStep(
      CvStepIndex.techs,
      CategoriesComponent,
      'Techs',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 5)
        .set('categories', [acaNames.Tech]),
      [true, true]
    );
    const categories0 = new MondoStep(
      CvStepIndex.categories0,
      CategoriesComponent,
      'Categories0',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 1)
        .set('categories', [acaNames.Category0]),
      [true, true]
    );
    const categories1 = new MondoStep(
      CvStepIndex.categories1,
      CategoriesComponent,
      'Categories1',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 1)
        .set('categories', [acaNames.Category1]),
      [true, true]
    );
    const categories2 = new MondoStep(
      CvStepIndex.categories2,
      CategoriesComponent,
      'Category2',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 1)
        .set('categories', [acaNames.Category2]),
      [true, true]
    );
    const categories3 = new MondoStep(
      CvStepIndex.categories3,
      CategoriesComponent,
      'Category3',
      new Map()
        .set('experience', this.cvFormService.experience)
        .set('restrictSelection', false)
        .set('maxSelection', 1)
        .set('categories', [acaNames.Category3]),
      [true, true]
    );
    const languages = new MondoStep(
      CvStepIndex.languages,
      LanguageComponent,
      'Languages',
      new Map().set('form', this.cvFormService.languages),
      [true, true]
    );
    const workExp = new MondoStep(
      CvStepIndex.workExperience,
      WorkExperienceComponent,
      'workExperience',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );
    const education = new MondoStep(
      CvStepIndex.education,
      EducationComponent,
      'education',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );
    const researchStay = new MondoStep(
      CvStepIndex.researchStay,
      ResearchStayComponent,
      'researchStayStudiesAbroad',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );
    const conferenceWorkshop = new MondoStep(
      CvStepIndex.workshops,
      ConferenceWorkshopComponent,
      'conferenceAndWorkshops',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );
    const teachingExp = new MondoStep(
      CvStepIndex.teaching,
      TeachingExperienceComponent,
      'teachingExperience',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );

    const additional = new MondoStep(
      CvStepIndex.additionalInfo,
      AdditionalInformationComponent,
      'additionalInformation',
      new Map().set('form', this.cvFormService.additionalInfo),
      [true, true]
    );
    const publications = new MondoStep(
      CvStepIndex.publications,
      PublicationsComponent,
      'publications',
      new Map().set('form', this.cvFormService.experience),
      [true, true]
    );
    const network = new MondoStep(
      CvStepIndex.network,
      NetworkStepComponent,
      'NetworkCVStep',
      new Map().set('form', this.cvFormService.network),
      [true, true]
    );
    const steps = [];
    steps.push(start);
    if (acaConfig.showBasicStep) {
      steps.push(basisSteps);
    }
    steps.push(personalDetails);
    steps.push(intro);
    if (acaNames.Foi && !acaConfig.showBasicStep) {
      steps.push(fois);
    }
    if (acaNames.Tech) {
      steps.push(techs);
    }
    if (acaNames.Category0) {
      steps.push(categories0);
    }
    if (acaNames.Category2) {
      steps.push(categories2);
    }
    if (acaNames.Category1) {
      steps.push(categories1);
    }
    if (acaNames.Category3 && !acaConfig.showApplicationProcessFunctions) {
      steps.push(categories3);
    }
    this.steps = steps.concat([
      // languages,
      // workExp,
      // education,
      // researchStay,
      // conferenceWorkshop,
      // teachingExp,
      // additional,
      // publications,
      // network,
    ]);
  }

  ngOnInit() {
    this.cvForm = this.cvFormService.getForm();
    this.subscribeToCv(this.cvKey);

    this.subscriptions.push(
      this.cvFormService.getFormChangeStream().subscribe((cvFromChange) => {
        if (cvFromChange) {
          cvFromChange.name = this.cv.name;
          cvFromChange.status = this.cv.status;
          this.cvChanged.emit(cvFromChange);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.cvKey &&
      changes.cvKey.currentValue &&
      !changes.cvKey.firstChange
    ) {
      if (this.cvSubscription) {
        this.cvSubscription.unsubscribe();
      }
      this.subscribeToCv(changes.cvKey.currentValue);
    }
  }

  subscribeToCv(key: string) {
    if (this.subscribedKey !== key && key !== MondoRoutes.none) {
      this.setupSteps();
      this.formCreated = false;
      this.subscribedKey = key;
      if (this.cvSubscription) {
        this.cvSubscription.unsubscribe();
        this.cvSubscription = null;
      }
      this.cvService.cvData$ = this.cvService.getDraftCv(key);
      this.cvSubscription = this.cvService.cvData$.subscribe((cvData) => {
        this.cv = cvData;
        if (!this.formCreated) {
          this.formCreated = true;
          this.cvFormService.patchCV(cvData);
        }
      });
    }
  }

  onStepChanged(index: CvStepIndex) {
    this.stepChanged.emit(index);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this.cvSubscription) {
      this.cvSubscription.unsubscribe();
    }
  }
}
