import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/core/auth.service';
import { LoggingService } from 'app/core/logging.service';
import { hardcodedValues } from 'hardcodedValues';
import { ExtendedLog, LogAction, ErrorLog } from 'app/shared/models/log';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { StrictEmailValidator } from '@helpers';
import { HttpClient } from '@angular/common/http';
import { acaConfig } from 'aca-config';
import { ScientistUser } from 'app/shared/models/user/scientistUser';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteComponent implements OnInit {
  @Input() showExpander = true;
  @Input() showInvite = false;
  inviteForm: FormGroup;
  showTitle = false;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.setupForm();
  }

  private setupForm(): void {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.required, StrictEmailValidator]],
      inviteName: '',
      personalText: '',
    });
  }

  sendInvite() {
    this.sendInvitation(
      this.email.value,
      this.inviteName.value ? this.inviteName.value : this.email.value,
      this.personalText.value ? this.personalText.value : ''
    );
  }

  get email() {
    return this.inviteForm.get('email');
  }

  get inviteName() {
    return this.inviteForm.get('inviteName');
  }

  get personalText() {
    return this.inviteForm.get('personalText');
  }

  private getOrg(user: MondoUser) {
    let org = '';
    if (this.authService.isScientist) {
      org = (user as ScientistUser).personalDetails
        ? `${hardcodedValues.from} ${
            (user as ScientistUser).personalDetails.companyName
          }`
        : '';
    }
    return org;
  }

  private getTitle(user: MondoUser) {
    let title = '';
    if (this.authService.isScientist && this.showTitle) {
      title =
        (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
          ? `${(user as ScientistUser).personalDetails.academicTitle} `
          : '';
    } else {
      // org = (user as IndustryUser).company
      //   ? (user as IndustryUser).company.name
      //   : '';
    }
    return title;
  }

  public async sendInvitation(
    toEmail: string,
    inviteName: string,
    personalText = ''
  ) {
    const url = environment.sendInviteEmail;
    const currentUser: MondoUser = this.authService.getCurrentUser();
    const body = {
      to: toEmail,
      from: hardcodedValues.senderMail,
      subject: hardcodedValues.mailSubject,
      templateId: acaConfig.inviteTemplateId,
      dynamic_template_data: {
        inviteName: `${hardcodedValues.Dear} ${inviteName},`,
        senderText: `${this.getTitle(currentUser)}${
          currentUser.displayName
        } ${this.getOrg(currentUser)} ${hardcodedValues.hasInvitedYouTo} ${
          hardcodedValues.join
        } ${hardcodedValues.dashboardWelcomeTitle}.`,
        personalText: personalText ? `"${personalText}"` : '',
        platformLink: hardcodedValues.academondoUrl,
      },
    };

    return await this.httpClient
      .post(url, body)
      .toPromise()
      .then(() => {
        this.loggingService.logAction(
          new ExtendedLog(LogAction.userInvited, toEmail, '')
        );
        this.authService.notEnoughPermission(
          `${hardcodedValues.YourInvitationTo}:  ${toEmail} ${hardcodedValues.hasBeenSent}!`,
          5000
        );
      })
      .catch((err) => {
        this.loggingService.logAction(new ErrorLog(err));
        this.authService.notEnoughPermission('unknownError', 5000);
      });
  }
}
