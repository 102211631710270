import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutingModel } from 'app/app.routing-model';
import { AuthGuard } from 'app/core/auth.guard';
import { NotificationsNewModule } from 'app/notifications-new/notifications-new.module';
import { CompanyProfileComponent } from 'app/profile-editor/user-profile/company-profile/company-profile.component';
import { ScientistProfileComponent } from 'app/profile-editor/user-profile/scientist-profile/scientist-profile.component';
import { UserProfileComponent } from 'app/profile-editor/user-profile/user-profile.component';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { SharedModule } from 'app/shared/shared.module';
import { CVModule } from 'app/stepper/cv/cv.module';
import { JobModule } from 'app/stepper/job/job.module';

export const profileEditor = RoutingModel.profileEditor;
const routes = [
  {
    path: profileEditor.path,
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    data: profileEditor.routeData,
  },
  {
    path: profileEditor.path + '/:tab',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    data: profileEditor.routeData,
  },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    CVModule,
    JobModule,
    NotificationsNewModule,
    SharedPipesModule,
  ],
  declarations: [
    UserProfileComponent,
    CompanyProfileComponent,
    ScientistProfileComponent,
  ],
})
export class ProfileEditorModule {}
