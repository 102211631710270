<div class="aca-heading action-bar" *ngIf="!editMode">
  <div class="buttons action-bar__inner">
    <ng-container *ngFor="let item of actionBarItems; trackBy: trackByFn">
      <button
        mat-button
        [class.action-bar__active-button]="
          isActive(item.canBeActive, item.activationUrl)
        "
        appDebounceClick
        (debounceClick)="item.onClick()"
      >
        <span fxHide fxShow.gt-md *ngIf="item.title" class="item">
          {{ item.title | preventHardcoding }}
        </span>
        <mat-icon
          fxShow
          fxHide.gt-md
          matTooltip="{{ item.title | preventHardcoding }}"
          class="action-bar-icons"
          >{{ item.icon }}</mat-icon
        >
      </button>
    </ng-container>
  </div>
</div>
