import { Author } from './author';

export class Publication {
  public static fromJson({ pmId, date, title, journal, authors }): Publication {
    const parsedAuthors = authors ? authors.map(a => Author.fromJson(a)) : [];
    return new Publication(
      pmId,
      new Date(date || new Date()),
      title,
      journal,
      parsedAuthors
    );
  }
  constructor(
    public pmId: string,
    public date: Date,
    public title: string,
    public journal: string,
    public authors: Author[]
  ) {}
}
