// import { acaNamesACADEMONDO } from '../src/aca-names-ACADEMONDO';
// import { acaNamesAC } from '../src/aca-names-AC';
// import { acaNamesOE } from '../src/aca-names-OE';
// import { acaNamesEBRAINS } from '../src/aca-names-EBRAINS';
// import { acaNamesSUSTAINABLE } from '../src/aca-names-SUSTAINABLE';
// import { acaNamesSYNAPSE } from '../src/aca-names-SYNAPSE';
// import { acaNamesMAGISTER } from '../src/aca-names-MAGISTER';
import { acaNamesSALES } from '../src/aca-names-SALES';
// import { acaNamesDLS } from '../src/aca-names-dls-klynge';
// import { acaNamesDESMV } from '../src/aca-names-DESMV';

// export const acaNames = acaNamesACADEMONDO;
// export const acaNames = acaNamesAC;
// export const acaNames = acaNamesOE;
// export const acaNames = acaNamesEBRAINS;
// export const acaNames = acaNamesSUSTAINABLE;
// export const acaNames = acaNamesSYNAPSE;
// export const acaNames = acaNamesMAGISTER;
export const acaNames = acaNamesSALES;
// export const acaNames = acaNamesDLS;
// export const acaNames = acaNamesDESMV;
