import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth.service';
import { SuggestionService } from 'app/core/suggestion.service';
import { SuggestedTechnique } from '../../models';
import { StrictEmailValidator } from '@helpers';

@Component({
  selector: 'app-suggest-technique-dialog',
  templateUrl: './suggest-technique-dialog.component.html',
  styleUrls: ['./suggest-technique-dialog.component.scss'],
})
export class SuggestTechniqueDialogComponent implements OnInit {
  suggestionForm: FormGroup;
  formError = false;
  formErrorMessage = '';

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SuggestTechniqueDialogComponent>,
    private suggestionService: SuggestionService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.suggestionForm = this.formBuilder.group({
      technique: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      email: new FormControl('', [Validators.required, StrictEmailValidator]),
      category: new FormControl('', []),
      uid: new FormControl('', []),
    });

    const user$ = this.authService.getCurrentUser$();
    const loggedIn$ = this.authService.getUserStatus$();

    loggedIn$.pipe(take(1)).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        user$.pipe(take(1)).subscribe((user) => {
          this.suggestionForm.get('email').setValue(user.email);
          this.suggestionForm.get('uid').setValue(user.uid);
        });
      }
    });
  }

  sendSuggestion(): void {
    const technique = this.suggestionForm.get('technique').value;
    const email = this.suggestionForm.get('email').value;
    const category = this.suggestionForm.get('category').value;
    const uid = this.suggestionForm.get('uid').value;
    this.suggestionService.suggestTechnique(
      new SuggestedTechnique(technique, email, category, '', uid)
    );
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
