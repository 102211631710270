import { NgModule } from '@angular/core';
import { CvViewEducationWorkExpComponent } from '../../viewer/cv/cv-view-education-work-exp/cv-view-education-work-exp.component';
import { CvViewPublicationsComponent } from '../../viewer/cv/cv-view-publications/cv-view-publications.component';
import { CvViewTeachingExpComponent } from '../../viewer/cv/cv-view-teaching-exp/cv-view-teaching-exp.component';
import { CvViewWorkshopsComponent } from '../../viewer/cv/cv-view-workshops/cv-view-workshops.component';
import { CVViewerComponent } from './cv-viewer.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ChatRoomComponent } from 'app/chat/chat-room/chat-room.component';
import { ChatModule } from 'app/chat/chat.module';
import { SharedPipesModule } from 'app/shared-pipes/shared-pipes.module';
import { SharedUiModule } from 'app/shared-ui/shared-ui.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedPipesModule,
    SharedUiModule,
    ChatModule,
  ],
  declarations: [
    CVViewerComponent,
    CvViewPublicationsComponent,
    CvViewWorkshopsComponent,
    CvViewTeachingExpComponent,
    CvViewEducationWorkExpComponent,
  ],
  providers: [],
  entryComponents: [ChatRoomComponent],
  exports: [CVViewerComponent],
})
export class CvViewerModule {}
