import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from 'app/core/user.service';
import { StrictEmailValidator } from '@helpers';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  @Input() title = 'changeEmail';
  public resetForm: FormGroup;
  public error = false;
  public errorMsg: string;
  public loading = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public fb: FormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, StrictEmailValidator]],
    });
  }

  get email() {
    return this.resetForm.get('email');
  }

  public submit() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'changeEmail';
    dialogRef.componentInstance.text = 'newEmailConfirm';
    dialogRef.componentInstance.yesText = 'yes';
    dialogRef.componentInstance.noText = 'cancel';
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loading = true;
        this.authService
          .changeEmail(this.email.value)
          .then(async () => {
            const user = this.authService.getCurrentUser();
            user.email = this.email.value;
            await this.userService.updateUser(user);
            this.error = false;
            this.authService.notEnoughPermission('newEmailText', 5000);
          })
          .catch((e) => {
            this.error = true;
            this.errorMsg = e;
          })
          .finally(() => (this.loading = false));
      }
    });
  }
}
