<div class="form-group" [formGroup]="form">
  <div formArrayName="workshops">
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="activeIndex === i"
        (opened)="activeIndex = i"
        *ngFor="
          let exp of workshopsArray.controls;
          let i = index;
          trackBy: trackByFn
        "
        [formGroupName]="i"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container *ngIf="getHeaderText(exp); else noTitle">
              {{ getHeaderText(exp) | urlSizeLimiter: 25 }}
            </ng-container>
            <ng-template #noTitle>
              {{ 'newWorkshopConference' | preventHardcoding }}
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="tab-view-content">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'title' | preventHardcoding }}</mat-label>
              <input matInput type="text" formControlName="title" />
            </mat-form-field>

            <app-country-picker
              [form]="getCountryFormGroup(exp)"
            ></app-country-picker>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'city' | preventHardcoding }}</mat-label>
              <input matInput type="text" formControlName="city" />
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>{{ 'contribution' | preventHardcoding }}</mat-label>
              <input matInput type="text" formControlName="contribution" />
            </mat-form-field>

            <app-date-picker
              [dateOptions]="datePickerOptions"
              [fControl]="getDateControl(exp)"
            ></app-date-picker>

            <app-text-editor
              [textFormControl]="getDescription(exp)"
              textEditorFormControlName="description"
              >{{ 'writeConference' | preventHardcoding }}</app-text-editor
            >

            <app-text-editor
              [textFormControl]="getAbstract(exp)"
              textEditorFormControlName="abstract"
              >{{ 'insertAbstract' | preventHardcoding }}</app-text-editor
            >
          </div>

          <mat-action-row class="button-row">
            <ng-container
              *ngIf="
                'areYouSureWantToDeleteX'
                  | preventHardcodingFormatText
                    : ('conferenceAndWorkshops'
                        | preventHardcoding) as displayText
              "
            >
              <button
                mat-raised-button
                color="warn"
                (click)="delete(exp, displayText)"
              >
                <mat-icon>delete</mat-icon>{{ 'delete' | preventHardcoding }}
              </button>
            </ng-container>
            <button mat-raised-button (click)="activeIndex = -1">
              <mat-icon>check</mat-icon>{{ 'done' | preventHardcoding }}
            </button>
          </mat-action-row>
        </ng-template>
      </mat-expansion-panel>
      <mat-action-row>
        <button (click)="add()" mat-raised-button color="primary">
          <mat-icon>add</mat-icon>{{ 'add' | preventHardcoding }}
        </button>
      </mat-action-row>
    </mat-accordion>
  </div>
</div>
