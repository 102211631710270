<div class="login-container">
  <!-- <div class="aca-logo">
    <img />
  </div> -->
  <div class="aca-name">
    <img title="logo" src="/assets/logos/Academondo.svg" />
  </div>
  <div class="login-form-container" *ngIf="!showResetForm">
    <app-sso
      *ngIf="'SSOEnabled' | preventHardcoding"
      [introText]="'SSOLoginIntro'"
      [text]="'SSOLogin'"
      [outroText]="'SSOLoginOutro'"
    ></app-sso>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field appearance="standard">
        <mat-label>{{ 'Email' | preventHardcoding }}</mat-label>
        <input
          title="email"
          type="email"
          name="email"
          matInput
          formControlName="email"
          required
        />
        <mat-error *ngIf="email?.errors?.email">{{
          'EmailMustBeAnEmailExSupportAtAcademondoCom' | preventHardcoding
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>{{ 'password' | preventHardcoding }}</mat-label>
        <input
          title="password"
          type="password"
          autocomplete="current-password"
          matInput
          formControlName="password"
          required
        />
      </mat-form-field>
      <div class="footer">
        <button
          class="login-button"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!loginForm.valid"
        >
          {{ 'login' | preventHardcoding }}
        </button>
        <button type="button" mat-button (click)="onResetPasswordClicked()">
          {{ 'forgotPassword' | preventHardcoding }}?
        </button>
      </div>
      <div class="verification">
        <mat-error class="small" *ngIf="error">
          {{ errorMsg | preventHardcoding }}
        </mat-error>
        <button
          color="primary"
          *ngIf="showResendVerificationMail"
          type="button"
          mat-raised-button
          (click)="sendVerificationEmail()"
        >
          {{ 'resendVerificationMail' | preventHardcoding }}
        </button>
        <small *ngIf="showEmailSentMsg">{{
          'verificationMailSent' | preventHardcoding
        }}</small>
      </div>
    </form>
  </div>
  <div *ngIf="showResetForm">
    <app-reset-password (onBack)="hideResetPassword()"></app-reset-password>
  </div>
  <div>
    <mat-card *ngIf="waitingForVerify" selected="true" color="primary">
      <p>{{ 'welcomeToAcademondo' | preventHardcoding }}!</p>
      <small>{{ 'pleaseVerifyYourEmail' | preventHardcoding }}</small>
    </mat-card>
    <div *ngIf="!waitingForVerify && canSignup" class="footer last">
      <p>
        <b>{{ 'dontHaveAnAccount' | preventHardcoding }}</b>
      </p>
      <button mat-raised-button color="primary" (click)="signup()">
        {{ 'signUp' | preventHardcoding }}
      </button>
    </div>
  </div>
</div>
