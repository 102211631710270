import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, catchError, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable, throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private AFStorage: AngularFireStorage,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}

  public uploadImage(
    file: any,
    userPath: string,
    fileName = '',
    percentageStatus?: (per: Observable<number>) => void
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const metadata = {
          cacheControl: 'public,max-age=31536000',
          contentType: 'image/jpeg',
        };
        const path = fileName
          ? `${this.authService.getCurrentUser().uid}/${userPath}/${fileName}`
          : `${
              this.authService.getCurrentUser().uid
            }/${userPath}/${Date.now()}_${file.name}`;
        const task = this.AFStorage.upload(path, file, metadata);
        if (percentageStatus) {
          percentageStatus(task.percentageChanges());
        }
        const fileRef = this.AFStorage.ref(path);
        const snapshot = task.snapshotChanges();

        snapshot
          .pipe(
            finalize(() =>
              fileRef
                .getDownloadURL()
                .pipe(take(1))
                .subscribe((p) => resolve(p))
            )
          )
          .subscribe();
      } else {
        reject();
      }
    });
  }

  public copyFileToNewPath(
    currentUserPath: string,
    newUserPath: string,
    newName = '',
    percentageStatus?: (per: Observable<number>) => void,
    fullPath = false
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let currentPath: string;
      if (fullPath) {
        currentPath = currentUserPath;
      } else {
        currentPath = `${
          this.authService.getCurrentUser().uid + '/' + currentUserPath
        }`;
      }
      const fileRef = this.AFStorage.ref(currentPath);

      fileRef.getDownloadURL().subscribe((url) => {
        return this.httpClient
          .get(url, { responseType: 'blob' })
          .subscribe((file) => {
            this.uploadImage(file, newUserPath, newName, percentageStatus).then(
              (u) => resolve(u)
            );
          });
      });
    });
  }

  public deleteFile(filePath: string, fullPath = false, name = '') {
    if (fullPath) {
      const path = name ? `${filePath}/${name}` : filePath;
      return this.AFStorage.ref(path).delete();
    } else {
      const path = name ? `${filePath}/${name}` : filePath;
      return this.AFStorage.ref(
        `${this.authService.getCurrentUser().uid}/${path}`
      ).delete();
    }
  }

  public startUpload(
    storagePath: string,
    file: File,
    cb: Function,
    percentageStatus: (per: Observable<number>) => void,
    fileSizeExceeds: string,
    incorrectFileFormat: string
  ) {
    if (
      !this.isFileTypeAccepted(file, incorrectFileFormat) ||
      !this.IsfileSizeAccepted(file, fileSizeExceeds)
    ) {
      return;
    } else {
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: file.type,
      };

      const path = `${storagePath}/${Date.now()}_${file.name}`;
      const ref = this.AFStorage.ref(path);
      const task = this.AFStorage.upload(path, file, metadata);

      // Progress monitoring
      percentageStatus(task.percentageChanges());
      return task.snapshotChanges().pipe(
        // tap(console.log),

        // The file's download URL
        finalize(async () => {
          const downloadUrl = await ref.getDownloadURL().toPromise();
          // process.stdout.write('finalize: ', 'post', downloadUrl);
          cb({
            name: file.name,
            path: path,
            url: downloadUrl,
            size: file.size,
          });

          // return this.db.collection('files').add( { downloadURL: downloadURL, path });
        }),
        catchError(() => throwError('Unable to upload file!'))
      );
    }
  }

  private IsfileSizeAccepted(file, fileSizeExceeds: string) {
    const fileSize = file.size / 1024 / 1024; // in MB
    const MAX = 15;
    if (fileSize > MAX) {
      this.authService.notEnoughPermission(
        `${fileSizeExceeds} ${MAX} MB`,
        5000
      );
      return false;
    } else {
      return true;
    }
  }

  private getFileExtension(filename: string) {
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(filename)[1];
  }

  private isFileTypeAccepted(file, incorrectFileFormat: string) {
    if (!file) {
      return false;
    }

    const fileExt = this.getFileExtension(file.name);
    const acceptedFileTypesDisplay = [
      '.gif',
      '.jpg',
      '.jpeg',
      '.png',
      '.svg',
      '.pdf',
      '.ics',
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ptt',
      '.pttx',
      '.txt',
    ].join(', ');
    const acceptedFileTypes = [
      'gif',
      'jpg',
      'jpeg',
      'png',
      'svg',
      'pdf',
      'ics',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'txt',
    ];

    if (acceptedFileTypes.includes(fileExt)) {
      return true;
    } else {
      this.authService.notEnoughPermission(
        `${incorrectFileFormat} ${acceptedFileTypesDisplay.toString()}`,
        5000
      );
      return false;
    }
  }
}
