export const configSALES = {
  academondo: 'ACADEMONDO',
  academondoDash: 'ACADEMONDO',
  academondoCom: 'sales.academondo.com',
  academondoUrl: 'https://sales.academondo.com',
  supportMail: 'support@academondo.com',
  reviewMail: 'support@academondo.com',
  senderMail: 'support@academondo.com',
  inviteTemplateId: 'd-f70e8eee6c9c4b72a8a1ee84f9e5415d', // sendgrid

  dateFormat: 'da-DK',
  languageCKEditor: 'da-DK',

  defaultUserCoverUrl:
    'https://strapi-prod.sos-ch-dk-2.exo.io/ebrains_ssd_call_ebrains_website_3a68136980.jpg',
  defaultEventCoverUrl:
    'https://images.unsplash.com/photo-1551818255-e6e10975bc17?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8ZXZlbnR8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
  defaultCommunityCoverUrl:
    'https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y29tbXVuaXR5JTIwd29ya3Nob3B8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
  defaultIdeaCoverUrl:
    'https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHByb2plY3QlMjBidXNpbmVzcyUyMHdvcmtpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60',
  welcomeWidgetCoverUrl:
    'https://strapi-prod.sos-ch-dk-2.exo.io/overview_cover_cc24ba93a1.jpg',

  termsLink:
    'https://firebasestorage.googleapis.com/v0/b/academondo.appspot.com/o/terms.html?alt=media&token=0e2dfc8b-672d-49bd-835d-944162cebbe4',
  privacyPolicyLink:
    'https://firebasestorage.googleapis.com/v0/b/academondo.appspot.com/o/privacy-policy.html?alt=media&token=5de3bf51-f454-433e-9d9c-15de16838c3d',
  copyRightDate: '© 2020,',

  academondoStreet: 'Holbergsvej 22,',
  academondoPostboxCity: '8800 Viborg',
  academondoCVR: '37919527',
  academondoPhone: '+45 21 42 64 44',

  twitterLink: 'https://twitter.com/academondo', // leave blank if it should not be shown
  facebookLink: 'https://www.facebook.com/AcadeMondo', // leave blank if it should not be shown
  linkedinLink: 'https://linkedin.com/company/academondo/', // leave blank if it should not be shown
  instagramLink: '', // https://www.instagram.com/companyName/ // leave blank if it should not be shown
  fbPagePluginLink: '', // 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAcadeMondo%2F&tabs&width=280&height=155&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=469250956745801', // leave blank if it should not be shown

  showTermsInNavbarMenu: false,

  showMaxSearchResultSelector: false,
  maxSearchResults: 50,

  SSOEnabled: false,
  groupFilesEnabled: true,
  enabledSubgroups: true,

  expireSites: false,
  expireProfiles: false,

  enableJobs: true,
  showMiddleName: false,

  slimSiteIndex: false,
  showDepartmentInPersonalDetails: false,
  showSexInPersonalDetails: false,

  showCreateCommunityButtonForUserWithoutPermission: true,
  showCreateEventButtonForUserWithoutPermission: true,
  showCreateIdeaButtonForUserWithoutPermission: true,

  showUsersWithoutCV: false,

  showApplicationProcessFunctions: false, // leave blank if it should not be shown

  registerVisitFunctions: '', //'Registrér besøg', // leave blank if it should not be shown

  showRelatedJobsFunctions: true, // leave blank if it should not be shown

  showCalendarInNetworks: false, // leave as false if it should not be shown'

  showMapWithSpecificColors: true, // leave as false if it should not be shown

  showMapInNetworks: false, // leave as false if it should not be shown

  showBorderedButtonsInNavBar: false, // leave as false if it should not be shown
  showCapitalizedButtonsInNavBar: false, // leave as false if it should not be shown
  showNavSpacersInNavBar: false, // leave as false if it should not be shown

  showAccountChangerInNavBar: false, // leave as false if it should not be shown
  showAccountSelectorIcons: false, // leave as false if it should not be shown
  showChangeAccountTypeInSignup: false, // leave as false if it should not be shown

  showSiteImageOnMap: true, // leave as false if it should not be shown
  showPlaceImageOnMap: false, // leave as false if it should not be shown
  showNetworkImageOnMap: false, // leave as false if it should not be shown
  showCommunityImageOnMap: false, // leave as false if it should not be shown
  showIdeaImageOnMap: false, // leave as false if it should not be shown
  showEventImageOnMap: false, // leave as false if it should not be shown
  showCreatorOfCommunities: true, // leave as false if it should not be shown
  showCreatorOfEvents: true, // leave as false if it should not be shown
  showCreatorOfIdeas: true, // leave as false if it should not be shown
  showOptionToRemoveUsersPostsAndEvEventsOnLeaving: false, // leave as false if it should not be shown
  showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser: true, // leave as false if it should not be shown
  showInviteWidget: true, // leave as false if it should not be shown
  showBasicStep: false, // leave as false if it should not be shown
  RegisterDirectMatch: '', // 'Registrer direkte match', // leave blank if it should not be shown
  contactConsentGiven: '', // 'indkøbsservice', // leave blank if it should not be shown

  u0Marker: '/assets/icons/dc3545-marker.svg',
  siteMarker: '/assets/icons/008bff-marker.svg',
  u2Marker: '/assets/icons/008bff-marker.svg',
  u3Marker: '/assets/icons/fd7e14-marker.svg',
  u4Marker: '/assets/icons/6f42c1-marker.svg',
  u5Marker: '/assets/icons/40722c-marker.svg',
  gMarker: '/assets/icons/6f42c1-marker.svg',
  cMarker: '/assets/icons/007816-marker.svg',
  eMarker: '/assets/icons/ffc107-marker.svg',
  iMarker: '/assets/icons/6c757d-marker.svg',
  nMarker: '/assets/icons/254e58-marker.svg',
  p0Marker: '/assets/icons/ffc107-marker.svg',
  p1Marker: '/assets/icons/e83e8c-marker.svg',
  p2Marker: '/assets/icons/6f42c1-marker.svg',
  p3Marker: '/assets/icons/6c757d-marker.svg',
};
