<div *ngIf="options">
  <mat-checkbox
    *ngIf="!options.hideOpenEndedCheckBox"
    #showEndDate
    color="primary"
    [formControl]="current"
    >{{ options.openEndedCheckboxText | preventHardcoding }}</mat-checkbox
  >
  <br />
  <mat-checkbox
    *ngIf="!options.hideStartNowCheckBox"
    (change)="startNowChanged($event.checked)"
    color="primary"
    [formControl]="startNow"
    >{{ options.startNowCheckboxText | preventHardcoding }}</mat-checkbox
  >
  <div class="dates-container">
    <div
      class="date-container"
      *ngIf="options.hideStartNowCheckBox || !startNow.value"
    >
      <div class="date-picker-container">
        <app-date-picker
          [dateOptions]="startOptions"
          [fControl]="startDate"
        ></app-date-picker>
      </div>
      <div *ngIf="startOptions.timePickerLabel" class="time-picker-container">
        <material-timepicker
          *ngIf="showStartTime"
          class="time-picker"
          color="primary"
          [label]="startOptions.timePickerLabel | preventHardcoding"
          appearance="fill"
          [userTime]="startTime"
          (change)="onChangeStartTime($event)"
        ></material-timepicker>
      </div>
    </div>
    <div
      class="date-container"
      *ngIf="options.hideOpenEndedCheckBox || (current && !current.value)"
    >
      <div class="date-picker-container">
        <app-date-picker
          [dateOptions]="endOptions"
          [fControl]="endDate"
        ></app-date-picker>
      </div>
      <div *ngIf="endOptions.timePickerLabel" class="time-picker-container">
        <material-timepicker
          *ngIf="showEndTime"
          class="time-picker"
          color="primary"
          [label]="endOptions.timePickerLabel | preventHardcoding"
          appearance="fill"
          [userTime]="endTime"
          (change)="onChangeEndTime($event)"
        ></material-timepicker>
      </div>
    </div>
  </div>
  <ng-container *ngIf="valueChanges$ | async"></ng-container>
</div>
