import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UserStatus, AuthService } from 'app/core/auth.service';
import {
  INavigationItem,
  MondoRoutes,
  RoutingModel,
} from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';
import { RoutingService } from 'app/core/routing.service';
import { MondoAccount } from 'app/shared/models/mondoAccount';
import { NavigationService } from 'app/core/navigation.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { NotificationCenterComponent } from 'app/notifications-new/components/notification-center/notification-center.component';
import { SsoAuthService } from 'app/core/sso-auth.service';
import { acaConfig } from 'aca-config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  navigationItems$: Observable<INavigationItem[]>;
  @Output() openSideNavEvent = new EventEmitter();
  routes = MondoRoutes;
  routingModel = RoutingModel;
  accountType: AccountType = AccountType.scientist;
  account: MondoAccount;
  private containerRef: ViewContainerRef;
  private notificationCenterComponentRef: ComponentRef<NotificationCenterComponent>;
  private get notificationCenter() {
    return this.containerRef;
  }
  @ViewChild('notificationCenter', { static: false, read: ViewContainerRef })
  private set notificationCenter(r) {
    this.containerRef = r;
    if (this.containerRef && !this.notificationCenterComponentRef) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const factory =
      this.resolver.resolveComponentFactory<NotificationCenterComponent>(
        NotificationCenterComponent
      );
    this.notificationCenterComponentRef =
      this.notificationCenter.createComponent(factory);
  }

  userStatus$: Observable<UserStatus>;
  loggedIn = false;
  showBorderedButtons: boolean;
  showCapitalizedButtons: boolean;
  showNavSpacers: boolean;
  showAccountChangerInNavBar: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private routingService: RoutingService,
    private resolver: ComponentFactoryResolver,
    private navigationService: NavigationService,
    private ssoAuthService: SsoAuthService
  ) {}

  ngOnInit() {
    this.navigationItems$ = this.navigationService.navItems$;
    const accountTypeFromUrl = this.getAccountTypeFromUrl();
    this.accountType = accountTypeFromUrl;
    this.account = MondoAccount.fromAccountType(this.accountType);
    this.userStatus$ = this.authService.getUserStatus$();
    this.showBorderedButtons = acaConfig.showBorderedButtonsInNavBar;
    this.showCapitalizedButtons = acaConfig.showCapitalizedButtonsInNavBar;
    this.showNavSpacers = acaConfig.showNavSpacersInNavBar;
    this.showAccountChangerInNavBar = acaConfig.showAccountChangerInNavBar;
  }

  private getAccountTypeFromUrl() {
    const urlParts = this.router.routerState.snapshot.url.split('/');
    const accountType = +urlParts[urlParts.length - 1];
    return accountType ? +accountType : AccountType.scientist;
  }

  signOut() {
    this.ssoAuthService.logout();
    this.authService.signOut();
  }

  public isAdmin() {
    return this.authService.isAdmin;
  }

  public onSideNavMenuClicked() {
    this.openSideNavEvent.emit();
  }

  isUserOfScientistType(type: AccountType) {
    return AuthService.isUserOfScientistType(type);
  }

  getChangeAccountTypeText(accountType: number) {
    return this.isUserOfScientistType(accountType) ? 'iAmACompany' : 'iAmAUser';
  }

  loggedInLogoClick() {
    // this.routingService.goToAbout();
    this.navigateToUserDefault();
  }

  navigateToUserDefault() {
    this.routingService.navigateToUserDefault(this.authService.getUserStatus());
  }

  navigateToSolutions() {
    window.open('https://solutions.academondo.com');
  }

  navigateToAdminPage() {
    this.routingService.navigateToRoute(this.routes.adminPage);
  }

  changeAccountType(currentAccountType: number) {
    this.accountType = this.getOppositeAccountType(currentAccountType).type;
    this.account = MondoAccount.fromAccountType(this.accountType);
    this.routingService.navigateToUrlWithDataArray(MondoRoutes.landingPage, [
      this.accountType,
    ]);
  }

  private getOppositeAccountType(accountType: number): MondoAccount {
    return this.isUserOfScientistType(accountType)
      ? MondoAccount.fromAccountType(AccountType.industry)
      : MondoAccount.fromAccountType(AccountType.scientist);
  }

  public getProfileFromAccountType(accountType: number) {
    this.routingService.navigateToUserProfile(accountType);
  }

  onAuxClick(e, route) {
    e.preventDefault();
    if (e.which === 2) {
      window.open(environment.academondoUrl + '/' + route);
    }
  }

  navigateToExternal(e, route: string) {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = route;
    link.target = '_blank';
    link.click();
  }

  showTerms() {
    window.open(acaConfig.termsLink, '_blank');
  }

  showPrivacyPolicy() {
    window.open(acaConfig.privacyPolicyLink, '_blank');
  }

  trackByFn(index, item) {
    return item.title;
  }
}
