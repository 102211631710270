import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  MondoFormArray,
  MondoFormBuilder,
  MondoFormGroup,
} from 'app/core/mondo-form-builder';
import { Country } from 'app/shared/models';
import { PickerMode } from 'app/shared/components/date-picker/date-picker.component';
import { DateRangeOptions } from 'app/shared/components/date-range-picker/date-range-picker.component';
import { Experience } from 'app/shared/models/cv/experience';
import { WorkExperience } from 'app/shared/models/cv/work-experience';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkExperienceComponent {
  @Input() form: MondoFormGroup<Experience>;
  public activeIndex: number;
  readonly dateRangOptions: DateRangeOptions = {
    allowFutureDates: false,
    allowPastDates: true,
    openEndedCheckboxText: 'currentEmployment',
    hideStartNowCheckBox: true,
    pickerMode: PickerMode.month,
  };

  constructor(private formBuilder: MondoFormBuilder) {}

  get workExperienceArray(): MondoFormArray<WorkExperience[]> {
    return this.form.getSafeArray((x) => x.workExperiences);
  }

  getCountryFormGroup(
    formGroup: MondoFormGroup<WorkExperience>
  ): MondoFormGroup<Country> {
    return formGroup.getSafeGroup((x) => x.country);
  }

  getHeaderText(formGroup: MondoFormGroup<WorkExperience>): string {
    return formGroup.getSafe<string>((x) => x.jobTitle);
  }

  getDescription(workExperiece: MondoFormGroup<WorkExperience>) {
    return workExperiece.getSafeControl((x) => x.description);
  }

  add(): void {
    this.workExperienceArray.push(
      this.formBuilder.group<WorkExperience>(new WorkExperience())
    );
    this.activeIndex = this.workExperienceArray.length - 1;
  }

  delete(i: number, text: string): void {
    if (confirm(text)) {
      this.workExperienceArray.removeAt(i);
      this.activeIndex = this.workExperienceArray.length - 1;
    }
  }

  trackByFn(index, item) {
    return item.uid;
  }
}
