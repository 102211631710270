import {
  MondoFormGroup,
  MondoFormBuilder,
  MondoFormArray,
} from 'app/core/mondo-form-builder';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { LanguageProficiency } from '../../../../shared/models/languages/language-proficiency';
import { Languages } from '../../../../shared/models/languages/languages';
import { LanguageDetails } from '../../../../shared/models/languages/language-details';
import { LanguageMultiSelector } from '../../../../shared/models/languages/language-multiselector';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  @Input() form: MondoFormGroup<Languages>;
  public activeIndex: number;
  languageProficiencies = LanguageProficiency;
  public languageProficiency = [
    {
      id: LanguageProficiency.beginner,
      name: LanguageProficiency.beginner,
      selected: false,
    },
    {
      id: LanguageProficiency.conversational,
      name: LanguageProficiency.conversational,
      selected: false,
    },
    {
      id: LanguageProficiency.proficient,
      name: LanguageProficiency.proficient,
      selected: false,
    },
    {
      id: LanguageProficiency.fluent,
      name: LanguageProficiency.fluent,
      selected: true,
    },
    {
      id: LanguageProficiency.bilingual,
      name: LanguageProficiency.bilingual,
      selected: false,
    },
    {
      id: LanguageProficiency.native,
      name: LanguageProficiency.native,
      selected: false,
    },
  ];

  constructor(private formBuilder: MondoFormBuilder) {}

  ngOnInit() {}

  get languagesArray(): MondoFormArray<LanguageDetails[]> {
    return this.form.getSafeArray((x) => x.languages);
  }

  getLanguageFormGroup(
    formGroup: MondoFormGroup<LanguageDetails>
  ): MondoFormGroup<LanguageMultiSelector> {
    return formGroup.getSafeGroup((x) => x.language);
  }

  getHeaderText(
    formGroup: MondoFormGroup<LanguageDetails>,
    levelText: string,
    languageText: string
  ): string {
    const language = formGroup.getSafe<LanguageMultiSelector>(
      (x) => x.language
    );

    if (language && language.name) {
      return `${language.name} - ${levelText}`;
    } else {
      return languageText;
    }
  }

  getLevel(formGroup: MondoFormGroup<LanguageDetails>) {
    return formGroup.getSafe<string>((x) => x.level);
  }

  add(): void {
    this.languagesArray.push(this.formBuilder.group(new LanguageDetails()));
    this.activeIndex = this.languagesArray.length - 1;
  }

  delete(i: number, confirmText: string): void {
    if (confirm(confirmText)) {
      this.languagesArray.removeAt(i);
      this.activeIndex = this.languagesArray.length - 1;
    }
  }

  trackByFn(index, item) {
    return item.key || item.id || item.uid;
  }
}
