import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class CommonStepperService {

  public networkSearchContainer: ViewContainerRef;

  setSearchNetworkContainer(element: ViewContainerRef) {
    this.networkSearchContainer = element;
  }

  get getSearchNetworkContainer(): ViewContainerRef {
    return this.networkSearchContainer;
  }

}
