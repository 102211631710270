<div class="site-viewer-element site-viewer-header">
  <h2 class="site-logo" *ngIf="site?.siteInfo?.nameSite && !logoUrl">
    {{ site?.siteInfo?.nameSite }}
  </h2>
  <div class="site-logo" *ngIf="logoUrl">
    <img mat-card-image [src]="logoUrl" />
  </div>
  <div class="site-catch-phrase-container">
    <p class="site-catch-phrase" *ngIf="site?.siteInfo?.catchPhrase">
      {{ site?.siteInfo?.catchPhrase }}
    </p>
  </div>
  <div class="profile-card__title" *ngIf="addressSite">
    <mat-icon>room</mat-icon><span>&nbsp;&nbsp;{{ addressSite }}</span>
  </div>
  <div class="profile-card__title" *ngIf="site?.siteInfo?.phone">
    <mat-icon>phone</mat-icon
    ><span
      >&nbsp;&nbsp;<a [href]="'tel:' + site?.siteInfo?.phone" target="_blank">{{
        site?.siteInfo?.phone
      }}</a></span
    >
  </div>
  <div
    class="profile-card__title"
    *ngIf="site?.siteInfo?.email"
    matTooltipPosition="left"
    matTooltip="{{ 'SiteEmail' | preventHardcoding }}"
  >
    <mat-icon>email</mat-icon
    ><span
      >&nbsp;&nbsp;<a
        [href]="'mailto:' + site?.siteInfo?.email"
        target="_blank"
        >{{ site?.siteInfo?.email }}</a
      ></span
    >
  </div>
  <div
    class="profile-card__title"
    *ngIf="site?.siteInfo?.contactEmail"
    matTooltipPosition="left"
    matTooltip="{{ 'contactEmail' | preventHardcoding }}"
  >
    <mat-icon>email</mat-icon
    ><span
      >&nbsp;&nbsp;<a
        [href]="'mailto:' + site?.siteInfo?.contactEmail"
        target="_blank"
        >{{ site?.siteInfo?.contactEmail }}</a
      ></span
    >
  </div>
  <div class="profile-card__title" *ngIf="site?.siteInfo?.websiteUrl">
    <mat-icon>web</mat-icon
    ><span
      >&nbsp;&nbsp;<a
        href="{{ site?.siteInfo?.websiteUrl | safeAngularLink }}"
        target="_blank"
        >{{ 'visitOfficialWebsite' | preventHardcoding }}</a
      ></span
    >
  </div>
  <div class="profile-card__title" *ngIf="site?.siteInfo?.linkedin">
    <span>
      <img class="linkedin-icon" src="/assets/landing-page/linkedin.png" /><a
        href="{{ site?.siteInfo?.linkedin | safeAngularLink }}"
        target="_blank"
        >{{ 'linkedin' | preventHardcoding }}</a
      >
    </span>
  </div>
</div>
