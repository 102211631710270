import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { IBaseStepItem } from '../../model/IBaseStepItem';
import { ItemType } from 'app/stepper/shared/model/ItemType';

@Component({
  selector: 'app-properties-dialog',
  templateUrl: './properties-dialog.component.html',
  styleUrls: ['./properties-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesDialogComponent implements OnInit {
  @Input() item: IBaseStepItem;
  @Input() newItem = false;
  @Input() itemType: ItemType;
  name: string;
  constructor(public dialogRef: MatDialogRef<PropertiesDialogComponent>) {}

  ngOnInit() {
    this.name = _.cloneDeep(this.item.name);
  }

  save() {
    if (this.name !== '') {
      this.item.name = this.name;
      this.dialogRef.close(this.item);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  getPropertiesTitle() {
    switch (this.itemType) {
      case ItemType.CV:
        return this.newItem ? 'giveTheCVAName' : 'renameCV';
      case ItemType.Job:
        return this.newItem ? 'giveTheJobAName' : 'renameJob';
      case undefined:
        return '';
    }
  }
}
