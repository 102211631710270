export class FenerumAccount implements IJsonFenerumAccount {

  public static fromJson(
    {
       company_name,
       code,
       legal_address,
       legal_zipcode,
       legal_city,
       legal_country,
       uuid
    }: IJsonFenerumAccount
  ): FenerumAccount {
    return new FenerumAccount(
      company_name,
       code,
       legal_address,
       legal_zipcode,
       legal_city,
       legal_country,
       uuid
    );
  }

  public static toJson(account: FenerumAccount): IJsonFenerumAccount {
    return {
      company_name: account.company_name,
      code: account.code,
      legal_address: account.legal_address,
      legal_zipcode: account.legal_zipcode,
      legal_city: account.legal_city,
      legal_country: account.legal_country,
      uuid: account.uuid
    };
  }

  constructor(
    public company_name: string,
    public code: string,
    public legal_address: string,
    public legal_zipcode: number,
    public legal_city: string,
    public legal_country: string,
    public uuid?: string) { }
}

interface IJsonFenerumAccount {
  company_name: string;
  code: string;
  legal_address: string;
  legal_zipcode: number;
  legal_city: string;
  legal_country: string;
  uuid?: string;
}
