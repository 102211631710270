import { Component, OnInit } from '@angular/core';
import { JobService } from './services/job.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { take, debounceTime, tap, finalize } from 'rxjs/operators';
import { Job } from 'app/stepper/job/model/job';
import { AuthService } from 'app/core/auth.service';
import { RoutingService } from 'app/core/routing.service';
import { MondoRoutes, RoutingModel } from 'app/app.routing-model';
import { ActionItem } from 'app/shared/models/action-item';
import { JobStepIndex } from '../shared/model/stepIndex';
import { IntroDialogComponent } from '../shared/components/intro-dialog/intro-dialog.component';
import { UserService } from '../../core/user.service';
import { ItemType } from 'app/stepper/shared/model/ItemType';
import { PropertiesDialogComponent } from 'app/stepper/shared/components/properties-dialog/properties-dialog.component';
import { JobViewerComponent } from 'app/shared/components/job-viewer/job-viewer.component';
import { Observable } from 'rxjs';
import { JobFormService } from './services/job-form.service';
import { ConfirmDialogComponent } from 'app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  job$: Observable<Job>;
  routeParams$: Observable<Params>;
  allJobs$: Observable<Job[]>;
  selectedStep: JobStepIndex = JobStepIndex.start;
  actionBarItems: ActionItem[] = [];
  showViewer = true;
  showBuilder = true;

  rootUrl = RoutingModel.jobBuilder.route;
  jobData$: Observable<Job>;
  jobId: string;

  constructor(
    private authService: AuthService,
    private jobService: JobService,
    private jobFormService: JobFormService,
    private userService: UserService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (
      this.authService.getCurrentUser().startUpInfo.hasSeenJobInfo === false
    ) {
      this.openStartInfo();
    }
    this.selectedStep = +this.route.snapshot.paramMap.get('step');
    this.setupActionBar();
    this.allJobs$ = this.jobService.getDraftJobs();
    this.routeParams$ = this.route.params.pipe(
      tap((params) => {
        const key = this.route.snapshot.paramMap.get('jobId');
        if (key !== this.jobId || !key) {
          this.jobId = key;
          this.job$ = this.jobService.getDraftJob(key);
          this.doesJobExist();
        }
      })
    );
  }

  private doesJobExist() {
    return this.jobService.jobExists(this.jobId).then((exists) => {
      if (!exists) {
        return this.allJobs$
          .pipe(take(1), debounceTime(1000))
          .subscribe((jobs) => {
            if (this.userHasNoJobs(jobs)) {
              if (!this.authService.canCreateJobFromSite) {
                this.jobService.createJob().then((jobKey) => {
                  this.loadJob(jobKey);
                });
              } else if (this.authService.canCreateMultipleSites) {
                this.authService.notEnoughPermission(
                  'youHaveNoJobsCreateOneFromTheListOfCompanies',
                  7000
                );
                this.routingService.ifNoJobNavigation(
                  this.authService.getUserStatus()
                );
              } else {
                this.jobService.createJob().then((jobKey) => {
                  this.loadJob(jobKey);
                });
              }
            } else {
              const lastUpdatedJob = this.getLastUpdatedJob(jobs);
              lastUpdatedJob
                ? this.loadJob(lastUpdatedJob.key)
                : this.loadJob(jobs[0].key);
            }
          });
      } else {
        this.loadJob(this.jobId);
      }
    });
  }

  private getLastUpdatedJob(jobs: Job[]) {
    return jobs
      .filter((job) => job && !!job.lastUpdate)
      .sort(
        (job1, job2) => job2.lastUpdate.getTime() - job1.lastUpdate.getTime()
      )[0];
  }

  private userHasNoJobs(jobs: Job[]) {
    return jobs.length <= 0;
  }

  private loadJob(key: string) {
    this.routingService.navigateToRoute(RoutingModel.jobBuilder.route, [
      key,
      this.selectedStep,
    ]);
  }

  private setupActionBar() {
    this.actionBarItems = [
      // new ActionItem(hardcodedValues.publishMenu, 'publish', this.publishJob.bind(this)),
      new ActionItem('rename', 'edit', this.openProperties),
      new ActionItem('delete', 'delete', this.deleteJob),
    ];
  }

  private openStartInfo() {
    const dialogRef = this.dialog.open(IntroDialogComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.itemType = ItemType.Job;

    dialogRef.afterClosed().subscribe(() => {
      const user = this.authService.getCurrentUser();
      user.startUpInfo.hasSeenJobInfo = true;
      this.userService.updateUser(user);
    });
  }

  public publishJob(job: Job) {
    // this.jobpublishService.publishJob(job ? job : this.job);
  }

  onStepChanged(index: number) {
    this.selectedStep = index;
  }

  public openProperties = (job?: Job, newJob?: boolean) => {
    const dialogRef = this.dialog.open(PropertiesDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.itemType = ItemType.Job;
    dialogRef.componentInstance.item = job;
    dialogRef.componentInstance.newItem = !!newJob;
    dialogRef.afterClosed().subscribe((jobToUpdate) => {
      this.jobService.updateDraftJob(jobToUpdate);
      this.jobFormService.patchJob(jobToUpdate);
    });
  };

  onJobChanged(job: Job) {
    if (job) {
      this.jobService.updateDraftJob(job);
    }
  }

  addNewJob() {
    this.jobService.createJob().then((key) => {
      this.jobService
        .getDraftJob(key)
        .pipe(
          take(1),
          finalize(() => this.loadJob(key))
        )
        .subscribe((job) => {
          this.openProperties(job, true);
        });
    });
  }

  public deleteJob = (job: Job) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
    });
    dialogRef.componentInstance.title = 'deleteJob';
    dialogRef.componentInstance.text = 'areYouSureWantToDeleteX';
    dialogRef.componentInstance.textX = 'job';
    dialogRef.componentInstance.yesText = 'delete';
    dialogRef.componentInstance.noText = 'cancel';
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((deleteJob) => {
        if (deleteJob) {
          if (job.status.canUnpublish()) {
            this.jobService.unpublishJob(job);
          }
          this.jobService
            .removeJob(job.key)
            .then(() => this.loadJob(MondoRoutes.none));
        } else {
          return;
        }
      });
  };

  dublicateJob(job: Job) {
    this.jobService.dublicateJob(job);
  }

  editJob(job: Job) {
    this.openProperties(job, false);
  }

  showBuilderChanged(state: boolean) {
    this.showBuilder = state;
  }

  showViewerChanged(state: boolean) {
    this.showViewer = state;
  }

  showJob(job: Job): void {
    const dialogRef = this.dialog.open(JobViewerComponent, {
      width: '750px',
    });
    dialogRef.componentInstance.job = job;
  }
}
