<div class="wrapper">
  <div class="header" *ngIf="header">
    <h2>{{ header | preventHardcoding }}</h2>
  </div>

  <br />

  <div *ngIf="text">
    <p>{{ text | preventHardcoding }}</p>
  </div>
  <div *ngIf="link">
    <a class="cool-link" [href]="link | preventHardcoding" target="_blank">{{
      link | preventHardcoding
    }}</a>
  </div>

  <div class="footer">
    <button #infoBtnOk mat-raised-button color="primary" (click)="close()">
      {{ 'Ok' | preventHardcoding }}
    </button>
  </div>
</div>
