const region:
  | 'us-central1'
  | 'us-east1'
  | 'us-east4'
  | 'europe-west1'
  | 'europe-west2'
  | 'asia-east2'
  | 'asia-northeast1' = 'us-central1';
const firebase = {
  apiKey: 'AIzaSyAr9h3wAYrBTrvvtLsFGLy0vf_tr9A_qR0',
  authDomain: 'sales-preview-demo.firebaseapp.com',
  databaseURL: 'https://sales-preview-demo.firebaseio.com',
  projectId: 'sales-preview-demo',
  storageBucket: 'sales-preview-demo.appspot.com',
  messagingSenderId: '1001780611588',
  appId: '1:1001780611588:web:e508833117d7638d1a0351',
  measurementId: 'G-LJBCEPW0KZ',
};
const parseDomain = 'http://localhost:1337';
const parse = {
  serverURL: `${parseDomain}/parse`,
};
const functionsBaseUrl = `https://${region}-${firebase.projectId}.cloudfunctions.net/`;
export const environment = {
  region: region,
  production: false,
  stripeKey: 'pk_test_KtxHbJxtqkRYQZDiDRNqqiAp',
  firebase: firebase,
  parse: parse,
  googleMapsApiKey: 'AIzaSyD_rwXyADXPZNvwLbSCC5W1fUcO0slxcYw',
  AGMAPIKEY: 'AIzaSyDhgnpBnuPVBPFeLXWWZL82z7KACK--L3s',
  academondoIdAtfenerum: '68504844-ccaa-4e50-9376-d26f53156cd1',
  fenerumStripeCardUrl: 'https://app.fenerum.com/billing/create_card/stripe/',
  sendgridEmail: functionsBaseUrl + 'sendgridEmail',
  sendInviteEmail: functionsBaseUrl + 'sendInviteEmail',
  resetNotificationSettings: 'resetNotificationSettings',
  checkAndPublishAllJobsNormal: 'checkAndPublishAllJobsNormal',
  checkAndPublishAllCVsNormal: 'checkAndPublishAllCVsNormal',
  checkAndPublishAllSitesNormal: 'checkAndPublishAllSitesNormal',
  baseFunctionsUrl: functionsBaseUrl,
  academondoUrl: 'https://preview.academondo.com',
  paymentHandler: 'paymentHandler',
  search: 'search',
  userAdministrationHandler: 'userAdministrationHandler',
  handleNetworkMemberAction: 'handleNetworkMemberAction',
  searchHandler: 'searchHandler',
  membershipHandler: 'membershipHandler',
  attendanceHandler: 'attendanceHandler',
  notificationHandler: 'notificationHandler',
  // authHandler: 'authHandler',
  requestAccessToken: 'requestAccessToken',
  requestAuthCode: 'requestAuthCode',
  requestUserInfo: 'requestUserInfo',
  ssoHandler: 'ssoHandler',

  solrMembers: `membersSearchDemo`,
  solrBillboard: `billboardSearchDemo`,
  solrNetwork: `networkSearchDemo`,
  solrCv: `cvSearchDemo`,
  solrSite: `siteSearchDemo`,
  solrV2: `search-demo`,

  auth: {
    // issuer: 'https://auths.tobiaswaagefeldballe.dk/auth/realms/hbp',
    issuer: 'https://iam.ebrains.eu/auth/realms/hbp',
    realm: 'hbp',
    clientId: 'academondo-demo-1', // The "Auth Code + PKCE" client
    responseType: 'code',
    // redirectUri: window.location.origin + '/login/',
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    scope: 'openid profile email roles team group', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 5000, // For faster testing
    timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  },
};
