<div *ngIf="startDate">
  <app-icon-title
    title="{{ 'employmentPeriod' | preventHardcoding }}"
    iconPath="/assets/icons/Calendar1.svg"
  >
  </app-icon-title>

  <p *ngIf="openEnded">{{ 'permanentPosition' | preventHardcoding }}</p>
  <p *ngIf="!openEnded">{{ 'temporaryPosition' | preventHardcoding }}</p>

  <table class="labeled-data-table smaller-light">
    <tr *ngIf="startDate">
      <td>{{ 'starting' | preventHardcoding }}:</td>
      <td *ngIf="startNow">{{ 'asSoonAsPossible' | preventHardcoding }}</td>
      <td *ngIf="!startNow"><app-date [date]="startDate"></app-date></td>
    </tr>
    <tr *ngIf="endDate && !openEnded">
      <td>{{ 'ending' | preventHardcoding }}:</td>
      <td><app-date [date]="endDate"></app-date></td>
    </tr>
  </table>
</div>
