import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-step-footer',
  templateUrl: './step-footer.component.html',
  styleUrls: ['./step-footer.component.scss']
})
export class StepFooterComponent implements OnInit {
  @Input() hideNext: boolean;
  @Input() hidePrev: boolean;
  @Input() vertical = true;

  constructor() { }

  ngOnInit() {
  }

}
