import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Job } from 'app/stepper/job/model/job';
import { JobService } from '../../services/job.service';
import { WantedStatus } from '../../model/wantedStatus';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth.service';
import { DirectMatchService } from 'app/core/direct-match.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/components/confirm-dialog/confirm-dialog.component';
import { RoutingService } from 'app/core/routing.service';
import { RoutingModel } from 'app/app.routing-model';
import { DirectMatchComponent } from 'app/shared/components/direct-match/direct-match.component';

@Component({
  selector: 'app-publish-job-dialog',
  templateUrl: './publish-job-dialog.component.html',
  styleUrls: ['./publish-job-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishJobDialogComponent implements OnInit {
  @Input() draftJob: Job;
  publishedJob$: Observable<Job>;
  canPublishJob$: Observable<boolean>;
  numberOfPubishedJobs$: Observable<number>;
  allowedNumberOfJobs$: Observable<number>;

  constructor(
    private dialogRef: MatDialogRef<PublishJobDialogComponent>,
    private jobService: JobService,
    private authService: AuthService,
    private routingService: RoutingService,
    private directMatchService: DirectMatchService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // this.publishedJob$ = this.jobService.getPublishedJob(this.draftJob.key);
    this.canPublishJob$ = this.jobService.canPublishJob();
    // this.numberOfPubishedJobs$ = this.jobService.getNumberPublishedJobs();
    // this.allowedNumberOfJobs$ = this.jobService.getAllowedNumberOfJobs();
  }

  onPublishClicked() {
    this.dialogRef.close(WantedStatus.publish);
  }

  onRepublishClicked() {
    this.dialogRef.close(WantedStatus.republish);
  }

  onUnpublishClicked() {
    this.dialogRef.close(WantedStatus.unpublish);
  }

  canPublish(job: Job) {
    return (
      _.isDate(job.jobVisibilityPeriod.startDate) &&
      _.isDate(job.jobVisibilityPeriod.endDate)
    );
  }

  private minReqForPublish(job): boolean {
    const text = this.jobService.minRequirementForPublish(job);
    if (text.length > 0) {
      this.authService.notEnoughPermission(text);
      return false;
    } else if (!this.authService.canPublishJob) {
      this.authService.notEnoughPermission();
      return false;
    } else {
      return true;
    }
  }

  directMatch(job) {
    if (this.minReqForPublish(job)) {
      this.openDirectMatch(job);
    }
  }

  public async openDirectMatch(job: Job) {
    const dm = await this.directMatchService.getCurrentDraftDM(job);
    const dialogRef = this.dialog.open(DirectMatchComponent, {
      minWidth: '300px',
      minHeight: '250px',
      maxWidth: '515px',
      maxHeight: '90vh',
    });
    dialogRef.componentInstance.job = job;
    dialogRef.componentInstance.directMatch = dm;
    // dialogRef.componentInstance.yesText = 'delete';
    // dialogRef.componentInstance.noText = 'cancel';

    // return new Promise((resolve, reject) => {
    //   this.subsciptions.push(
    //     dialogRef.afterClosed()
    //       .pipe(take(1))
    //       .subscribe(deleteJob => {
    //         if (deleteJob) {
    //           this.removeItemAndStatus(
    //             id,
    //           ).then(() => resolve(true));
    //         } else {
    //           resolve(false);
    //         }
    //       })
    //   );
    // });
  }
}
