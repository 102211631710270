import { Injectable } from '@angular/core';
import { ParseService } from 'app/core/parse.service';
import { IDatabase } from '../../shared-services/db-access/IDatabase';

@Injectable()
export class ParseDAO implements IDatabase {
  constructor(private db: ParseService) {}
  getAll<T>(table) {
    return [];
  }

  getById<T>(id, table) {
    return {} as T;
  }

  list<T>(table: string, cb?: (ref: any) => any) {
    return this.db.list<T>(table);
  }

  list$<T>(table: string, cb?: (ref: any) => any) {
    return this.db.list<T>(table) as any; // get observable
  }

  object<T>(table: string) {
    return this.db.getObject<T>('id', table);
  }

  object$<T>(table: string) {
    return this.db.getObject<T>('id', table) as any; // get observable
  }

  createPushId() {
    alert('not implemented');
    return 'not implemented';
  }

  ref(table: string) {
    alert('not implemented');
  }
}
