
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PubMedESearchResponse, PubMedArticleResponse } from '../shared/models/pubmed';
const xml2sj = require('xml2js');

@Injectable()
export class PubmedService {
  parser;
  constructor(private httpClient: HttpClient) {
    this.parser = new xml2sj.Parser({explicitArray: false});
   }

  public getArticleIdsFromTerm(term: string, retmax = 350, retstart = 0, sort = 'relevance' ) {
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&sort=${sort}&retstart=${retstart}&retmax=${retmax}&term=${term}`;
    const idStream: Observable<string[]> = this.httpClient.get(url)
      .pipe(
        map(response => {
          const responseAsJson = response as PubMedESearchResponse;
          const ids = responseAsJson.esearchresult.idlist;
          if (ids) {
            return ids;
          } else {
            throw new Error(responseAsJson.esearchresult.ERROR);
          }
        }),
        catchError((e) => {
          return observableThrowError('Pubmed failed to repond with a list of ID\'s\nPlease try again');
        })
      );
    return idStream;
  }

  public getArticlesFromIds(ids: string[]): Observable<any> {
    if (!ids.length) {
      return observableThrowError('No articles found with that search term\nPlease try again');
    }
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&retmode=xml&id=${ids}`;
    const articleStream = this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(
        map((response: string) => {
          let result: PubMedArticleResponse;
          this.parser.parseString(response, function (e, res) {
            result = res;
          });
          return result.PubmedArticleSet.PubmedArticle;
        }),
        catchError((e) => {
          return observableThrowError('Pubmed failed to repond with articles\nPlease try again');
        })
      );
    return articleStream;
  }

  public arrayObjectHack<T>(val: T): T[] { // if array has length of 1 it returns object from pubmed
    if (!val) {
      return [];
    }
    if (val instanceof Array) {
      return val;
    }

    if (val instanceof Object) {
      const resultArray: Array<T> = [];
      resultArray.push(val);
      return resultArray;
    }
  }
}
