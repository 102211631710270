<perfect-scrollbar>
  <div>
    <div *ngIf="cv$ | async as cv" class="a4-card" [@enterAnimation]>
      <div [id]="cvSteps.personalDetails" class="cv_personal_info cv_section">
        <div class="row">
          <div
            class="col-md-5 col-5 pdr-0 viewer-contents"
            [ngClass]="{ 'col-md-7': !cv.personalDetails?.pictureUrl }"
          >
            <div class="header_wrap">
              <div
                class="header_title"
                [ngClass]="{
                  'cv-name-small-font': showNameSmall(cv),
                  'cv-name-smaller-font': showNameSmaller(cv),
                  'cv-name-smallest-font': showNameSmallest(cv)
                }"
              >
                {{ cv.personalDetails?.firstName }}
                {{ cv.personalDetails?.middleName }}
                {{ cv.personalDetails?.lastName }}
                <small
                  class="header_subtitle"
                  *ngIf="cv.personalDetails?.academicTitle"
                >
                  <span>{{ cv.personalDetails?.academicTitle }}</span>
                </small>
                <small
                  class="header_subtitle"
                  *ngIf="
                    !cv.personalDetails?.academicTitle &&
                    getRecentTitle(cv) as recentTitle
                  "
                >
                  <span>{{ recentTitle }}</span>
                </small>
                <small
                  class="header_subtitle"
                  *ngIf="cv.personalDetails?.companyName"
                >
                  <span>{{ cv.personalDetails?.companyName }}</span>
                </small>
                <small
                  class="header_subtitle"
                  *ngIf="
                    !cv.personalDetails?.companyName &&
                    getRecentLocation(cv) as recentLoc
                  "
                >
                  <span>{{ recentLoc }}</span>
                </small>
                <small
                  class="header_subtitle"
                  *ngIf="
                    cv.personalDetails?.department &&
                    ('showDepartmentInPersonalDetails' | preventHardcoding)
                  "
                >
                  <span>{{ cv.personalDetails?.department }}</span>
                </small>
                <!-- <p class="saved-comment hide-on-print">
                  Everything you see here is saved, but not shown to the public until you press save
                </p> -->
              </div>
            </div>
          </div>

          <div
            class="col-md-5 col-5 details_wrap viewer-contents"
            [ngClass]="{
              'cv-personal-details-pull-right': !cv.personalDetails?.pictureUrl
            }"
          >
            <label class="t_t_u smaller-regular">{{
              'personalDetails' | preventHardcoding
            }}</label>
            <ul
              class="p_d_list smallest-light"
              [ngClass]="{
                'cv-personal-details-smaller-font':
                  getEmailLength(cv.personalDetails?.contactInfo?.email) > 28,
                'cv-personal-details-smallest-font':
                  getEmailLength(cv.personalDetails?.contactInfo?.email) > 31
              }"
            >
              <li class="row" *ngIf="cv.personalDetails?.dob">
                <div class="col-4">
                  {{ 'dateOfBirth' | preventHardcoding }}:
                </div>
                <div class="col-8">
                  <span
                    ><app-date [date]="cv.personalDetails?.dob"></app-date
                  ></span>
                </div>
              </li>
              <li
                class="row"
                *ngIf="
                  cv.personalDetails?.address?.city ||
                  cv.personalDetails?.address?.country?.code
                "
              >
                <div class="col-4">{{ 'address' | preventHardcoding }}:</div>
                <div class="col-8">
                  <span *ngIf="cv.personalDetails?.address.city">{{
                    cv.personalDetails?.address.city
                  }}</span>
                  <span
                    *ngIf="
                      cv.personalDetails?.address.city &&
                      cv.personalDetails?.address.country.code
                    "
                    >,
                  </span>
                  <span *ngIf="cv.personalDetails?.address.country.code">{{
                    cv.personalDetails?.address.country.code
                  }}</span>
                </div>
              </li>
              <li class="row" *ngIf="cv.personalDetails?.contactInfo?.email">
                <div class="col-4">{{ 'Email' | preventHardcoding }}:</div>
                <div class="col-8">
                  {{ cv.personalDetails?.contactInfo?.email }}
                </div>
              </li>
              <li class="row" *ngIf="cv.personalDetails?.contactInfo?.phone">
                <div class="col-4">{{ 'phone' | preventHardcoding }}:</div>
                <div class="col-8">
                  {{ cv.personalDetails?.contactInfo?.phone }}
                </div>
              </li>
              <li
                class="row"
                *ngIf="
                  cv.personalDetails?.contactInfo?.website &&
                  cv.personalDetails?.contactInfo?.website.trim().length > 0
                "
              >
                <div class="col-4">
                  {{ 'visitOfficialWebsite' | preventHardcoding }}:
                </div>
                <div class="col-8">
                  {{ cv.personalDetails?.contactInfo?.website }}
                </div>
              </li>
              <li class="row" *ngIf="cv.personalDetails?.linkedin">
                <div class="col-4">{{ 'linkedin' | preventHardcoding }}:</div>
                <div class="col-8">
                  <a
                    href="{{ cv.personalDetails?.linkedin | safeAngularLink }}"
                    target="_blank"
                    >{{ 'linkedin' | preventHardcoding }}</a
                  >
                </div>
              </li>
              <ng-container *ngIf="'showTwitter' | preventHardcoding">
                <li class="row" *ngIf="cv.personalDetails?.twitter">
                  <div class="col-4">{{ 'twitter' | preventHardcoding }}:</div>
                  <div class="col-8">
                    <a
                      href="{{ cv.personalDetails?.twitter | safeAngularLink }}"
                      target="_blank"
                      >{{ 'twitter' | preventHardcoding }}</a
                    >
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="'showGithub' | preventHardcoding">
                <li class="row" *ngIf="cv.personalDetails?.github">
                  <div class="col-4">{{ 'github' | preventHardcoding }}:</div>
                  <div class="col-8">
                    <a
                      href="{{ cv.personalDetails?.github | safeAngularLink }}"
                      target="_blank"
                      >{{ 'github' | preventHardcoding }}</a
                    >
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
          <div
            class="col-md-2 col-2 viewer_image text-center viewer-contents"
            *ngIf="cv.personalDetails?.pictureUrl"
          >
            <img
              alt="profile picture"
              *ngIf="cv.personalDetails?.pictureUrl"
              mat-card-image
              src="{{ cv.personalDetails?.pictureUrl }}"
            />
          </div>
        </div>
        <div class="divider clearfix"></div>
      </div>
      <div
        [id]="cvSteps.introText"
        *ngIf="cv.intro?.text && !cv.intro.exclude"
        class="cv_section"
      >
        <div class="intro_text" [innerHTML]="cv.intro.text | safeHtml"></div>
        <div class="divider clearfix"></div>
      </div>
      <app-cv-view-education-work-exp
        [id]="cvSteps.education"
        [cv]="cv"
      ></app-cv-view-education-work-exp>
      <div *ngIf="cv.experience">
        <app-cv-view-workshops
          [id]="cvSteps.workshops"
          [cv]="cv"
        ></app-cv-view-workshops>
        <app-cv-view-teaching-exp
          [id]="cvSteps.teaching"
          [cv]="cv"
        ></app-cv-view-teaching-exp>
        <div
          class="skills-wrap cv_section"
          *ngIf="
            cv.experience?.techniques?.length > 0 ||
            cv.experience?.interests?.length > 0 ||
            cv.languages?.languages?.length > 0 ||
            cv.experience?.category0?.length > 0 ||
            cv.experience?.category1?.length > 0 ||
            cv.experience?.category2?.length > 0 ||
            cv.experience?.category3?.length > 0
          "
        >
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Foi' | preventHardcoding) &&
                cv.experience?.interests?.length > 0
              "
            >
              <div class="cv_technical">
                <h5 [id]="cvSteps.fois" class="title_icon normal-regular">
                  {{ 'Fois' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light side-by-side">
                  <li
                    *ngFor="
                      let interest of cv.experience.interests;
                      trackBy: trackByFn
                    "
                  >
                    {{ interest.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Category0' | preventHardcoding) &&
                cv.experience?.category0?.length > 0
              "
            >
              <div class="cv_technical">
                <h5
                  [id]="cvSteps.categories0"
                  class="title_icon normal-regular"
                >
                  {{ 'Categories0' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light">
                  <li
                    *ngFor="
                      let cat0 of cv.experience.category0;
                      trackBy: trackByFn
                    "
                  >
                    {{ cat0.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Category2' | preventHardcoding) &&
                cv.experience?.category2?.length > 0
              "
            >
              <div class="cv_technical">
                <h5
                  [id]="cvSteps.categories2"
                  class="title_icon normal-regular"
                >
                  {{ 'Category2' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light">
                  <li
                    *ngFor="
                      let cat0 of cv.experience.category2;
                      trackBy: trackByFn
                    "
                  >
                    {{ cat0.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Category1' | preventHardcoding) &&
                cv.experience?.category1?.length > 0
              "
            >
              <div class="cv_technical">
                <h5
                  [id]="cvSteps.categories1"
                  class="title_icon normal-regular"
                >
                  {{ 'Categories1' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light">
                  <li
                    *ngFor="
                      let cat1 of cv.experience.category1;
                      trackBy: trackByFn
                    "
                  >
                    {{ cat1.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Category3' | preventHardcoding) &&
                cv.experience?.category3?.length > 0
              "
            >
              <div class="cv_technical">
                <h5
                  [id]="cvSteps.categories3"
                  class="title_icon normal-regular"
                >
                  {{ 'Categories3' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light">
                  <li
                    *ngFor="
                      let cat3 of cv.experience.category3;
                      trackBy: trackByFn
                    "
                  >
                    {{ cat3.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="
                ('Tech' | preventHardcoding) &&
                cv.experience?.techniques?.length > 0
              "
            >
              <div class="cv_technical">
                <h5 [id]="cvSteps.techs" class="title_icon normal-regular">
                  {{ 'Techs' | preventHardcoding }}
                </h5>
                <ul class="double-column smaller-light">
                  <li
                    *ngFor="
                      let technique of cv.experience.techniques;
                      trackBy: trackByFn
                    "
                  >
                    {{ technique.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <app-viewer-languages
                [id]="cvSteps.languages"
                languageTitle="{{ 'Languages' | preventHardcoding }}"
                [languages]="cv.languages"
              ></app-viewer-languages>
            </div>
          </div>
          <div class="divider clearfix"></div>
        </div>
        <div
          [id]="cvSteps.additionalInfo"
          *ngIf="!cv.additionalInfo.exclude && cv.additionalInfo.description"
          class="cv_section"
        >
          <div
            class="intro_text"
            [innerHTML]="cv.additionalInfo.description | safeHtml"
          ></div>
          <div class="divider clearfix"></div>
        </div>
        <app-cv-view-publications
          [id]="cvSteps.publications"
          [cv]="cv"
        ></app-cv-view-publications>
      </div>
      <!-- <div>
        <button mat-raised-button (click)="openChat(cv)">
          {{ 'Openchat' | preventHardcoding }}
        </button>
      </div> -->
      <div class="hide-on-print" *ngIf="canSeePrintableCV()">
        <button mat-raised-button color="primary" (click)="showProfile(cv.key)">
          {{ 'Printable' | preventHardcoding }} {{ 'cv' | preventHardcoding }}
        </button>
      </div>
    </div>
  </div>
</perfect-scrollbar>
