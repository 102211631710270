<ng-container *ngIf="isReady; then ready; else loading"></ng-container>
<ng-container *ngIf="userStatus$| async"></ng-container>
<ng-container *ngIf="isAuthed$ | async"></ng-container>

<ng-template #ready>
  <app-navbar [@enterAnimation]></app-navbar>
  <main [ngClass]="loggedIn ? 'page-wrap' : 'page-wrap-new'">
    <router-outlet #route="outlet"></router-outlet>
  </main>
</ng-template>

<ng-template #loading>
  <app-splash-screen></app-splash-screen>
</ng-template>
